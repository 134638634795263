import { Box, Button, Divider, Input, ListItem, ListItemText, Popover, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import SyncLoader from "react-spinners/SyncLoader";
import { useParams } from 'react-router-dom';
import { UserMoreMenu } from '../user';
import axios from 'axios';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import YouTubeIcon from '@mui/icons-material/YouTube';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFileUrl } from '../../../utils/firebaseUtils';


const LectureResourcesTable = ({ setLoadingTable }) => {
    const [loading, setLoading] = React.useState(true)
    const [users, usersSet] = React.useState()
    const { lectId, batchId } = useParams()
    const [isEdit, setIsEdit] = React.useState(false)

    const [lectureTitle, setLectureTitle] = React.useState()

    const [focus, setFocused] = React.useState(false);
    const [file, setFile] = React.useState()
    const [fileLoading, setFileLoading] = useState(false);
    //Modal
    // const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setIsEdit(true);
    // const handleClose = () => setIsEdit(false);

    //Getting Details
    React.useEffect(() => {

        const token = localStorage.getItem("token");
        async function fetchUsers() {

            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/lecture-resources/${lectId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            console.log(responseJson?.data);
            usersSet(responseJson?.data);
            setLoading(false)
            setLoadingTable(false)


        }

        fetchUsers();
    }, []);
    const handleChange = (e) => {
        const { value } = e.target
        // setLectureTitle(() => {
        //     return { title: value }
        // })
        setLectureTitle(value)

    }
    const changeHandler = async (event) => {
        setFile(await getFileUrl(event.target?.files[0]));


    };
    const [iid, setiid] = React.useState()
    //console.log('IIIId' + iid)
    return (
      <>
        {loading ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            {" "}
            <SyncLoader color="#8080ff" loading={loading} size={20} />
          </Stack>
        ) : (
          <Box spacing={3} sx={{ width: "60%" }}>
            <Typography variant="h4">Lecture Resources</Typography>
            {users?.length != 0 ? (
              users?.map((item, i) => {
                const changeHandler = async (event) => {
                  if (item?.resource_type == "link") {
                    setFile(event.target.value);
                  } else if (item?.resource_type == "pdf") {
                    setFileLoading(true);
                    setFile(await getFileUrl(event.target.files[0]));
                    setFileLoading(false);
                  }
                };
                //console.log(file)
                //console.log(item?.resource_type)
                const handleUpdate = async () => {
                  if(fileLoading){
                    toast.warning("Please wait while the file is being uploaded!");
                    return;
                  }
                  setLoading(true);
                  const token = localStorage.getItem("token");
                  const authToken = token;
                  const payload = {
                    resource_url: file,
                    title: lectureTitle,
                  }
                  const config = {
                    headers: {
                      Authorization: `Bearer ${authToken}`,
                    },
                  };

                  const res = await axios.put(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-lecture-resources/${iid}`, payload, config);
                  
                  if(res.data.response_code===200){
                  setLoading(false);
                  toast.success(res.data.message);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
                else{
                  setLoading(false);
                  toast.error(res.data.message);
                }
                };
                return (
                  <div key={i}>
                    {item.status==="true" ? (
                      <>
                        <Stack
                          key={i}
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            pl={0}
                            pt={2}
                            mb={2}
                            sx={{ position: "relative" }}
                          >
                            {isEdit && iid === item.id ? (
                              item?.resource_type == "link" ||
                              item?.resource_type == "yt_videos" ? (
                                <Input
                                  onChange={changeHandler}
                                  // value={val}
                                  name="link"
                                  // label={item?.upload_file?.fileLoc}
                                  placeholder={item?.resource_url}
                                  type="url"
                                />
                              ) : (
                                <>
                                  <input
                                    style={{
                                      display: "none",
                                    }}
                                    id="choose-file"
                                    type="file"
                                    onChange={changeHandler}
                                  />
                                  <label htmlFor="choose-file">
                                    <FileUploadIcon
                                      sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                          color: "red",
                                        },
                                      }}
                                    />
                                  </label>
                                </>
                              )
                            ) : (
                              <a
                                href={item.resource_url}
                                style={{ color: "black" }}
                              >
                                {item?.resource_type == "link" ? (
                                  <InsertLinkIcon
                                    fontSize="large"
                                    p={1}
                                    style={{
                                      background: "lightGray",
                                      height: "50px",
                                      width: "50px",
                                      padding: "10px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                ) : item?.resource_type == "pdf" ? (
                                  <PictureAsPdfIcon
                                    fontSize="large"
                                    p={1}
                                    style={{
                                      background: "lightGray",
                                      height: "50px",
                                      width: "50px",
                                      padding: "10px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                ) : item?.resource_type == "yt_videos" ? (
                                  <YouTubeIcon
                                    fontSize="large"
                                    p={1}
                                    style={{
                                      background: "lightGray",
                                      height: "50px",
                                      width: "50px",
                                      padding: "10px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                ) : (
                                  <VideoFileIcon
                                    fontSize="large"
                                    p={1}
                                    style={{
                                      background: "lightGray",
                                      height: "50px",
                                      width: "50px",
                                      padding: "10px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                )}
                              </a>
                            )}
                            <Stack>
                              <Typography variant="h7" mt={0.8}>
                                {isEdit && iid === item.id ? (
                                  <Input
                                    onChange={handleChange}
                                    // value={value}
                                    name="subject"
                                    placeholder={item.title}
                                  />
                                ) : (
                                  item.title
                                )}
                              </Typography>

                              {/* <Typography sx={{ fontSize: '10px' }}>
                                            <span style={{ fontWeight: '700' }}>Time:</span>  1h2m3s
                                        </Typography> */}
                            </Stack>
                            {/* <MoreVertIcon style={{ ...moreVertIcon }} aria-describedby={id} variant="contained" onClick={handleClick} /> */}
                          </Stack>
                          {isEdit && iid === item.id ? (
                            <Button
                              onClick={handleUpdate}
                              variant="contained"
                              sx={{ height: "10%", marginTop: "25px" }}
                            >
                              Update
                            </Button>
                          ) : (
                            ""
                          )}
                          <Box onClick={() => setiid(item.id)} sx={{ pt: 3 }}>
                            {" "}
                            <UserMoreMenu
                              id={item.id}
                              req="delete-lecture-resources"
                              isEdit={isEdit}
                              setIsEdit={setIsEdit}
                              title="lectureResourcetable"
                              details={item}
                            />
                          </Box>
                        </Stack>
                        <Divider />
                      </>
                    ) : null}
                  </div>
                );
              })
            ) : (
              <Typography variant="h5">No Resources</Typography>
            )}
            <ToastContainer
              position="bottom-center"
              autoClose={1000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </Box>
        )}
      </>
    );
}

export default LectureResourcesTable