import * as Yup from "yup";
import React, { useState, useEffect } from "react";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CategoryComponent from "../../../utils/CategoryComponent";
// ----------------------------------------------------------------------
import { getFileUrl } from "../../../utils/firebaseUtils";
export default function AddNotes() {
  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState();
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const [languages, setLanguages] = useState([]);

  //* CATEGORY IMPLEMENTATION useStates *
  const [selectedCategory, setSelectedCategory] = useState("");
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    // subject: Yup.string().required('subject is required'),
    // file_url: Yup.string().required('Url  is required'),
    // language: Yup.string().required('Language is required'),
    // notes_type: Yup.string().required('Type Required'),
    is_active: Yup.string().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    // subject: '',
    // file_url: '',
    language: "",
    notes_type: "",
    is_active: "true",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [file, setFile] = useState();

  // const changeHandler = async (event) => {
  //   setFile(event.target?.files[0]);
  // };
  const onSubmit = async (data) => {
    if (file?.size) {
      setLoading(true);

      const payload = {
        ...data,
        fileUrl: fileUrl,
        course: selectedCategory,
        state_exam_id: selectedState,
        board_id: selectedBoard,
        exam_id: centralExam || stateExam,
        class_id: classVal,
      };

      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          // 'content-type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-notes`,
          payload,
          config
        )
        .then((response) => {
          //console.log(response?.data);
          if (response?.data?.response_code === 200) {
            toast.success(response?.data?.message);
            setFile(null);
            setShowBanner(false);
            setFocused(false);
            setVal(false);
            setMsg("");
            setLoading(false);
            reset();
            setTimeout(() => window.location.reload(), 1000);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };
  const changeHandler = async (event) => {
    setLoading(true);
    setFile(event.target.files[0]);
    setFileUrl(await getFileUrl(event.target.files[0]));
    setLoading(false);
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(false)
    async function fetchUsers() {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-subjects`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resSubject = await res.json();
      setSubject(resSubject?.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);
  const [focus, setFocused] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  //console.log(showBanner)
  //console.log(file)

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch languages");
      }
      const data = await response.json();
      setLanguages(data.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Notes</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="title" label="Title" />
              {/* <RHFTextField name="subject" label="Subject" select>
                  {[
                    subject?.map((sub, i) => {
                      return (
                        <MenuItem key={i} value={sub.id}>
                          {sub.name}
                        </MenuItem>
                      );
                    })
                  ]}
                </RHFTextField> */}
            </Stack>

            <Stack direction="row" spacing={3}>
              {/* //!temperory
                <RHFTextField
                  name="language"
                  label="language"
                  sx={{ width: "100%" }}
                  select
                >
                  {languages?.map((language, index) => (
                    <MenuItem key={index} value={language.medium_code}>
                      {language.medium_name}
                    </MenuItem>
                  ))}
                </RHFTextField> */}

              <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
                <RHFTextField
                  name="file_url"
                  label="PDF"
                  error={!file && val}
                  onChange={changeHandler}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setShowBanner(true)}
                  type={showBanner || focus ? "file" : "text"}
                  helperText="Pdf Files Only"
                  inputProps={{ accept: "application/pdf" }}
                />
                {!file && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "red",
                    }}
                  >
                    {msg}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <CategoryComponent
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              classVal={classVal}
              setClass={setClass}
              centralExam={centralExam}
              setCentralExam={setCentralExam}
              stateExam={stateExam}
              setStateExam={setStateExam}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              selectedBoard={selectedBoard}
              setSelectedBoard={setSelectedBoard}
            />
            <Stack direction="row" spacing={3}>
              {/* <RHFTextField name="notes_type" label="Type" select>
                  <MenuItem value="shorts">Shorts</MenuItem>
                  <MenuItem value="NCERT">NCERT</MenuItem>
                </RHFTextField> */}
              <RHFTextField name="is_active" label="Status" select>
                <MenuItem value={"true"}>Publish</MenuItem>
                <MenuItem value={"false"}>Draft</MenuItem>
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
