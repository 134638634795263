import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'


const BatchCard = ({ value, batch, title }) => {

    // //console.log(batch)

    const BatchStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "31%",
        maxWidth: "31%",
        minHeight: "130px",
        background: "#e6e6ff",

        borderRadius: "15px",
        boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.20)",
        transition: "all 0.3s",
        translate: `${value}px`,
        padding: "0 5%",
        // translate: `20px`
    };
    return (
        <Box sx={{ ...BatchStyle }}>
            <Typography color="black" fontSize="medium" sx={{ textAlign: 'center', fontWeight: '700' }}>
                {batch?.name ? batch?.name : title}
            </Typography>
        </Box>
    )
}

export default BatchCard