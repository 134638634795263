import PropTypes from 'prop-types';
import { set, sub } from 'date-fns';
import { noCase } from 'change-case';

import { useState, useRef, useEffect } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../utils/formatTime';
// components
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import axios from 'axios';
// ----------------------------------------------------------------------

const NOTIFICATIONS = [
  {
    // id: faker.datatype.uuid(),
    title: 'Your order is placed',
    description: 'waiting for shipping',
    avatar: null,
    type: 'order_placed',
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true,
  },
  {
    // id: faker.datatype.uuid(),
    // title: faker.name.findName(),
    description: 'answered to your comment on the Minimal',
    avatar: '/static/mock-images/avatars/avatar_2.jpg',
    type: 'friend_interactive',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {

    description: '5 unread messages',
    avatar: null,
    type: 'chat_message',
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {

    description: 'sent from Guido Padberg',
    avatar: null,
    type: 'mail',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {

    description: 'Your order is being shipped',
    avatar: null,
    type: 'order_shipped',
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
];

export default function NotificationsPopover() {
  const anchorRef = useRef(null);

  const [notifications, setNotifications] = useState(NOTIFICATIONS);

  const totalUnRead = notifications.filter((item) => item?.isUnRead === true).length;

  const [open, setOpen] = useState(null);
  // const [TotalUnread, setTotalUnread] = useState([]);

  const [notificationTest, setNotificationsTest] = useState([]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications?.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  let count = 0
  //Getting Notifications
  useEffect(() => {
    const token = localStorage.getItem("token");
    // async function fetchUsers() {
    //   const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getAllNotificationsTeacher`, {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${token}`
    //     },

    //   });
    //   const responseJson = await fullResponse.json();

    //   // setNotificationsTest((list) => [...list, ...responseJson?.data])





    // }

    // fetchUsers();
  }, []);


  let arr = notificationTest.filter(i => i.isUnRead)

  // //console.log(arr) 
  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={arr.length} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {arr.length} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notificationTest?.map((notificationTestt, i) => {
              return (
                <NotificationItem key={i} notification={notificationTestt} arr={arr} />
              )
            })}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                {/* Before that */}
              </ListSubheader>
            }
          >
            {/* {arr?.map((a, i) => {
              return (
                <NotificationItem key={i} notification={a} />
              )
            })} */}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, arr }) {
  // const { title, Avatar } = renderContent(notification);
  //console.log(arr)
  const title = (
    <Typography variant="subtitle2">
      {notification?.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification?.notificationBody)}
      </Typography>
    </Typography>
  );


  const handleUnread = async (id) => {
    const authToken = localStorage.getItem('token')
    const config = {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    };
    // const res = await axios.put(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updateTeacherNotificationToRead/${id}`, {}, config);
    // arr?.map((item, index) => {
    //   if (item?.id == id) {
    //     item?.isUnRead = false
    //   }
    // })
    //console.log(res)

  }
  return (
    <ListItemButton

      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification?.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => handleUnread(notification.id)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }} src={notification.Avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={title}
        sx={{
          textTransform: 'capitalize',
        }}
        secondary={
          <Typography
            title='hello'
            variant="caption"
            sx={{

              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification?.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification?.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification?.notificationBody)}
      </Typography>
    </Typography>
  );

  // if (notification.type === 'order_placed') {
  //   return {
  //     avatar: <img alt='Alternate' src="/static/icons/ic_notification_package.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'order_shipped') {
  //   return {
  //     avatar: <img alt='Alternate' src="/static/icons/ic_notification_shipping.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: <img alt='Alternate' src="/static/icons/ic_notification_mail.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: <img alt='Alternate' src="/static/icons/ic_notification_chat.svg" />,
  //     title,
  //   };
  // }
  return {
    Avatar: notification.avatar ? <img alt='Alternate' src='/static/mock-images/avatars/avatar_2.jpg' /> : null,
    title,
  };
}