import { Avatar, Button, Card, Stack, Tabs, Tab, TextField, Box, IconButton, Menu, MenuItem } from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import io from "socket.io-client";
import socket from "./sockets";
import { RHFTextField } from "../../../components/hook-form";
import { useParams } from "react-router-dom";
import { message, Typography } from "antd";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
const CryptoJS = require("crypto-js");

function extractYouTubeVideoId(url) {
  // Regular expressions to match various YouTube URL formats
  const patterns = [
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?.*&v=([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtu.be\/([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/live\/([a-zA-Z0-9_\-?]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?list=[a-zA-Z0-9_-]+&v=([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube-nocookie\.com\/embed\/([a-zA-Z0-9_-]+)/,
  ];

  // Try each pattern and return the video ID if a match is found
  for (const pattern of patterns) {
    const match = url.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }

  // If no match is found, return null or handle the case as needed
  return null;
}

const YoutubeLive = () => {
  const { lectId, lectTitle, lectDesc, ytUrl } = useParams();
  let newLink = "";
  const decodedYtUrl = decodeURIComponent(ytUrl);

  Array.from(decodedYtUrl).forEach((elem) => {
    if (elem !== "@") newLink += elem;
    else newLink += "/";

    // console.log('newLin', newLink)
  });
  // console.log(newLink);
  var videoId = extractYouTubeVideoId(newLink);
  const [newUrl, setnewUrl] = useState(
    `https://www.youtube.com/embed/${videoId}`
  );
  const detailObject = JSON.parse(localStorage.getItem("detailObject"));
  const name = detailObject?.username;
  const username = detailObject?.username;
  // console.log(detailObject?.data);
  const id = detailObject?.adminId;
  const profileIcon =
    detailObject?.profilePhoto ??
    "https://storage-upschindi.s3.ap-south-1.amazonaws.com/data/images/avatar.png";
  // let socket = io.connect('https://backend-prod.invictaa.com/api/v1', { transports: ['websocket'] })

  const [msg, setMsg] = useState("");
  const [description, setDescription] = useState("");
  const [data, setData] = useState([])

  const [isLiveFullScreen, setIsLiveFullScreen] = useState(false);
  // const containerRef = useRef(null);
  const [tabValue, setTabValue] = useState(0);
  const joinRoom = () => {
    const roomId = lectId;

    socket.emit("create", roomId);
  };

  useEffect(() => {
    joinRoom();
  }, []);




  const [users, usersSet] = useState();

  const [chatId, setChatId] = useState("")
  /**
   * Fetch the chat history of the lecture
   * @returns {Promise<void>}
   */





  const fetchChatHistory = async () => {
    const token = localStorage.getItem("token");
    // Make a GET request to the server to fetch the chat history
    const fullResponse = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-chat/${lectId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Extract the JSON response from the server
    const responseJson = await fullResponse.json();
    console.log(responseJson);

    // If the server returned a valid response, set the chat history to the state
    if (responseJson?.data) {
      setData(responseJson?.data);
    }
  };
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    /**
     * Fetch lecture details from the server
     * @param {string} lectId The ID of the lecture
     * @returns {Promise<void>}
     */
    async function fetchDetails() {
      // Make a GET request to the server to fetch the lecture details
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-lecture/${lectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Extract the JSON response from the server
      const responseJson = await fullResponse.json();
      console.log(responseJson?.data);

      // If the server returned a valid response, extract the video ID from the link
      if (responseJson?.data) {
        const videoId = extractYouTubeVideoId(
          responseJson?.data?.lecture_type == "1"
            ? responseJson?.data?.link
            : responseJson?.data?.material_url
        );
        console.log(videoId);

        // Update the video URL in the state
        if (videoId) {
          setnewUrl(`https://www.youtube.com/embed/${videoId}`);
        }
      }

      // Update the description in the state
      setDescription(responseJson?.data?.description);

      // Update the users in the state
      usersSet(responseJson?.data);
      // setLoading(false)
      // setLoadingTable(false)
    }
    fetchChatHistory()

    fetchDetails();
  }, []);

  /**
   * Handles changes to the message input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e The event that triggered the change.
   */


  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const handleMenuOpen = (event, message) => {
    setAnchorEl(event.currentTarget);
    setChatId(message?.id)
    setSelectedMessage(message);
  };

  /**
   * Closes the context menu for a chat message.
   * Called when the user clicks outside of the context menu or when the user closes the menu.
   * @function
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMessage(null);
  };

  /**
   * Handles the deletion of a chat message.
   * This function is responsible for executing the logic to delete a chat message.
   * The implementation details need to be added.
   * @function
   * @returns {Promise<void>}
   */
  const handleDelete = async () => {
    // Add delete logic here
    handleMenuClose();

    const token = localStorage.getItem("token");

    const url = `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-chat/${chatId}`;

    /**
     * The HTTP request method
     * @type {string}
     */
    const method = "PUT";

    /**
     * The request headers
     * @type {import("axios").AxiosRequestHeaders}
     */
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    /**
     * The request body
     * @type {any}
     */
    const data = {};

    /**
     * The response data
     * @type {any}
     */
    const response = await axios.delete(url, { headers });

    /**
     * Handle the response data
     */
    if (response.status == 200) {
      // TODO: Add logic to handle the response data
      toast.success("Message Deleted successfully")
      fetchChatHistory()
    } else {
      // TODO: Add logic to handle errors
    }
  };

  const handleBlockUser = async (msg) => {
    // Add block user logic here

    const token = localStorage.getItem("token");
    console.log("User ", msg)
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const resp = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/block-user`, { user_id: msg.userId, lecture_id: msg.lectureId }, { headers })
    if (resp.status == 200) {
      toast.success(resp.data.message)
      fetchChatHistory();
    }
    handleMenuClose();
  };
  const handleUnBlockUser = async (msg) => {
    // Add block user logic here

    const token = localStorage.getItem("token");
    console.log("User ", msg)
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const resp = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/unblock-user`, { user_id: msg.userId, lecture_id: msg.lectureId }, { headers })
    if (resp.status == 200) {
      toast.success(resp.data.message);
      fetchChatHistory();
    }
    handleMenuClose();
  };

  /**
   * Handles the action of hiding a chat message.
   * Closes the menu and initiates the chat hiding process.
   */
  const handleHideMessage = () => {
    // Close the menu
    handleMenuClose();

    // Initiate the chat hiding process
    hindeChatHandler();
  };
  /**
   * Handles the chat hiding functionality.
   * This function is responsible for executing the logic to hide a chat message.
   * The implementation details need to be added.
   * @function
   * @returns {Promise<void>}
   */
  const hindeChatHandler = async () => {
    /**
     * The API URL for hiding a chat message
     * @type {string}
     */
    const token = localStorage.getItem("token");

    const url = `${process.env.REACT_APP_LIVE_URL_NEW}/admin/hide-chat/${chatId}`;

    /**
     * The HTTP request method
     * @type {string}
     */
    const method = "PUT";

    /**
     * The request headers
     * @type {import("axios").AxiosRequestHeaders}
     */
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    /**
     * The request body
     * @type {any}
     */
    const data = {};

    /**
     * The response data
     * @type {any}
     */
    const response = await axios.put(url, data, { headers });

    /**
     * Handle the response data
     */
    if (response.status == 200) {
      // TODO: Add logic to handle the response data
      toast.success("Message hidded successfully")
      fetchChatHistory();
    } else {
      // TODO: Add logic to handle errors
    }
  }
  const showChatHandler = async () => {
    /**
     * The API URL for hiding a chat message
     * @type {string}
     */
    const token = localStorage.getItem("token");

    const url = `${process.env.REACT_APP_LIVE_URL_NEW}/admin/show-chat/${chatId}`;

    /**
     * The HTTP request method
     * @type {string}
     */
    const method = "PUT";

    /**
     * The request headers
     * @type {import("axios").AxiosRequestHeaders}
     */
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    /**
     * The request body
     * @type {any}
     */
    const data = {};

    /**
     * The response data
     * @type {any}
     */
    const response = await axios.put(url, data, { headers });

    /**
     * Handle the response data
     */
    if (response.status == 200) {
      // TODO: Add logic to handle the response data
      toast.success(response.data.message);
      fetchChatHistory();
    } else {
      // TODO: Add logic to handle errors
    }
  }

  <ToastContainer position="bottom-center" />
  return (
    // <div
    //   className="parent"
    //   style={{
    //     height: "100%",
    //   }}
    // >
    //   <Stack direction={isLiveFullScreen ? "column" : "row"} gap="20px">
    //     <Stack
    //       direction="column"
    //       sx={{ width: "100%", height: "80vh !important" }}
    //     >
    //       <div
    //         style={{
    //           height: isLiveFullScreen ? "70%" : "60%",
    //           width: "100%",
    //           padding: "20px",
    //         }}
    //       >
    //         <div
    //           style={{
    //             height: "100%",
    //             width: "100%",
    //             background: "black",
    //             borderRadius: "10px",
    //             marginBottom: "10px",
    //             overflow: "hidden",
    //           }}
    //         >
    //           <iframe
    //             // width={isLiveFullScreen ? "100%" : "780"}
    //             // height={isLiveFullScreen ? "340" : "255"}
    //             width={isLiveFullScreen ? "100%" : "100%"}
    //             height={isLiveFullScreen ? "440" : "360"}
    //             // src='https://www.youtube.com/embed/DAEnJzUNZYE?si=59XwYssuy9g29qEN'>
    //             src={newUrl}
    //             allowFullScreen
    //           >
    //             {/* // src={newUrl}> */}
    //           </iframe>
    //         </div>
    //       </div>
    //       <div style={{ width: '100%', height: '100%' }}>
    //         {/* Header Section for Lecture Name */}
    //         <h5 style={{ height: '5%', padding: '10px' }}>{users?.title}</h5>

    //         {/* Chat Area */}
    //         <div style={{ height: '95%', width: '100%', overflowY: 'auto' }}>
    //           {data?.map((msg, index) => (
    //             <Card
    //               key={index}
    //               sx={{
    //                 margin: '10px 0',
    //                 padding: '10px',
    //                 display: 'flex',
    //                 flexDirection: 'row',
    //                 alignItems: 'center',
    //               }}
    //             >
    //               <div style={{ flexGrow: 1 }}>
    //                 <Typography variant="subtitle2" color="text.secondary">
    //                    <b> {msg?.name}</b> {/* User name */}
    //                 </Typography>
    //                 <Typography variant="body2"> {msg?.message}</Typography> {/* Message */}
    //                 <Typography variant="caption" color="text.secondary">
    //                   {msg?.created_at?.slice(0, 10) + " " + msg?.created_at?.slice(11, 16)} {/* Message time */}
    //                 </Typography>
    //               </div>
    //               {/* More Options Button */}
    //               <IconButton onClick={(e) => handleMenuOpen(e, msg)}>
    //                 <MoreVertIcon />
    //               </IconButton>
    //               {/* Menu for More Options */}
    //               <Menu
    //                 anchorEl={anchorEl}
    //                 open={Boolean(anchorEl && selectedMessage === msg)}
    //                 onClose={handleMenuClose}
    //               >
    //                 <MenuItem onClick={handleDelete}>Delete</MenuItem>
    //                 <MenuItem onClick={() => {
    //                   if (msg?.isBlocked) {
    //                     handleUnBlockUser(msg)
    //                   }
    //                   else {
    //                     handleBlockUser(msg)
    //                   }

    //                 }}>{msg?.isBlocked ? "Unblock" : "Block"}</MenuItem>
    //                 <MenuItem onClick={()=>{
    //                   if(msg?.status == 1){
    //                     handleHideMessage()
    //                   }
    //                   else{
    //                     showChatHandler()
    //                   }
                     
                      
    //                 }}>{msg?.status == 1 ? "Hide" : "Visible"}</MenuItem>
    //               </Menu>
    //             </Card>
    //           ))}
    //         </div>
    //       </div>
    //     </Stack>


    //   </Stack>
    // </div>
    <div
      className="parent"
      style={{
        height: "100%",
      }}
    >
      <Stack direction={isLiveFullScreen ? "column" : "row"} gap="20px">
        <Stack
          direction="column"
          sx={{ width: "100%", height: "80vh !important" }}
        >
          <div
            style={{
              height: isLiveFullScreen ? "70%" : "60%",
              width: "100%",
              padding: "20px",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                background: "black",
                borderRadius: "10px",
                marginBottom: "10px",
                overflow: "hidden",
              }}
            >
              <iframe
                width={isLiveFullScreen ? "100%" : "780"}
                height={isLiveFullScreen ? "340" : "255"}
                // src='https://www.youtube.com/embed/DAEnJzUNZYE?si=59XwYssuy9g29qEN'>
                src={newUrl}
                allowFullScreen
              >
                {/* // src={newUrl}> */}
              </iframe>
            </div>
          </div>
          <h5 style={{ height: "5%" }}>{users?.title}</h5>
          <div style={{ height: "50%", width: "100%" }}>
            <Card
              sx={{
                height: "100%",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <h4 >{users?.description}</h4> */}
             <div dangerouslySetInnerHTML={{__html:users?.description}}></div>
            </Card>
          </div>
        </Stack>
        <Stack
          direction="column"
          sx={{ width: isLiveFullScreen ? "100%" : "70%" }}
        >
          <Card
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "hidden",
            }}
          >
            <div
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "10px ",
                paddingRight: "10px",
                alignItems: "center",
                background: "rgba(255, 137, 158, 0.12)",
              }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* fill="#9603F2" */}
                <path
                  d="M7.5 24C7.075 24 6.71875 23.8563 6.43125 23.5688C6.14375 23.2812 6 22.925 6 22.5V19.5H25.5V6H28.5C28.925 6 29.2812 6.14375 29.5687 6.43125C29.8562 6.71875 30 7.075 30 7.5V30L24 24H7.5ZM0 22.5V1.5C0 1.075 0.14375 0.71875 0.43125 0.43125C0.71875 0.14375 1.075 0 1.5 0H21C21.425 0 21.7812 0.14375 22.0688 0.43125C22.3563 0.71875 22.5 1.075 22.5 1.5V15C22.5 15.425 22.3563 15.7813 22.0688 16.0688C21.7812 16.3563 21.425 16.5 21 16.5H6L0 22.5ZM19.5 13.5V3H3V13.5H19.5Z"
                  fill="#EA506B"
                  fill-opacity="0.75"
                />
              </svg>
              &nbsp; Live Comments
              <Button onClick={() => setIsLiveFullScreen(!isLiveFullScreen)}>
                {!isLiveFullScreen ? <ZoomOutMapIcon /> : <ZoomInMapIcon />}
              </Button>
            </div>

            <div
              style={{
                overflowY: "scroll",
                // padding: "20px",
            
                display: "flex",
                flexDirection: "column-reverse",
                height: isLiveFullScreen ? "50vh" : "60vh",
              }}
            >
              {data?.map((msg,index) => {
                // console.log(item)
                // console.log
                return (
                  <Card
                  key={index}
                  sx={{
                    margin: '10px 0',
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2" color="text.secondary">
                       <b> {msg?.name}</b> {/* User name */}
                    </Typography>
                    <Typography variant="body2"> {msg?.message}</Typography> {/* Message */}
                    <Typography variant="caption" color="text.secondary">
                      {msg?.created_at?.slice(0, 10) + " " + msg?.created_at?.slice(11, 16)} {/* Message time */}
                    </Typography>
                  </div>
                  {/* More Options Button */}
                  <IconButton onClick={(e) => handleMenuOpen(e, msg)}>
                    <MoreVertIcon />
                  </IconButton>
                  {/* Menu for More Options */}
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl && selectedMessage === msg)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    <MenuItem onClick={() => {
                      if (msg?.isBlocked) {
                        handleUnBlockUser(msg)
                      }
                      else {
                        handleBlockUser(msg)
                      }

                    }}>{msg?.isBlocked ? "Unblock" : "Block"}</MenuItem>
                    <MenuItem onClick={()=>{
                      if(msg?.status == 1){
                        handleHideMessage()
                      }
                      else{
                        showChatHandler()
                      }
                     
                      
                    }}>{msg?.status == 1 ? "Hide" : "Visible"}</MenuItem>
                  </Menu>
                </Card>
                );
             
              })}
            </div>
        
          </Card>
        </Stack>
      </Stack>
    </div>
  );
};

export default YoutubeLive;
