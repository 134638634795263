import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";
const token = localStorage.getItem("token");
const authToken = token;
const  headers={
    "content-type": "application/json",
    Authorization: `Bearer ${authToken}`,
  }
const SocialMedia = () => {
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({ id: null, title: "", link: "", type: "" });
  const [editMode, setEditMode] = useState(false);

  // Fetch the social media data
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-social`,{headers});
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle create/update submission
  const handleSubmit = async () => {
    try {
      if (editMode) {
        // Update
        await axios.put(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-social/${formData.id}`,formData,{headers});
      } else {
        // Create
        await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-social`,  formData ,{headers});
      }
      fetchData();
      setModalOpen(false);
      setFormData({ id: null, title: "", link: "", type: "" });
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await axios.get(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-social/${id}`, {},{headers});
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  // Open modal for create or edit
  const openModal = (item = null) => {
    if (item) {
      setEditMode(true);
      setFormData(item);
    } else {
      setEditMode(false);
      setFormData({ id: null, title: "", link: "", type: "" });
    }
    setModalOpen(true);
  };

  // Fetch data on mount
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box p={4}>
      <Button variant="contained" onClick={() => openModal()}>
        Add Social Media
      </Button>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.link}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => openModal(item)}
                    sx={{ mr: 1 }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDelete(item.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for create/update */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={formData.type}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="Facebook">Facebook</MenuItem>
              <MenuItem value="Twitter">Twitter</MenuItem>
              <MenuItem value="Instagram">Instagram</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Link"
            name="link"
            value={formData.link}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
         
          <TextField
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button variant="contained" onClick={handleSubmit}>
            {editMode ? "Update" : "Create"}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default SocialMedia;
