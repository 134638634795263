import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { User } from '../../../context/context'

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();


  const [fetchMessage, setFetchMessage] = useState()
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });


  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {

    try {
      const res = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/admin-teacher-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      const result = await res.json()
      setFetchMessage(result.response_code===200 ? 'Log In Successfully' : result.message)
      if (result.response_code===200) {
        const userData = result?.data?.user;
        console.log(userData)
        if (result.data.user.role === "admin") {
          localStorage.setItem("role", 'Admin');
          localStorage.setItem("idOfAdmin", userData.id);
          localStorage.setItem("detailObject", JSON.stringify(userData));
          localStorage.setItem("details", userData.name);
          localStorage.setItem("token", userData.token);
          localStorage.setItem("isLoggedIn", true);
          // navigate('/dashboard/app', { replace: true });
          window.location.href = '/dashboard/app';
        } else if (userData.role === "subadmin") {
          localStorage.setItem("role", userData.role);
          localStorage.setItem("idOfAdmin", userData.id);
          localStorage.setItem('id', userData.id)
          localStorage.setItem("detailObject", JSON.stringify(userData));
          localStorage.setItem("details", userData.name);
          // navigate('/dashboard/app', { replace: true });
          window.location.href = '/dashboard/app';
          localStorage.setItem("token", userData.token);
          localStorage.setItem("isLoggedIn", true);
        }
        else if (userData.role === "teacher") {
          localStorage.setItem("role", userData.role);
          localStorage.setItem("idOfAdmin", userData.id);
          localStorage.setItem('id', userData.id)
          localStorage.setItem("detailObject", JSON.stringify(userData));
          localStorage.setItem("details", userData.name);
          // navigate('/dashboard/app', { replace: true });
          window.location.href = '/dashboard/app';
          localStorage.setItem("token", userData.token);
          localStorage.setItem("isLoggedIn", true);
        }

      }



      // window.location = "/";

    } catch (error) {
      //console.log(error);
    }

  };
  const [label2, setLabel] = useState(true)
  // const onChange3 = (e) => {
  //   const { value } = e.target
  //   if (value == '') {
  //     setLabel(false)
  //   }
  //   //console.log(value)

  // }



  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <RHFTextField
            name="email"
            label="Email address"
            InputLabelProps={{ shrink: label2 }}
          />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <RHFCheckbox name="remember" label="Remember me" />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ bgcolor: "#8080ff" }}
        >
          Login
        </LoadingButton>
      </FormProvider>
      <Typography sx={{ color: "#8080ff", textAlign: "center" }}>
        {fetchMessage}
      </Typography>
    </>
  );
}
