import "simplebar/src/simplebar.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import { MathJaxContext } from "better-react-mathjax";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import { UserContext } from "./context/context";
import './index.css'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <UserContext>
      <MathJaxContext config={{
            tex: {
              inlineMath: [["$", "$"]],
            },
          }}>
        <App />
        </MathJaxContext>
      </UserContext>
    </BrowserRouter>
  </HelmetProvider>
);

serviceWorker.unregister();
reportWebVitals();
