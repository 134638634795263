import axios from "axios";
const token = localStorage.getItem("token");
const authToken = token;
const  headers={
    "content-type": "application/json",
    Authorization: `Bearer ${authToken}`,
  }

export const getGroups=async()=>{
    try {
        const res=await axios.get(
            `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-select-groups`,
            // `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-select-course?exam_id=${subCategories[0]?.id}&course=${selectedCategory}`,
            {
              headers
            }
          );
          console.log("Data ", res.data.data);
          return res.data.data
    } catch (error) {
        console.log(error)
        return []
    }
 
}