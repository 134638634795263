export const optionsForTeacher = [
  // {
  //   label: "General Permission",
  //   value: ["All Batches", "All Lecture", "All Coupon"],
  // },
  {
    label: "Users",
    value: "1",
    options: [
      {
        label: "All Users",
        value: "All Users",
      },
      {
        label: "Course Purchases",
        value: "Course Purchases",
      },
      {
        label: "Test Series Purchases",
        value: "Test Series Purchases",
      },
      {
        label: "Purchase Report",
        value: "Purchase Report",
      },
      {
        label: "User Report",
        value: "User Report",
      },
    ],
  },
  {
    label: "Course",
    value: "2",
    options: [
      {
        label: "All Courses",
        value: "All Batches",
      },
      {
        label: "Add Course",
        value: "Add Batch",
      },
      {
        label: "All Lecture",
        value: "All Lecture",
      },
      {
        label: "Add Lecture",
        value: "Add Center Lecture",
      },
      {
        label: "Add Resource",
        value: "Add Center Resource",
      },
    ],
  },
  {
    label: "Test Series",
    value: "3",
    options: [
      {
        label: "All Test Series",
        value: "All Test Series",
      },
      {
        label: "Add Test Series",
        value: "Add Test Series",
      },
      {
        label: "All Test",
        value: "All Test",
      },
      {
        label: "Add Test",
        value: "Add Test",
      },
    ],
  },
  {
    label: "Mock Test",
    value: "4",
    options: [
      {
        label: "All Mock Test",
        value: "All Quiz",
      },
      {
        label: "Add Mock Test",
        value: "Add Quiz",
      },
      {
        label: "All Question",
        value: "All Question",
      },
      {
        label: "Add Question",
        value: "Add Question",
      },
    ],
  },
  {
    label: "Coupon",
    value: "5",
    options: [
      {
        label: "All Coupon",
        value: "All Coupon",
      },
      {
        label: "Add Coupon",
        value: "Add Coupon",
      },
    ],
  },
  {
    label: "Resources",
    value: "6",
    options: [
      //         {
      //             label: 'Recorded Video', value: 'Recorded Video'
      //         },
      //         {
      //             label: 'Add Recorded Video', value: 'Add Recorded Video'
      //         },
      {
        label: "Pathyakram",
        value: "Pathyakram",
      },

      {
        label: "Add Pathyakram",
        value: "Add Pathyakram",
      },
      {
        label: "Notes",
        value: "Notes",
      },
      {
        label: "Add Notes",
        value: "Add Notes",
      },
      //         {
      //             label: 'Previous Year Question Papers', value: 'Previous Year Question Papers'
      //         },
      //         {
      //             label: 'Add Previous Year Question Papers', value: 'Add Previous Year Question Papers'
      //         },
      //         {
      //             label: 'AIR', value: 'AIR'
      //         },
      //         {
      //             label: 'Add AIR', value: 'Add AIR'
      //         },
      {
        label: "News Clip",
        value: "News Clip",
      },
      {
        label: "Add News Clip",
        value: "Add News Clip",
      },
      //         {
      //             label: 'Teacher Generated Content Of Batch', value: 'Teacher Generated Content Of Batch'
      //         },
      //         {
      //             label: 'Alerts', value: 'Alerts'
      //         },
      //         {
      //             label: 'Add Alerts', value: 'Add Alerts'
      //         },

      //     ]
      // }
      // #region assignment (teacher)
      // {
      //   label: "Assignment",
      //   value: "7",
      //   options: [
      //     {
      //       label: "All Assignment",
      //       value: "All Assignment",
      //     },
      //     {
      //       label: "Add Assignment",
      //       value: "Add Assignment",
      //     },
      //   ],
      // },
      // , {
      //     label: 'Manage Staff', value: '8'
      //     , options: [
      //         {
      //             label: 'All Staff', value: 'All Staff'
      //         },
      //         {
      //             label: 'Add Staff', value: 'Add Staff'
      //         },

      //     ]
      // }
      // , {
      //     label: 'Testimonial', value: '9'
      //     , options: [
      //         {
      //             label: 'All Testimonial', value: 'All Testimonial'
      //         },
      //         {
      //             label: 'Add Testimonial', value: 'Add Testimonial'
      //         },

      //     ]
      // }
      // , {
      //     label: 'General Settings', value: '10'
      //     , options: [
      //         {
      //             label: 'Bannner', value: 'Bannner'
      //         },
      //         {
      //             label: 'Add Bannner', value: 'Add Bannner'
      //         },
      //         {
      //             label: 'Logout Users', value: 'Logout Users'
      //         },
      //         {
      //             label: 'Subject', value: 'Subject'
      //         },
      //         {
      //             label: 'Add Subject', value: 'Add Subject'
      //         },
      //         {
      //             label: 'Youtube', value: 'Youtube'
      //         },
      //         {
      //             label: 'Add Youtube', value: 'Add Youtube'
      //         },
      //         {
      //             label: 'Telegram', value: 'Telegram'
      //         },
      //         {
      //             label: 'Add Telegram', value: 'Add Telegram'
      //         },
      //         {
      //             label: 'Category', value: 'Category'
      //         },
      //         {
      //             label: 'Add Category', value: 'Add Category'
      //         },
      //         {
      //             label: 'Sub Category', value: 'Sub Category'
      //         }
      //         ,
      //         {
      //             label: 'Add Sub Category', value: 'Add Sub Category'
      //         }
      //         ,
      //         {
      //             label: 'Contact Us', value: 'Contact Us'
      //         },
      //         {
      //             label: 'Add Contact Us', value: 'Add Contact Us'
      //         }
      //         ,
      //         {
      //             label: 'Help And Support', value: 'Help And Support'
      //         },
    ],
  },
];
export const options = [
  {
    label: "Users",
    value: "1",
    options: [
      {
        label: "All Users",
        value: "All Users",
      },
      {
        label: "Purchase Report",
        value: "Purchase Report",
      },
      {
        label: "Course Purchases",
        value: "Course Purchases",
      },
      {
        label: "Test Series Purchases",
        value: "Test Series Purchases",
      },
      {
        label: "Subscription Purchases",
        value: "Subscription Purchases",
      },
      // {
      //   label: "User Report",
      //   value: "User Report",
      // },
    ],
  },
  {
    label: "Course",
    value: "2",
    options: [
      {
        label: "All Courses",
        value: "All Batches",
      },
      {
        label: "Add Course",
        value: "Add Batch",
      },
      // {
      //   label: "All Lecture",
      //   value: "All Lecture",
      // },
      {
        label: "Add Lecture",
        value: "Add Center Lecture",
      },
      // {
      //   label: "Add Resource",
      //   value: "Add Center Resource",
      // },
    ],
  },
  {
    label: "Test Series",
    value: "3",
    options: [
      {
        label: "All Test Series",
        value: "All Test Series",
      },
      {
        label: "Add Test Series",
        value: "Add Test Series",
      },
      // {
      //   label: "All Test",
      //   value: "All Test",
      // },
      // {
      //   label: "Add Test",
      //   value: "Add Test",
      // },
    ],
  },
  {
    label: "Mock Test",
    value: "4",
    options: [
      {
        label: "All Mock Test",
        value: "All Mock Test",
      },
      {
        label: "Add Manual Test",
        value: "Add Manual Test",
      },
      {
        label: "Add Template Test",
        value: "Add Template Test",
      },
      // {
      //   label: "All Question",
      //   value: "All Question",
      // },
      // {
      //   label: "Add Question",
      //   value: "Add Question",
      // },
    ],
  },
  // {
  //   label: "Coupon",
  //   value: "5",
  //   options: [
  //     {
  //       label: "All Coupon",
  //       value: "All Coupon",
  //     },
  //     {
  //       label: "Add Coupon",
  //       value: "Add Coupon",
  //     },
  //   ],
  // },
  {
    label: "Resources",
    value: "6",
    options: [
      {
        label: "Syllabus",
        value: "Syllabus",
      },
      {
        label: "Add Syllabus",
        value: "Add Syllabus",
      },
      {
        label: "Notes",
        value: "Notes",
      },
      {
        label: "Add Notes",
        value: "Add Notes",
      },
      {
        label: "Audios",
        value: "Audios",
      },
      {
        label: "Add Audio",
        value: "Add Audio",
      },
      {
        label: "Demo Videos",
        value: "Demo Videos",
      },
      {
        label: "Add Demo Videos",
        value: "Add Demo Videos",
      },
      // {
      //   label: "Previous Year Question Papers",
      //   value: "Previous Year Question Papers",
      // },
      // {
      //   label: "Add Previous Year Question Papers",
      //   value: "Add Previous Year Question Papers",
      // },
      // {
      //   label: "AIR",
      //   value: "AIR",
      // },
      // {
      //   label: "Add AIR",
      //   value: "Add AIR",
      // },
      // {
      //   label: "News Clip",
      //   value: "News Clip",
      // },
      // {
      //   label: "Add News Clip",
      //   value: "Add News Clip",
      // },
      // {
      //   label: "Teacher Generated Content Of Batch",
      //   value: "Teacher Generated Content Of Batch",
      // },
      // {
      //   label: "Alerts",
      //   value: "Alerts",
      // },
      // {
      //   label: "Add Alerts",
      //   value: "Add Alerts",
      // },
    ],
  },

  {
    label: "Subscriptions",
    value: "7",
    options: [
      {
        label: "All Subscriptions",
        value: "All Subscriptions",
      },
      {
        label: "Add Subscription",
        value: "Add Subscription",
      },
    ],
  },

  {
    label: "Notification",
    value: "8",
    options: [
      {
        label: "All Notifications",
        value: "All Notifications",
      },
      // {
      //   label: "Add Notification",
      //   value: "Add Notification",
      // },
    ],
  },

  // {
  //   label: "Announcement",
  //   value: "8",
  //   options: [
  //     {
  //       label: " All  Announcement",
  //       value: "All Announcement",
  //     },
  //     {
  //       label: "Add Announcement",
  //       value: "Add Announcement",
  //     },
  //   ],
  // },
  // #region assignment
  // {
  //   label: "Assignment",
  //   value: "7",
  //   options: [
  //     {
  //       label: "All Assignment",
  //       value: "All Assignment",
  //     },
  //     {
  //       label: "Add Assignment",
  //       value: "Add Assignment",
  //     },
  //   ],
  // },
  // {
  //   label: 'Manage Staff', value: '8'
  //   , options: [
  //     {
  //       label: 'All Staff', value: 'All Staff'
  //     },
  //     {
  //       label: 'Add Staff', value: 'Add Staff'
  //     },

  //   ]
  // },

  // {
  //   label: "Testimonial",
  //   value: "9",
  //   options: [
  //     {
  //       label: "All Testimonial",
  //       value: "All Testimonial",
  //     },
  //     {
  //       label: "Add Testimonial",
  //       value: "Add Testimonial",
  //     },
  //   ],
  // },
  {
    label: "General Settings",
    value: "10",
    options: [
      {
        label: "Banner",
        value: "Banner",
      },
      {
        label: "Add Banner",
        value: "Add Banner",
      },
      // {
      //   label: "Logout Users",
      //   value: "Logout Users",
      // },
      {
        label: "Subject",
        value: "Subject",
      },
      {
        label: "Add Subject",
        value: "Add Subject",
      },

      {
        label: "All Coupon",
        value: "All Coupon",
      },
      {
        label: "Add Coupon",
        value: "Add Coupon",
      },
      // {
      //   label: "Youtube",
      //   value: "Youtube",
      // },
      // {
      //   label: "Add Youtube",
      //   value: "Add Youtube",
      // },
      // {
      //   label: "Telegram",
      //   value: "Telegram",
      // },
      // {
      //   label: "Add Telegram",
      //   value: "Add Telegram",
      // },
      // {
      //   label: "Category",
      //   value: "Category",
      // },
      // {
      //   label: "Add Category",
      //   value: "Add Category",
      // },
      // {
      //   label: "Sub Category",
      //   value: "Sub Category",
      // },
      // {
      //   label: "Add Sub Category",
      //   value: "Add Sub Category",
      // },
      // {
      //   label: "Contact Us",
      //   value: "Contact Us",
      // },
      // {
      //   label: "Add Contact Us",
      //   value: "Add Contact Us",
      // },
      // {
      //   label: "Help And Support",
      //   value: "Help And Support",
      // },
    ],
  },
];

export const keyValueTab = [
  // user Related
  {
    label: "All Users",
    value: "All Users",
  },
  {
    label: "Purchase Report",
    value: "Purchase Report",
  },
  {
    label: "Course Purchases",
    value: "Course Purchases",
  },
  {
    label: "Test Series Purchases",
    value: "Test Series Purchases",
  },
  {
    label: "Subscription Purchases",
    value: "Subscription Purchases",
  },
  {
    label: "All Courses",
    value: "All Batches",
  },
  {
    label: "Add Course",
    value: "Add Batch",
  },
  {
    label: "Add Lecture",
    value: "Add Center Lecture",
  },
  {
    label: "All Test Series",
    value: "All Test Series",
  },
  {
    label: "Add Test Series",
    value: "Add Test Series",
  },
  {
    label: "All Mock Test",
    value: "All Mock Test",
  },
  {
    label: "Add Manual Test",
    value: "Add Manual Test",
  },
  {
    label: "Add Template Test",
    value: "Add Template Test",
  },
  {
    label: "Syllabus",
    value: "Syllabus",
  },
  {
    label: "Add Syllabus",
    value: "Add Syllabus",
  },
  {
    label: "Notes",
    value: "Notes",
  },
  {
    label: "Add Notes",
    value: "Add Notes",
  },
  {
    label: "Audios",
    value: "Audios",
  },
  {
    label: "Add Audio",
    value: "Add Audio",
  },
  {
    label: "Demo Videos",
    value: "Demo Videos",
  },
  {
    label: "Add Demo Videos",
    value: "Add Demo Videos",
  },
  {
    label: "All Subscriptions",
    value: "All Subscriptions",
  },
  {
    label: "Add Subscription",
    value: "Add Subscription",
  },
  {
    label: "All Notifications",
    value: "All Notifications",
  },
  // {
  //   label: "Add Notification",
  //   value: "Add Notification",
  // },
  // {
  //   label: 'All Staff', 
  //   value: 'All Staff'
  // },
  // {
  //   label: 'Add Staff', 
  //   value: 'Add Staff'
  // },
  {
    label: "Banner",
    value: "Banner",
  },
  {
    label: "Add Banner",
    value: "Add Banner",
  },
  {
    label: "Subject",
    value: "Subject",
  },
  {
    label: "Add Subject",
    value: "Add Subject",
  },

  {
    label: "All Coupon",
    value: "All Coupon",
  },
  {
    label: "Add Coupon",
    value: "Add Coupon",
  },
];

export default { options, keyValueTab, optionsForTeacher };
