import {
  Avatar,
  Box,
  Card,
  Container,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import MessageIcon from "@mui/icons-material/Message";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
const avatarStyle = {
  position: "absolute",
  top: "-2px",
  // left: '35%',
  // right: 0,
  zIndex: 999,
};
const iconStyle = {
  color: "black",
  background: "#e6e6ff",
  borderRadius: "50%",
  padding: "5px",
};
const StudentCard = ({ role, detail }) => {
  // const { role, detail } = params;

  return (
    <Container sx={{ height: "100%", zIndex: 9, position: "relative" }}>
      <Box sx={{ height: "8%", display: "flex", justifyContent: "center" }}>
        {" "}
        <Avatar
          sx={{ width: 124, height: 124, ...avatarStyle }}
          alt="jjhzbs"
          src={detail?.profilePhoto}
        />
      </Box>
      <Card sx={{ height: "94%" }}>
        <Stack>
          <Box sx={{ height: "100px", width: "100%" }}></Box>
          <Typography sx={{ textAlign: "center", fontWeight: "700" }}>
            {/* {name} */}
            {detail?.first_name
              ? detail?.first_name + " " + detail?.last_name
              : detail?.StudentName}
          </Typography>
          <Typography sx={{ textAlign: "center", color: "lightgray" }}>
            Invictaa
          </Typography>
          <Stack
            spacing={4}
            mt={2}
            mb={2}
            direction="row"
            justifyContent="center"
          >
            {/* <Tooltip title="Message" arrow>
                            <IconButton>
                                <MessageIcon fontSize="large" sx={{ ...iconStyle }} />
                            </IconButton>
                        </Tooltip> */}
            <Tooltip title={detail?.mobile_no} arrow>
              <IconButton>
                <LocalPhoneIcon fontSize="large" sx={{ ...iconStyle }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={detail?.email} arrow>
              <IconButton>
                <EmailIcon fontSize="large" sx={{ ...iconStyle }} />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          {/* <Stack justifyContent="space-between" sx={{ minHeight: "200px" }}>
            <Stack p={2}>
              <Typography fontSize="medium" sx={{ fontWeight: "600" }}>
                About Us
              </Typography>
              {role == "Student" && (
                <Typography fontSize="medium" sx={{ fontWeight: "600" }}>
                  Address-{" "}
                  <span
                    style={{
                      fontWeight: "400",
                      color: "gray",
                      marginLeft: "10px",
                      textTransform: "capitalize",
                      wordBreak: "break-all",
                    }}
                  >
                    {detail?.Address == ""
                      ? "Address Not Added"
                      : detail?.Address}
                  </span>{" "}
                </Typography>
              )}
              {role == "Teacher" && (
                <Typography fontSize="medium" sx={{ fontWeight: "600" }}>
                  Access-
                  {detail?.accessToContent?.map((item, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: "400",
                        color: "gray",
                        marginLeft: "10px",
                        textTransform: "capitalize",
                        wordBreak: "break-all",
                      }}
                    >
                      {item}
                    </span>
                  ))}
                </Typography>
              )}
              {role == "Teacher" && (
                <Typography fontSize="medium" sx={{ fontWeight: "600" }}>
                  Subject-
                  {detail?.subject?.map((item, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: "400",
                        color: "gray",
                        marginLeft: "10px",
                        textTransform: "capitalize",
                        wordBreak: "break-all",
                      }}
                    >
                      {item?.title}
                    </span>
                  ))}
                </Typography>
              )}
            </Stack>
            <Divider />
            <Stack p={2}>
              <Typography fontSize="medium" sx={{ fontWeight: "600" }}>
                Profession-{" "}
                <span
                  style={{
                    fontWeight: "400",
                    color: "gray",
                    marginLeft: "10px",
                  }}
                >
                  {role}
                </span>
              </Typography>

              <Typography
                fontSize="medium"
                color="gray"
                sx={{ fontWeight: "400" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </Stack>
            {role == "Teacher" && <Divider />}
            <Stack p={2}>
              {role == "Teacher" && (
                <Typography fontSize="medium" sx={{ fontWeight: "600" }}>
                  Qualification-{" "}
                  <span
                    style={{
                      fontWeight: "400",
                      color: "gray",
                      marginLeft: "10px",
                    }}
                  >
                    {detail?.qualification}
                  </span>
                </Typography>
              )}
              {role == 'Teacher' && <Typography fontSize="medium" color='gray' sx={{ fontWeight: '400' }}></Typography>}
            </Stack>
          </Stack> */}
          {/* <Typography pr={2} color="#F95368" sx={{ textAlign: 'end' }}>View More ></Typography> */}
        </Stack>
      </Card>
    </Container>
  );
};

export default StudentCard;
