import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
  Box,
  Tab,
  Tabs,
  TableHead,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// For Dialog Box
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CSVLink } from "react-csv"; // for CSV file download
import { useTheme } from "@emotion/react";
import AddCoupon from "./AddCustomNotification";
// import { TabPanel } from "@mui/lab";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "S.No", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "desc", label: "Description", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "created", label: "Created At", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomNotification() {
  const access = JSON.parse(localStorage.getItem("detailObject"));
  const theme = useTheme();

  // Code for handling Dialog box
  const [download, setDownload] = useState(true);
  const [errorOTP, setErrorOtp] = useState();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    console.log("new tab value", newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const sendOtp = () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      action: "Exporting All Student Data",
    };

    // axios
    //   .post(
    //     `${process.env.REACT_APP_LIVE_URL}/adminTeacher/send-otp`,
    //     data,
    //     config
    //   )
    //   .then((response) => {
    //   })
    //   .catch((e) => {
    //     //console.log(e);
    //   });
  };
  const verifyOtp = () => {
    //console.log(document.getElementById('name').value);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      otp: document.getElementById("name").value,
    };

    // axios
    //   .post(
    //     `${process.env.REACT_APP_LIVE_URL}/adminTeacher/verify-otp`,
    //     data,
    //     config
    //   )
    //   .then((response) => {
    //     // //console.log(response);
    //     if (response.data.status == false) {
    //       setErrorOtp("Invalid Otp");
    //     }
    //     else {
    //       setDownload(false);
    //       setErrorOtp("Successfully Verified");
    //     }
    //   })
    //   .catch((e) => {
    //     //console.log(e);
    //   });
  };

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPageRows = users
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((user) => user.id);
      setSelectedRows(currentPageRows);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };
  const handleRowSelect = (userId) => {
    if (selectedRows.includes(userId)) {
      setSelectedRows(selectedRows.filter((id) => id !== userId));
    } else {
      setSelectedRows([...selectedRows, userId]);
    }
  };
  const handleDeleteButtonDisabled = () => {
    return selectedRows.length === 0;
  };

  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-alert`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      console.log(responseJson);
      usersSet(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");

    // await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/deleteAlert/${id}`, {
    //   // await fetch(`https://backend-prod.invictaa.com/api/v1/api/v1/${section}/${req}/${id}`, {
    //   method: 'DELETE',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${token}`
    //   },

    // });

    // toast.success('Deleted')
    // setTimeout(() => window.location.reload()
    //   , 1000)
    // const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      id: id,
    };
    axios
      .delete(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-alert/${id}`,
        config
      )
      .then((response) => {
        // Handle the success response
        toast.success(response.data.message);
        //console.log("Delete request successful", response);
        setTimeout(() => window.location.reload(), 1000);
      })
      .catch((error) => {
        // Handle the error response
        toast.success(error);
        console.error("Error deleting resource", error);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  let helperArray = [];
  const isUserNotFound =
    filteredUsers.length === 0 || filteredUsers[0] === "no";

  filteredUsers?.map((item) =>
    helperArray.push({
      ID: item?.id,
      Title: item?.title,
      Is_active: item?.is_active,
    })
  );

  const handleDeleteSelected = async () => {
    const token = localStorage.getItem("token");
    // console.log([...selectedRows]);
    // const res = await axios.delete(
    //   `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-alert`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   },
    //   { ids: [...selectedRows] }
    // );
    // console.log(res);
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-alert`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids: [...selectedRows] }),
      }
    );
    const fullres = res.json();
    toast.success("Delete successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1500);

    console.log(res);
  };

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader color={"#e6e6ff"} loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Announcements">
          <Typography variant="h3" gutterBottom>
            Announcements
          </Typography>
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <div
                style={{
                  width: "100%",
                  marginTop: "2rem",
                  marginBottom: "1rem",
                }}
                className="w-full mb-8"
              >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    sx={{ width: "100%" }}
                  >
                    <Tab
                      label="Announcements"
                      sx={{ width: "100%" }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Add Announcements"
                      sx={{ width: "100%" }}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <Card>
                    <Stack
                      pr={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      // height={"50px"}
                    >
                      <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                      />
                      {selectedRows.length > 0 && (
                        <Box height={"40px"}>
                          {/* <Typography>{selectedRows?.length}</Typography> */}
                          <Button
                            variant="contained"
                            color="error"
                            sx={{
                              height: "40px",
                            }}
                            onClick={handleDeleteSelected}
                            // disabled={handleDeleteButtonDisabled()}
                          >
                            Delete Selected Rows
                          </Button>
                        </Box>
                      )}

                      {/* <ExportCSV csvData={users} fileName="Notes Data" /> */}

                      {/* <Button variant="outlined" onClick={ () => { handleClickOpen(); sendOtp(); }}>
                Export
              </Button> */}
                      <Dialog open={open} onClose={handleClose}>
                        {download ? (
                          <>
                            <DialogTitle>OTP Varification</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                OTP is send successfully to your regisered
                                e-mail.
                              </DialogContentText>
                              <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="OTP Number"
                                type="number"
                                fullWidth
                                variant="standard"
                              />
                              <DialogContentText
                                sx={{
                                  color: "red",
                                  fontSize: "12px",
                                  padding: "3px",
                                }}
                              >
                                {errorOTP}
                              </DialogContentText>
                            </DialogContent>

                            <DialogActions>
                              <Button onClick={handleClose}>Cancel</Button>
                              <Button onClick={verifyOtp}>Verify</Button>
                            </DialogActions>
                          </>
                        ) : (
                          <>
                            <DialogTitle>Download File</DialogTitle>
                            <DialogContent>
                              <CSVLink data={users} filename="AllCoupons.csv">
                                Download
                              </CSVLink>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  handleClose();
                                  setDownload(true);
                                }}
                              >
                                Cancel
                              </Button>
                            </DialogActions>
                          </>
                        )}
                      </Dialog>
                    </Stack>

                    <Scrollbar>
                      <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                  indeterminate={
                                    selectedRows.length > 0 &&
                                    selectedRows.length < users.length
                                  }
                                />
                              </TableCell>
                              {TABLE_HEAD.map((headCell) => (
                                <TableCell
                                  key={headCell.id}
                                  align={headCell.alignRight ? "right" : "left"}
                                >
                                  {headCell.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredUsers[0] !== "no" &&
                              filteredUsers
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                ?.map((row, index) => {
                                  const {
                                    id,
                                    user,
                                    title,
                                    description,
                                    alert_type,
                                    is_active,
                                    created_at,
                                  } = row;

                                  return (
                                    <TableRow
                                      hover
                                      key={id}
                                      tabIndex={-1}
                                      role="checkbox"
                                    >
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          checked={selectedRows.includes(
                                            row.id
                                          )}
                                          onChange={() =>
                                            handleRowSelect(row.id)
                                          }
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle2">
                                          {index + 1}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        pl={2}
                                      >
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            noWrap
                                          >
                                            {title}
                                          </Typography>
                                        </Stack>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        pl={2}
                                        style={{
                                          minWidth: "32rem",
                                          maxWidth: "32rem",
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                        >
                                          <Typography variant="subtitle2" Wrap>
                                            {description}
                                          </Typography>
                                        </Stack>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        pl={2}
                                      >
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            noWrap
                                          >
                                            {alert_type}
                                          </Typography>
                                        </Stack>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        pl={2}
                                      >
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            noWrap
                                            color={"primary"}
                                          >
                                            {is_active === "true"
                                              ? "Active"
                                              : "Inactive"}
                                          </Typography>
                                        </Stack>
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        style={{ minWidth: "6.5rem" }}
                                      >
                                        {created_at?.split("T")[0]}
                                      </TableCell>
                                      <TableCell align="left">
                                        <Button
                                          onClick={() => {
                                            handleDelete(id);
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                          {isUserNotFound && (
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={6}
                                  sx={{ py: 3 }}
                                >
                                  <SearchNotFound searchQuery={filterName} />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                    <ToastContainer
                      position="bottom-center"
                      autoClose={1000}
                      hideProgressBar
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={users?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Card>
                  {/* <Box mt={2} width={"100%"}>
                    <Button
                      variant="contained"
                      color="error"
                      width={"100%"}
                      onClick={handleDeleteSelected}
                      disabled={handleDeleteButtonDisabled()}
                    >
                      Delete Selected Rows
                    </Button>
                  </Box> */}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <AddCoupon />
                </TabPanel>
              </div>
            </Stack>

            {/* {value==="allAlerts"?
             <>
            
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              </Stack> </>:
            <AddCoupon/>} */}
          </Container>
        </Page>
      )}
    </>
  );
}
