import React from "react";
import * as Yup from "yup";
import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams, useResolvedPath } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  IconButton,
  FormControl,
  InputAdornment,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Autocomplete,
  Grid,
  Paper,
  Box,
  Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { User } from "../../../context/context";
import Page from "../../../components/Page";
import { useTheme } from "@mui/material/styles";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./test.css";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import { Delete } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function getStyles(name, teacher, theme) {
//     return {
//         fontWeight:
//             teacher.indexOf(name) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }
const AddTest = () => {
  const navigate = useNavigate();
  // const { setRole } = useContext(User)
  const { testSeriesId } = useParams();
  // const [category, setCategory] = React.useState('');
  // const [type, setType] = React.useState('');
  const [users, usersSet] = React.useState([]);
  const [loading, setLoading] = useState(false);
  // const [paperType, setPaperType] = useState('objective')
  const [subject, setSubject] = useState();
  const [subjectList, setSubjectList] = useState([]);
  const [subjects, setSubjects] = useState([]);
    const [selSubject, setSelSubject] = useState([]);

  

  const theme = useTheme();

  const LoginSchema = Yup.object().shape({
    title: Yup.string().required("Test Title is required"),
   // starting_date: Yup.string().required("Starting Date is required"),
    result_type: Yup.string().required("Result type is required is required"),
    instructions: Yup.string().required("Instructions are required"),
    duration: Yup.string().required("Duration are required"),
    nagetiveMarking: Yup.boolean().required("Required"),
    nagetiveMarks: Yup.number().when(
      "nagetiveMarking",
      (nagetiveMarking, schema) => {
        return nagetiveMarking
          ? schema.test(
              "positive-and-greater-than-zero",
              "Negative Marks must be a positive number greater than zero",
              (value) => value > 0
            )
          : schema;
      }
    ),
    // totalMarks: Yup.string().required('Total Marks'),
  });

  const defaultValues = {
    title: "",
    starting_date: "",
    result_type: "automatic",
    instructions: "",
    No_of_question: "",
    question_paper_type: "objective",
    duration: "",
    nagetiveMarking: false,
    // totalMarks: '',
    eachQuestionMarks: 0,
    nagetiveMarks: 0,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [val, setVal] = useState(false);

  const [testseriesData, setTestSeriesData] = useState({});


 const handleAdd = () => {
  // setTotalCount(0);

   setSubjects([
     ...subjects,
     {
       id: Date.now(),
       name: "",
       subject_id: [],
       chapter_id: [],
       chapterOptions: [],
     },
   ]);
 };

 const handleDelete = (id) => {
   setSubjects(subjects.filter((subject) => subject.id !== id));
 };

 const handleChange = (id, key, value) => {
   setSubjects(
     subjects.map((subject) =>
       subject.id === id ? { ...subject, [key]: value } : subject
     )
   );
   if (key === "subject_id") {
     setSelSubject(value);
   }
 };

 const handleChapterChange = (id, chapters) => {
   setSubjects(
     subjects.map((subject) =>
       subject.id === id ? { ...subject, chapter_id: chapters } : subject
     )
   );
 };

 
  
  
  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchTestSeries = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-testseries/${testSeriesId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fullResponse = await response.data;
      setTestSeriesData(fullResponse.data);
      console.log("Test Series Data", fullResponse.data);
    };
    fetchTestSeries();
  }, [testSeriesId]);

  //Date
  const [focus, setFocused] = React.useState(false);
  const [showStartingDate, setShowStartingDate] = React.useState(false);

  //Subject
 useEffect(() => {
  const token = localStorage.getItem("token");

  async function fetchUsers() {
    setLoading(true); // Set loading to true at the start
    try {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-subject-by-goal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            course: testseriesData?.course,
            language: testseriesData?.language,
            class_exam_id:
              testseriesData?.exam_id || testseriesData?.class_id,
          }),
        }
      );

      const resSubject = await res.json();
      console.log("This is subject", resSubject);

      // Process the subject data
      const filteredSubjects = resSubject?.data?.map((subject) => ({
        id: subject.subject_id,
        name: subject.subject_name,
      }));

      setSubjectList(filteredSubjects); // Update the state with the processed data
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setLoading(false); // Set loading to false at the end, regardless of success or failure
    }
  }

  fetchUsers();
}, [testseriesData]);


  //Banner
  const [file, setFile] = useState();
  const [msg, setMsg] = useState("");
  // const [focusThree, setFocusedThree] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(false);
  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
  };

  //Answer Template
  const [focusTwo, setFocusedTwo] = React.useState(false);
  const [showBannerTwo, setShowBannerTwo] = React.useState(false);
  const [answerTemplate, setAnswerTemplate] = useState();
  const changeHandlerTwo = async (event) => {
    setAnswerTemplate(event.target?.files[0]);
  };

  const [progress, setProgress] = useState();
  const [showNegative, setShowNegative] = useState(false);

  function getStyles(name, teacher, theme) {
    return {
      fontWeight:
        teacher.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  //const handleChangeSubject = (_, newVal) => {
  //  console.log(newVal);
  //  setsubjectList(newVal);
 // };

  //Adding Details
  const onSubmit = async (data) => {
    console.log(data);
    console.log("here is the subject list ", subjectList);
    if (subjectList.length === 0) {
      toast.error("Please select subjects");
      return;
    }
    // return;
    setLoading(true);

    const formData = new FormData();
    // formData.append('questionPaper', file);
    // formData.append('answerTemplate', answerTemplate);
    formData.append("testseries_id", testSeriesId);
    formData.append("title", data?.title);
    formData.append("instructions", data?.instructions);
    formData.append("duration", data?.duration);
    formData.append("starting_date", data?.starting_date);
    formData.append("nagetiveMarking", data?.nagetiveMarking);
    // formData.append('totalmarks', data?.totalMarks);
    if (showNegative) {
      formData.append("nagetiveMarks", data?.negativeMarks);
    }

    let token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data?.loaded) / data?.total));
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-test`,
        {
          ...data,
          question_id: [],
          subject_id: [],

          subjects: subjects,
          //subject_id: subjectList.map((sub) => sub?.subject_id),
          testseries_id: testSeriesId,
          nagetiveMarking: data?.nagetiveMarking ? "true" : "false",
        },
        config
      )
      .then((response) => {
        console.log(response?.data);
        if (response?.data?.response_code === 200) {
          toast.success("Test Added");
          setShowBanner(false);
          setShowStartingDate(false);
          setLoading(false);
          setTimeout(() => {
            navigate(`/dashboard/test/testSeriesDetails/${testSeriesId}`);
          }, 1000);
          reset();
        } else {
          toast.error(response?.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e);
        setLoading(false);
      });
  };

  return (
    <>
      <Page title="Test Series">
        <Stack spacing={3} ml={5}>
          <Typography variant="h3">
            {" "}
            <IconButton
              onClick={() => window.history.back()}
              style={{ marginRight: "0.5rem", marginBottom: "0.3rem" }}
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
            Add Test{" "}
          </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              <Stack spacing={3} direction="row">
                <RHFTextField name="title" label="Test Name" />
                <RHFTextField
                  name="starting_date"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  label="Starting Date"
                  onClick={() => setShowStartingDate(true)}
                  type={showStartingDate || focus ? "datetime-local" : "text"}
                />
              </Stack>
              <Stack direction="row" spacing={3}>
                <RHFTextField
                  name="result_type"
                  label="Result Type"
                  sx={{ width: "50%" }}
                  select
                >
                  <MenuItem
                    value={"automatic"}
                    // onClick={() => setShowNegative(true)}
                  >
                    Automatic
                  </MenuItem>
                  <MenuItem
                    value={"manual"}
                    // onClick={() => setShowNegative(false)}
                  >
                    Manual
                  </MenuItem>
                </RHFTextField>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {/* <RHFTextField
                    name="totalMarks"
                    label="Total Marks"
                    type="number"
                  /> */}
                <RHFTextField
                  name="nagetiveMarking"
                  label="Negative Marking"
                  sx={{ width: "100%" }}
                  select
                  value={showNegative}
                >
                  <MenuItem value={true} onClick={() => setShowNegative(true)}>
                    Yes
                  </MenuItem>
                  <MenuItem
                    value={false}
                    onClick={() => setShowNegative(false)}
                  >
                    No
                  </MenuItem>
                </RHFTextField>
                {showNegative && (
                  <RHFTextField
                    name="nagetiveMarks"
                    label="Negative Marking"
                    sx={{ width: "100%" }}
                    type="number"
                  />
                )}
                {/* <RHFTextField
                  name="No_of_question"
                  label="No Of Questions"
                  type="number"
                /> */}
                <RHFTextField
                  name="duration"
                  label="Duration (In Minutes)"
                  type="number"
                />
                <RHFTextField
                  name="eachQuestionMarks"
                  label="Marks for each Question"
                  type="number"
                />
              </Stack>

              <Stack>
                <Typography variant="h6" marginBottom={"0.5rem"}>
                  Add Subjects
                </Typography>
                {subjects.map((subject) => (
                  <Paper
                    key={subject.id}
                    elevation={3}
                    sx={{ p: 2, mb: 2, backgroundColor: "transparent" }}
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <TextField
                        label="Subject Name"
                        variant="outlined"
                        value={subject.name}
                        onChange={(e) =>
                          handleChange(subject.id, "name", e.target.value)
                        }
                        fullWidth
                      />
                      <FormControl fullWidth>
                        <InputLabel>Subjects</InputLabel>
                        <Select
                          multiple
                          value={subject.subject_id}
                          onChange={(e) =>
                            handleChange(
                              subject.id,
                              "subject_id",
                              e.target.value
                            )
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected &&
                                selected.map((value, selectedIndex) => {
                                  const subjectName = subjectList.find(
                                    (sub) => sub.id === value
                                  )?.name;
                                  return (
                                    <Chip
                                      key={value}
                                      label={`${
                                        selectedIndex + 1
                                      }. ${subjectName}`}
                                    />
                                  );
                                })}
                            </Box>
                          )}
                        >
                          {subjectList.map((subjectOption, index) => (
                            <MenuItem
                              key={subjectOption?.id}
                              value={subjectOption?.id}
                            >
                              {`${index + 1}. ${subjectOption?.name}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <IconButton
                        onClick={() => handleDelete(subject.id)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                   
                  </Paper>
                ))}
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAdd}
                  sx={{ mt: 2, py: 0.5, px: 2, width: "10%" }}
                >
                  + Add
                </Button>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {/* <RHFTextField name="validity" label="Validity ()" /> */}
                <RHFTextEditor name="instructions" label="Instructions" />
              </Stack>

              {/* <RHFTextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                /> */}
            </Stack>

            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <RHFCheckbox name="remember" label="Remember me" />
                <Link variant="subtitle2" underline="hover" sx={{ color: '#EA506B' }}>
                    Forgot password?
                </Link>
            </Stack> */}

            {/* progress Bar */}
            <div className="mainDiv">
              <div className="childDiv" style={{ width: `${progress}%` }}>
                <span>{progress}% </span>
              </div>
            </div>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                width: "80%",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
              onClick={() => {
                setMsg("Required");
                setVal(true);
                // setMsgDemoVideo('Demo Video Required')
              }}
            >
              Add
            </LoadingButton>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <Typography sx={{ color: 'red', textAlign: 'center' }}>{fetchMessage}</Typography> */}
        </Stack>
      </Page>
    </>
  );
};

export default AddTest;
