import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Typography,
  MenuItem,
  InputLabel,
  Grid,
  TextField, Autocomplete,
  IconButton,
  Button,
  Box,
  Chip,
  FormControl,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { ToastContainer, toast } from "react-toastify";
import CategoryComponent from "../../../utils/CategoryComponent";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import { getFileUrl } from "../../../utils/firebaseUtils";
import axios from "axios";
import MultiLevelSelect from "react-select-multi-level";
import { Delete } from "@mui/icons-material";
import { getGroups } from "../../../utils";
// import { options, options, options, options } from "numeral";

// ----------------------------------------------------------------------

export default function AddSubscription() {
  const streamCategory = [
    {
      id: "class",
      title: "School Exams",
    },
    {
      id: "central-exam",
      title: "Central Exams",
    },
    {
      id: "state-exam",
      title: "State Exams",
    },
  ];

  const [examCategory, setExamCategory] = useState("");
  const [exam, setExam] = useState("");
  const [accessContent, setAccessContent] = useState([]);
  const [courses, setCourses] = useState([]);
  const [testSeries, setTestSeries] = useState([]);
  const [data, setData] = useState([]);

  const accessContentOptions = [
    { label: "Explore Subjects", value: "explore-subject" },
    { label: "Mock Tests", value: "mock-test" },
    { label: "Courses", value: "courses" },
    { label: "Test Series", value: "testseries" },
    { label: "PYP", value: "pyps" },
    { label: "Subscription", value: "subscription" },
  ];

  const handleAccessContentChange = (index, event) => {
    const {
      target: { value },
    } = event;
    const newPlans = subscriptionPlans.map((plan, i) =>
      i === index
        ? {
          ...plan,
          accessToContent:
            typeof value === "string" ? value.split(",") : value,
        }
        : plan
    );
    setSubscriptionPlans(newPlans);
  };

  const handleCoursesChange = (index, event) => {
    const {
      target: { value },
    } = event;
    const newPlans = subscriptionPlans.map((plan, i) =>
      i === index
        ? {
          ...plan,
          course_id: typeof value === "string" ? value.split(",") : value,
        }
        : plan
    );
    setSubscriptionPlans(newPlans);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/select-subscription`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      console.log(responseJson?.data);
      setData(responseJson.data);
      setLoading(false);

    }

    fetchUsers();
  }, []);
  const handleTestSeriesChange = (index, event) => {
    const {
      target: { value },
    } = event;
    const newPlans = subscriptionPlans.map((plan, i) =>
      i === index
        ? {
          ...plan,
          testseries_id: typeof value === "string" ? value.split(",") : value,
        }
        : plan
    );
    setSubscriptionPlans(newPlans);
  };

  const updateAccessContent = (type, values) => {
    setAccessContent((prev) => {
      let updatedContent = [...prev];
      if (type === "Courses") {
        updatedContent = updatedContent.filter(
          (content) =>
            !coursesOptions.map((option) => option.label).includes(content)
        );
        values.forEach((value) => {
          const selectedOption = coursesOptions.find(
            (option) => option.value === value
          );
          if (
            selectedOption &&
            !updatedContent.includes(selectedOption.label)
          ) {
            updatedContent.push(selectedOption.label);
          }
        });
      } else if (type === "Test Series") {
        updatedContent = updatedContent.filter(
          (content) =>
            !testSeriesOptions.map((option) => option.label).includes(content)
        );
        values.forEach((value) => {
          const selectedOption = testSeriesOptions.find(
            (option) => option.value === value
          );
          if (
            selectedOption &&
            !updatedContent.includes(selectedOption.label)
          ) {
            updatedContent.push(selectedOption.label);
          }
        });
      }
      return updatedContent;
    });
  };

  const handleDeleteAccessContent = (content) => {
    setAccessContent((prev) => prev.filter((c) => c !== content));
    const selectedCourseOption = coursesOptions.find(
      (option) => option.label === content
    );
    if (selectedCourseOption) {
      setCourses((prev) =>
        prev.filter((course) => course !== selectedCourseOption.value)
      );
    }
    const selectedTestSeriesOption = testSeriesOptions.find(
      (option) => option.label === content
    );
    if (selectedTestSeriesOption) {
      setTestSeries((prev) =>
        prev.filter((series) => series !== selectedTestSeriesOption.value)
      );
    }
  };

  const [coursesOptions, setCoursesOptions] = useState([]);
  const [testSeriesOptions, setTestSeriesOptions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [exam_groups_id, setExamGroupsId] = useState("")

  // const courseOptions = ["Course 1", "Course 2", "Course 3"]; // Replace with actual course options
  // const testSeriesOptions = ["Test Series 1", "Test Series 2", "Test Series 3"];

  const navigate = useNavigate();
  const [fetchMessage, setFetchMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState();
  const [showStartingDate, setShowStartingDate] = useState(false);
  const [showEndingDate, setShowEndingDate] = useState(false);
  const [focus, setFocused] = useState(false);
  const [focusTwo, setFocusedTwo] = useState(false);
  const [image_url, setImageUrl] = useState("");
  const [focusThree, setFocusedThree] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategories, setSubCategories] = useState(null);
  const [states, setStates] = useState();
  const [boards, setBoards] = useState();
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const changeHandler = async (event) => {
    setImageLoading(true);
    const url = await getFileUrl(event.target?.files[0]);
    setImageUrl(url);
    setImageLoading(false);
  };

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    // amount: Yup.number().required("Amount Required"),
    // start_date: Yup.string().required("Starting Date Required"),
    // end_date: Yup.string().required("Ending Date Required"),
    // course: Yup.string().required("Category Required"),
    // validity: Yup.number().required("Validity Required"),
    description: Yup.string().required("Deswcription Required"),
    is_active: Yup.string().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    discount: 0,
    course: "central-exam",

    // category: '',

    language: "",
    is_active: "true",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    console.log("hello");
    if (imageLoading) {
      toast.warning("Image is loading please wait.");
      return;
    }
    setLoading(true);

    let token = localStorage.getItem("token");

    try {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/add-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ...data,
            course: selectedCategory,
            exam_groups_id:exam_groups_id,
            state_exam_id: selectedState,
            board_id: selectedBoard,
            exam_id: centralExam || stateExam,
            class_id: classVal,
            // accessToContent: accessContent,
            // course_id: courses,
            // testseries_id: testSeries,
            plan: subscriptionPlans,
            image_url: image_url,
          }),
        }
      );

      const result = await res.json();
      if (result.response_code === 200) {
        toast.success(result.message);
        reset();
        window.location.href = "/dashboard/subscription";
      } else {
        toast.error(result?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const dataGroup = await getGroups();
      setGroups(dataGroup);
      const response = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        //  `${process.env.REACT_APP_LIVE_URL}/adminPanel/get-subcategory/eSCuWT792yKq1ZBSP4RKS`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch languages");
      }
      const data = await response.json();
      setLanguages(data.data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };



  const handleAddPlan = () => {
    setSubscriptionPlans([
      ...subscriptionPlans,
      {
        amount: "",
        validity: "",
        discounted_amount: "",
        accessToContent: [],
        course_id: [],
        testseries_id: [],
        subscription_id: "",
        subscription_plan_id: "",
        plans: [],
      },
    ]);
  };

  // Function to handle changes in the plan fields (amount, validity, discounted_amount, etc.)
  const handlePlanChange = (index, name, value) => {
    const newPlans = subscriptionPlans.map((plan, i) =>
      i === index ? { ...plan, [name]: value } : plan
    );
    setSubscriptionPlans(newPlans);
  };

  // Function to handle deletion of a plan
  const handlePlanDelete = (index) => {
    const newPlans = subscriptionPlans.filter((_, i) => i !== index);
    setSubscriptionPlans(newPlans);
  };



  // Function to handle changes in subscription_id and subscription_plan_id for child plans
  const handlePlanChangeArr = (planIndex, subIndex, name, value) => {
    const newPlans = subscriptionPlans.map((plan, i) =>
      i === planIndex
        ? {
            ...plan,
            plans: plan.plans.map((sub, sIdx) =>
              sIdx === subIndex ? { ...sub, [name]: value } : sub
            ),
          }
        : plan
    );
    setSubscriptionPlans(newPlans);
  };

  // Function to add a new subscription row under the main plan
  const addSubscriptionRow = (planIndex) => {
    const newPlans = subscriptionPlans.map((plan, i) =>
      i === planIndex
        ? {
            ...plan,
            plans: [...plan.plans, { subscription_id: "", subscription_plan_id: "" }],
          }
        : plan
    );
    setSubscriptionPlans(newPlans);
  };

  // Function to remove a subscription row
  const removeSubscriptionRow = (planIndex, subIndex) => {
    const newPlans = subscriptionPlans.map((plan, i) =>
      i === planIndex
        ? {
            ...plan,
            plans: plan.plans.filter((_, sIdx) => sIdx !== subIndex),
          }
        : plan
    );
    setSubscriptionPlans(newPlans);
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);

  const handleCategoryChange = async (event) => {
    const selectedCategoryId = event.target.value;
    console.log(selectedCategoryId);
    console.log(event.target.value);
    setStates(null);
    setBoards(null);
    setSubCategories(null);
    setSelectedBoard(null);
    setSelectedState(null);

    setIsCategoryLoading(true);
    setSelectedCategory(selectedCategoryId);

    const token = localStorage.getItem("token");
    switch (selectedCategoryId) {
      case "central-exam":
        const centralExams = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-central-exams`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSubCategories(centralExams?.data?.data);
        console.log(centralExams?.data?.data);

        break;

      case "state-exam":
        const stateList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-states`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(stateList?.data?.data);
        setStates(stateList?.data?.data);
        break;

      case "class":
        const boardsList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-boards`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(boardsList?.data?.data)
        setBoards(boardsList?.data?.data);
        break;

      default:
        break;
    }
    // Fetch subcategories based on the selected category
    setIsCategoryLoading(false);
  };

  const handleStateChange = async (e) => {
    setIsCategoryLoading(true);
    setSelectedState(e.target.value);
    const currentState = e.target.value;
    setSubCategories(null);
    const stateExamsList = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-state-exams`,
      { state_exam_id: currentState }
    );
    console.log(stateExamsList.data);
    setSubCategories(stateExamsList.data.data);
    // setCoursesOptions(
    //   stateExamsList.data.data?.map((courseList) => ({
    //     label: courseList.name,
    //     value: courseList.id,
    //   }))
    // );
    setIsCategoryLoading(false);
  };

  const handleBoardChange = async (e) => {
    setIsCategoryLoading(true);
    const currentBoard = e.target.value;
    setSelectedBoard(e.target.value);
    setSubCategories(null);
    const boardExamsList = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-classes`,
      { board_id: currentBoard }
    );
    console.log(boardExamsList.data);
    setSubCategories(boardExamsList.data.data);

    setIsCategoryLoading(false);
  };

  const handleSubCategoryChange = (e) => {
    setClass(null);
    setStateExam(null);
    setCentralExam(null);

    if (boards) setClass(e.target.value);
    else if (states) setStateExam(e.target.value);
    else setCentralExam(e.target.value);

  };

  // const [testSeries, setTestSeries] = useState();
  useEffect(() => {
    // #region Fetch test series based on the selected category
    const fetchCentralTestSeries = async () => {
      const token = localStorage.getItem("token");
      if (!centralExam || selectedCategory != "central-exam") return;
      console.log(centralExam);
      const testSeriesList = await axios.get(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-select-testseries`,
        // `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-select-testseries?exam_id=${centralExam}&course=${selectedCategory}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(testSeriesList?.data?.data);
      setTestSeriesOptions(
        testSeriesList?.data?.data?.map((testSeriesList) => ({
          label: testSeriesList.name,
          value: testSeriesList.id,
        }))
      );
    };


    // #region Fetch courses based on the selected category

    const fetchCenteralCourses = async () => {
      const token = localStorage.getItem("token");
      if (!subCategories || selectedCategory != "central-exam") return;
      const coursesList = await axios.get(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-select-course`,
        // `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-select-course?exam_id=${subCategories[0]?.id}&course=${selectedCategory}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(coursesList?.data?.data);
      setCoursesOptions(
        coursesList?.data?.data?.map((courseList) => ({
          label: courseList.name,
          value: courseList.id,
        }))
      );
    };

    fetchCentralTestSeries();
    
    fetchCenteralCourses();
   
  }, []);

  const changeGroupHandler =(e)=>{
    setExamGroupsId(e.target.value)
  
 }
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Subscription</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />

            <Stack direction={"row"} spacing={3}>


              <RHFTextField
                name="file"
                onChange={changeHandler}
                id="file"
                label="Subscription Image"
                // error={!file && val}
                required
                sx={{ width: "50%" }}
                helperText="Image Only"
                onFocus={() => setFocusedThree(true)}
                onBlur={() => setFocusedThree(false)}
                // inputProps={{ multiple: true }}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                onClick={() => setPdf(true)}
                inputProps={{ accept: "image/*" }}
                type={pdf || focusThree ? "file" : "text"}
              />
            </Stack>

            <Stack direction={"row"} spacing={3}>
              <RHFTextField
                name="category"
                label="Category"
                sx={{ width: "50%" }}
                select
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                {streamCategory?.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </RHFTextField>

              {states && (
                <RHFTextField
                  name="states"
                  label="State"
                  sx={{ width: "50%" }}
                  select
                  value={selectedState}
                  onChange={handleStateChange}
                >
                  {states?.map((state, index) => (
                    <MenuItem key={index} value={state.id}>
                      {state.name}
                    </MenuItem>
                  ))}
                </RHFTextField>
              )}

              {boards && (
                <RHFTextField
                  name="boards"
                  label="Boards"
                  sx={{ width: "50%" }}
                  select
                  value={selectedBoard}
                  onChange={handleBoardChange}
                >
                  {boards?.map((board, index) => (
                    <MenuItem key={index} value={board.id}>
                      {board.name}
                    </MenuItem>
                  ))}
                </RHFTextField>
              )}

              <RHFTextField
                name="subCategory"
                label="Subcategory"
                sx={{ width: "50%" }}
                select
                onChange={handleSubCategoryChange}
              >
                {/* //!This subcategory is working for all categories simultaneously */}

                {subCategories?.map((subcategory, index) => (
                  <MenuItem key={index} value={subcategory.id}>
                    {subcategory?.name || subcategory?.class_name}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Stack>

            <RHFTextField
                name="exam_groups_id"
                label="Exam groups"
                sx={{ width: "50%" }}
                select
                value={exam_groups_id}
                onChange={changeGroupHandler}
              >
                {groups?.map((board, index) => (
                  <MenuItem key={index} value={board.id}>
                    {board.name}
                  </MenuItem>
                ))}
              </RHFTextField>

            {/* #region add subscription  */}
            <Grid item xs={12}>
              <Typography variant="h6">Subscription Plans</Typography>
              <div>
                

                {subscriptionPlans.map((plan, index) => (
                  <Stack direction="column" gap="0.5rem" key={index}>
                    <Grid container spacing={2} alignItems="center" marginTop="1.3rem">
                      <Grid item xs={4}>
                        <TextField
                          name="amount"
                          label="Pricing"
                          fullWidth
                          value={plan.amount}
                          onChange={(e) => handlePlanChange(index, "amount", e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="validity"
                          label="Validity (In Days)"
                          fullWidth
                          value={plan.validity}
                          onChange={(e) => handlePlanChange(index, "validity", e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="discounted_amount"
                          label="Discounted Price"
                          fullWidth
                          value={plan.discounted_amount}
                          onChange={(e) => handlePlanChange(index, "discounted_amount", e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton color="error" onClick={() => handlePlanDelete(index)}>
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <FormControl fullWidth>
                      <InputLabel>Access to Content</InputLabel>
                      <Select
                        multiple
                        value={plan.accessToContent}
                        onChange={(e) => handleAccessContentChange(index, e)}
                        input={<OutlinedInput label="Access to Content" />}
                        renderValue={(selected) =>
                          selected
                            .map(
                              (value) =>
                                accessContentOptions.find((option) => option.value === value)?.label || value
                            )
                            .join(", ")
                        }
                      >
                        {accessContentOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <Checkbox checked={plan.accessToContent.indexOf(option.value) > -1} />
                            <ListItemText primary={option.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2}>
                        {plan.accessToContent.includes("courses") && (
                          <FormControl sx={{ width: "50%" }}>
                            <InputLabel>Courses</InputLabel>
                            <Select
                              multiple
                              value={plan.course_id}
                              onChange={(e) => handleCoursesChange(index, e)}
                              input={<OutlinedInput label="Courses" />}
                              renderValue={(selected) =>
                                selected
                                  .map(
                                    (value) =>
                                      coursesOptions.find((option) => option.value === value)?.label || value
                                  )
                                  .join(", ")
                              }
                            >
                              {coursesOptions.map((course) => (
                                <MenuItem key={course.value} value={course.value}>
                                  <Checkbox checked={plan.course_id.indexOf(course.value) > -1} />
                                  <ListItemText primary={course.label} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}

                        {plan.accessToContent.includes("subscription") && (
                          <div style={{ width: "100%" }}>
                            {plan.plans.map((subPlan, subIndex) => (
                              <div
                                key={subIndex}
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  gap: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <FormControl sx={{ width: "45%" }}>
                                  <Autocomplete
                                    options={data}
                                    getOptionLabel={(option) => option.title}
                                    value={data.find((opt) => opt.id === subPlan.subscription_id) || null}
                                    onChange={(e, newValue) =>
                                      handlePlanChangeArr(index, subIndex, "subscription_id", newValue?.id)
                                    }
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                  />
                                </FormControl>

                                <FormControl sx={{ width: "45%" }}>
                                  <Autocomplete
                                    options={
                                      data.find((sub) => sub.id === subPlan.subscription_id)?.plan || []
                                    }
                                    getOptionLabel={(plan) =>
                                      "₹" + plan.amount + " / " + plan.validity + " Day"
                                    }
                                    value={
                                      data
                                        .find((sub) => sub.id === subPlan.subscription_id)
                                        ?.plan.find((p) => p.id === subPlan.subscription_plan_id) || null
                                    }
                                    onChange={(e, newValue) =>
                                      handlePlanChangeArr(
                                        index,
                                        subIndex,
                                        "subscription_plan_id",
                                        newValue?.id
                                      )
                                    }
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                  />
                                </FormControl>

                                <IconButton
                                  color="error"
                                  onClick={() => removeSubscriptionRow(index, subIndex)}
                                >
                                  <Delete />
                                </IconButton>
                              </div>
                            ))}
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => addSubscriptionRow(index)}
                            >
                              Add Subscription
                            </Button>
                          </div>
                        )}

                        {plan.accessToContent.includes("testseries") && (
                          <FormControl sx={{ width: "50%" }}>
                            <InputLabel>Test Series</InputLabel>
                            <Select
                              multiple
                              value={plan.testseries_id}
                              onChange={(e) => handleTestSeriesChange(index, e)}
                              input={<OutlinedInput label="Test Series" />}
                              renderValue={(selected) =>
                                selected
                                  .map(
                                    (value) =>
                                      testSeriesOptions.find((option) => option.value === value)?.label || value
                                  )
                                  .join(", ")
                              }
                            >
                              {testSeriesOptions.map((testSeries) => (
                                <MenuItem key={testSeries.value} value={testSeries.value}>
                                  <Checkbox checked={plan.testseries_id.indexOf(testSeries.value) > -1} />
                                  <ListItemText primary={testSeries.label} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Stack>
                    </Grid>
                  </Stack>
                ))}
              </div>
              <Button
                variant="outlined"
                onClick={handleAddPlan}
                style={{ marginTop: 16 }}
              >
                + Add
              </Button>
            </Grid>

            <Stack direction="row" spacing={3}>
              {/* <RHFTextField name="category" label="Category" select>
                            {users?.map((item) => { return <MenuItem value={item.title}>{item.title}</MenuItem> })}



                        </RHFTextField> */}
              {/* <RHFTextField
                name="validity"
                label="Validity(in months)"
                type="number"
              /> */}
              <RHFTextField
                name="is_active"
                label="Status"
                type="checkbox"
                select
              >
                <MenuItem value={"true"}>publish</MenuItem>
                <MenuItem value={"false"}>Draft</MenuItem>
              </RHFTextField>
            </Stack>

            <Stack>
              <RHFTextEditor name="description" label="Description" />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                marginTop: "5%",
                "&:hover": { background: " #8080ff" },
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}

// const paln=[{validity:3,amount:399}]
