import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Box,
  FormControl,
  Autocomplete,
  Grid
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "../../../components/hook-form";

import { useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import axios from "axios";

import Page from "../../../components/Page";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TagsInput } from "react-tag-input-component";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.css";

import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import { getFileUrl } from "../../../utils/firebaseUtils";
import { AccessTime } from "@mui/icons-material";
import { getGroups } from "../../../utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AddBatch = () => {
  // const [users, usersSet] = useState([]);
  const [subject, setSubject] = useState([]);
  const [allteachers, teacherSet] = useState([]);
  const [teacher, setteacher] = useState([]);
  const [subjectList, setsubjectList] = useState([]);
  const [loading, setLoading] = useState(true);

  // const [streamCategory, setStreamCategory] = useState([]);

  const [languages, setLanguages] = useState([]);

  const navigate = useNavigate();
  const [type, setType] = useState(true);
  const [selected, setSelected] = useState([]);
  const [uploadSize, setUploadSize] = useState({
    completed: 0,
    totalSize: 0,
  });

  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [submitted, setSubmitted] = React.useState(false);

  // combo
  const [combo, setCombo] = useState([]);
  const [comboChecked, setComboChecked] = useState(false);
  const [testSeriesOptions, setTestSeriesOptions] = useState([]);
  const [loadingTestSeries, setLoadingTestSeries] = useState(false);
  const [testSeriesValue, setTestSeriesValue] = useState();
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [states, setStates] = useState();
  const [boards, setBoards] = useState();
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [tags, setTags] = useState([]);
  const [groups, setGroups] = useState([]);
  
  const [lifetimeAccess, setLifetimeAccess] = useState(false);


  const [pdfFocus, setPdfFocused] = useState(false);
  const [pdf, setPdf] = useState();

  const streamCategory = [
    {
      id: "class",
      title: "School Exams",
    },
    {
      id: "central-exam",
      title: "Central Exams",
    },
    {
      id: "state-exam",
      title: "State Exams",
    },
  ];

  const theme = useTheme();
  const LoginSchema = Yup.object().shape({
    // file: Yup.mixed().required('File is required'),
    batch_name: Yup.string().required("Name is required"),
    // category: Yup.string().required('category is required'),
    //exam_type: Yup.string().required("Exam type is required"),
    // subject: Yup.array().required('Subject is required'),
    // subjects: Yup.array().min(1, 'Subject is required').required('Subject is required'),
    // teacherEmail: Yup.string().required('teacherEmail is required'),

    starting_date: Yup.string().required("Starting Date is required"),
    // ending_date: Yup.string().required("Ending Date is required"),
    //mode: Yup.string().required("mode is required"),
    materials: Yup.string().required("materials is required"),
    // discount: Yup.string().required('discount is required'),
    charges: Yup.string().required("charges is required"),
    description: Yup.string().required("descriptoin is required"),
    // file: Yup.string().required('banner is required'),
    language: Yup.string().required("language is required"),
  
    is_active: Yup.boolean().required("Status Required is required"),
  });

  const defaultValues = {
    batch_name: "",
    // category: '',
    exam_type: "",
    //stream: "",
    category: "",
    // subject: '',
    // teacherEmail: '',
    starting_date: "",
    ending_date: "",
    mode: "",
    materials: "",
    discount: "0",
    charges: "",
    description: "",
    // file: null,
    language: "",
    remark: "",
    validity: "",
    registration_end_date: "",

    // course_review: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [file, setFile] = useState();
  // const [demoVideo, setDemoVideo] = useState();
  const [msg, setMsg] = useState("");

  const changeHandler = async (event) => {
    setFile(
      event.target.files[0] ? await getFileUrl(event.target.files[0]) : null
    );
  };
  // const changeHandlerTwo = async (event) => {
  //   if (!type) {
  //     setDemoVideo(event.target.files);
  //   } else {
  //     setDemoVideo(event.target.value);
  //   }
  //   // setDemoVideo(event.target.files)
  // };

  //Add Details'
  const [exam_groups_id, setExamGroupsId] = useState("")

  const onSubmit = async (data) => {
    setShowProgress(true);
    setLoading(true);

    const payload = {
      course_banner: file,
      combo: comboChecked && testSeriesValue ? "true" : "false",
      testseries_id: (comboChecked && testSeriesValue) ?? testSeriesValue,
      materials: data?.materials,
      exam_groups_id: exam_groups_id,
      subject: subjectList.map((sub) => sub?.id),
      demoVideo: selected,
      teachers: teacher,
      name: data?.batch_name,
      course: selectedCategory,
      state_exam_id: selectedState,
      board_id: selectedBoard,
      exam_id: centralExam || stateExam,
      class_id: classVal,
      subCategory: data?.subCategory,
      exam_type: data?.exam_type,
      starting_date: data?.starting_date,
      ending_date: data?.ending_date,
      mode: data?.mode,
      discount: data?.discount,
      charges: data?.charges,
      description: data?.description,
      language: data?.language,
      remark: data?.remark,
      registration_end_date: data?.registration_end_date,
      course_review: "Review",
      is_active: data?.is_active ? "true" : "false",
      access: lifetimeAccess ? 1 : 0,

      curriculum: pdf ? await getFileUrl(pdf) : "",
      tags: [...tags],
    };

    let token = localStorage.getItem("token");
    console.log("payload-->>>" + payload);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);

        setUploadSize((prev) => ({
          completed: Math.floor(loaded / 1000000),
          totalSize: Math.floor(total / 1000000),
        }));
        // setInterval(() => {
        //     if (percent < 90) {
        //         setProgress(prev => prev + 10)
        //     }
        // }, 1200)
        if (percent < 90) {
          setProgress(percent);
        }
      },
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    };
    // formData.forEach((value, key) => {
    //   console.log(`${key}: ${value}`);
    // });
    console.log(payload);
    if (tags.length > 0) {
      axios
        .post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-course`,
          payload,
          config
        )
        .then((response) => {
          //console.log(response.data);
          console.log(response);

          if (response.data?.response_code === 200) {
            toast.success("Batch Details Added");
            setShowBanner(false);
            setShowEndingDate(false);
            setShowRegisterEnd(false);
            setShowStartingDate(false);
            const batchId = response.data?.data?.id; // Extracting the batch ID from the response
            navigate(`/dashboard/batchDetails/${batchId}`); // Redirecting to the batch details page with the extracted ID
            setProgress(100);
            setSubmitted(true);
            reset();
          } else toast.error(response.data?.message);
        })
        .catch((e) => {
          //console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (tags.length === 0) {
      setTimeout(() => {
        toast.error("Tags are Required");
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const data = await getGroups();
      setGroups(data)
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-admin`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      console.log(responseJson?.data);
      teacherSet(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  //Getting category
  useEffect(() => {
    const token = localStorage.getItem("token");

    async function fetchUsers() {
      setLoading(false);
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/classroom-subjects`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resSubject = await res.json();
      console.log("This is subject", resSubject);
      setSubject(resSubject.data);
      setLoading(false);
      // const fullResponseCategory = await fetch(
      //   `${process.env.REACT_APP_LIVE_URL}/adminPanel/get-subcategory/jWXUL6FI9m8vUuj7KLeFM`,
      //   {
      //     method: "GET",

      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // const responseJsonCatergory = await fullResponseCategory.json();
      // setStreamCategory(responseJsonCatergory.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);
  // //console.log(streamCategory)

  // cambo
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (comboChecked) {
      // Fetch Test Series details for Combo
      setLoadingTestSeries(true);
      axios
        .get(
          `${process.env.REACT_APP_LIVE_URL_NEW
          }/admin/get-testseries-by-goal?course=${selectedCategory}&exam_id=${centralExam || stateExam
          }&class_id=${classVal}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setTestSeriesOptions(response.data?.data);
          console.log(response.data?.data);
          setLoadingTestSeries(false);
        })
        .catch((error) => {
          console.error("Error fetching Test Series details:", error);
          setLoadingTestSeries(false);
        });
    }
  }, [comboChecked]);

  const handleCategoryChange = async (event) => {
    const selectedCategoryId = event.target.value;
    console.log(selectedCategoryId);
    console.log(event.target.value);
    setStates(null);
    setBoards(null);
    setSubCategories(null);
    setSelectedBoard(null);
    setSelectedState(null);

    setIsCategoryLoading(true);
    setSelectedCategory(selectedCategoryId);

    const token = localStorage.getItem("token");
    switch (selectedCategoryId) {
      case "central-exam":
        const centralExams = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-central-exams`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSubCategories(centralExams?.data?.data);
        break;

      case "state-exam":
        const stateList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-states`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(stateList?.data?.data);
        setStates(stateList?.data?.data);
        break;

      case "class":
        const boardsList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-boards`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(boardsList?.data?.data)
        setBoards(boardsList?.data?.data);
        break;

      default:
        break;
    }
    // Fetch subcategories based on the selected category
    setIsCategoryLoading(false);
  };

  const handleStateChange = async (e) => {
    setIsCategoryLoading(true);
    setSelectedState(e.target.value);
    const currentState = e.target.value;
    setSubCategories(null);
    const stateExamsList = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-state-exams`,
      { state_exam_id: currentState }
    );
    console.log(stateExamsList.data);
    setSubCategories(stateExamsList.data?.data);
    setIsCategoryLoading(false);
  };

  const handleBoardChange = async (e) => {
    setIsCategoryLoading(true);
    const currentBoard = e.target.value;
    setSelectedBoard(e.target.value);
    setSubCategories(null);
    const boardExamsList = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-classes`,
      { board_id: currentBoard }
    );
    console.log(boardExamsList.data);
    setSubCategories(boardExamsList.data?.data);
    setIsCategoryLoading(false);
  };

  const handleSubCategoryChange = (e) => {
    setClass(null);
    setStateExam(null);
    setCentralExam(null);

    if (boards) setClass(e.target.value);
    else if (states) setStateExam(e.target.value);
    else setCentralExam(e.target.value);

    console.log("boards", classVal);
    console.log("states", stateExam);
    console.log("central", centralExam);
  };

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const languageData = await axios.post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (languageData?.status !== 200)
        throw new Error("Failed to fetch languages");

      setLanguages(languageData?.data?.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);

  const [focus, setFocused] = React.useState(false);
  const [focusTwo, setFocusedTwo] = React.useState(false);
  const [focusThree, setFocusedThree] = React.useState(false);
  const [focusFour, setFocusedFour] = React.useState(false);
  const [showStartingDate, setShowStartingDate] = React.useState(false);
  const [showEndingDate, setShowEndingDate] = React.useState(false);
  const [showRegisterEnd, setShowRegisterEnd] = React.useState(false);

  const [showBanner, setShowBanner] = React.useState(false);
  const [showDemoVideo, setShowDemoVideo] = React.useState(false);

  const changeHandler2 = async (event) => {
    setPdf(event.target?.files[0]);
    // const url = await getFileUrl(event.target?.files[0]);
    //console.log(file)
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setteacher(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeSubject = (event, newVal) => {
    // console.log(subjectList);
    setsubjectList(newVal);
  };

  const [val, setVal] = useState(false);
  const [bannerType, setBannerType] = useState(false);


  const handleLifetimeAccessChange = (event) => {
    setLifetimeAccess(event.target.checked);
  };

  const changeGroupHandler = (e) => {
    setExamGroupsId(e.target.value)

  }
  return (
    <>
      {/* //! BACKDROP LOADER 
     <div>
              
              <Backdrop
                sx={{ color: '#8080ff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                open={isCategoryLoading}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div> */}
      <Page title="Batch">
        <Stack spacing={3} ml={5}>
          <Typography className="page-heading">Add Course </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              <Stack direction={"row"} spacing={3}>
                <RHFTextField
                  name="batch_name"
                  label="Course Name"
                  sx={{ width: "100%" }}
                />
              </Stack>

              <Stack direction={"row"} spacing={3}>
                <RHFTextField
                  name="category"
                  label="Category"
                  sx={{ width: "50%" }}
                  select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  {streamCategory?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </RHFTextField>

                {states && (
                  <RHFTextField
                    name="states"
                    label="State"
                    sx={{ width: "50%" }}
                    select
                    value={selectedState}
                    onChange={handleStateChange}
                  >
                    {states?.map((state, index) => (
                      <MenuItem key={index} value={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </RHFTextField>
                )}

                {boards && (
                  <RHFTextField
                    name="boards"
                    label="Boards"
                    sx={{ width: "50%" }}
                    select
                    value={selectedBoard}
                    onChange={handleBoardChange}
                  >
                    {boards?.map((board, index) => (
                      <MenuItem key={index} value={board.id}>
                        {board.name}
                      </MenuItem>
                    ))}
                  </RHFTextField>
                )}

                <RHFTextField
                  name="subCategory"
                  label="Subcategory"
                  sx={{ width: "50%" }}
                  select
                  onChange={handleSubCategoryChange}
                >
                  {/* //!This subcategory is working for all categories simultaneously */}

                  {subCategories?.map((subcategory, index) => (
                    <MenuItem key={index} value={subcategory.id}>
                      {subcategory?.name || subcategory?.class_name}
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Stack>
           
              <RHFTextField
                name="exam_groups_id"
                label="Exam groups"
                sx={{ width: "50%" }}
                select
                value={exam_groups_id}
                onChange={changeGroupHandler}
              >
                {groups?.map((board, index) => (
                  <MenuItem key={index} value={board.id}>
                    {board.name}
                  </MenuItem>
                ))}
              </RHFTextField>
              <Stack direction="row" spacing={3}>
                <Stack direction="column" sx={{ width: "50%" }}>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    name="subject"
                    options={subject || []}
                    value={subjectList}
                    onChange={handleChangeSubject}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Select Subjects "
                        placeholder="Choose Subjects for this course"
                        value={subjectList}
                      />
                    )} required
                  />

                  {subjectList.length == 0 && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </Typography>
                  )}
                </Stack>

                <Stack direction="column" sx={{ width: "50%" }}>
                  <FormControl>
                    <InputLabel>Teacher</InputLabel>
                    <Select
                      label="teacher"
                      multiple
                      // error={teacher.length == 0 && val}
                      name="teacher"
                      value={teacher}
                      onChange={handleChange}
                      sx={{ width: "100%", color: "black", zIndex: 9 }}
                      MenuProps={MenuProps}
                    >
                      {allteachers?.map((name, index) => (
                        <MenuItem
                          key={index}
                          value={name.id}
                          style={getStyles(name, teacher, theme)}
                        >
                          {name?.name || "NA"}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* {teacher.length == 0 && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </Typography>
                  )} */}
                </Stack>
              </Stack>
              <RHFTextField
                name="exam_groups_id"
                label="Exam groups"
                sx={{ width: "50%" }}
                select
                value={exam_groups_id}
                onChange={changeGroupHandler}
              >
                {groups?.map((board, index) => (
                  <MenuItem key={index} value={board.id}>
                    {board.name}
                  </MenuItem>
                ))}
              </RHFTextField>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lifetimeAccess}
                        onChange={handleLifetimeAccessChange}
                        color="primary"
                      />
                    }
                    label="Lifetime Access"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <RHFTextField
                      name="starting_date"
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      InputProps={{
                        classes: {
                          input: "CustomTextField",
                        },
                      }}
                      label="Starting Date"
                      onClick={() => setShowStartingDate(true)}
                      type={showStartingDate || focus ? "date" : "text"}
                      sx={{ flex: 1 }}
                    />
                    {!lifetimeAccess && (
                      <RHFTextField
                        name="ending_date"
                        onFocus={() => setFocusedTwo(true)}
                        onBlur={() => setFocusedTwo(false)}
                        InputProps={{
                          classes: {
                            input: "CustomTextField",
                          },
                        }}
                        label="Ending Date"
                        onClick={() => setShowEndingDate(true)}
                        type={showEndingDate || focusTwo ? "date" : "text"}
                        sx={{ flex: 1 }}
                      />
                    )}
                    <RHFTextField
                      name="materials"
                      label="Course Type"
                      sx={{ flex: 1 }}
                      select
                    >
                      <MenuItem value="live">Live</MenuItem>
                      <MenuItem value="recorded">Recorded</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                    </RHFTextField>
                  </Stack>
                </Grid>
              </Grid>

              <Stack
                direction="row"
                alignItems="flex-start" // Change to flex-start to align inputs at the top
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField
                  name="charges"
                  label="Regular Price"
                  type="number"
                  id="custom-css-outlined-input"
                  sx={{ minHeight: "72px" }} // Ensure the same height to align with helperText
                />
                <RHFTextField
                  name="discount"
                  label="Discount Price"
                  type="number"
                  sx={{ minHeight: "72px" }} // Same height as above
                />

                <Stack
                  direction="column"
                  sx={{ width: "100%", minHeight: "72px" }}
                >
                  {" "}
                  {/* Same height to ensure alignment */}
                  <RHFTextField
                    name="file"
                    error={!file && val}
                    onChange={changeHandler}
                    onFocus={() => setFocusedThree(true)}
                    onBlur={() => setFocusedThree(false)}
                    inputProps={{ accept: "image/*" }}
                    helperText="Image with a size of 712x356 pixels, maintaining a 2:1 aspect ratio (jpeg,jpg,png,webp)"
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    label="Banner"
                    onClick={() => setShowBanner(true)}
                    type={showBanner || focusThree ? "file" : "text"}
                    sx={{
                      marginBottom: "-8px", // Adjust margin to align helper text better
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginTop: "4px", // Reduce space between input and helper text
                      },
                    }}
                  />
                  {!file && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </Typography>
                  )}
                </Stack>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <TagsInput
                      value={selected}
                      onChange={setSelected}
                      name="link"
                      placeHolder="Enter Youtube Links (Embedded Links)"
                      className="rti--input"
                    />
                    {/* {selected.length === 0 && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginTop: "5px",
                          marginLeft: "10px",
                          color: "red",
                        }}
                      >
                        {msg}
                      </Typography>
                    )} */}
                  </Stack>
                }

                <RHFTextField
                  name="language"
                  label="language"
                  sx={{ width: "100%" }}
                  select
                >
                  {languages?.map((language, index) => (
                    <MenuItem key={index} value={language?.medium_code}>
                      {language?.medium_name}
                    </MenuItem>
                  ))}
                  <MenuItem key="both" value={3}>
                    Both
                  </MenuItem>
                </RHFTextField>

                <RHFTextField
                  name="registration_end_date"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  label="Registeration End Date"
                  onClick={() => setShowRegisterEnd(true)}
                  type={showRegisterEnd || focus ? "date" : "text"}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField name="remark" label="Remark" />
                <RHFTextField
                  name="is_active"
                  label="Status"
                  sx={{ width: "100%" }}
                  select
                  defaultValue={true}
                  type="checkbox"
                >
                  <MenuItem value={true}>Publish</MenuItem>
                  <MenuItem value={false}> Draft </MenuItem>
                </RHFTextField>

                <RHFTextField
                  name="curriculum"
                  onChange={changeHandler2}
                  id="curriculum"
                  label="Curriculum (Optional)"
                  // error={!file && val}
                  sx={{ my: 2 }}
                  // helperText="Pdf Only"
                  onFocus={() => setPdfFocused(true)}
                  onBlur={() => setPdfFocused(false)}
                  // inputProps={{ multiple: true }}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPdf(true)}
                  inputProps={{ accept: "application/pdf" }}
                  type={pdf || pdfFocus ? "file" : "text"}
                />
              </Stack>

              <Stack direction="column" sx={{ width: "100%" }}>
                <TagsInput
                  value={tags}
                  onChange={setTags}
                  name="tags"
                  placeHolder="Enter Tags"
                  className="rti--input"
                />
                {tags.length === 0 && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "red",
                    }}
                  >
                    {"Tags are required"}
                  </Typography>
                )}
              </Stack>


              <RHFTextEditor name="description" label="Description" />
            </Stack>


            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                width: "80%",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
              onClick={() => {
                setMsg("Required");
                setVal(true);
                // setMsgDemoVideo('Demo Video Required')
              }}
            >
              Add
            </LoadingButton>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <Typography color={positive ? 'green' : "red"} sx={{ textAlign: 'center', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Stack>
      </Page>
    </>
  );
};

export default AddBatch;
