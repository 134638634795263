import React from "react";
import * as Yup from "yup";
import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams, useResolvedPath } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { User } from "../../../context/context";
import Page from "../../../components/Page";
import { useTheme } from "@mui/material/styles";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const UpdateQuiz = () => {
  const navigate = useNavigate();
  // const { setRole } = useContext(User)
  const { quizId } = useParams();
  const [loading, setLoading] = useState();
  const [languages, setLanguages] = useState([]);


  // making all the variables to store data
  // const [title,setTitle] = React.useState('');
  // const [description,setdescription] = React.useState('');
  // const [duration,setduration] = React.useState('');
  // const [language,setLanguage] = React.useState('');
  // const [NoOfQues,setNoOfQuestion] = React.useState('');
  // const [isNegative,setIsNegative] = React.useState(true);
  // const [isActive,setIsActive] = React.useState(false);
  const [file, setFile] = React.useState();
  const theme = useTheme();

  const LoginSchema = Yup.object().shape({});

  const [value, setValue] = React.useState({
    quiz_title: "",
    quiz_duration: "",
    is_active: "",
    is_negative: "",
    quiz_desc: "",
    negativeMarks: "",
    eachQueMarks: "",
    quiz_banner: "",
    language: "",
  });

  const [value2, setValue2] = React.useState("jhbj");
  const onChange2 = (e) => {
    const { name, value } = e.target;
    setValue((prev) => ({ ...prev, [name]: value }));
  };
  // variable default values
  const defaultValues = {
    quiz_title: "",
    quiz_desc: "",
    quiz_duration: "",
    language: "",
    is_active: "",
    is_negative: "",
    negativeMarks: "",
    eachQueMarks: "",
    quiz_banner: "",
  };
  //console.log(value);
  // to get predefines handlers for form and datahandling
  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
  };

  //Helper Function for geting the quiz data

  const addDetails = (TestSeriesDetails) => {
    let arr2 = [];
    TestSeriesDetails?.data?.map((item) => {
      arr2.push(item?._id);
    });
    // setteacher(arr2)
    setValue((prev) => ({
      ...prev,
      quiz_title: TestSeriesDetails?.quiz_title,
      quiz_banner: TestSeriesDetails?.quiz_banner[0],
      quiz_duration: TestSeriesDetails?.quiz_duration,
      quiz_desc: TestSeriesDetails?.quiz_desc,
      is_active: TestSeriesDetails?.is_active,
      is_negative: TestSeriesDetails?.is_negative,
      negativeMarks: TestSeriesDetails?.negativeMarks,
      eachQueMarks: TestSeriesDetails?.eachQueMarks,
      language: TestSeriesDetails?.language,

    }));
  };

  //console.log(value);

  // to get the quiz data
  const [testSeriesDetails, setTestSeriesDetails] = React.useState();
  // React.useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   async function fetchUsers() {
  //     const fullResponse = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/adminPanel/getQuizDetails/${quizId}`,
  //       {
  //         // const fullResponse = await fetch(`http://localhost:5000/api/v1/adminPanel/getQuizDetails/${quizId}`, {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const responseJson = await fullResponse.json();
  //     setTestSeriesDetails(responseJson?.data);

  //     addDetails(responseJson?.data);
  //   }

  //   fetchUsers();
  // }, []);
  // //console.log(testSeriesDetails)

  const [val, setVal] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
  };

  //Banner
  const [msg, setMsg] = useState("");
  const [focusThree, setFocusedThree] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(false);

  //Adding Details
  const onSubmit = async (data) => {
    setLoading(true);

    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    } else {
      formData.append("file", value?.quiz_banner);
    }

    formData.append("title", value?.quiz_title);
    formData.append("description", value?.quiz_desc);
    formData.append("duration", value?.quiz_duration);
    formData.append("language", value?.language);
    formData.append("isActive", value?.is_active);
    formData.append("isNegative", value?.is_negative);
    formData.append("negativeMarks", value?.negativeMarks);
    formData.append("eachQueMarks", value?.eachQueMarks);

    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(`/adminPanel/editQuiz/${quizId}`, formData, config)
      .then((response) => {
        // axios.put(`http://localhost:5000/api/v1/adminPanel/editQuiz/${quizId}`, formData, config).then((response) => {

        toast.success("Test Series Details Added");
        setShowBanner(false);

        if (response?.data?.msg === "Quiz Updated Successfully") {
          setLoading(false);
          setTimeout(() => {
            navigate("/dashboard/quiz");
          }, 1000);
          reset();
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };


  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {

      const languageData = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      });

      if (languageData?.status !== 200)
        throw new Error("Failed to fetch languages");

      setLanguages(languageData?.data?.data);

    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);


  return (
    <>
      <Page quiz_title="Test Series">
        <Stack spacing={3} ml={5}>
          <Typography variant="h3">Update Mock Test</Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              <RHFTextField
                name="quiz_title"
                value={value?.quiz_title}
                onChange={onChange2}
              />

              <RHFTextField
                name="quiz_desc"
                value={value?.quiz_desc}
                onChange={onChange2}
              />
              <Stack direction="row" spacing={3}>
                <RHFTextField
                  name="quiz_duration"
                  label="quiz_duration"
                  value={value?.quiz_duration}
                  type="number"
                  onChange={onChange2}
                />

                <RHFTextField
                  name="language"
                  label={value?.language}
                  onChange={onChange2}
                  sx={{ width: "100%" }}
                  select
                >
                  {languages?.map((language, index) => (
                    <MenuItem key={index} value={language?.medium_code}>
                      {language?.medium_name}
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Stack>
              <RHFTextField
                name="file"
                error={!file && val}
                onChange={changeHandler}
                onFocus={() => setFocusedThree(true)}
                onBlur={() => setFocusedThree(false)}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                label="Banner"
                onClick={() => setShowBanner(true)}
                type={showBanner || focusThree ? "file" : "text"}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {/* <RHFTextField name="no_ques" label='no_ques' value={value?.no_ques} onChange={onChange2} /> */}
                <RHFTextField
                  name="is_negative"
                  label="Allow Negative Marking"
                  sx={{ width: "100%" }}
                  select
                  value={value?.is_negative}
                  onChange={onChange2}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </RHFTextField>
                <RHFTextField
                  name="is_active"
                  label="Status"
                  sx={{ width: "100%" }}
                  select
                  onChange={onChange2}
                  value={value?.is_active}
                >
                  <MenuItem value={true}>Publish</MenuItem>
                  <MenuItem value={false}>Draft</MenuItem>
                </RHFTextField>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField
                  name="negativeMarks"
                  label="Negative Marking"
                  value={value?.negativeMarks}
                  onChange={onChange2}
                />
                <RHFTextField
                  name="eachQueMarks"
                  label="Each Question Marks"
                  value={value?.eachQueMarks}
                  onChange={onChange2}
                />
              </Stack>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                width: "80%",
                marginTop: "5%",
                "&:hover": { background: " #8080ff" },
              }}
            >
              Update
            </LoadingButton>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <Typography sx={{ color: 'red', textAlign: 'center' }}>{fetchMessage}</Typography> */}
        </Stack>
      </Page>
    </>
  );
};

export default UpdateQuiz;
