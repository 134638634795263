import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem, Select, InputLabel, } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, } from '../../../components/hook-form';


import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Page from '../../../components/Page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';









const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



const AssignTeacherToBatch = () => {

  const [allteachers, teacherSet] = useState([]);
  const [teacher, setteacher] = React.useState([]);
  const navigate = useNavigate()
  const { batchId } = useParams()
  const theme = useTheme();
  const LoginSchema = Yup.object().shape({
  });

  const defaultValues = {
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });


  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;




  //Assign Teachers
  const onSubmit = async (data) => {
    // //console.log(data);



    let token = localStorage.getItem("token");

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    let obj = {
      teachers: teacher,

    }
    //console.log(obj)
    axios.put(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/assign-teachers/${batchId}`, obj, config).then((response) => {
      console.log(response?.data)
      if (response?.data?.response_code == 200) {
        toast.success("Batch Details Updated")
        reset()
        setTimeout(() => {
          navigate('/dashboard/batches')
        }, 1000)
      }
      else {
        toast.error(response?.data?.errors || response?.data?.message || response?.data?.error || "Something went Wrong")
      }
    })
      .catch(e => {
        //console.log(e);
      })
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-admin`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      const responseJson = await fullResponse.json();
      console.log(responseJson?.data)
      teacherSet(responseJson?.data);
    }

    async function fetchAssignedTeacher() {
      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-course-by-id/${batchId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      const responseJson = await fullResponse.json();
      const teacherIds = responseJson?.data?.teachers.map(teacher => teacher.id);
      setteacher(teacherIds);
    }

    fetchUsers();
    fetchAssignedTeacher();
  }, []);




  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setteacher(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <>
      <Page title="Batch">
        <Stack spacing={3} ml={5}>
          <Typography variant="h2">Add Teacher To Batch </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" sx={{ width: "40%" }}>
              <InputLabel
                sx={{
                  width: "20%",
                  marginRight: "20px",
                  alignSelf: "center",
                }}
              >
                Assign Teachers
              </InputLabel>
              <Select
                // label    ="Name"
                // id="demo-multiple-name"
                // labelId="test-select-label"
                label=""
                multiple
                name="teacher"
                value={teacher}
                onChange={handleChange}
                sx={{ width: "100%", color: "black", zIndex: 9 }}
                MenuProps={MenuProps}
              >
                {allteachers?.map((name) => (
                  <MenuItem
                    key={name}
                    value={name.id}
                    style={getStyles(name, teacher, theme)}
                  >
                    {name.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                width: "40%",
                marginTop: "2%",
                "&:hover": { background: " #8080ff" },
              }}
            >
              Add
            </LoadingButton>
          </FormProvider>
          {/* <Typography color={positive ? 'green' : "red"} sx={{ textAlign: 'center', textTransform: 'capitalize', width: '40%' }}>{fetchMessage}</Typography> */}
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Stack>
      </Page>
    </>
  );
}

export default AssignTeacherToBatch