import React from "react";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { useEffect } from "react";

// form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";

import { useTheme } from "@emotion/react";
import { getFileUrl } from "../../../utils/firebaseUtils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const LectureForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  // const { setRole } = useContext(User)
  // const [focus, setFocused] = React.useState(false);
  // const [resources, setResources] = React.useState(false);
  const [loading, setLoading] = useState(false);
  // const [focusThree, setFocusedThree] = React.useState(false);
  // const [focusTwo, setFocusedTwo] = React.useState(false);

  // const [showStartingDate, setShowStartingDate] = React.useState(false);
  // const [showEndingDate, setShowEndingDate] = React.useState(false);

  const [users, usersSet] = React.useState([]);
  const { Id, batchName } = useParams();
  const [positive, setPositive] = useState(false);
  const [fetchMessage, setFetchMessage] = useState();
  const [starting, setStarting] = React.useState(dayjs());
  const [ending, setEnding] = React.useState(dayjs());
  // const [languages, setLanguages] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [materialType, setMaterialType] = React.useState("");
  const [redirect, setRedirect] = useState(false);

  const [focusThree, setFocusedThree] = React.useState(false);
  const [showAudio, setShowAudio] = React.useState(false);

  const handleChangetag = (event) => {
    const {
      target: { value },
    } = event;
    setTagsList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChange = (newValue) => {
    setStarting(newValue);
  };
  const handleChangeTwo = (newValue) => {
    setEnding(newValue);
  };

  const LoginSchema = Yup.object().shape({
    lecture_title: Yup.string().required("Title is required"),
    // batch_name: Yup.string().required('Batch Name is required'),
    description: Yup.string().required("Description is required"),
    // LiveOrRecorded: Yup.string().required("Type is required"),
    // starting_date: Yup.string().required('Staring date is required'),
    // ending_date: Yup.string().required('Ending Date is required'),
    subject: Yup.string().required("Subject  required"),
    // language: Yup.string().required("language  required"),
    material_type: Yup.string().required("Material Type is Required"),
    //lecture_type: Yup.string().required("Lecture Type  required"),
    // link: Yup.string().required('Link  required'),
    // materials: Yup.string().required('Resources  required'),
  });

  const defaultValues = {
    lecture_type: 2,
    lecture_title: "",
    // batch_name: '',
    description: "",
    // starting_date: '',
    // ending_date: '',
    subjectid: "",
    // LiveOrRecorded: "",
    // language: "",
    remember: true,
    link: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const [file, setFile] = useState();
  const [val, setVal] = useState(false);

  function matchYoutubeUrl(url) {
    var p =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|live\/|embed\/)?([a-zA-Z0-9_-]{11})/;
    var matches = url.match(p);
    if (matches) {
      setValidateUrl(true);
      return matches[1];
    }
    return false;
  }

  const [utubeurl, setUtubeurl] = useState("");
  const [validateYoutubeUrl, setValidateUrl] = useState(false);

  function check(sender) {
    //console.log("huu");
    // var url = $('#txt').val();
    var id = matchYoutubeUrl(utubeurl);
    if (id) {
      setValidateUrl(true);
      //console.log("Cameeeeeeee");
      // alert(id);
    } else {
      //console.log("else of validate");
      setValidateUrl(false);
      // alert('Incorrect URL');
    }
  }

  const changeHandler = async (event) => {
    setFile(
      event.target.files[0] ? await getFileUrl(event.target.files[0]) : null
    );
  };
  let formatDate = (date) => {
    // console.log(dayjs(date).format('YYYY-MM-DD HH:mm:ss'))
    return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const onSubmit = async (data) => {
    // //console.log(data);
    let StartingDate = formatDate(starting.$d);
    let EndingDate = formatDate(ending.$d);
    console.log("submit function triggered");
    // console.log(validateYoutubeUrl);
    if (!youtubeVideo || validateYoutubeUrl || data?.material_type === "au") {
      setLoading(true);
      const formData = new FormData();
      // formData.append("file", file);
      // formData.append("lecture_type", data?.LiveOrRecorded);
      formData.append("title", data?.lecture_title);
      formData.append("description", data?.description);
      formData.append("lecture_type", data?.type);
      // formData.append("language", data?.language);
      formData.append("material_type", data?.material_type);
      formData.append(
        "material_url",
        materialType !== "vi" ? file : data?.type == "2" ? utubeurl : null
      );
      // if (youtubeVideo) {
      //   formData.append("link", utubeurl);
      // } else {
      //   formData.append("link", file);
      // }
      formData.append(
        "link",
        materialType === "vi" && data?.type == "1" ? utubeurl : null
      );

      if (youtubeVideo) {
        formData.append("LiveOrRecorded", data?.LiveOrRecorded);
      } else {
        formData.append("LiveOrRecorded", 1);
      }
      formData.append("subject", data?.subject);
      formData.append("course", Id);
      formData.append("starting_date", StartingDate);
      formData.append("ending_date", EndingDate);
      formData.append("tags", tagsList);

      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (tagsList.length > 0) {
        axios
          .post(
            `${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-lecture`,
            formData,
            config
          )
          .then((response) => {
            console.log(response?.data);
            setFetchMessage();

            if (response?.data?.response_code === 200) {
              if (redirect ) {
                navigate(`/dashboard/batchDetails/${Id}`);
              } else {
                toast.success("Added The Lecture Details");
                setLoading(false);
               window.location.reload();
              }

              setTimeout(() => {
                // navigate(`/dashboard/addCenterLecture`);
              }, 1000);
            } else {
              toast.error(response?.data?.message);
              setLoading(false);
            }
          })
          .catch((e) => {
            //console.log(e);
          });
      }
    }
    if (tagsList.length === 0) {
      setTimeout(() => {
        toast.error("Tags are Required");
        setLoading(false);
      }, 1000);
    }
  };

  // const fetchLanguages = async () => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const languageData = await axios.post(
  //       `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (languageData?.status !== 200)
  //       throw new Error("Failed to fetch languages");

  //     setLanguages(languageData?.data?.data);
  //   } catch (error) {
  //     console.error("Error fetching languages:", error);
  //   }
  // };

  // // Fetch languages when the component mounts
  // useEffect(() => {
  //   fetchLanguages();
  // }, []);

  // //console.log(starting.$d)
  // //console.log(ending.$d)
  const [lectureMedium, setLectureMedium] = React.useState();
  const [liveStatus, setLiveStatus] = useState(
    lectureMedium === "live" ? "1" : "2"
  );
  const [youtubeStatus, setYoutubeStatus] = useState("YT");
  //Getting Batch
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-course-by-id/${Id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await res.json();
      usersSet(responseJson?.data?.subjects);
      if (responseJson?.data) {
        setLectureMedium(responseJson?.data?.course?.materials);
      }

      // setLoading(false)
    }

    async function fetchTags() {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-tag-by-course-id/${Id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await res.json();
      setTags(responseJson?.data);
      // console.log(responseJson?.data);
    }
    fetchUsers();
    fetchTags();
  }, []);
  // //console.log(users);

  //Youtube Link
  const [youtubeVideo, setYoutubeVideo] = useState(true);
  const options = [
    { value: "1", label: "Live" },
    { value: "2", label: "Recorded" },
  ];

  // //console.log(utubeurl)

  return (
    <Grid pl={5}>
      <Typography variant="h2" ml={2}>
        Add Lecture {"  "}({batchName})
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Typography variant="h6" ml={2} mb={4}>
          Enter the below required details to create a lecture
        </Typography>
      </Stack>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {/* <Stack direction="row" spacing={5} > */}
        <Stack spacing={3} sx={{ width: "70%" }}>
          {/* <Controller
        control={control}
        defaultValue=""
        render={({ field }) => ( */}
          {/*
            <RHFTextField name="lecture_type" label="Lecture Type" select>
              <MenuItem
                value="YT"
                onClick={() => {
                  setYoutubeStatus("YT");
                  setYoutubeVideo(true);
                }}
              >
                YouTube
              </MenuItem>
              <MenuItem
                value="APP"
                onClick={() => {
                  setYoutubeStatus("APP");
                  setYoutubeVideo(true);
                }}
              >
                App
              </MenuItem>
            </RHFTextField>
              */}
          {/* //   )}
      // /> */}

          <RHFTextField name="lecture_title" label="Title " />

          {/* <RHFTextField
            name="description"
            label="Description "
            multiline
            rows={3}
          /> */}

          <Stack direction="row" spacing={3}>
            {/* <RHFTextField
              name="language"
              label="language"
              sx={{ width: "100%" }}
              select
            >
              {languages?.map((language, index) => (
                <MenuItem key={index} value={language?.medium_code}>
                  {language?.medium_name}
                </MenuItem>
              ))}
            </RHFTextField> */}
            <RHFTextField name="subject" label="Subject" select>
              {users?.map((sub, i) => {
                return (
                  <MenuItem key={i} value={sub.id}>
                    {sub.name}
                  </MenuItem>
                );
              })}
            </RHFTextField>
            <RHFTextField name="material_type" label="Material Type" select>
              <MenuItem value="au" onClick={() => setMaterialType("au")}>
                Audio
              </MenuItem>
              <MenuItem value="vi" onClick={() => setMaterialType("vi")}>
                Video
              </MenuItem>
            </RHFTextField>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            {materialType !== "au" ? (
              <Controller
                name="type"
                control={control}
                defaultValue={liveStatus}
                render={({ field }) => (
                  <RHFTextField
                    {...field}
                    label="Type"
                    select
                    onChange={(e) => {
                      setLiveStatus(e.target.value);
                      field.onChange(e); // Ensure the form value is updated
                    }}
                    value={field.value} // Set the value from the form state
                  >
                    {lectureMedium === "live" ? (
                      <MenuItem value="1">Live</MenuItem>
                    ) : lectureMedium === "recorded" ? (
                      <MenuItem value="2">Recorded</MenuItem>
                    ) : (
                      options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))
                    )}
                  </RHFTextField>
                )}
              />
            ) : (
              <Controller
                name="type"
                control={control}
                defaultValue={liveStatus}
                render={({ field }) => (
                  <RHFTextField
                    {...field}
                    label="Type"
                    select
                    value={field.value}
                    onChange={(e) => {
                      setLiveStatus("2");
                      field.onChange("2"); // Ensure the form value is updated
                    }}
                  >
                    <MenuItem value="2">Recorded</MenuItem>
                  </RHFTextField>
                )}
              />
            )}

            {materialType === "vi" ? (
              <RHFTextField
                name="link"
                label="Link"
                type="url"
                value={utubeurl}
                onChange={(e) => setUtubeurl(e.target.value)}
              />
            ) : (
              <Stack direction="column" sx={{ width: "100%" }}>
                <RHFTextField
                  name="file"
                  error={!file && val}
                  onChange={changeHandler}
                  onFocus={() => setFocusedThree(true)}
                  onBlur={() => setFocusedThree(false)}
                  inputProps={{ accept: "audio/*" }}
                  label="Audio"
                  onClick={() => setShowAudio(true)}
                  type={showAudio || focusThree ? "file" : "text"}
                />
                {!file && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "red",
                    }}
                  >
                    {"Audio Required"}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>

          {liveStatus === "1" && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack
                direction="row"
                spacing={3}
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: "16px",
                }}
              >
                <DateTimePicker
                  label="Starting time"
                  value={starting.$d}
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "100%" }} />
                  )}
                />
                <DateTimePicker
                  label="Ending time"
                  value={ending.$d}
                  onChange={handleChangeTwo}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%", color: "black" }}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          )}

          <Stack direction="column" sx={{ width: "100%" }}>
            <FormControl>
              <InputLabel>Tags</InputLabel>
              <Select
                label="Tags"
                multiple
                // error={teacher.length == 0 && val}
                name="teacher"
                value={tagsList}
                onChange={handleChangetag}
                sx={{ width: "100%", color: "black", zIndex: 9 }}
                MenuProps={MenuProps}
              >
                {tags?.map((name, index) => (
                  <MenuItem
                    key={index}
                    value={name.id}
                    style={getStyles(name, tagsList, theme)}
                  >
                    {name?.name || "NA"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {tagsList.length === 0 && (
              <Typography
                sx={{
                  fontSize: "12px",
                  marginTop: "5px",
                  marginLeft: "10px",
                  color: "red",
                }}
              >
                {"Tags are required!"}
              </Typography>
            )}
          </Stack>

          <RHFTextEditor name="description" label="Description" />
        </Stack>
        <Stack
          direction="row"
          spacing={3}
          mt={3}
          justifyContent="center"
          width={"70%"}
        >
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            onClick={() => {
              setRedirect(true);
              check();
            }}
            // onClick={() => navigate("/dashboard/batches")}
            sx={{
              bgcolor: "#e6e6ff",
              color: "black",
              width: "70%",
              marginTop: "5%",
              "&:hover": { background: "#8080ff" },
            }}
          >
            Add & Close
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            onClick={check}
            sx={{
              bgcolor: "#e6e6ff",
              color: "black",
              width: "70%",
              marginTop: "5%",
              "&:hover": { background: "#8080ff" },
            }}
          >
            Add & Continue
          </LoadingButton>
        </Stack>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
    </Grid>
  );
};

export default LectureForm;
