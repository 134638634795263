import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CategoryComponent from "../../../utils/CategoryComponent";
import { FormProvider, RHFTextField } from "../../../components/hook-form";

export default function UpdateCoupon() {
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [isGlobal, setIsGlobal] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [states, setStates] = useState();
  const [boards, setBoards] = useState();
  const [category, setCategory] = React.useState();



  const { id } = useParams(); // Get the coupon ID from URL params
  const navigate = useNavigate(); // For navigating after update

  const RegisterSchema = Yup.object().shape({
    coupon_code: Yup.string().required("Coupon code is required"),
    coupon_percentage: Yup.number()
      .required("Coupon percentage is required")
      .min(0)
      .max(100),
    start_from: Yup.date().required("Start date is required"),
    end_to: Yup.date().required("End date is required"),
    is_active: Yup.string().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    coupon_code: "",
    coupon_percentage: "",
    start_from: "",
    end_to: "",
    language: "",
    notes_type: "",
    is_active: "true",
    access: 1, // Default to "Lifetime" for initial load
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const formatDateForSubmission = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return date.toISOString().slice(0, 19).replace("T", " ");
  };
  const [categoryComponents, setCategoryComponents] = useState([
    {
      selectedCategory: null,
      classVal: null,
      centralExam: null,
      stateExam: null,
      selectedState: null,
      selectedBoard: null,
    },
  ]);
  const fetchCouponDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-coupon-by-id/${id}`,
        config
      );

      if (response?.data?.response_code === 200) {
        const couponData = response.data.data;

        // Set form values with fetched data
        reset({
          name: couponData.name,
          coupon_code: couponData.coupon_code,
          coupon_percentage: couponData.coupon_percentage,
          start_from: couponData.start_from,
          end_to: couponData.end_to,
          is_active: couponData.status === 1 ? "true" : "false",
          access: couponData.access,
        });

        // Set category and other related states
        setCategory(couponData?.course);
        // setSubCategories(responseJson.data?.subCategory);
        setStates(couponData?.state_exam_name);
        setStateExam(couponData?.exam_name);
        setBoards(couponData?.boards_name);
        setClass(couponData?.classes_name);
        setIsGlobal(couponData.global === 1);
        setIsPrivate(couponData.coupon_type == "0");
        const goalsData=couponData.goals?.map((goal)=>{
          return {
           id: goal.id,
           selectedBoard: goal.board_id,
         
           classVal: goal.class_id,
           selectedState: goal.state_exam_id,
           centralExam: goal.exam_id,
           selectedCategory: goal.course,
          }
        })
        console.log("Goals ",goalsData)
        setCategoryComponents(goalsData)
      }
    } catch (error) {
      console.error("Error fetching coupon details:", error);
    }
  };

  const removeCategoryComponent = (index) => {
    setCategoryComponents(categoryComponents.filter((_, i) => i !== index));
  };
  const onSubmit = async (data) => {
    setLoading(true);

    const formattedStartFrom = formatDateForSubmission(data.start_from);
    const formattedEndTo = formatDateForSubmission(data.end_to);

    const payload = {
      //   course: selectedCategory,
      //   board_id: selectedBoard,
      //   class_id: classVal,
      //   state_id: selectedState,
      //   exam_id: centralExam || stateExam,
      name: data.name,
      coupon_code: data.coupon_code,
      coupon_percentage: data.coupon_percentage,
      start_from: formattedStartFrom,
      end_to: formattedEndTo,
      status: data.is_active === "true" ? 1 : 0,
      access: data.access,
      global: isGlobal ? 1 : 0,
      goals:categoryComponents,
      coupon_type: isPrivate ? 0 : 1,

    };

    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-coupon/${id}`,
        payload,
        config
      );

      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message);
        setLoading(false);

        setTimeout(() => {
          navigate("/dashboard/allCoupon"); // Redirect to the coupons list after successful update
        }, 1500);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCouponDetails();
  }, []);

  useEffect(() => {
    const fetchLanguages = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch languages");
        }
        const data = await response.json();
        setLanguages(data.data);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  // Effect to set default values based on fetched data
  useEffect(() => {
    if (methods.getValues("access") === 1) {
      methods.setValue("access", 1); // Set default to "Lifetime"
    } else if (methods.getValues("access") === 2) {
      methods.setValue("access", 2); // Set default to "One time"
    }

    setIsGlobal(methods.getValues("global") === 1);
  }, [methods]);
  const updateComponent = (index, key, value) => {
    const updatedComponents = [...categoryComponents];
    updatedComponents[index][key] = value;
    setCategoryComponents(updatedComponents);
  };
  const addCategoryComponent = () => {
    setCategoryComponents([
      ...categoryComponents,
      {
        selectedCategory: null,
        classVal: null,
        centralExam: null,
        stateExam: null,
        selectedState: null,
        selectedBoard: null,
      },
    ]);
  };
  console.log("Data ",categoryComponents)
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Update Coupon</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            {/* Form Fields */}
            <Stack direction="row" spacing={3}>
              <RHFTextField name="name" label="Name" />
              <RHFTextField name="access" label="Coupon Type" select>
                <MenuItem value={1}>Multiple Time</MenuItem>
                <MenuItem value={2}>One time</MenuItem>
              </RHFTextField>
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField name="coupon_code" label="Coupon Code" />
              <RHFTextField
                name="coupon_percentage"
                label="Coupon Percentage"
                type="number"
              />
            </Stack>

            <Stack direction="row" spacing={36}>
              <Controller
                name="global"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={isGlobal}
                        onChange={(e) => setIsGlobal(e.target.checked)}
                      />
                    }
                    label="All Courses"
                  />
                )}
              />

              <Controller
                name="private"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={isPrivate}
                        onChange={(e) => setIsPrivate(e.target.checked)}
                      />
                    }
                    label="Private"
                  />
                )}
              />
            </Stack>

            {!isGlobal && (
              <Stack direction={"row"} spacing={3}>
                <RHFTextField
                  name="category"
                  // label="Category"
                  sx={{ width: "50%" }}
                  // select
                  value={category}
                  disabled

                // onChange={handleCategoryChange}
                />

                {states && (
                  <RHFTextField
                    name="states"
                    //   label="State"
                    sx={{ width: "50%" }}
                    // select
                    value={states}
                    disabled

                  // onChange={handleStateChange}
                  />
                )}

                {boards && (
                  <RHFTextField
                    name="boards"
                    // label="Boards"
                    sx={{ width: "50%" }}
                    // select
                    value={boards}
                    disabled

                  // onChange={handleBoardChange}
                  />
                )}

                <RHFTextField
                  name="subCategory"
                  // label="Subcategory"
                  sx={{ width: "50%" }}
                  value={stateExam || classVal}
                  disabled

                // select
                // onChange={handleSubCategoryChange}
                />
              </Stack>
            )}
            <div>


              {categoryComponents.map((component, index) => (<>
                <div key={index} style={{ display: "flex", alignItems: "center" }}>
                  <CategoryComponent
                    width={"100%"}
                    selectedCategory={component.selectedCategory}
                    setSelectedCategory={(value) => updateComponent(index, 'selectedCategory', value)}
                    classVal={component.classVal}
                    setClass={(value) => updateComponent(index, 'classVal', value)}
                    centralExam={component.centralExam}
                    setCentralExam={(value) => updateComponent(index, 'centralExam', value)}
                    stateExam={component.stateExam}
                    setStateExam={(value) => updateComponent(index, 'stateExam', value)}
                    selectedState={component.selectedState}
                    setSelectedState={(value) => updateComponent(index, 'selectedState', value)}
                    selectedBoard={component.selectedBoard}
                    setSelectedBoard={(value) => updateComponent(index, 'selectedBoard', value)}
                  />

                  <Button sx={{ margin: 2 }} variant="contained"
                    color="secondary"
                    onClick={() => removeCategoryComponent(index)}>Remove</Button>
                </div>

              </>
              ))}
              <Button sx={{ margin: 2 }} variant="contained"
                color="primary"
                onClick={addCategoryComponent}>Add</Button>

            </div>

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="start_from"
                label="Start From"
                type="datetime-local"
              />
              <RHFTextField
                name="end_to"
                label="End To"
                type="datetime-local"
              />
            </Stack>

            <RHFTextField name="is_active" label="Status" select>
              <MenuItem value="true">Published</MenuItem>
              <MenuItem value="false">Draft</MenuItem>
            </RHFTextField>

            <LoadingButton
              sx={{ mt: 2, mb: 5 }}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading || isSubmitting}
            >
              Update Coupon
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Stack>
      <ToastContainer position="bottom-center" />
    </>
  );
}
