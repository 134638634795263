import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { reset } from 'numeral';
import { ToastContainer, toast } from 'react-toastify';
import CategorySelectComponent from './CategorySelectOptions';
import CategorySelectOptions from './CategorySelectOptions';
import { nanoid } from 'nanoid';
// ----------------------------------------------------------------------

export default function AddCategory({ optionsArray, setOptionsArray }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [parent, setParent] = useState('principal')
  const [parentList, setParentList] = useState();
  const [optionsMap, setOptionsMap] = useState();
  const [status, setStatus] = useState(true);

  // const addNewCategory = (parentId, newObject) => {
  //   for (let i = optionsArray.length - 1; i >= 0; i--) {
  //     if (optionsArray[i].parentId === parentId) {
  //       setOptionsArray(prevArray => [
  //         ...prevArray.slice(0, i + 1),
  //         newObject,
  //         ...prevArray.slice(i + 1),
  //       ]);
  //       break;
  //     }
  //   }
  // };


  // useEffect(()=>{
  //   const token = localStorage.getItem('token')
  //   async function f(){
  //     const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/get-select-category`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     const response = await res.json();
  //     setOptionsMap(response?.data?.categorySubCategory);
  //     console.log(response?.data?.categorySubCategory)
  //     console.log("This is list",response)
  //     // setParentList(response?.data)
  //   }
  //   f()
  // }, [])

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required('Title required'),
    parent: Yup.string().required('Parent Required'),

  });

  const defaultValues = {
    title: '',
    parent: 'principal',
    is_active: true
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true)
    if (!parent) {
      toast.error("Please Select Parent Category, Choose None if you want it as a Main Category!");
      setLoading(false);
      return false;
    }
    let token = localStorage.getItem("token");
    const categoryId = nanoid()
    const newCat = { ...data, parent, _id: categoryId }
    // addNewCategory(newCat, parent)
    if (!optionsMap[parent])
      optionsMap[parent] = []
    optionsMap[newCat] = []
    console.log(optionsMap[parent]);
    const isDuplicate = optionsMap[parent]?.some(obj => obj.title.trim() === data.title.trim())
    if (isDuplicate) {
      toast.error("Can't Have Duplicate Sub-Category in same Category");
      setLoading(false);
      return;
    }

    try {


      console.log("Updated Options Map", { ...optionsMap, [parent]: [...optionsMap[parent], newCat] })
      setOptionsMap(obj => ({ ...obj, [parent]: [...obj[parent], newCat] }))
      // setLoading(false)

      console.log("Post ka data ", { ...(data), title: data.title.trim(), parent })
      // const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addCategorialDetails`, {
      //     method: 'POST',
      //     headers: {
      //         'Content-Type': 'application/json',
      //         Authorization: `Bearer ${token}`
      //     },
      //     body: JSON.stringify(newCat)
      // })
      // const result = await res.json()
      // console.log("response result",result)
      // if (result.status) {
      //   toast.success(result?.msg || result?.message)
      //   setParent('principal')
      //   setLoading(false)
      //   reset()

      // }
      // else{
      //   setLoading(false)
      //   toast.error(result?.msg?.message)
      // }
    } catch (error) {
      console.log(error);
      toast.error(error.message || error.msg);
      console.log(optionsMap[parent])
      setLoading(false);
    }

  };

  return (
    <>
      <Stack spacing={4} px={5}>
        <Typography variant="h3">Add Category</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={"column"} spacing={5} sx={{ width: "70%" }} >
            <Stack spacing={1} direction={"column"}>
              <InputLabel style={{ fontSize: "0.9rem" }} >Title</InputLabel>
              <RHFTextField name="title" style={{ width: "100%" }} />
            </Stack>
            <Stack spacing={1}>
              <InputLabel style={{ fontSize: "0.9rem" }}>Choose Parent Category</InputLabel>
              <FormControl style={{ width: "100%" }} >
                <select
                  name='parent'

                  aria-placeholder='Choose a category'
                  style={{ fontSize: "15px", cursor: 'pointer', height: "55px", borderRadius: "8px", background: "transparent", padding: "10px", borderColor: "grey", color: "#212B36 !important" }}
                  value={parent}
                  onChange={(e) => setParent(e.target.value)}

                  label="Parent Category"
                >
                  {/* <option   disabled selected value="">
            <div style={{color:"green"}}>Choose Parent Category</div>
          </option> */}
                  <option value="principal">
                    <em style={{ padding: "" }}>None</em>
                  </option>
                  {optionsMap && <CategorySelectOptions parentId={"principal"} setParent={setParent} optionsMap={optionsMap} />}
                </select>
              </FormControl>

            </Stack>
            <Stack direction="column" style={{ width: "100%" }} spacing={1}>

              <InputLabel style={{ fontSize: "0.9rem" }}>Status</InputLabel>
              <RHFTextField
                name="is_active"
                // label="Status"
                type="checkbox"
                defaultValue={true}
                select
                value={status}
                onChange={e => setStatus(e.target.value)}
              >
                <MenuItem value={true}>Publish</MenuItem>
                <MenuItem value={false}>Draft</MenuItem>
              </RHFTextField>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                marginTop: "5%",
                "&:hover": { background: " #8080ff" },
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
