import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddBanner from './AddBanner';
// import Student from '../../../pages/User';
import BannerTable from './BannerTable';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Banner = () => {
  const access = JSON.parse(localStorage.getItem('detailObject')) || {};
  const role = localStorage.getItem('role') || '';
  const accessToContent = access?.accessToContent || [];
  const [value, setValue] = React.useState(() => {
    if (role == 'Admin') return 0;
    if (accessToContent?.includes("Banner")) return 0;
    if (accessToContent?.includes("Add Banner")) return 1;
    return 0;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ width: '100%', }}>
          {(role === 'Admin' || accessToContent.includes('Banner')) && (
            <Tab value={0} label="All Banners" sx={{ width: '100%' }} {...a11yProps(0)} />
          )}
          {(role === 'Admin' || accessToContent.includes('Add Banner')) && (
            <Tab value={1} label="Add Banner" sx={{ width: '100%' }} {...a11yProps(1)} />
          )}
        </Tabs>
      </Box>

      {(role === 'Admin' || accessToContent.includes('Banner')) && (
        <TabPanel value={value} index={0}>
          <BannerTable />
        </TabPanel>
      )}
      {(role === 'Admin' || accessToContent.includes('Add Banner')) && (
        <TabPanel value={value} index={1}>
          <AddBanner />
        </TabPanel>
      )}



    </Box >
  );
}

export default Banner