import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddTeacherForm from '../register/AddTeacherForm';
import Student from '../../../pages/User';
import AddContact from './AddContact';
import ContactTable from './ContactTable';
import AddCategory from './AddCategory';
import CategoryTable from './CategoryTable';
import getOptionsArray from './getOptionsArray';
import { useTransition } from 'react';
import CategoryTableNew from './CategoryTableNew';
import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const Category = () => {
    const access = JSON.parse(localStorage.getItem('detailObject'));
    const [isPending, startTransition] = useTransition()
    const [value, setValue] = React.useState(0);
    const [optionsMap, setOptionsMap] = useState()
    const [optionsArray, setOptionsArray] = React.useState([])
    // useEffect(()=>{
    //     const token = localStorage.getItem('token')
    //     async function f(){

    //         const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/get-select-category`,
    //         {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         const response = await res.json();
    //         if(response.status===false){
    //             window.alert(response.err.message+" "+response.msg)
    //         }
    //         //   console.log(response)
    //         setOptionsArray([])
    //         const optionsArrayRes = await getOptionsArray(response?.data?.categorySubCategory);
    //         setOptionsArray(optionsArrayRes);
    //         setOptionsMap(response?.data?.categorySubCategory)
    //     }
    //     startTransition(()=>f())

    //   }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ width: '100%', }}>
                    <Tab label="All Category" sx={{ width: '100%' }} {...a11yProps(0)} />
                    {(access?.access?.includes('Add Category') || access?.access.includes('all')) && (<Tab label="Add Category" sx={{ width: '100%' }} {...a11yProps(1)} />)}

                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {console.log("pending state", isPending)}
                {!isPending ? <CategoryTableNew optionsArray={optionsArray} setOptionsArray={setOptionsArray} />
                    : <Skeleton variant="rounded" width={"70vw"} height={"60vh"} />}
            </TabPanel>
            {(access?.access?.includes('Add Category') || access?.access.includes('all')) && (<TabPanel value={value} index={1}>
                {!isPending && <AddCategory optionsArray={optionsArray} setOptionsArray={setOptionsArray} />}
            </TabPanel>)}

        </Box >
    );
}

export default Category