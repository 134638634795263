import React, { useEffect } from "react";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import { values } from "lodash";
import { useTheme } from "@emotion/react";
import { getFileUrl } from "../../../utils/firebaseUtils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const UpdateLectureDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [focus, setFocused] = React.useState(false);
  const [resources, setResources] = React.useState(false);
  const [users, usersSet] = React.useState([]);
  const { lectId, batchId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fetchMessage, setFetchMessage] = useState();
  const [starting, setStarting] = React.useState(dayjs("2022-01-01T21:11:54"));
  const [ending, setEnding] = React.useState(dayjs("2022-01-01T21:11:54"));
  const [languages, setLanguages] = useState([]);
  const [materialType, setMaterialType] = React.useState("");
  const [link, setLink] = React.useState("");
  const [liveStatus, setLiveStatus] = useState("");
  const [focusThree, setFocusedThree] = React.useState(false);
  const [showAudio, setShowAudio] = React.useState(false);
    const [type, setType] = useState("");


  const options = [
    { value: "1", label: "Live" },
    { value: "2", label: "Recorded" },
  ];
  const [file, setFile] = useState();
  const [val, setVal] = useState(false);

  const changeHandler = async (event) => {
    setFile(
      event.target.files[0] ? await getFileUrl(event.target.files[0]) : null
    );
    setAurl(event.target.files[0] ? await getFileUrl(event.target.files[0]) : null)
  };

  const handleChange = (newValue) => {
    setStarting(newValue);
  };
  const handleChangeTwo = (newValue) => {
    setEnding(newValue);
  };

  const LoginSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  // const [file, setFile] = useState();

  // const changeHandler = async (event) => {
  //   setFile(event.target?.files[0]);
  // };

  //Getting Existing details
  const [lectureDetails, setLectureDetails] = useState();
  const [mUrl, setMurl] = useState("");
  const [aUrl, setAurl] = useState("");
  const [value, setValue] = useState({});
  const [tagsList, setTagsList] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-lecture/${lectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await fullResponse.json();
      setLectureDetails(responseJson?.data);
      setTagsList(responseJson?.data?.tags.split(",").map(Number));
      console.log(responseJson?.data);
      //console.log(responseJson?.data?.starting_date);
      setStarting(dayjs(responseJson?.data?.starting_date));
      setEnding(dayjs(responseJson?.data?.ending_date));

      methods.reset({
        ...responseJson?.data,
        link: responseJson?.data?.link, // Set the link field with previous link
      });
      setLink( responseJson?.data?.link)
      setMurl(responseJson?.data?.material_url)
      setAurl(responseJson?.data?.material_url)
      setValue((prev) => ({
        ...prev,
        lecture_type: responseJson?.data?.lecture_type,
        title: responseJson?.data?.title,
        language: responseJson?.data?.language,
        description: responseJson?.data?.description,
        subject: responseJson?.data?.subject,
      }));
      setMaterialType(responseJson?.data?.material_type);
      setLiveStatus(responseJson?.data?.lecture_type); // Set the liveStatus based on lecture_type
    }
    fetchUsers();
  }, []);
  // //console.log(lectureDetails?.starting_date);
  //console.log(starting);
  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const languageData = await axios.post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (languageData?.status !== 200)
        throw new Error("Failed to fetch languages");

      setLanguages(languageData?.data?.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);

  const [lectureMedium, setLectureMedium] = useState();
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-course-by-id/${batchId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await res.json();
      // setSubjects(responseJson?.data?.subjects);
      if (responseJson?.data) {
        setLectureMedium(responseJson?.data?.course?.materials);
      }

      // setLoading(false)
    }
    fetchUsers();
  }, []);

  let formatDate = (date) => {
    // console.log(dayjs(date).format('YYYY-MM-DD HH:mm:ss'))
    return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const [selectSubject, setSelectSubject] = useState("");
console.log('M RL ',mUrl)
  const onSubmit = async (data) => {
    let StartingDate = formatDate(starting.$d);
    let EndingDate = formatDate(ending.$d);
    console.log("data->", data, "value->", value);

    // delete value?.subject;
    // value.subject = value.subject.id;
    // return;
    // const payload = { ...value, starting_date: StartingDate, ending_date: EndingDate }
    const payload = {
      ...value,
      subject: selectSubject,
      material_type: materialType,
      lecture_type: liveStatus,
      starting_date: StartingDate,
      ending_date: EndingDate,
      material_url: `${
        materialType == "vi" ? mUrl :  aUrl
      }`,
      link:  link,
      tags: tagsList.filter((item) => item !== null),
    };
    console.log("payload--->>>>>", payload);
    // return;

    setLoading(true);
    // //console.log(starting.$d == 'Invalid Date')

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .put(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-lecture/${lectId}`,
        payload,
        config
      )
      .then((response) => {
        console.log(response?.data);

        if (response.data.response_code === 200) {
          reset();
          setLoading(false);
          toast.success("Lecture Details Updated");

          setTimeout(() => {
            navigate(`/dashboard/batchDetails/${batchId}`);
             //window.location.reload();
          }, 1500);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [tags, setTags] = useState([]);

  //Getting Batch
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-course-by-id/${batchId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseSubject = await res.json();
      usersSet(responseSubject?.data?.subjects);

      setLoading(false);
    }

    async function fetchTags() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-tag-by-course-id/${batchId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      console.log(responseJson?.data);
      setTags(responseJson?.data);
    }
    fetchUsers();
    fetchTags();
  }, []);

  
    const onChange2 = (e) => {
      // console.log(e);
      const { name, value } = e.target;
      setValue((prev) => ({ ...prev, [name]: value }));
  };
  
  const handleChangeTags = (event) => {
    const {
      target: { value },
    } = event;
    setTagsList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <Grid direction="row" pl={5}>
      <Typography variant="h2" ml={2}>
        Update Lecture Details
      </Typography>
      <Typography variant="h6" ml={2} mb={4}>
        Enter the below required details to update a lecture
      </Typography>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {/* <Stack direction="row" spacing={5} > */}
        <Stack spacing={3} sx={{ width: "70%" }}>
          <RHFTextField
            name="title"
           // label="Title"
            value={value?.title}
            onChange={onChange2}
          />

          {/* <RHFTextEditor name="description" value={value?.description} onChange={onChange2} /> */}

          <Stack direction="row" spacing={3}>
            {/* <RHFTextField
              name="language"
              label={`${value.language || ""} (language code)`}
              value={value.language}
              sx={{ width: "100%" }}
              select
            >
              {languages?.map((language, index) => (
                <MenuItem key={index} value={language?.medium_code}>
                  {language?.medium_name}
                </MenuItem>
              ))}
            </RHFTextField>
            {console.log(value)} */}
            <RHFTextField
              name="subject"
              label={`${value.subject?.name || "NA"} (Subject)`}
              onChange={(e) => setSelectSubject(e.target.value)}
              select
            >
              {users?.map((sub, i) => {
                return (
                  <MenuItem key={i} value={sub.id}>
                    {sub.name}
                  </MenuItem>
                );
              })}
            </RHFTextField>
            {/* <RHFTextField
              name="lecture_type"
              label={`${value?.lecture_type || ""} (Resource Type)`}
              // value={value.lecture_type}
              select
            >
              <MenuItem value={1}>Live</MenuItem>
              <MenuItem value={2}>Recorded</MenuItem>
            </RHFTextField> */}
            <TextField
              select
              label={`${materialType} (Material Type)`}
              value={materialType}
              onChange={(event) => setMaterialType(event.target.value)}
              sx={{ minWidth: "48%" }}
            >
              <MenuItem value="au">Audio</MenuItem>
              <MenuItem value="vi">Video</MenuItem>
            </TextField>
          </Stack>
          <Stack direction="column" spacing={3} sx={{ width: "100%" }}>
            <Stack direction="row" spacing={3}>
              {materialType === "vi" ? (
                <RHFTextField
                  name="type"
                  label={`${
                    value?.lecture_type == "1" ? "Live" : "Recorded" || ""
                  } ( Type) `}
                  select
                  onChange={(e) => {
                    setLiveStatus(e.target.value);
                  }}
                  value={liveStatus}
                >
                  {lectureMedium === "live" ? (
                    <MenuItem key={1} value="1">
                      Live
                    </MenuItem>
                  ) : lectureMedium === "recorded" ? (
                    <MenuItem key={2} value="2">
                      Recorded
                    </MenuItem>
                  ) : (
                    options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  )}
                </RHFTextField>
              ) : (
                <RHFTextField name="type" label="Type" select>
                  <MenuItem value="2" onClick={() => setLiveStatus("2")}>
                    Recorded
                  </MenuItem>
                </RHFTextField>
              )}

              {materialType === "vi" ? (
                <RHFTextField
                  name="link"
                  value={value.lecture_type=="1"?link:mUrl}
                  label="Link"
                  onChange={(e)=>{
                    if(value.lecture_type=="1"){
                    setLink(e.target.value)
                    }
                    else{
                      setMurl(e.target.value)
                    }
                  }}
                  placeholder={lectureDetails?.link || ""}
                  style={{ maxWidth: "50%" }}
                />
              ) : (
                <Stack direction="column" sx={{ width: "100%" }}>
                  <RHFTextField
                    name="file"
                    error={!file && val}
                    onChange={changeHandler}
                    onFocus={() => setFocusedThree(true)}
                    onBlur={() => setFocusedThree(false)}
                    inputProps={{ accept: "audio/*" }}
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    label="Audio"
                    onClick={() => setShowAudio(true)}
                    type={showAudio || focusThree ? "file" : "text"}
                  />
                  <Typography> {mUrl} </Typography>
                  {!file && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {"Audio Required"}
                    </Typography>
                  )}
                </Stack>
              )}
            </Stack>

            {liveStatus === "1" && (
              <Stack direction="row" spacing={3} sx={{ marginTop: "16px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Starting Date"
                    value={starting.$d}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                  <DateTimePicker
                    label="End Time"
                    value={ending.$d}
                    onChange={handleChangeTwo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%", color: "black" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            )}
          </Stack>

          <Stack spacing={3} direction="row">
            {/*
                <RHFTextField
                  id="assi"
                  name="materials"
                  label="Resources"
                  onChange={changeHandler}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setResources(true)}
                  type={resources || focus ? "file" : "text"}
                />*/}
            {/* <RHFTextField
              name="material_type"
              label={`${value?.material_type || ""} (Material Type)`}
              select
            >
              <MenuItem value="au">Audio</MenuItem>
              <MenuItem value="vi">Video</MenuItem>
            </RHFTextField>
            <RHFTextField
              name="link"
              label={`${lectureDetails?.link || ""} (Resource Link)`}
              // label={"Resource Link"}
              type="url"
            /> */}
          </Stack>
          <Stack>
            <FormControl>
              <InputLabel>Tags</InputLabel>
              <Select
                label="Tags"
                multiple
                disabled
                name="tags"
                value={tagsList}
                onChange={handleChangeTags}
                sx={{ width: "100%", color: "black", zIndex: 9 }}
                MenuProps={MenuProps}
              >
                {tags?.map((tag, index) => {
                  return (
                    <MenuItem
                      key={tag?.id}
                      value={tag?.id}
                      style={getStyles(tag?.name, tags, theme)}
                    >
                      {tag?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>

          <RHFTextEditor
            name="description"
            value={value?.description}
            label={"Description"}
            onChange={(value) => {
              setValue((prev) => ({ ...prev, description: value }));
            }}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          // loading={loading}
          sx={{
            bgcolor: "#e6e6ff",
            color: "black",
            width: "70%",
            marginTop: "5%",
            "&:hover": { background: "#8080ff" },
          }}
        >
          Update
        </LoadingButton>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
    </Grid>
  );
};

export default UpdateLectureDetails;
