import Iconify from "../../components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const role = localStorage.getItem("role");
const detailObject = localStorage.getItem("detailObject");
let d = JSON.parse(detailObject);
console.log(d)
//Main Heading
let studentsheading = {};
if (
  d?.accessToContent?.includes("All Users") ||
  d?.accessToContent?.includes("Course Purchases") ||
  d?.accessToContent?.includes("Test Series Purchases") ||
  d?.accessToContent?.includes("Purchase Report") ||
  d?.accessToContent?.includes("Subscription Purchases")
) {
  //console.log(' goneInside')
  studentsheading = {
    title: "Users",
    path: "#",
    icon: getIcon("eva:people-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let batchesheading = {};
if (
  d?.accessToContent?.includes("All Batches") ||
  d?.accessToContent?.includes("Add Batch") ||
  d?.accessToContent?.includes("Add Center Lecture") ||
  d?.accessToContent?.includes("Add Center Resource")
) {
  batchesheading = {
    title: "Courses",
    path: "#",
    icon: getIcon("eva:shopping-bag-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}

let courseheading = {};
if (
  d?.accessToContent?.includes("All Batches") ||
  d?.accessToContent?.includes("Add Batch") ||
  d?.accessToContent?.includes("Add Center Lecture")
) {

  courseheading = {
    title: "Courses",
    path: "#",
    icon: getIcon("eva:shopping-bag-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let testseriesheading = {};
if (
  d?.accessToContent?.includes("All Test Series") ||
  d?.accessToContent?.includes("Add Test Series")
) {
  //console.log('came in here test ')
  testseriesheading = {
    title: "Test Series",
    path: "#",
    icon: getIcon("eva:file-text-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let quizheading = {};
if (
  d?.accessToContent?.includes("All Quiz") ||
  d?.accessToContent?.includes("Add Quiz")
) {
  //console.log('came in here test ')
  quizheading = {
    title: "Quiz",
    path: "#",
    icon: getIcon("material-symbols:quiz-outline"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let couponheading = {};
if (
  d?.accessToContent?.includes("All Coupon") ||
  d?.accessToContent?.includes("Add Coupon")
) {
  //console.log('came in here test ')
  couponheading = {
    title: "Coupon",
    path: "/dashboard/allCoupon",
    icon: getIcon("akar-icons:book-close"),
    // dropIcon: getIcon("gridicons:dropdown"),
    // upIcon: getIcon("mi:caret-up"),
  };
}

let resourcesheading = {};
if (
  d?.accessToContent?.includes("Syllabus") ||
  d?.accessToContent?.includes("Add Syllabus")||
  d?.accessToContent?.includes("Audios") ||
  d?.accessToContent?.includes("Add Audio") ||
  d?.accessToContent?.includes("Demo Videos") ||
  d?.accessToContent?.includes("Add Demo Videos")||
  d?.accessToContent?.includes("Notes")||
  d?.accessToContent?.includes("Add Notes")
) {
  resourcesheading = {
    title: "Resources",
    path: "#",
    icon: getIcon("eva:alert-triangle-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let generalsettings = {};
if (
  d?.accessToContent?.includes("Banner") ||
  d?.accessToContent?.includes("Add Banner") ||
  d?.accessToContent?.includes("Subject") ||
  d?.accessToContent?.includes("Add Subject") ||
  d?.accessToContent?.includes("All Coupon") ||
  d?.accessToContent?.includes("Add Coupon") 
) {
  generalsettings = {
    title: "General Settings",
    path: "#",
    icon: getIcon("ci:settings"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let managestaff = {};
if (
  d?.accessToContent?.includes("Manage Staff")
) {
  managestaff = {
    title: "Manage Staff",
    path: "/dashboard/staff",
    icon: getIcon("ic:outline-manage-accounts"),
    dropIcon: getIcon("mi:caret-down"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let assignment = {};
if (
  d?.accessToContent?.includes("All Assignment") ||
  d?.accessToContent?.includes("Add Assignment")
) {
  assignment = {
    title: "Assignment",
    path: "/dashboard/assignment",
    icon: getIcon("eva:book-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let testimonial = {};
if (
  d?.accessToContent?.includes("All Testimonial") ||
  d?.accessToContent?.includes("Add Testimonial")
) {
  testimonial = {
    title: "Testimonial",
    path: "/dashboard/testimonial",
    icon: getIcon("dashicons:testimonial"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}

//Sub Heading
let students = {};
if (d?.accessToContent?.includes("All Users") ||
  d?.accessToContent?.includes("Purchase Report")
) {
  students = {
    title: "All Users",
    path: "/dashboard/students",
    navicon: "ph:student",
    cName: "sub-nav",
  };
}
let studentsbatches = {};
if (d?.accessToContent?.includes("Course Purchases") ||
  d?.accessToContent?.includes("Test Series Purchases") ||
  d?.accessToContent?.includes("Subscription Purchases")
) {
  studentsbatches = {
    title: "Purchases",
    path: "/dashboard/studentBatches",
    navicon: "ic:baseline-support-agent",
    cName: "sub-nav",
  };
}

let testseriesPurchase = {};
if (d?.accessToContent?.includes("Test Series Purchases")) {
  testseriesPurchase = {
    title: "Test Series Purchases",
    path: "/dashboard/studentTestSeries",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}

let purchaseReport = {};
if (d?.accessToContent?.includes("Purchase Report")) {
  purchaseReport = {
    title: "Purchase Report",
    path: "/dashboard/purchaseReport",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}

let userReport = {};
if (d?.accessToContent?.includes("User Report")) {
  userReport = {
    title: "User Report",
    path: "/dashboard/userReport",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}

let allbatches = {};
if (d?.accessToContent?.includes("All Batches")) {
  allbatches = {
    title: "All Courses",
    path: "/dashboard/batches",
    navicon: "material-symbols:batch-prediction",
    cName: "sub-nav",
  };
}
let addbatches = {};
if (d?.accessToContent?.includes("Add Batch")) {
  addbatches = {
    title: "Add Courses",
    path: "/dashboard/addBatch",
    navicon: "bxs:message-square-add",
    cName: "sub-nav",
  };
}
let addCenterLectures = {};
if (d?.accessToContent?.includes("Add Center Lecture")) {
  // console.log(accessToContentTab);
  addCenterLectures = {
    title: "Add Lecture",
    path: "/dashboard/addCenterLecture",
    navicon: "bxs:message-square-add",
    cName: "sub-nav",
  };
  // console.log({...addCenterLecture});
}


let allCourse = {};
if (d?.accessToContent?.includes("All Batches")) {
  allCourse = {
    title: "All Courses",
    path: "/dashboard/batches",
    navicon: "material-symbols:batch-prediction",
    cName: "sub-nav",
  };
}
let addCourse = {};
if (d?.accessToContent?.includes("Add Batch")) {
  addCourse = {
    title: "Add Course",
    path: "/dashboard/addBatch",
    navicon: "bxs:message-square-add",
    cName: "sub-nav",
  };
}
let addLecture = {};
if (d?.accessToContent?.includes("Add Center Lecture")) {
  // console.log(accessToContentTab);
  addLecture = {
    title: "Add Lecture",
    path: "/dashboard/addCenterLecture",
    navicon: "bxs:message-square-add",
    cName: "sub-nav",
  };
  // console.log({...addCenterLecture});
}
let addCenterResource = {};
if (d?.accessToContent?.includes("Add Center Lecture")) {
  // console.log(accessToContentTab);
  addCenterResource = {
    title: "Add Resource",
    path: "/dashboard/addCenterResource",
    navicon: "bxs:message-square-add",
    cName: "sub-nav",
  };
  // console.log({...addCenterLecture});
}
let testseries = {};
if (d?.accessToContent?.includes("All Test Series")) {
  testseries = {
    title: "All Test Series",
    path: "/dashboard/test",
    navicon: "akar-icons:book-close",
    cName: "sub-nav",
  };
}
let addtestseries = {};
if (d?.accessToContent?.includes("Add Test Series")) {
  addtestseries = {
    title: "Add Test Series",
    path: "/dashboard/addTest",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}
let quiz = {};
if (d?.accessToContent?.includes("All Quiz")) {
  quiz = {
    title: "All Quiz",
    path: "/dashboard/quiz",
    navicon: "akar-icons:book-close",
    cName: "sub-nav",
  };
}
let addquiz = {};
if (d?.accessToContent?.includes("Add Quiz")) {
  addquiz = {
    title: "Add Quiz",
    path: "/dashboard/addQuiz",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}

let allcoupon = {};
if (d?.accessToContent?.includes("All Coupon")) {
  allcoupon = {
    title: "All Coupon",
    path: "/dashboard/allCoupon",
    navicon: "akar-icons:book-close",
    cName: "sub-nav",
  };
}
let addcoupon = {};
if (d?.accessToContent?.includes("Add Coupon")) {
  addcoupon = {
    title: "Add Coupon",
    path: "/dashboard/addCoupon",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}
let recordedvideos = {};
if (d?.accessToContent?.includes("Recorded Video")) {
  recordedvideos = {
    title: "Recorded Video",
    path: "/dashboard/recorded",
    navicon: "carbon:course",
    cName: "sub-nav",
  };
}
let Syllabus = {};
if (d?.accessToContent?.includes("Syllabus") ||
d?.accessToContent?.includes("Add Syllabus")
) {
  Syllabus = {
    title: "Syllabus",
    path: "/dashboard/Pathyakram",
    navicon: "carbon:course",
    cName: "sub-nav",
  };
}
let notes = {};
if (d?.accessToContent?.includes("Notes") ||
d?.accessToContent?.includes("Add Notes") 
) {
  notes = {
    title: "Notes",
    path: "/dashboard/notes",
    navicon: "charm:notes",
    cName: "sub-nav",
  };
}
let pyq = {};
if (d?.accessToContent?.includes("Previous Year Question Papers")) {
  pyq = {
    title: "Previous Year Question Papers",
    path: "/dashboard/previousYearQuestionPapers",
    navicon: "carbon:course",
    cName: "sub-nav",
  };
}
let air = {};
if (d?.accessToContent?.includes("AIR")) {
  air = {
    title: "AIR",
    path: "/dashboard/air",
    navicon: "carbon:radio",
  };
}
let newsclip = {};
if (d?.accessToContent?.includes("News Clip")) {
  newsclip = {
    title: "News Clip",
    path: "/dashboard/newsClip",
    navicon: "wpf:news",
  };
}
let teachercontent = {};
if (d?.accessToContent?.includes("Teacher Generated Content Of Batch")) {
  teachercontent = {
    title: "Teacher Generated Content of Batch",
    path: "/dashboard/teacherGenerated",
    navicon: "bx:book-content",
  };
}
let alerts = {};
if (d?.accessToContent?.includes("Alerts")) {
  alerts = {
    title: "Alerts",
    path: "/dashboard/customNotification",
    navicon: "carbon:course",
    cName: "sub-nav",
  };
}
let teacher = {};
if (d?.accessToContent?.includes("Teachers")) {
  teacher = {
    title: "Teachers",
    path: "/dashboard/teacher",
    navicon: "fa-solid:chalkboard-teacher",
    cName: "sub-nav",
  };
}
let mod = {};
if (d?.accessToContent?.includes("Moderators")) {
  mod = {
    title: "Moderators",
    path: "/dashboard/moderators",
    navicon: "ic:outline-interpreter-mode",
    cName: "sub-nav",
  };
}
let subadmin = {};
if (d?.accessToContent?.includes("Sub Admin")) {
  subadmin = {
    title: "Sub Admin",
    path: "/dashboard/subAdmin",
    navicon: "subway:admin-1",
  };
}
let banner = {};
if (d?.accessToContent?.includes("Banner")|| d?.accessToContent?.includes("Add Banner")) {
  banner = {
    title: "Banner",
    path: "/dashboard/banner",
    navicon: "ri:file-settings-line",
    cName: "sub-nav",
  };
}
let logoutusers = {};
if (d?.accessToContent?.includes("Logout Users")) {
  logoutusers = {
    title: "Logout Users",
    path: "/dashboard/logoutUsers",
    navicon: "ant-design:logout-outlined",
    cName: "sub-nav",
  };
}
let subject = {};
if (d?.accessToContent?.includes("Subject") || d?.accessToContent?.includes("Add Subject")) {
  subject = {
    title: "Subject",
    path: "/dashboard/subject",
    navicon: "ri:file-settings-line",
    cName: "nav",
  };
}
let youtube = {};
if (d?.accessToContent?.includes("Youtube")) {
  youtube = {
    title: "Youtube",
    path: "/dashboard/youtube",
    navicon: "ri:file-settings-line",
    cName: "sub-nav",
  };
}
let telegram = {};
if (d?.accessToContent?.includes("Telegram")) {
  telegram = {
    title: "Telegram",
    path: "/dashboard/telegram",
    navicon: "ri:file-settings-line",
    cName: "sub-nav",
  };
}
let category = {};
if (d?.accessToContent?.includes("Category")) {
  category = {
    title: "Category",
    path: "/dashboard/category",
    navicon: "ic:outline-category",
    cName: "sub-nav",
  };
}
let subcategory = {};
if (d?.accessToContent?.includes("Sub Category")) {
  subcategory = {
    title: "Sub Category",
    path: "/dashboard/subCategory",
    navicon: "ic:outline-category",
    cName: "sub-nav",
  };
}
let contactus = {};
if (d?.accessToContent?.includes("Contact Us")) {
  contactus = {
    title: "Contact Us",
    path: "/dashboard/contactUs",
    navicon: "cil:contact",
    cName: "sub-nav",
  };
}
let helpandsupport = {};
if (d?.accessToContent?.includes("Help And Support")) {
  helpandsupport = {
    title: "Help and Support",
    path: "/dashboard/helpAndSupport",
    navicon: "ic:baseline-support-agent",
    cName: "sub-nav",
  };
}

let audios = {};
if (d?.accessToContent?.includes("Audios")||
d?.accessToContent?.includes("Add Audio")
) {
  audios = {
    title: "Audios",
    path: "/dashboard/audio",
    navicon: "ri:file-settings-line",
    cName: "sub-nav",
  };
}
let demovideos = {};
if (d?.accessToContent?.includes("Demo Videos")||
d?.accessToContent?.includes("Add Demo Videos")
) {
  demovideos = {
    title: "Demo Videos",
    path: "/dashboard/youtube",
    navicon: "ri:file-settings-line",
    cName: "sub-nav",
  };
}

let subscription = {};
if (d?.accessToContent?.includes("All Subscriptions") ||
  d?.accessToContent?.includes("Add Subscription")
) {
  subscription = {
    title: "Subscriptions",
    icon: getIcon("mdi:subscriptions"),
    path: "/dashboard/subscription",
    navicon: "carbon:course",
    cName: "nav",
  };
}

let notification = {};
if (d?.accessToContent?.includes("All Notifications") ) {
  notification = {
    title: "Notification",
    path: "/dashboard/notification",
    icon: getIcon("mdi:announcement"),
    navicon: "carbon:course",
    cName: "nav",
  };
}

let mockheading = {};
if (d?.accessToContent?.includes("All Mock Test") ||
  d?.accessToContent?.includes("Add Manual Test") ||
  d?.accessToContent?.includes("Add Template Test")
) {
  mockheading = {
    title: "Mock Test",
    path: "#",
    icon: getIcon("eva:file-text-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}

let allMockTest = {};
if (d?.accessToContent?.includes("All Mock Test")) {
  allMockTest = {
    title: "All Mock Test",
    path: "/dashboard/mockTest",
    navicon: "akar-icons:book-close",
    cName: "sub-nav",
  };
}

let addManualTest = {};
if (d?.accessToContent?.includes("Add Manual Test")) {
  addManualTest = {
    title: "Add Manual Test",
    path: "/dashboard/manual",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}

let addTemplateTest = {};
if (d?.accessToContent?.includes("Add Template Test")) {
  addTemplateTest = {
    title: "Add Template Test",
    path: "/dashboard/template",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}


// also used for subadmin
export const navConfigForTeacher = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon("eva:pie-chart-2-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  },
  // User
  {
    ...studentsheading,

    subNav: [
      {
        ...students,
      },
      {
        ...studentsbatches,
      }
    ],
  },
  // Course Named as Batches..
  {
    ...courseheading,
    subNav: [
      {
        ...allCourse,
      },
      {
        ...addCourse,
      },
      {
        ...addLecture,
      },
    ],
  },
  // Test Series
  {
    ...testseriesheading,
    subNav: [
      {
        ...testseries,
      },
      {
        ...addtestseries,
      },
    ],
  },
  // Mock Test
  {
    ...mockheading,
    subNav: [
      {
        ...allMockTest
      },
      {
        ...addManualTest
      },
      {
        ...addTemplateTest
      }
    ]
  },

  // Resources
  {
    ...resourcesheading,
    subNav: [
      {
        ...Syllabus,
      },
      {
        ...notes,
      },
      {
        ...audios
      },
      {
        ...demovideos
      }
    ],
  },

  // SubScription
  {
    ...subscription
  },

  // Notification
  {
    ...notification
  },

  //  manage Staff
  // {
  //   ...managestaff
  // },

  // banner
  {
    ...generalsettings,
    subNav: [
      {
        ...banner
      },

      // coupon
      {
        ...couponheading,
      },

      // subject
      {
        ...subject
      },
    ]
  }
];


console.log(navConfigForTeacher)