import React, { useState } from 'react';
import { Modal, TextField, Box, Button, Stack } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

export default function CategoryEditModal({ open, onClose, categoryData }) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    console.log(event.target.value);
    setInputValue(event.target.value);
  };

  const handleSaveChanges = async () => {
    // onSave(inputValue);
    // try {
    //     const token = localStorage.getItem('token');
    //     const data = await axios.put(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updatedCategorialDetails/${categoryData?._id}`, {
    //         title:inputValue,
    //         parent:categoryData?.parentId,
    //         type:categoryData?.type,
    //         is_active:categoryData?.is_active
    //     }, 
    // {
    //     headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`,
    //     },
    // })
    //     console.log("category edit api response",data);
    //     toast.success("Category Updated Successfully!", {

    //         closeOnClick:window.location.reload(),
    //     })



    // } catch (error) {
    //     toast.error("An Unexpected Error Occured!");
    //     console.log("an error occured :", error);
    // }
    setInputValue('');
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <TextField
          id='text'
          label={categoryData?.title}
          variant="outlined"
          placeholder="Enter new Category"
          fullWidth
          type='text'
          value={inputValue}
          style={{ marginBottom: "2rem" }}
          onChange={handleInputChange}
          InputProps={{
            // Ensure that autoCapitalize and autoCorrect are set to "on"
            autoCapitalize: 'on',
            autoCorrect: 'on',
            // Add other necessary props if required
          }}
          inputProps={{
            // Ensure that autoComplete is set to "on"
            autoComplete: 'on',
            // Add other necessary props if required
          }}
        />
        <Stack direction={'row-reverse'} justifyContent={"space-between"}>
          <Button variant="contained" onClick={handleSaveChanges}>
            Save Changes
          </Button>
          <ToastContainer />
          <Button onClick={onClose} sx={{ mr: 2 }}>
            Back
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}


