import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
import { Link, useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import VideoFileIcon from "@mui/icons-material/VideoFile";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    id: "sno",
    label: "S.No",
    alignRight: false,
    editable: true,
  },
  { id: "title", label: "Title", alignRight: false, editable: true },
  { id: "batch_id", label: "Course", alignRight: false, editable: true },
  { id: "lecture_id", label: "Lecture", alignRight: false },
  { id: "file_url", label: "File ", alignRight: false },
  { id: "language", label: "Language", alignRight: false },
  { id: "created_at", label: "Date", alignRight: false },
  { id: "is_active", label: "Status", alignRight: false },
  // { id: '', label: 'Action', alignRight: false, },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function RecordedTable() {
  const [page, setPage] = useState(0);
  // const [users, usersSet] = React.useState([]);
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [users, usersSet] = useState();
  const [selectedId, setSelectedId] = useState();
  const [loading, setLoading] = useState(true);
  const role = localStorage.getItem("role");

  //Getting Record
  const [isActiveValue, setIsActiveValue] = useState(false);
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   async function fetchUsers() {
  //     const fullResponse = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/adminPanel/getRecordedVideo`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const responseJson = await fullResponse.json();
  //     usersSet(responseJson.status);

  //     setLoading(false);
  //   }

  //   fetchUsers();
  // }, []);

  //batch details
  const [batchDetails, setBatchDetails] = useState();
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   async function fetchUsers() {
  //     const fullResponse = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const responseJson = await fullResponse.json();
  //     setBatchDetails(responseJson.data);
  //     setLoading(false);
  //   }
  //   fetchUsers();
  // }, []);
  //console.log(batchDetails)

  //Lecture Details
  const [batchId, setBatchId] = useState();
  const [lectureDetails, setLectureDetails] = useState();
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   async function fetchUsers() {
  //     const fullResponse = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLecturedetails?BatchId=${batchId}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const responseJson = await fullResponse.json();
  //     setLectureDetails(responseJson.data);
  //   }
  //   fetchUsers();
  // }, [batchId]);
  //console.log(lectureDetails)
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;
  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});

  const [file, setFile] = useState();

  const [lectureId, setLectureId] = useState();
  const [batchChanged, setBatchChanged] = useState(true);

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Recorded Videos">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              {/* <Typography variant="h3" gutterBottom>
                            All Live Lectures
                        </Typography> */}
              {/* <Button variant='contained' sx={{ width: '20%', alignSelf: 'center', background: '#EA506B', '&:hover': { background: 'red' } }} >Add Student</Button> */}
            </Stack>

            <Card>
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          let {
                            id,
                            title,
                            file_url,
                            language,
                            is_active,
                            _id,
                            batch_id,
                            lecture_id,
                            created_at,
                            is_verfied,
                          } = row;
                          const isItemSelected = selected.indexOf(title) !== -1;
                          let sno = index + 1 + rowsPerPage * page;

                          //console.log(row)
                          //Handling File Update
                          const changeHandler = async (event) => {
                            setFile(event.target.files[0]);
                          };

                          //Update
                          const onChange = (e, row, _id) => {
                            const { name, value } = e.target;
                            if (name == "is_active") {
                              setIsActiveValue(true);
                            }
                            if (name == "batch_id") {
                              setBatchChanged(false);
                            }
                            setUpdatedValues((prev) => {
                              return { ...prev, [name]: value };
                            });
                          };
                          const handleUpdate = async () => {
                            let data;
                            data = { ...updatedValues };

                            makeReq(data);
                          };
                          const makeReq = async (data) => {
                            //console.log(data)
                            if (batchChanged) {
                              setLoading(true);
                              const token = localStorage.getItem("token");
                              const authToken = token;
                              const formData = new FormData();
                              if (file) {
                                formData.append("file", file);
                              }
                              if (data.title) {
                                formData.append("title", data.title);
                              }
                              if (batchId) {
                                formData.append("batch_id", batchId);
                              } else {
                                formData.append("batch_id", batch_id?._id);
                              }
                              if (lectureId) {
                                formData.append("lecture_id", lectureId);
                              } else {
                                formData.append("lecture_id", lecture_id?._id);
                              }

                              if (data.language) {
                                formData.append("language", data.language);
                              }
                              if (isActiveValue) {
                                formData.append("is_active", data.is_active);
                              }

                              const config = {
                                headers: {
                                  "content-type": "multipart/form-data",
                                  Authorization: `Bearer ${authToken}`,
                                },
                              };
                              {/* const res = await axios.put(
                                `${process.env.REACT_APP_LIVE_URL}/adminPanel/UpdateRecordedVideo/${_id}`,
                                formData,
                                config
                              ); */}
                              //console.log(res.msg)
                              setLoading(false);
                              toast.success("Updated");
                              setTimeout(() => window.location.reload(), 800);
                            }
                          };

                          //Verification
                          const handleVerify = async () => {
                            setLoading(true);
                            const token = localStorage.getItem("token");
                            const authToken = token;
                            const config = {
                              headers: {
                                "content-type": "application/json",
                                Authorization: `Bearer ${authToken}`,
                              },
                            };
                            {/* const res = await axios.put(
                              `${process.env.REACT_APP_LIVE_URL}/adminPanel/verifyUploadedVideoByAdmin/${_id}`,
                              {},
                              config
                            ); */}
                            toast.success("Updated");
                            setTimeout(() => {
                              setLoading(false);
                              window.location.reload();
                            }, 600);
                          };

                          return (
                            <>
                              {" "}
                              <TableRow
                                hover
                                key={index}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, title)} />
                                                    </TableCell> */}
                                <TableCell>
                                  <Typography variant="subtitle2">
                                    {sno}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {/* <Avatar alt={name} src={avatarUrl} /> */}
                                    <Typography variant="subtitle2" noWrap>
                                      {isEdit && selectedId === _id ? (
                                        <Input
                                          onChange={(e) => onChange(e, row)}
                                          // value={title}
                                          name="title"
                                          placeholder={title}
                                        />
                                      ) : (
                                        <span>{title}</span>
                                      )}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Typography variant="subtitle3" noWrap>
                                    {isEdit && selectedId === _id ? (
                                      <TextField
                                        onChange={(e) => onChange(e, row)}
                                        size="small"
                                        label={batch_id?.batch_name}
                                        name="batch_id"
                                        placeholder={batch_id?.batch_name}
                                        select
                                        sx={{ width: "100px" }}
                                      >
                                        {batchDetails?.map((item) => (
                                          <MenuItem
                                            key={item?._id}
                                            value={item?._id}
                                            onClick={() =>
                                              setBatchId(item?._id)
                                            }
                                          >
                                            {" "}
                                            {item?.batch_name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    ) : (
                                      <span>{batch_id?.batch_name}</span>
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Typography variant="subtitle3" noWrap>
                                    {isEdit && selectedId === _id ? (
                                      <TextField
                                        onChange={(e) => onChange(e, row)}
                                        size="small"
                                        label={lecture_id?.lecture_title}
                                        helperText={
                                          !batchChanged ? "Select This" : null
                                        }
                                        name="lecture_title"
                                        error={!batchChanged}
                                        placeholder={lecture_id?.lecture_title}
                                        select
                                        sx={{ width: "100px" }}
                                      >
                                        {lectureDetails?.map((item) => (
                                          <MenuItem
                                            key={item?._id}
                                            value={item?._id}
                                            onClick={() => {
                                              setLectureId(item?._id);
                                              setBatchChanged(true);
                                            }}
                                          >
                                            {" "}
                                            {item?.lecture_title}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    ) : (
                                      <span> {lecture_id?.lecture_title}</span>
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Typography variant="subtitle3" noWrap>
                                    {isEdit && selectedId === _id ? (
                                      <>
                                        <input
                                          style={{
                                            display: "none",
                                          }}
                                          id="choose-file"
                                          type="file"
                                          name="file"
                                          onChange={changeHandler}
                                        />
                                        <label htmlFor="choose-file">
                                          <FileUploadIcon
                                            sx={{
                                              cursor: "pointer",
                                              "&:hover": {
                                                color: "red",
                                              },
                                            }}
                                          />
                                        </label>
                                      </>
                                    ) : (
                                      <a
                                        href={file_url?.fileLoc}
                                        style={{ color: "black" }}
                                      >
                                        <VideoFileIcon
                                          sx={{
                                            cursor: "pointer",
                                            "&:hover": {
                                              color: "red",
                                            },
                                          }}
                                        />
                                      </a>
                                    )}
                                    {/* <a href={file_url?.fileLoc} style={{ color: 'black' }}>
                                                                <VideoFileIcon

                                                                    sx={{
                                                                        cursor: 'pointer', '&:hover': {
                                                                            color: 'red'
                                                                        }
                                                                    }} />
                                                            </a> */}
                                  </Typography>
                                </TableCell>

                                <TableCell align="left">
                                  {isEdit && selectedId === _id ? (
                                    <TextField
                                      onChange={(e) => onChange(e, row)}
                                      size="small"
                                      label={
                                        language === "hi"
                                          ? "Hindi"
                                          : language == "en"
                                            ? "English"
                                            : "Hindi/English"
                                      }
                                      name="language"
                                      placeholder={
                                        language === "hi"
                                          ? "Hindi"
                                          : language == "en"
                                            ? "English"
                                            : "Hindi/English"
                                      }
                                      select
                                      sx={{ width: "100px" }}
                                    >
                                      <MenuItem value="hi">Hindi</MenuItem>
                                      <MenuItem value="en">English</MenuItem>
                                      <MenuItem value="enhi">
                                        Bilingual
                                      </MenuItem>
                                    </TextField>
                                  ) : (
                                    <span>
                                      {" "}
                                      {language === "hi"
                                        ? "Hindi"
                                        : language == "en"
                                          ? "English"
                                          : "Hindi/English"}
                                    </span>
                                  )}
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Typography variant="subtitle3" noWrap>
                                    {created_at?.split(" ")[0]}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  {isEdit && selectedId === _id ? (
                                    <TextField
                                      onChange={(e) => onChange(e, row)}
                                      size="small"
                                      label={is_active ? "Active" : "Inactive"}
                                      name="is_active"
                                      placeholder={
                                        is_active ? "Active" : "Inactive"
                                      }
                                      select
                                      sx={{ width: "100px" }}
                                    >
                                      <MenuItem value={true}>Active</MenuItem>
                                      <MenuItem value={false}>
                                        Inactive
                                      </MenuItem>
                                    </TextField>
                                  ) : (
                                    <span
                                      style={{
                                        background: is_active ? "green" : "red",
                                        padding: "5px",
                                        borderRadius: "10px",
                                        color: "white",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {is_active ? "Active" : "Inactive"}
                                    </span>
                                  )}
                                  {/* <span style={{ background: is_active ? 'green' : 'red', padding: '5px', borderRadius: '10px', color: 'white', fontWeight: '500' }}>{is_active ? 'Active' : 'Inactive'}</span> */}
                                </TableCell>
                                <TableCell align="left">
                                  {is_verfied ? (
                                    <span
                                      style={{
                                        border: "2px solid green",
                                        borderRadius: "8px",
                                        padding: "4px",
                                        color: "green",
                                      }}
                                    >
                                      Verified
                                    </span>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      onClick={handleVerify}
                                    >
                                      Verify
                                    </Button>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {isEdit && selectedId === _id ? (
                                    <Button
                                      variant="outlined"
                                      onClick={handleUpdate}
                                    >
                                      Update
                                    </Button>
                                  ) : null}
                                </TableCell>

                                {role == "Admin" && (
                                  <TableCell
                                    align="right"
                                    onClick={() => {
                                      setBatchId(batch_id?._id);
                                      setSelectedId(_id);
                                    }}
                                  >
                                    <UserMoreMenu
                                      id={_id}
                                      req="deleteRecordedVideo"
                                      isEdit={isEdit}
                                      setIsEdit={setIsEdit}
                                      setLoading={setLoading}
                                    />
                                  </TableCell>
                                )}
                              </TableRow>
                            </>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
