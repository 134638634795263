import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import * as React from "react";
import Iconify from "../../../components/Iconify";
import { Link, useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";

import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { UserMoreMenu } from "../user";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ListItemStyle = {
  cursor: "pointer",
  transition: "",
  padding: "5px 10px",
  "&:hover": {
    bgcolor: "lightgray",
  },
};
const AccordionStyle = {
  boxShadow: "1px 1px 7px 1px rgba(128,128,128,0.4)",
  background: "#dad7d772",
  borderRadius: "28px",
  width: "80%",
  transition: "all 0.3s",
  position: "relative",
};

const RecordedLectures = () => {
  const navigate = useNavigate();
  const [users, usersSet] = React.useState();
  const [teachers, setTeachers] = React.useState();
  const { Id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [lectureId, setLectureId] = React.useState();
  const [iid, setiid] = React.useState();
  const [fetchMessage, setFetchMessage] = React.useState();
  const [assignedTeacherEmail, setAssignedTeacherEmail] = React.useState();
  const [showAccordian, setShowAccordian] = React.useState(false);
  //Popover
  const [openCo, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    setLectureId(id);
    setAssignedTeacherEmail(value);
  };

  //Toast
  // const notify = () => toast("Added the Teacher");
  // notify()
  //Add Teacher to lecture
  const addteacher = async () => {
    const token = localStorage.getItem("token");
    // toast.success("Added the Teacher")
    const authToken = token;
    const data = {
      teacherEmail: assignedTeacherEmail,
    };
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    // axios
    //   .post(
    //     `${process.env.REACT_APP_LIVE_URL}/adminPanel/addTeacherToLecture/${lectureId}`,
    //     data,
    //     config
    //   )
    //   .then((response) => {
    //     //console.log(response.data);

    //     setFetchMessage(response.data.msg);
    //     if (response.data.msg === "added the teacher ") {
    //       toast.success("Added the Teacher");
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 600);
    //     }
    //   })
    //   .catch((e) => {
    //     //console.log(e);
    //   });
  };

  // React.useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   async function fetchUsers() {
  //     const fullResponse = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLecturedetails?BatchId=${Id}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const responseJson = await fullResponse.json();
  //     usersSet(responseJson.data);

  //     setLoading(false);
  //     const teacherResponse = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/adminTeacher/teacherList`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const teacher = await teacherResponse.json();
  //     setTeachers(teacher.data);
  //   }

  //   fetchUsers();
  // }, []);

  //Recorded Lectures

  const [lectures, setLectures] = React.useState();
  // React.useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   async function fetchUsers() {
  //     const fullResponse = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/adminPanel/getRecordedVideo`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const responseJson = await fullResponse.json();
  //     setLectures(responseJson.status);
  //     //console.log(responseJson.status)
  //     setLoading(false);
  //   }

  //   fetchUsers();
  // }, []);

  const handleClickCollapse = () => {
    setOpen(!openCo);
  };

  const role = localStorage.getItem("role");
  let i = 0;
  return (
    <>
      {" "}
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Box spacing={3} ml={5} sx={{}}>
          <Stack
            direction="row"
            justifyContent="space-between"
            mb={3}
            sx={{ width: "80%" }}
          >
            <Typography variant="h3" sx={{ mb: "30px" }}>
              All Recorded Lectures
            </Typography>
            {/* {role === 'Admin' && <Button variant='contained' sx={{ width: '20%', alignSelf: 'center', background: '#EA506B', '&:hover': { background: 'red' } }} onClick={() => navigate(`/dashboard/batchDetails/addLecture/${Id}`)}>Add Lectures</Button>} */}
          </Stack>
          <div>
            <Stack sx={{ width: "100%", borderRadius: "10px" }}>
              {lectures?.map((item, index) => {
                return (
                  <>
                    {" "}
                    {item.is_verfied ? (
                      <Box
                        key={index}
                        sx={{ marginBottom: "25px", ...AccordionStyle }}
                      >
                        <Box
                          pl={5}
                          pr={3}
                          pt={2}
                          pb={2}
                          onClick={() => {
                            setiid(item._id);
                            setShowAccordian(!showAccordian);
                            handleClickCollapse();
                          }}
                          sx={{
                            cursor: "pointer",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            position: "relative",
                          }}
                        >
                          <Stack spacing={0.5}>
                            <Typography
                              sx={{ fontSize: "22px", fontWeight: "bold" }}
                            >
                              Lecture {item.is_verified ? "" : ++i}
                            </Typography>
                            <Typography sx={{ fontSize: "15px" }}>
                              Topic: {item.title}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "15px", fontWeight: "bold" }}
                            >
                              Date:
                              <span style={{ fontWeight: "400" }}>
                                {" "}
                                {item.created_at}
                              </span>
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ width: "25%" }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                width: "60%",
                                alignSelf: "center",
                                background: "#e6e6ff",
                                color: "black",
                                "&:hover": { background: "#8080ff" },
                              }}
                            >
                              <a
                                href={item.file_url}
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}
                              >
                                Play
                              </a>
                            </Button>

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ width: "150px" }}>
                                {role === "Admin" && (
                                  <>
                                    <ListItem
                                      sx={{ ...ListItemStyle }}
                                      onClick={handleClose}
                                    >
                                      <Iconify
                                        icon="eva:edit-fill"
                                        width={20}
                                        height={20}
                                      />

                                      <ListItemText
                                        primary="Edit"
                                        primaryTypographyProps={{
                                          variant: "body2",
                                        }}
                                        sx={{ ml: 2, color: "gray" }}
                                      />
                                    </ListItem>

                                    <ListItem
                                      sx={{ ...ListItemStyle }}
                                      onClick={handleClose}
                                    >
                                      <Iconify
                                        icon="eva:trash-2-outline"
                                        width={20}
                                        height={20}
                                      />

                                      <ListItemText
                                        primary="Delete"
                                        primaryTypographyProps={{
                                          variant: "body2",
                                        }}
                                        sx={{ ml: 2, color: "gray" }}
                                      />
                                    </ListItem>
                                  </>
                                )}
                                <ListItem
                                  sx={{ ...ListItemStyle }}
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/lectureResources/${item.lecture_id}/${item.batch_id}`
                                    )
                                  }
                                >
                                  <AttachFileIcon color="gray" />
                                  <ListItemText
                                    primary="Resources"
                                    primaryTypographyProps={{
                                      variant: "body2",
                                    }}
                                    sx={{ ml: 2, color: "gray" }}
                                  />
                                </ListItem>
                              </Typography>
                            </Popover>
                          </Stack>
                        </Box>

                        <Collapse in={openCo} timeout="auto" unmountOnExit>
                          {item._id == iid && (
                            <Stack
                              pl={5}
                              pr={5}
                              spacing={1}
                              sx={{
                                bgcolor: "#d6d6d6",
                                borderRadius: "0 0 28px 28px",
                              }}
                            >
                              {/* <Typography mt={2}> <span style={{ fontWeight: '700' }}>Assigned Teacher :</span> {teacherName ? teacherName?.FullName : 'No Teacher Assigned'}</Typography> */}
                              {/* <Typography> <span style={{ fontWeight: '700' }}></span> {item.description}</Typography> */}
                              <Stack direction="row" spacing={3} pb={3}>
                                {/* {teacherName ? '' : (<><TextField label='Assign Teacher'
                                                InputLabelProps={{
                                                    style: { color: 'black', },
                                                }}
                                                sx={{ width: '100%' }} onChange={(e) => handleChange(e, item._id)} select>
                                                {teachers?.map((teac, i) => {
                                                    return <MenuItem key={i} value={teac.email} >{teac.FullName}</MenuItem>
                                                })}
                                            </TextField>
                                                <Button variant='contained' sx={{ width: '40%', alignSelf: 'center', background: '#e6e6ff', color:'black','&:hover': { background: 'red' } }}
                                                    onClick={addteacher}
                                                ><Link to='#' style={{ color: 'white', textDecoration: 'none' }}> Add Teacher</Link></Button></>)} */}
                              </Stack>
                            </Stack>
                          )}
                        </Collapse>
                        <Box
                          sx={{
                            position: "absolute",
                            top: "47px",
                            right: "1%",
                          }}
                        >
                          {" "}
                          <UserMoreMenu
                            title="BatchDetails"
                            lectId={item.lecture_id}
                            batchId={item.batch_id}
                          />
                        </Box>
                      </Box>
                    ) : null}
                  </>
                );
              })}
            </Stack>
            <ToastContainer
              position="bottom-center"
              autoClose={1000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            {/* <Typography variant='h4' sx={{ color: 'green', textTransform: 'capitalize', textAlign: 'center', width: '70%' }}>{fetchMessage}</Typography> */}
          </div>
        </Box>
      )}
    </>
  );
};

export default RecordedLectures;
