import React, { useState } from "react";
import { createBrowserHistory } from "history";
import axios from "axios";
import { responsiveFontSizes } from "@mui/material";
import "./style.css";
import { Button } from "@mui/material";

const Oath2callback = ({ props }) => {
  const history = createBrowserHistory();

  const [url, setUrl] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code");

  const reDectUrl = "https://backend-prod.invictaa.com/api/v1/oauth2callback";
  const Id = localStorage.getItem("Id");
  const batchName = localStorage.getItem("batchName");

  const youtubeGo = async () => {
    const token = localStorage.getItem("token");
    const authToken = token;

    // const config = {
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${authToken}`,
    //   },
    // };

    // try {
    //   const response = await axios.get(
    //     `${process.env.REACT_APP_LIVE_URL}/adminTeacher/authorize?reDectUrl=${reDectUrl}`,
    //     config
    //   );
    //   const newUrl = response?.data?.data;
    //   setUrl(newUrl);
    // } catch (error) {
    //   //console.log(error);
    // }
  };

  const handleclick = async () => {
    //console.log("Button clicked");

    const token = localStorage.getItem("token");
    const authToken = token;

    // const config = {
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${authToken}`,
    //   },
    // };

    // //console.log("Before API call");
    // await axios
    //   .get(
    //     `${process.env.REACT_APP_LIVE_URL}/adminTeacher/token?code=${code}`,
    //     config
    //   )
    //   .then((response) => {
    //     //console.log("API call response:", response?.data);

    //     if (response?.data?.status === true) {
    //       //console.log("Success .... Redirect to .. where it came", response?.data);
    //       //console.log("token",response?.data?.data?.access_token);
    //       localStorage.setItem(
    //         "Live_token",
    //         response?.data?.data?.access_token
    //       );
    //       window.location.href = `/dashboard/batchDetails/liveLecture/${Id}/${batchName}`;
    //       // history.push(`/dashboard/batchDetails/liveLecture/${Id}/${batchName}`);
    //     } else if (response?.data?.status === 200) {
    //       window.location.href = response?.data?.data;
    //       //   youtubeGo().then(() => {
    //       //     if (url !== "") {
    //       //       // window.open(url, "_blank");
    //       //       window.location.href = url;
    //       //     }
    //       //   });
    //     } else {
    //       //console.log(response?.data, "Try Again ... Redirect to add lecture page");

    //       youtubeGo().then(() => {
    //         if (url !== "") {
    //           // window.open(url, "_blank");
    //           window.location.href = url;
    //         }
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     //console.log("API call error:", error);
    //   });

    //console.log("After API call");
  };

  return (
    <div className="main-body">
      <div className="inner-body">
        <h2>Authorize Your Self for Adding Live Youtube Lecture</h2>
        <Button
          variant="contained"
          sx={{
            width: "max-content",
            alignSelf: "center",
            background: "#e6e6ff",
            color: "black",
            "&:hover": { background: "#8080ff" },
          }}
          onClick={handleclick}
        >
          Add Live Lecture
        </Button>
      </div>
    </div>
  );
};

export default Oath2callback;
