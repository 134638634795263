import React, { useState } from "react";

export default function CategorySelectOptions({
  parentId,
  depth = 0,
  optionsMap,
}) {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {optionsMap[parentId].length > 0 &&
        optionsMap[parentId]?.map((option, key) => (
          <React.Fragment key={option?._id}>
            <option
              style={{
                width: "100%",
                display: "flex",
                padding: "2rem",
                fontSize: "15px",
                margin: "10px",
                cursor: "pointer",
              }}
              value={option._id}
            >
              {[...Array(depth)]?.map(() => {
                return <>&mdash;</>;
              })}
              {option.title}
            </option>
            {/* Ye neeche vala h main recursive call */}
            {optionsMap[option._id]?.length > 0 && (
              <CategorySelectOptions
                optionsMap={optionsMap}
                depth={depth + 1}
                parentId={option._id}
              />
            )}
          </React.Fragment>
        ))}
    </>
  );
}
