import * as Yup from "yup";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
  Card,
  Select,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import "./general.css";
import MultiSelectComponent from "./MultiSelectComponent";
import { getFileUrl } from "../../../utils/firebaseUtils";
import CategoryComponent from "../../../utils/CategoryComponent";

// ----------------------------------------------------------------------

export default function UpdateBanner() {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the banner ID from the URL
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const [idList, setIdList] = useState();
  const [linkType, setLinkType] = useState("custom");
  const [bannerUrl, setBannerUrl] = useState();
  const [file, setFile] = useState();

  // Category-related state
  const [selectedCategory, setSelectedCategory] = useState("");
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [isGlobal, setIsGlobal] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [states, setStates] = useState();
  const [boards, setBoards] = useState();
  const [category, setCategory] = React.useState();

  // Schema validation with Yup
  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    BannerType: Yup.string().required("Banner Type required"),
    is_active: Yup.boolean().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    BannerType: "APP",
    is_active: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  // Fetch banner data on component mount
  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-banner/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const bannerData = response?.data?.data;
        // Set the fetched data as default values
        reset({
          title: bannerData?.title,
          BannerType: bannerData?.BannerType || "APP",
          is_active: bannerData?.is_active,
          customLink: bannerData?.linkType === "custom" ? bannerData?.link : "",
        });

        setBannerUrl(bannerData?.banner_url);
        setLinkType(bannerData?.linkType);
        // setFile(bannerData?.banner_url);

        setCategory(bannerData?.course);
        setStates(bannerData?.state_exam_name);
        setStateExam(bannerData?.exam_name);
        setBoards(bannerData?.boards_name);
        setClass(bannerData?.classes_name);
        setIsGlobal(bannerData?.access === 1);
        setShowLink(bannerData?.linkType !== "custom");
      } catch (error) {
        console.error("Failed to fetch banner data:", error);
      }
    };

    fetchBannerData();
  }, [id, reset]);

  const changeHandler = async (event) => {
    setFile(event.target?.files);
    const url = await getFileUrl(event.target?.files[0]);
    setBannerUrl(url);
    const fileName = document.getElementById("file").value.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
  };

  const onSubmit = async (data) => {
    if (file || bannerUrl) {
      setLoading(true);

      const payload = {
        title: data?.title,
        category: "Banner",
        BannerType: "APP",
        language: "en",
        is_active: data?.is_active.toString(),
        banner_url: bannerUrl,
        linkType: linkType,
        link: linkType === "custom" ? data?.customLink : id,
        // course: selectedCategory,
        // state_exam_id: selectedState,
        // board_id: selectedBoard,
        // exam_id: centralExam || stateExam,
        // class_id: classVal,
        access: isGlobal ? 1 : 0,
      };

      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      try {
        console.log("Respn ", payload)
        const response = await axios.put(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-banner/${id}`,
          payload,
          config
        );
     
        toast.success(response?.data?.message);
        if (response?.data?.response_code === 200) {
          setLoading(false);
          setPdf(false);
          reset();
          window.location.href = "/dashboard/banner";
        }
      } catch (e) {
        console.error("Failed to update banner:", e);
        setLoading(false);
      }
    } else {
      setRestrict("Please Select pdf or jpg");
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography variant="h3">Update Banner</Typography>
        <Card style={{ padding: "1rem 1.25rem", width: "80%" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <RHFTextField name="title" label="Title" />
              <Stack direction="row" spacing={3}>
                <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  id="file"
                  label="File"
                  error={!file && val}
                  helperText="Image Only"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPdf(true)}
                  inputProps={{ accept: "image/*" }}
                  type={pdf || focus ? "file" : "text"}
                />
              </Stack>
              <p1>{bannerUrl}</p1>

              <Stack direction="row" spacing={3}>
                <Controller
                  name="global"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={isGlobal}
                          onChange={(e) => setIsGlobal(e.target.checked)}
                        />
                      }
                      label="All Courses"
                    />
                  )}
                />
              </Stack>

              {!isGlobal && (
                <Stack direction={"row"} spacing={3}>
                  <RHFTextField
                    name="category"
                    // label="Category"
                    sx={{ width: "50%" }}
                    // select
                    value={category}
                    disabled

                  // onChange={handleCategoryChange}
                  />

                  {states && (
                    <RHFTextField
                      name="states"
                      //   label="State"
                      sx={{ width: "50%" }}
                      // select
                      value={states}
                      disabled

                    // onChange={handleStateChange}
                    />
                  )}

                  {boards && (
                    <RHFTextField
                      name="boards"
                      // label="Boards"
                      sx={{ width: "50%" }}
                      // select
                      value={boards}
                      disabled

                    // onChange={handleBoardChange}
                    />
                  )}

                  <RHFTextField
                    name="subCategory"
                    // label="Subcategory"
                    sx={{ width: "50%" }}
                    value={stateExam || classVal}
                    disabled

                  // select
                  // onChange={handleSubCategoryChange}
                  />
                </Stack>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={showLink}
                    onChange={(e) => setShowLink(e.target.checked)}
                  />
                }
                label="Link"
              />

              {showLink && (
                <Stack direction="row" spacing={3}>
                  <Select
                    value={linkType}
                    onChange={(e) => setLinkType(e.target.value)}
                    style={{ width: "50%" }}
                  >
                    <MenuItem value="custom">Custom</MenuItem>
                    <MenuItem value="get-select-course">Courses</MenuItem>
                    <MenuItem value="getTestSeriesdetails">
                      Test Series
                    </MenuItem>
                  </Select>

                  {linkType === "custom" ? (
                    <RHFTextField
                      name="customLink"
                      label="Custom Link"
                      required
                      style={{ width: "50%" }}
                    />
                  ) : (
                    <Select
                      value={id}
                      onChange={(e) => setIdList(e.target.value)}
                      style={{ width: "50%" }}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                    </Select>
                  )}
                </Stack>
              )}
              <Controller
                name="is_active"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Status"
                    style={{ width: "50%" }}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Select Status</em>
                    </MenuItem>
                    <MenuItem value={true}>Published</MenuItem>
                    <MenuItem value={false}>Draft</MenuItem>
                  </Select>
                )}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
              >
                Update Banner
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Card>
      </Stack>
    </>
  );
}
