import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

export default function DialogUserTable({
  open,
  handleClose,
  field,
  statusStatus,
  userId,
  verificationStatus,
}) {
  const [loading, setLoading] = useState(false);

  const update = async () => {
    setLoading(true);
    console.log(open, field, userId, statusStatus, verificationStatus);
    switch (field) {
      case "status":
        try {
          const token = localStorage.getItem("token");
          const response = await axios.put(
            `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-user-status/${userId}`,
            { status: statusStatus ? 0 : 1 },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(response?.data);

          if (response?.data?.response_code == 200) {
            sessionStorage.clear();
            toast.success(
              response?.data?.message || "Status updated successfully !!"
            );
            setTimeout(() => window.location.reload(), 2000);
            setLoading(false);
          } else {
            toast.error("Something went wrong!");
            setLoading(false);
          }
        } catch (error) {
          toast.error(error || error?.message);
          setLoading(false);
        }
        break;

      case "verification":
        try {
          const token = localStorage.getItem("token");

          const response = await axios.put(
            `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-mobile-verification/${userId}`,
            { is_otp_verified: verificationStatus ? 0 : 1 },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(response?.data);
          if (response?.data?.response_code == 200) {
            sessionStorage.clear();
            toast.success(
              response?.data?.message ||
                "Verification Status updated successfully !!"
            );
            setTimeout(() => window.location.reload(), 2000);
            setLoading(false);
          } else {
            toast.error("Something went wrong!");
            setLoading(false);
          }
        } catch (error) {
          toast.error("couldn't update the status, Something went wrong!");
          setLoading(false);
        }
        break;

      default:
        break;
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Do you want to update {field} {field === "status" ? "" : "status"} ?
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText>This will Update {field}</DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>NO</Button>
        <Button onClick={() => update()}>
          {loading ? <CircularProgress size={25} /> : "YES"}
        </Button>
        <ToastContainer position="bottom-center" />
      </DialogActions>
    </Dialog>
  );
}
