import PropTypes from "prop-types";
// material
import { Paper, Typography } from "@mui/material";
import Img from "../../src/data/not_found.png";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = "", ...other }) {
  return (
    <Paper
      {...other}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "200px",
      }}
    >
      <Typography gutterBottom align="center" variant="subtitle1">
        <img
          src={Img}
          style={{ width: "80px", margin: "auto" }}
          alt="Your Alt Text"
        />
        <p style={{ fontSize: "10px", marginTop: "5px" }}>
          We couldn’t find what you’re looking for.
        </p>
        {/* Data Not found ❗❗ */}
      </Typography>
       
    </Paper>
  );
}
