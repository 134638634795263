import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../sections/@dashboard/user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "#", label: "#", alignRight: false },
  { id: "id", label: "Enrolment No.", alignRight: false },
  { id: "first_name", label: "Name", alignRight: false },
  { id: "mobile_no", label: "Phone No.", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "action", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user?.first_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function NewlyResitered({ usersData }) {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState(usersData);
  const [loading, setLoading] = useState(false);
  //Getting Details
  const role = localStorage.getItem("role");
  useEffect(() => {
    const token = localStorage.getItem("token");

    // if (role === "Admin") {
    //   async function fetchUsers() {
    //     try {
    //       const fullResponse = await fetch(
    //         `${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails`,
    //         {
    //           method: "GET",
    //           headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${token}`,
    //           },
    //         }
    //       );

    //       const responseJson = await fullResponse?.json();
    //       let limitedUsers = responseJson?.data?.slice(0, 100); // Limit to the first 100 users
    //       usersSet(limitedUsers);
    //       setLoading(false);

    //       if (responseJson?.msg === "not an admin") {
    //         localStorage.removeItem("isLoggedIn");
    //         localStorage.removeItem("details");
    //         navigate("/");
    //       }
    //     } catch (error) {
    //       console.error("Error fetching users:", error);
    //       // Handle error
    //     }
    //   }

    // fetchUsers();

    setLoading(false);

  }, [navigate, role]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n.first_name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;
  let count = 0;
  let style = {};
  if (role !== "Admin") {
    style = { display: "none" };
  }
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader
            color=" #8080ff"
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Dashboard">
          <Container
            style={style}
            sx={{
              minWidth: "100%",
            }}
          >
            <Card>
              <Typography variant="h6" pl={2} pt={2}>
                Newly Registered Students
              </Typography>
              <Scrollbar>
                <TableContainer sx={{ minWidth: "100%" }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      title="NewlyRegistered"
                    />
                    <TableBody>
                      {filteredUsers
                        ?.slice(0, 40)
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          const {
                            id,
                            enrollId,
                            first_name,
                            email,
                            profile_image,
                            profilePhoto,
                            mobile_no,
                            avatarUrl,
                            userId,
                          } = row;
                          const isItemSelected =
                            selected.indexOf(first_name) !== -1;
                          count = index + 1 + rowsPerPage * page;
                          return (
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {count}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography noWrap>
                                  {id}
                                </Typography>
                              </TableCell>

                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    alt={first_name}
                                    src={profile_image}
                                    sx={{ width: 24, height: 24 }}
                                  />
                                  <Typography noWrap>
                                    {first_name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                {mobile_no || "NA"}
                              </TableCell>
                              <TableCell align="left">{email}</TableCell>
                              {/* <TableCell align="left">{username}</TableCell> */}

                              {/* <TableCell align="right">
                                <UserMoreMenu id={userId} />
                              </TableCell> */}
                              <TableCell align="left">
                                {row.status === 1 ? (
                                  <Typography variant="subtitle2" noWrap color={'green'}>
                                    Active
                                  </Typography>
                                ) : (
                                  <Typography variant="subtitle2" noWrap color={'red'}>
                                    InActive
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
