import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  TextField,
  MenuItem,
  TableHead,
  Box,
  IconButton,
  Menu,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import ImageIcon from "@mui/icons-material/Image";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFileUrl } from "../../../utils/firebaseUtils";
import MoreVertIcon from "@mui/icons-material/MoreVert";


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "id", label: "S.No", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "banner_url", label: "Banner", alignRight: false },
  { id: "course", label: "Category", alignRight: false },

  { id: "exam name", label: "Exam Name / Class", alignRight: false },
  // { id: 'category', label: 'Category', alignRight: false },
  // { id: 'language', label: 'Language', alignRight: false },
  // { id: 'language', label: 'Language', alignRight: false },
  // { id: "BannerType", label: "BannerType", alignRight: false },
  { id: "created_at", label: "AddedAt", alignRight: false },
  { id: "is_active", label: "Status", alignRight: false },
  { id: "is_active", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function BannerTable() {
  const detailObject = JSON.parse(localStorage.getItem("detailObject"));
  const accessArr = detailObject?.access_level?.split(',');
  const role = localStorage.getItem('role');
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();
  const [bannerUrl, setBannerUrl] = useState();

  const [isActiveValue, setIsActiveValue] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPageRows = users
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((user) => user.id);
      setSelectedRows(currentPageRows);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };
  const handleRowSelect = (userId) => {
    if (selectedRows.includes(userId)) {
      setSelectedRows(selectedRows.filter((id) => id !== userId));
    } else {
      setSelectedRows([...selectedRows, userId]);
    }
  };
  const handleDeleteButtonDisabled = () => {
    return selectedRows.length === 0;
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-banner`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
      console.log(responseJson?.data);
      setLoading(false);
      // if (responseJson?.msg === 'not an admin') {
      //     localStorage.removeItem("isLoggedIn")
      //     localStorage.removeItem("details")
      //     navigate('/')
      // };
    }

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );
  let helperArray = [];
  filteredUsers?.map((item) =>
    helperArray.push({
      id: item.id,
      title: item?.title,
      BannerType: item?.BannerType,
      bannerUrl: item?.banner_url,
      createdAt: item?.created_at,
    })
  );
  const isUserNotFound = filteredUsers.length === 0;
  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  const handleDeleteSelected = async () => {
    const token = localStorage.getItem("token");
    console.log([...selectedRows]);
    // const res = await axios.delete(
    //   `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-banner`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   },
    //   { ids: [...selectedRows] }
    // );
    // console.log(res);
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-banner`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids: [...selectedRows] }),
      }
    );
    const fullres = res.json();
    toast.success("Delete successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1500);

    console.log(res);
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-banner/${selectedRow?.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        toast.success("Banner deleted successfully.", {
          position: "bottom-center",
        });

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error("Failed to delete Banner. Please try again.", {
          position: "bottom-center",
        });
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.", {
        position: "bottom-center",
      });
      console.error("An error occurred while deleting the Banner:", error);
    }
    handleMenuClose();
  };

  //console.log(updatedValues);
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Banner">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
                {selectedRows.length > 0 && (
                  <Box height={"40px"}>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ height: "40px" }}
                      onClick={handleDeleteSelected}
                    >
                      Delete Selected Rows
                    </Button>
                  </Box>
                )}
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {
                          (role == 'Admin' || accessArr?.includes('delete')) &&
                          <TableCell padding="checkbox">

                            <Checkbox
                              checked={selectAll}
                              onChange={handleSelectAll}
                              indeterminate={
                                selectedRows.length > 0 &&
                                selectedRows.length < users.length
                              }
                            />
                          </TableCell>
                        }
                        {TABLE_HEAD.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.alignRight ? "right" : "left"}
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {filteredUsers
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          const {
                            title,
                            id,
                            category,
                            language,
                            course,
                            exam_name,
                            classes_name,
                            created_at,
                            is_active,
                            banner_url,
                            BannerType,
                          } = row;

                          const createdDate = created_at.split("T")[0];
                          const isItemSelected = selected.indexOf(title) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                            {
                              (role == 'Admin' || accessArr?.includes('delete'))&&
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedRows.includes(row.id)}
                                  onChange={() => handleRowSelect(row.id)}
                                />
                              </TableCell>
                            }
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {index + 1 + rowsPerPage * page}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {title}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <a
                                  href={banner_url || "/#"}
                                  style={{ color: "black" }}
                                  target="_blank"
                                >
                                  <img
                                    src={banner_url}
                                    alt={title}
                                    style={{ width: "150px" }}
                                  />
                                </a>
                              </TableCell>
                              <TableCell align="center">{course}</TableCell>
                              <TableCell align="center">
                                {exam_name || classes_name || "NA"}
                              </TableCell>
                              <TableCell align="left">{createdDate}</TableCell>
                              <TableCell align="left">
                                <span
                                  style={{
                                    padding: "5px",
                                    borderRadius: "10px",
                                    color:
                                      is_active === "true" ? "green" : "red",
                                    fontWeight: "500",
                                  }}
                                >
                                  {is_active === "true" ? "Active" : "Inactive"}
                                </span>
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setSelectedRow(row);
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleMenuClose}
                                >
                                {
                                  (role=='Admin'|| accessArr?.includes("write"))&&
                                  <MenuItem
                                    onClick={() => {
                                      navigate(
                                        `/dashboard/updatebaner/${selectedRow?.id}`
                                      );
                                      handleMenuClose();
                                    }}
                                  >
                                    Edit
                                  </MenuItem>
                                }
                                {
                                  (role=='Admin'|| accessArr?.includes("delete"))&&
                                  <MenuItem onClick={handleDelete}>
                                    Delete
                                  </MenuItem>
                                }

                                </Menu>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </Container>
        </Page>
      )}
    </>
  );
}
