import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Popper,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import * as React from "react";
import Iconify from "../../../components/Iconify";
import { Link, useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import Page from "../../../components/Page";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { UserMoreMenu } from "../user";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BatchDetailsCard from "./components/BatchDetailsCard";
import RecordedLecturePlay from "./RecordedLecturePlay";
import "./style.css";
import { useState, useEffect } from "react";

// for list
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Margin } from "@mui/icons-material";
import { DataGrid, GridToolbar, GridToolbarExport } from "@mui/x-data-grid";
import { render } from "react-dom";

const ListItemStyle = {
  cursor: "pointer",
  transition: "",
  padding: "5px 10px",
  "&:hover": {
    bgcolor: "#8080ff",
  },
};
const AccordionStyle = {
  // boxShadow: '1px 1px 7px 1px rgba(128,128,128,0.4)',
  background: "#e6e6ff",
  borderRadius: "18px",
  width: "90%",
  transition: "all 0.3s",
  position: "relative",
  transition: "all 0.2s",
  "&:hover": {
    background: "#ccccff",
  },
};

const BatchDetails = () => {
  // const theme = useTheme();
  const access = JSON.parse(localStorage.getItem("detailObject"));

  const navigate = useNavigate();
  const [users, usersSet] = React.useState();
  const [teachers, setTeachers] = React.useState();
  const { Id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [lectureId, setLectureId] = React.useState();
  const [iid, setiid] = React.useState();
  const [fetchMessage, setFetchMessage] = React.useState();
  const [assignedTeacherEmail, setAssignedTeacherEmail] = React.useState([]);
  const [showAccordian, setShowAccordian] = React.useState("0"); //for accordian

  // for edit teacher
  const [editTeacher, setEditTeacher] = useState(false);

  //Popover
  // const [openCo, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [batchName, setBatchName] = React.useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
    console.log("selected Rows", selectedRows);
  };
  // useEffect(() => {
  //   console.log("selected Row data", selectedRows);
  // }, [selectedRows]);
  const handleDeleteButtonDisabled = () => {
    return selectedRows.length === 0;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    setLectureId(id);
    setAssignedTeacherEmail(value);
    //console.log(lectureId);
  };

  //Adding teacher
  const addteacher = async () => {
    const token = localStorage.getItem("token");

    const authToken = token;
    const data = {
      teacherEmail: assignedTeacherEmail,
    };
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    // axios
    //   .post(
    //     `${process.env.REACT_APP_LIVE_URL}/adminPanel/addTeacherToLecture/${lectureId}`,
    //     data,
    //     config
    //   )
    //   .then((response) => {
    //     //console.log(response?.data?.msg);
    //     setFetchMessage(response?.data?.msg);
    //     if (response?.data?.msg === "Added the teacher") {
    //       toast.success("Added the Teacher");
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 600);
    //     }
    //   })
    //   .catch((e) => {
    //     //console.log(e);
    //   });
  };

  const [teacherLectureDetails, setTeacherLectureDetails] = React.useState();
  React.useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    //Admin Side Lecture Details
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-lecture-by-course-id/${Id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      console.log(responseJson?.data);
      usersSet(
        responseJson?.data?.filter((lecture) => {
          return lecture.course === Number(Id);
        })
      );

      //Teacher Side Lecture Details

      // const TeacherDetailsResponse = await fetch(
      //   `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLectureDetailsAssigned?BatchId=${Id}`,
      //   {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // const TeacherDetailsResponseJson = await TeacherDetailsResponse.json();
      // setTeacherLectureDetails(TeacherDetailsResponseJson.data);
      // setLoading(false);
      // const teacherResponse = await fetch(
      //   `${process.env.REACT_APP_LIVE_URL}/adminTeacher/teacherList`,
      //   {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // const teacher = await teacherResponse.json();
      // setTeachers(teacher.data);
    }

    fetchUsers();
    setLoading(false);
  }, []);

  const [courseName, setCourseName] = React.useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    //Admin Side Lecture Details
    async function fetchCourseName() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-course-by-id/${Id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setCourseName(responseJson?.data?.course?.name);
    }
    fetchCourseName();
  }, []);

  //Youtube Link
  const [youtubeVideo, setYoutubeVideo] = useState(false);

  // For Go live token storage
  // const reDectUrl = "https://admin-panel.upschindi.online/oauth/callback";
  const reDectUrl = "https://backend-prod.invictaa.com/api/v1/oauth2callback";
  //  const reDectUrl = "http://dev.upschindi.online/oauth/callback";
  // const reDectUrl = window.location.href;
  const [liveStatus, setLiveStatus] = useState("");
  const [youtubeStatus, setYoutubeStatus] = useState("");
  const [url, setUrl] = useState("");
  const CryptoJS = require("crypto-js");

  const youtubeGomain = async () => {
    // Set a variable in local storage
    localStorage.setItem("Id", Id);
    localStorage.setItem("batchName", batchName);

    const youtubeGo = async () => {
      const token = localStorage.getItem("token");

      const authToken = token;

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      // try {
      //   const response = await axios.get(
      //     `${process.env.REACT_APP_LIVE_URL}/adminTeacher/authorize?reDectUrl=${reDectUrl}`,
      //     config
      //   );
      //   const newUrl = response?.data?.data;
      //   setUrl(newUrl);
      //   //console.log(response);
      // } catch (error) {
      //   //console.log(error);
      // }
    };

    await youtubeGo();

    if (url !== "") {
      window.open(url, "_blank");
      //window.location.href = url;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    if (code) {
      // Do something with the authorization code
      //console.log("My Code", code);
    }
  };

  // End of Go Live token Storage

  const detailObject = JSON.parse(localStorage.getItem("detailObject"));
  const accessArr = detailObject?.access_level?.split(',');
  const contentAccess = detailObject?.accessToContent
  const role = localStorage.getItem('role');
  console.log(accessArr)

  // for list
  const [openk, setOpenk] = React.useState(false);

  const handleClickk = () => {
    setOpenk(!openk);
  };

  //! below code is for DataGrid

  const CustomExportToolbar = () => (
    <div>
      <GridToolbarExport />
    </div>
  );

  const audioRef = React.useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleTogglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const audioElement = audioRef.current;

    const handleAudioEnd = () => {
      setIsPlaying(false);
    };

    if (audioElement) {
      audioElement.addEventListener("ended", handleAudioEnd);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener("ended", handleAudioEnd);
      }
    };
  }, [isPlaying]);

  const columns = [
    // { field: "id", headerName: "S No.", width: 150 },
    { field: "title", headerName: "Lecture Name", width: 200 },
    // {
    //   field: "starting_date",
    //   headerName: "Starting Date",
    //   width: 150,
    //   valueGetter: ({ value }) => {
    //     return value?.split(" ")[0] || "NA";
    //   },
    // },
    // {
    //   field: "lecture_type", headerName: "Lecture Type", width: 150,
    //   valueGetter: ({ value }) => {
    //     return value == '1' ? "Live" : value == '2' ? "Recorded" : "NA";
    //   },
    // },

    {
      field: "subject",
      headerName: "Subject",
      width: 200,
      valueGetter: ({ value }) => {
        return value?.name || "NA";
      },
    },
    {
      field: "material_type",
      headerName: "Material",
      width: 200,
      valueGetter: ({ value }) => {
        return value === "vi" ? "Video" : "Audio" || "NA";
      },
    },
    // {
    //   field: "lecture_type",
    //   headerName: "Lecture Type",
    //   width: 200,
    //   valueGetter: ({ value }) => {
    //     return value === "1" ? "Live" : "Recorded" || "NA";
    //   },
    // },

    {
      field: "lecture_type",
      headerName: "Lecture Type",
      width: 150,
      valueGetter: (params) => {
        const lectureType = params.row.lecture_type;
        if (lectureType === "1") {
          return "Live";
        } else if (lectureType === "2") {
          return "Recorded";
        } else {
          return "NA";
        }
      },
    },

    {
      field: "actions",
      headerName: "View",
      width: 200,
      renderCell: (params) => (
        <>
          {params?.row?.lecture_type === "1" &&
            params?.row?.material_type === "vi" ? (
            <a
              href={`/dashboard/youtubeLive/${params?.row?.id}/${params?.row?.title
                }/${"<p>description</p>"}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: "60%",
                color: "white",
                textDecoration: "none",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "100px" }}
              >
                Live
              </Button>
            </a>
          ) : params?.row?.lecture_type === "2" &&
            params?.row?.material_type === "vi" ? (
            <a
              href={`/dashboard/youtubeLive/${params?.row?.id}/${params?.row?.title
                }/${"<p>description</p>"}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: "60%",
                color: "white",
                textDecoration: "none",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "100px" }}
              >
                View
              </Button>
            </a>
          ) : (
            <Box>
              <audio
                ref={audioRef}
                src={params?.row?.material_url?.replace(/\\/g, "")}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "100px" }}
                onClick={handleTogglePlayPause}
              >
                {isPlaying ? "Pause" : "Play"}
              </Button>
            </Box>
          )}
        </>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Box
        // sx={{
        //   position: "absolute",
        //   top: "47px",
        //   right: "1%",
        // }}
        >
          {" "}
          <UserMoreMenu
            title="BatchDetails"
            lectId={params?.id}
            id={params?.id}
            req="delete-lecture"
            batchId={Id}
            setLoading={setLoading}
          />
        </Box>
      ),
    },
  ];

  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const startIndex = page * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedUsers = users?.slice(startIndex, endIndex);

  const handlePageChange = (event, newPage) => setPage(newPage);

  const handleRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target?.value, 10));
    setPage(0); // Reset page when changing rows per page
  };

  // useEffect(() => {
  //   // Reset selected rows when userRows changes
  //   setSelectedRows([]);
  // }, [users]);

  const handleSelectAllRows = () => {
    // Get current page rows
    const currentPageRows = paginatedUsers
      ?.slice(page * pageSize, (page + 1) * pageSize)
      ?.map((row) => row.id);
    setSelectedRows(currentPageRows);
  };
  const handleDeleteSelected = async () => {
    const token = localStorage.getItem("token");
    console.log([...selectedRows]);
    // const res = await axios.delete(
    //   `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-banner`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   },
    //   { ids: [...selectedRows] }
    // );
    // console.log(res);
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-lecture`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids: [...selectedRows] }),
      }
    );
    const fullres = res.json();
    toast.success("Delete successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1500);

    console.log(res);
  };
  const [videoPlay, setVideoPlay] = React.useState(false);
  return (
    <>
      {" "}
      {loading && !users ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Lecture">
          <Grid container spacing={1} sx={{ height: "80vh" }}>
            {/* sx={{ height: "100%" , margin :"15px" , boxSizing: 'border-box'}} */}
            <Grid item xs={12} sm={12} md={8}>
              <Box spacing={3} ml={5}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={3}
                  sx={{ width: "90%" }}
                >
                  <Typography className="page-heading">
                    All Lectures {`(${courseName})`}
                  </Typography>

                  {(contentAccess?.includes("Add Center Lecture") ||role=='Admin') && (
                      <Button
                        variant="contained"
                        sx={{
                          width: "20%",
                          alignSelf: "center",
                          background: " #8080ff",
                          color: "white",
                          "&:hover": { background: " #0000ff" },
                          textWrap: "nowrap",
                        }}
                        onClick={() => {
                          navigate(
                            `/dashboard/batchDetails/addLecture/${Id}/${batchName}`
                          );
                        }}
                      >
                        Add Lecture
                      </Button>
                    )}
                </Stack>
                <div>
                  {role === "Admin" && (
                    <Stack sx={{ width: "100%", borderRadius: "10px" }}>
                      {users ? (
                        users.length === 0 ? (
                          <Typography variant="h4">No Lecture</Typography>
                        ) : (
                          <>
                            <DataGrid
                              rows={paginatedUsers}
                              columns={columns}
                              hideFooterPagination
                              onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                setPage(0); // Reset page when changing page size
                              }}
                              page={page}
                              onPageChange={handlePageChange}
                              style={{
                                marginLeft: "30px",
                                marginTop: "10px",
                                marginBottom: "30px",
                                border: "none",
                              }}
                              checkboxSelection
                              // onSelectionModelChange={(newSelection) => {
                              //   handleSelectionChange(
                              //     newSelection.selectionModel
                              //   );
                              // }}
                              onRowSelectionModelChange={(newSelection) => {
                                handleSelectionChange(newSelection);
                              }}
                              // selectionModel={selectedRows}
                              rowSelectionModel={selectedRows}
                              components={{
                                Toolbar: () => {
                                  return <GridToolbar />;
                                },
                                ExportToolbar: CustomExportToolbar,
                              }}
                            // slotProps={{
                            //   toolbar:{
                            //     showQuickFilter:true
                            //   }
                            // }}
                            />

                            <TablePagination
                              rowsPerPageOptions={[5, 10, 20]}
                              component="div"
                              count={users?.length}
                              rowsPerPage={pageSize}
                              page={page}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleRowsPerPageChange}
                            />
                            {selectedRows.length > 0 && (
                              <Button
                                variant="contained"
                                color="error"
                                onClick={handleDeleteSelected}
                                style={{
                                  marginLeft: "30px",
                                  marginTop: "10px",
                                  marginBottom: "30px",
                                }}
                              >
                                Delete Selected Lectures
                              </Button>
                            )}
                          </>
                        )
                      ) : (
                        // users?.map((item, index) => {
                        //   // debugger;
                        //   //? temperory
                        //   // const [teacherName] = item?.teacher;

                        //   // for initials show 1st accordian
                        //   // if(showAccordian==='0'){
                        //   //     setShowAccordian('1');
                        //   //     setiid(item?.id);
                        //   // }

                        //   let date = item?.starting_date.split(" ")[0];
                        //   let today = new Date();
                        //   let todayDate =
                        //     today.getDate() +
                        //     "-" +
                        //     (today.getMonth() + 1) +
                        //     "-" +
                        //     today.getFullYear();
                        //   let check = todayDate.split("-")[0];
                        //   let checkMonth = todayDate.split("-")[1];

                        //   let golive;

                        //   if (check < 10) {
                        //     if (checkMonth > 9) {
                        //       let day = "0" + today.getDate();
                        //       let todayDate =
                        //         day +
                        //         "-" +
                        //         (today.getMonth() + 1) +
                        //         "-" +
                        //         today.getFullYear();
                        //       golive = todayDate == date;
                        //     } else {
                        //       let day = "0" + today.getDate();
                        //       let month = "0" + (today.getMonth() + 1);
                        //       let todayDate =
                        //         day + "-" + month + "-" + today.getFullYear();
                        //       golive = todayDate == date;
                        //     }
                        //   } else {
                        //     if (checkMonth > 9) {
                        //       let todayDate =
                        //         today.getDate() +
                        //         "-" +
                        //         (today.getMonth() + 1) +
                        //         "-" +
                        //         today.getFullYear();
                        //       golive = todayDate == date;
                        //     } else {
                        //       let month = "0" + (today.getMonth() + 1);
                        //       let todayDate =
                        //         today.getDate() +
                        //         "-" +
                        //         month +
                        //         "-" +
                        //         today.getFullYear();
                        //       golive = todayDate == date;
                        //     }
                        //   }
                        //   let newLink = "";
                        //   // console.log(item?.link);
                        //   Array.from(item?.link).forEach((elem) => {
                        //     if (elem !== "/") newLink += elem;
                        //     else newLink += "@";

                        //     // console.log('newLin', newLink)
                        //   });
                        //   // console.log(item?.link)
                        //   const encodedYtUrl = encodeURIComponent(newLink);
                        //   // console.log(encodedYtUrl);
                        //   // console.log(item?.description);
                        //   // let lectDescription = '';
                        //   // if( item?.description.includes("<p>")){
                        //   //   const helperString = item?.description.split(">")[1]
                        //   //   lectDescription = helperString.split("<")[0];
                        //   // }else{
                        //   //   lectDescription = item?.description;
                        //   // }
                        //   const helperString = item?.description?.split(">")[1];
                        //   const lectDescription = helperString?.split("<")[0];
                        //   // console.log(item);
                        //   // console.log(`/dashboard/livestream/${item?.title}/${item?.id}/${Id}/${lectDescription}`)

                        //   return (
                        //     //#region 2
                        //     <Box
                        //       key={index}
                        //       sx={{ marginBottom: "25px", ...AccordionStyle }}
                        //     >
                        //       <Box
                        //         pl={5}
                        //         pr={3}
                        //         pt={2}
                        //         pb={2}
                        //         onClick={() => {
                        //           setiid(item?.id);
                        //           // setShowAccordian(!showAccordian);
                        //           // handleClickCollapse(item?.id);
                        //         }}
                        //         sx={{
                        //           cursor: "pointer",
                        //           width: "100%",
                        //           display: "flex",
                        //           flexDirection: "row",
                        //           justifyContent: "space-between",
                        //           position: "relative",
                        //         }}
                        //       >
                        //         <Stack spacing={0.5}>
                        //           <Typography
                        //             color=" #000000"
                        //             sx={{
                        //               fontSize: "22px",
                        //               fontWeight: "bold",
                        //             }}
                        //           >
                        //             Lecture {index + 1}{" "}
                        //             {item?.lecture_type == "YT"
                        //               ? "( YouTube )"
                        //               : ""}
                        //           </Typography>
                        //           <Typography
                        //             sx={{ fontSize: "18px", fontWeight: "500" }}
                        //           >
                        //             Topic: {item?.title}
                        //           </Typography>
                        //           <Typography
                        //             sx={{ fontSize: "18px", fontWeight: "500" }}
                        //           >
                        //             Subject: {item?.subject?.name || item?.subject}
                        //           </Typography>
                        //           <Typography
                        //             sx={{ fontSize: "15px", fontWeight: "600" }}
                        //           >
                        //             Date:
                        //             <span
                        //               style={{
                        //                 fontWeight: "400",
                        //                 color: "black",
                        //               }}
                        //             >
                        //               {" "}
                        //               {date}
                        //             </span>
                        //           </Typography>
                        //         </Stack>
                        //         {
                        //           //#region 1

                        //           item?.lecture_type != "YT" && (
                        //             <Stack
                        //               direction="row"
                        //               alignItems="center"
                        //               justifyContent="space-between"
                        //               sx={{ width: "25%", height: "110px" }}
                        //             >
                        //               {item?.LiveOrRecorded == 1 && (
                        //                 <Button
                        //                   variant="contained"
                        //                   disabled={!golive}
                        //                   sx={{
                        //                     width: "60%",
                        //                     alignSelf: "center",
                        //                     background: "#8080ff",
                        //                     color: "white",
                        //                     borderRadius: "20px",
                        //                     "&:hover": {
                        //                       background: "#0000ff",
                        //                     },
                        //                   }}
                        //                 >
                        //                   <Link
                        //                     to={
                        //                       item?.LiveOrRecorded == 1
                        //                         ? // ? `/dashboard/livestream/${item?.title}/${item?.id}/${Id}/${item?.description}`
                        //                         `/dashboard/livestream/${item?.title}/${item?.id}/${Id}/${lectDescription}`
                        //                         : "/dashboard/app"
                        //                     }
                        //                     style={{
                        //                       color: "white",
                        //                       textDecoration: "none",
                        //                     }}
                        //                   >
                        //                     {" "}
                        //                     {item?.LiveOrRecorded === 1
                        //                       ? "Go Live"
                        //                       : "View"}
                        //                   </Link>
                        //                 </Button>
                        //               )}
                        //               {item?.LiveOrRecorded == 2 && (
                        //                 <>
                        //                   <a
                        //                     href={item?.material?.fileLoc}
                        //                     // href={item?.link}
                        //                     style={{
                        //                       width: "60%",
                        //                       color: "white",
                        //                       textDecoration: "none",
                        //                     }}
                        //                   >
                        //                     <Button
                        //                       variant="contained"
                        //                       sx={{
                        //                         width: "100%",
                        //                         height: "100%",
                        //                         alignSelf: "center",
                        //                         background: " #8080ff",
                        //                         color: "white",
                        //                         borderRadius: "20px",
                        //                         "&:hover": {
                        //                           background: " #0000ff",
                        //                         },
                        //                       }}
                        //                     >
                        //                       View
                        //                     </Button>
                        //                   </a>
                        //                 </>
                        //               )}
                        //             </Stack>
                        //           )
                        //           //#endregion 1
                        //         }

                        //         {
                        //           //#region go live button 1
                        //           item?.lecture_type == "YT" && (
                        //             <div className="button-align">
                        //               <Stack
                        //                 direction="row"
                        //                 alignItems="center"
                        //                 justifyContent="space"
                        //                 sx={{ width: "100%", height: "100%" }}
                        //               >
                        //                 <a
                        //                   // href={item?.link}
                        //                   // href={`/dashboard/youtubeLive/${item?.id}/${item?.title}/?ytUrl=${CryptoJS.AES.encrypt(item?.link, 'myKey').toString().toLowerCase()}`}
                        //                   href={`/dashboard/youtubeLive/${item?.id}/${item?.title}/${lectDescription}/${encodedYtUrl}`}
                        //                   target="_blank"
                        //                   rel="noopener noreferrer"
                        //                   style={{
                        //                     width: "60%",
                        //                     color: "white",
                        //                     textDecoration: "none",
                        //                   }}
                        //                 >
                        //                   {item?.LiveOrRecorded ==
                        //                     2 ? (
                        //                     <Button
                        //                       variant="contained"
                        //                       // disabled={!golive}
                        //                       sx={{
                        //                         width: "100%",
                        //                         alignSelf: "center",
                        //                         background: " #8080ff",
                        //                         color: "white",
                        //                         borderRadius: "20px",
                        //                         "&:hover": {
                        //                           background: " #0000ff",
                        //                         },
                        //                       }}
                        //                       onClick={() => setVideoPlay(true)}
                        //                     >
                        //                       View
                        //                     </Button>
                        //                   ) : (
                        //                     <Button
                        //                       variant="contained"
                        //                       disabled={!golive}
                        //                       sx={{
                        //                         width: "100%",
                        //                         alignSelf: "center",
                        //                         background: " #8080ff",
                        //                         color: "white",
                        //                         borderRadius: "20px",
                        //                         "&:hover": {
                        //                           background: " #0000ff",
                        //                         },
                        //                       }}
                        //                     // onClick={() => setVideoPlay(true)}
                        //                     // onClick={() => {
                        //                     //   // console.log(`${item?.id}/${item?.link}/${item?.title}`);
                        //                     //   console.log(`/dashboard/youtubeLive/${item?.id}/${item?.link}/${item?.title}`);
                        //                     //   // navigate(`/dashboard/youtubeLive/${item?.id}/${item?.link}/${item?.title}`)
                        //                     // }}
                        //                     >
                        //                       Go Live
                        //                     </Button>
                        //                   )}
                        //                 </a>
                        //               </Stack>
                        //             </div>
                        //           )
                        //           //#endregion
                        //         }
                        //       </Box>

                        //       <Collapse
                        //         in={item?.id == iid}
                        //         timeout="auto"
                        //         unmountOnExit
                        //       >
                        //         {item?.id == iid && (
                        //           <Stack
                        //             pl={5}
                        //             pr={5}
                        //             spacing={1}
                        //             sx={{
                        //               bgcolor: "rgba(145, 158, 171, 0.24)",
                        //               borderRadius: "0 0 18px 18px",
                        //             }}
                        //           >
                        //             {/* <Typography mt={2}>
                        //               {" "}
                        //               <span style={{ fontWeight: "700" }}>
                        //                 Assigned Teacher :
                        //               </span>{" "}
                        //               {teacherName
                        //                 ? teacherName?.FullName
                        //                 : "No Teacher Assigned"}
                        //             </Typography> */}
                        //             <Typography>
                        //               {" "}
                        //               <span style={{ fontWeight: "700" }}>
                        //                 Description :
                        //               </span>{" "}
                        //               {item?.description.replace(
                        //                 /(<([^>]+)>)/gi,
                        //                 ""
                        //               )}
                        //             </Typography>
                        //             {/* <Stack direction="row" spacing={3} pb={3}>
                        //               {!editTeacher ? (
                        //                 <>
                        //                   <Button
                        //                     variant="contained"
                        //                     sx={{
                        //                       width: "40%",
                        //                       alignSelf: "center",
                        //                       background: " #8080ff",
                        //                       color: "white",
                        //                       "&:hover": {
                        //                         background: " #0000ff",
                        //                       },
                        //                     }}
                        //                     onClick={setEditTeacher(
                        //                       !editTeacher
                        //                     )}
                        //                   >
                        //                     <Link
                        //                       to="#"
                        //                       style={{
                        //                         color: "white",
                        //                         textDecoration: "none",
                        //                       }}
                        //                     >
                        //                       {" "}
                        //                       Edit Teacher
                        //                     </Link>
                        //                   </Button>
                        //                 </>
                        //               ) : (
                        //                 <>
                        //                   <TextField
                        //                     label="Assign Teacher"
                        //                     InputLabelProps={{
                        //                       style: { color: "black" },
                        //                     }}
                        //                     sx={{ width: "100%" }}
                        //                     onChange={(e) =>
                        //                       handleChange(e, item?.id)
                        //                     }
                        //                     select
                        //                   >
                        //                     {teachers?.map((teac, i) => {
                        //                       return (
                        //                         <MenuItem
                        //                           key={i}
                        //                           value={teac.email}
                        //                         >
                        //                           {teac.FullName}
                        //                         </MenuItem>
                        //                       );
                        //                     })}
                        //                   </TextField>
                        //                   <Button
                        //                     variant="contained"
                        //                     sx={{
                        //                       width: "40%",
                        //                       alignSelf: "center",
                        //                       background: " #8080ff",
                        //                       color: "white",
                        //                       "&:hover": {
                        //                         background: " #0000ff",
                        //                       },
                        //                     }}
                        //                     onClick={addteacher}
                        //                   >
                        //                     <Link
                        //                       to="#"
                        //                       style={{
                        //                         color: "black",
                        //                         textDecoration: "none",
                        //                       }}
                        //                     >
                        //                       {" "}
                        //                       Update Teacher
                        //                     </Link>
                        //                   </Button>
                        //                 </>
                        //               )}
                        //             </Stack> */}
                        //           </Stack>
                        //         )}
                        //       </Collapse>
                        //       <Box
                        //         sx={{
                        //           position: "absolute",
                        //           top: "47px",
                        //           right: "1%",
                        //         }}
                        //       >
                        //         {" "}
                        //         <UserMoreMenu
                        //           title="BatchDetails"
                        //           lectId={item?.id}
                        //           id={item?.id}
                        //           req="delete-lecture"
                        //           batchId={Id}
                        //           setLoading={setLoading}
                        //         />
                        //       </Box>
                        //     </Box>
                        //     //  #endregion 2
                        //   );
                        // })
                        <div
                          style={{
                            display: "flex",
                            marginTop: "5rem",
                            width: "100%",
                            marginInline: "auto",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress />
                        </div>
                        // <Typography variant="h4">No Lecture</Typography>
                      )}
                    </Stack>
                  )}
                  {role === "subadmin" && (
                    <Stack sx={{ width: "100%", borderRadius: "10px" }}>
                      {users ? (
                        users?.map((item, index) => {
                          // console.log(item);
                          debugger;
                          // const [teacherName] = item?.teacher;

                          // for initials show 1st accordian
                          // if(showAccordian==='0'){
                          //     setShowAccordian('1');
                          //     setiid(item?.id);
                          // }

                          let date = item?.starting_date.split(" ")[0];
                          let today = new Date();
                          let todayDate =
                            today.getDate() +
                            "-" +
                            (today.getMonth() + 1) +
                            "-" +
                            today.getFullYear();
                          let check = todayDate.split("-")[0];
                          let checkMonth = todayDate.split("-")[1];

                          let golive;

                          if (check < 10) {
                            if (checkMonth > 9) {
                              let day = "0" + today.getDate();
                              let todayDate =
                                day +
                                "-" +
                                (today.getMonth() + 1) +
                                "-" +
                                today.getFullYear();
                              golive = todayDate == date;
                            } else {
                              let day = "0" + today.getDate();
                              let month = "0" + (today.getMonth() + 1);
                              let todayDate =
                                day + "-" + month + "-" + today.getFullYear();
                              golive = todayDate == date;
                            }
                          } else {
                            if (checkMonth > 9) {
                              let todayDate =
                                today.getDate() +
                                "-" +
                                (today.getMonth() + 1) +
                                "-" +
                                today.getFullYear();
                              golive = todayDate == date;
                            } else {
                              let month = "0" + (today.getMonth() + 1);
                              let todayDate =
                                today.getDate() +
                                "-" +
                                month +
                                "-" +
                                today.getFullYear();
                              golive = todayDate == date;
                            }
                          }
                          return (
                            //#region 2
                            <Box
                              key={index}
                              sx={{ marginBottom: "25px", ...AccordionStyle }}
                            >
                              <Box
                                pl={5}
                                pr={3}
                                pt={2}
                                pb={2}
                                onClick={() => {
                                  setiid(item?.id);
                                  // setShowAccordian(!showAccordian);
                                  // handleClickCollapse(item?.id);
                                }}
                                sx={{
                                  cursor: "pointer",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  position: "relative",
                                }}
                              >
                                <Stack spacing={0.5}>
                                  <Typography
                                    color="#000000."
                                    sx={{
                                      fontSize: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Lecture {index + 1}{" "}
                                    {item?.lecture_type == "YT"
                                      ? "( YouTube )"
                                      : ""}
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: "18px", fontWeight: "500" }}
                                  >
                                    Topic: {item?.title}
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: "18px", fontWeight: "500" }}
                                  >
                                    Subject:{" "}
                                    {item?.subject?.name || item?.subject}
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: "15px", fontWeight: "600" }}
                                  >
                                    Date:
                                    <span
                                      style={{
                                        fontWeight: "400",
                                        color: "black",
                                      }}
                                    >
                                      {" "}
                                      {date}
                                    </span>
                                  </Typography>
                                </Stack>
                                {
                                  //#region 1

                                  item?.lecture_type != "YT" && (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      sx={{ width: "25%", height: "100%" }}
                                    >
                                      {item?.LiveOrRecorded == 1 && (
                                        <Button
                                          variant="contained"
                                          disabled={!golive}
                                          sx={{
                                            width: "60%",
                                            alignSelf: "center",
                                            background: " #8080ff",
                                            color: "white",
                                            borderRadius: "20px",
                                            "&:hover": {
                                              background: " #0000ff",
                                            },
                                          }}
                                        >
                                          <Link
                                            to={
                                              item?.LiveOrRecorded == 1
                                                ? `/dashboard/livestream/${item?.title}/${item?.id}/${Id}/${item?.description}`
                                                : "/dashboard/app"
                                            }
                                            style={{
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                          >
                                            {" "}
                                            {item?.LiveOrRecorded === 1
                                              ? "Go Live"
                                              : "View"}
                                          </Link>
                                        </Button>
                                      )}
                                      {item?.LiveOrRecorded == 2 && (
                                        <>
                                          <a
                                            href={item?.material?.fileLoc}
                                            // href={item?.link}
                                            style={{
                                              width: "60%",
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              sx={{
                                                width: "100%",
                                                alignSelf: "center",
                                                background: " #8080ff",
                                                color: "white",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                  background: " #0000ff",
                                                },
                                              }}
                                            >
                                              View
                                            </Button>
                                          </a>
                                        </>
                                      )}
                                    </Stack>
                                  )
                                  //#endregion 1
                                }

                                {
                                  //#region go live button 1
                                  item?.lecture_type == "YT" && (
                                    <div className="button-align">
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ width: "100%", height: "100%" }}
                                      >
                                        <a
                                          href={item?.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            width: "60%",
                                            color: "white",
                                            textDecoration: "none",
                                          }}
                                        >
                                          {item?.LiveOrRecorded == 2 ? (
                                            <Button
                                              variant="contained"
                                              // disabled={!golive}
                                              sx={{
                                                width: "100%",
                                                height: "100%",
                                                alignSelf: "center",
                                                background: " #8080ff",
                                                color: "white",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                  background: " #0000ff",
                                                },
                                              }}
                                              onClick={() => setVideoPlay(true)}
                                            >
                                              View
                                            </Button>
                                          ) : (
                                            <Button
                                              variant="contained"
                                              disabled={!golive}
                                              sx={{
                                                width: "100%",
                                                alignSelf: "center",
                                                background: " #8080ff",
                                                color: "white",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                  background: " #0000ff",
                                                },
                                              }}
                                              onClick={() => setVideoPlay(true)}
                                            >
                                              Go Live
                                            </Button>
                                          )}
                                        </a>
                                      </Stack>
                                    </div>
                                  )
                                  //#endregion
                                }
                              </Box>

                              <Collapse
                                in={item?.id == iid}
                                timeout="auto"
                                unmountOnExit
                              >
                                {item?.id == iid && (
                                  <Stack
                                    pl={5}
                                    pr={5}
                                    spacing={1}
                                    sx={{
                                      bgcolor: "rgba(145, 158, 171, 0.24)",
                                      borderRadius: "0 0 18px 18px",
                                    }}
                                  >
                                    {/* <Typography mt={2}>
                                      {" "}
                                      <span style={{ fontWeight: "700" }}>
                                        Assigned Teacher :
                                      </span>{" "}
                                      {teacherName
                                        ? teacherName?.FullName
                                        : "No Teacher Assigned"}
                                    </Typography> */}
                                    <Typography>
                                      {" "}
                                      <span style={{ fontWeight: "700" }}>
                                        Description :
                                      </span>{" "}
                                      {item?.description.replace(
                                        /(<([^>]+)>)/gi,
                                        ""
                                      )}
                                    </Typography>
                                    {/* <Stack direction="row" spacing={3} pb={3}>
                                      {!editTeacher ? (
                                        <>
                                          <Button
                                            variant="contained"
                                            sx={{
                                              width: "40%",
                                              alignSelf: "center",
                                              background: " #8080ff",
                                              color: "white",
                                              "&:hover": {
                                                background: " #0000ff",
                                              },
                                            }}
                                            onClick={setEditTeacher(
                                              !editTeacher
                                            )}
                                          >
                                            <Link
                                              to="#"
                                              style={{
                                                color: "white",
                                                textDecoration: "none",
                                              }}
                                            >
                                              {" "}
                                              Edit Teacher
                                            </Link>
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <TextField
                                            label="Assign Teacher"
                                            InputLabelProps={{
                                              style: { color: "black" },
                                            }}
                                            sx={{ width: "100%" }}
                                            onChange={(e) =>
                                              handleChange(e, item?.id)
                                            }
                                            select
                                          >
                                            {teachers?.map((teac, i) => {
                                              return (
                                                <MenuItem
                                                  key={i}
                                                  value={teac.email}
                                                >
                                                  {teac.FullName}
                                                </MenuItem>
                                              );
                                            })}
                                          </TextField>
                                          <Button
                                            variant="contained"
                                            sx={{
                                              width: "40%",
                                              alignSelf: "center",
                                              background: " #8080ff",
                                              color: "white",
                                              "&:hover": {
                                                background: " #0000ff",
                                              },
                                            }}
                                            onClick={addteacher}
                                          >
                                            <Link
                                              to="#"
                                              style={{
                                                color: "white",
                                                textDecoration: "none",
                                              }}
                                            >
                                              {" "}
                                              Update Teacher
                                            </Link>
                                          </Button>
                                        </>
                                      )}
                                    </Stack> */}
                                  </Stack>
                                )}
                              </Collapse>
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "47px",
                                  right: "1%",
                                }}
                              >
                                {" "}
                                <UserMoreMenu
                                  title="BatchDetails"
                                  lectId={item?.id}
                                  id={item?.id}
                                  req="delete-lecture"
                                  batchId={Id}
                                  setLoading={setLoading}
                                />
                              </Box>
                            </Box>
                            //  #endregion 2
                          );
                        })
                      ) : (
                        <div
                          sx={{
                            display: "flex",
                            width: "full",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress />
                        </div>
                        // <Typography variant="h4">No Lecture</Typography>
                      )}
                    </Stack>
                  )}
                  {
                    //#region 3
                    role == "Teacher" && (
                      <Stack sx={{ width: "100%", borderRadius: "10px" }}>
                        {teacherLectureDetails?.length > 0 ? (
                          teacherLectureDetails?.map((item, index) => {
                            //? temperory-> const [teacherName] = item?.teacher;

                            {
                              /* //console.log(item?.starting_date); */
                            }
                            let date = item?.starting_date.split(" ")[0];
                            let today = new Date();
                            let todayDate =
                              today.getDate() +
                              "-" +
                              (today.getMonth() + 1) +
                              "-" +
                              today.getFullYear();
                            let check = todayDate.split("-")[0];
                            let checkMonth = todayDate.split("-")[1];

                            let golive;

                            if (check < 10) {
                              if (checkMonth > 9) {
                                let day = "0" + today.getDate();
                                let todayDate =
                                  day +
                                  "-" +
                                  (today.getMonth() + 1) +
                                  "-" +
                                  today.getFullYear();
                                golive = todayDate == date;
                              } else {
                                let day = "0" + today.getDate();
                                let month = "0" + (today.getMonth() + 1);
                                let todayDate =
                                  day + "-" + month + "-" + today.getFullYear();
                                golive = todayDate == date;
                              }
                            } else {
                              if (checkMonth > 9) {
                                let todayDate =
                                  today.getDate() +
                                  "-" +
                                  (today.getMonth() + 1) +
                                  "-" +
                                  today.getFullYear();
                                golive = todayDate == date;
                              } else {
                                let month = "0" + (today.getMonth() + 1);
                                let todayDate =
                                  today.getDate() +
                                  "-" +
                                  month +
                                  "-" +
                                  today.getFullYear();
                                golive = todayDate == date;
                              }
                            }
                            {
                              /* //console.log(`/dashboard/batch/batchDetails/recordedLecture/${item?.material?.fileLoc}`) */
                            }
                            //console.log(golive);
                            return (
                              <Box
                                key={index}
                                sx={{ marginBottom: "25px", ...AccordionStyle }}
                              >
                                <Box
                                  pl={5}
                                  pr={3}
                                  pt={2}
                                  pb={2}
                                  onClick={() => {
                                    setiid(item?.id);
                                    // setShowAccordian(!showAccordian);
                                    // handleClickCollapse();
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    position: "relative",
                                  }}
                                >
                                  <Stack spacing={0.5}>
                                    <Typography
                                      color="#000000."
                                      sx={{
                                        fontSize: "22px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Lecture {index + 1}{" "}
                                      {item?.lecture_type == "YT"
                                        ? "( YouTube )"
                                        : ""}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Topic: {item?.title}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Date:
                                      <span
                                        style={{
                                          fontWeight: "400",
                                          color: "black",
                                        }}
                                      >
                                        {" "}
                                        {date}
                                      </span>
                                    </Typography>
                                  </Stack>
                                  {item?.lecture_type != "YT" && (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      sx={{ width: "25%" }}
                                    >
                                      {item?.LiveOrRecorded == 1 && (
                                        <Button
                                          variant="contained"
                                          disabled={!golive}
                                          sx={{
                                            width: "60%",
                                            alignSelf: "center",
                                            background: " #8080ff",
                                            color: "white",
                                            borderRadius: "20px",
                                            "&:hover": {
                                              background: " #0000ff",
                                            },
                                          }}
                                        >
                                          <Link
                                            to={
                                              item?.LiveOrRecorded == 1
                                                ? `/dashboard/livestream/${item?.title}/${item?.id}/${Id}/${item?.description}`
                                                : "/dashboard/app"
                                            }
                                            style={{
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                          >
                                            {" "}
                                            {item?.LiveOrRecorded === 1
                                              ? "Go Live"
                                              : "View"}
                                          </Link>
                                        </Button>
                                      )}
                                      {item?.LiveOrRecorded == 2 && (
                                        <a
                                          href={item?.material?.fileLoc}
                                          style={{
                                            width: "60%",
                                            color: "white",
                                            textDecoration: "none",
                                          }}
                                        >
                                          <Button
                                            variant="contained"
                                            sx={{
                                              width: "100%",
                                              alignSelf: "center",
                                              background: " #8080ff",
                                              color: "white",
                                              borderRadius: "20px",
                                              "&:hover": {
                                                background: " #0000ff",
                                              },
                                            }}
                                          >
                                            View
                                          </Button>
                                        </a>
                                      )}
                                    </Stack>
                                  )}
                                  {item?.lecture_type == "YT" && (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      sx={{ width: "25%" }}
                                    >
                                      <a
                                        href={item?.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          width: "60%",
                                          color: "white",
                                          textDecoration: "none",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          disabled={!golive}
                                          sx={{
                                            width: "100%",
                                            alignSelf: "center",
                                            background: " #8080ff",
                                            color: "white",
                                            borderRadius: "20px",
                                            "&:hover": {
                                              background: " #0000ff",
                                            },
                                          }}
                                          onClick={() => setVideoPlay(true)}
                                        >
                                          Go Live 1
                                        </Button>
                                      </a>
                                    </Stack>
                                  )}
                                </Box>

                                <Collapse
                                  in={item?.id == iid}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {item?.id == iid && (
                                    <Stack
                                      pl={5}
                                      pr={5}
                                      spacing={1}
                                      sx={{
                                        bgcolor: "rgba(145, 158, 171, 0.24)",
                                        borderRadius: "0 0 18px 18px",
                                      }}
                                    >
                                      {/* <Typography mt={2}>
                                        {" "}
                                        <span style={{ fontWeight: "700" }}>
                                          Assigned Teacher :
                                        </span>{" "}
                                        {teacherName
                                          ? teacherName?.FullName
                                          : "No Teacher Assigned"}
                                      </Typography> */}
                                      <Typography>
                                        {" "}
                                        <span style={{ fontWeight: "700" }}>
                                          Description :
                                        </span>{" "}
                                        {item?.description}
                                      </Typography>
                                      {/* <Stack direction="row" spacing={3} pb={3}>
                                        {teacherName ? (
                                          ""
                                        ) : (
                                          <>
                                            <TextField
                                              label="Assign Teacher"
                                              InputLabelProps={{
                                                style: { color: "black" },
                                              }}
                                              sx={{ width: "100%" }}
                                              onChange={(e) =>
                                                handleChange(e, item?.id)
                                              }
                                              select
                                            >
                                              {teachers?.map((teac, i) => {
                                                return (
                                                  <MenuItem
                                                    key={i}
                                                    value={teac.email}
                                                  >
                                                    {teac.FullName}
                                                  </MenuItem>
                                                );
                                              })}
                                            </TextField>
                                            <Button
                                              variant="contained"
                                              sx={{
                                                width: "40%",
                                                alignSelf: "center",
                                                background: " #8080ff",
                                                color: "white",
                                                "&:hover": {
                                                  background: " #0000ff",
                                                },
                                              }}
                                              onClick={addteacher}
                                            >
                                              <Link
                                                to="#"
                                                style={{
                                                  color: "white",
                                                  textDecoration: "none",
                                                }}
                                              >
                                                {" "}
                                                Add Teacher
                                              </Link>
                                            </Button>
                                          </>
                                        )}
                                      </Stack> */}
                                    </Stack>
                                  )}
                                </Collapse>
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: "47px",
                                    right: "1%",
                                  }}
                                >
                                  {" "}
                                  <UserMoreMenu
                                    title="BatchDetails"
                                    lectId={item?.id}
                                    id={item?.id}
                                    req="delete-lecture"
                                    batchId={Id}
                                    setLoading={setLoading}
                                  />
                                </Box>
                              </Box>
                            );
                          })
                        ) : (
                          <Typography variant="h4">No Lecture</Typography>
                        )}
                      </Stack>
                    )
                    // #endregion3
                  }
                  <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />

                  {/* <Typography variant='h4' sx={{ color: 'green', textTransform: 'capitalize', textAlign: 'center', width: '70%' }}>{fetchMessage}</Typography> */}
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {/* <Box spacing={3} ml={5}>
                <BatchDetailsCard batchId={Id} setBatchName={setBatchName} />
              </Box> */}
              <BatchDetailsCard batchId={Id} setBatchName={setBatchName} />
            </Grid>
          </Grid>
        </Page>
      )}
    </>
  );
};

export default BatchDetails;
