import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Stack,
  Typography,
  TableContainer,
  TablePagination,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
import { useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "S.No", alignRight: false },
  { id: "que_title", label: "Title", alignRight: false },
  { id: "doubt_desc", label: "Description ", alignRight: false },
  { id: "created_at", label: "Date", alignRight: false },
];

// ----------------------------------------------------------------------

export default function DoubtsTable() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { quizId, quizName } = useParams();
  const [users, setUsers] = useState(null); // Initialize users as null
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     try {
  //       const token = localStorage.getItem("token");
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_LIVE_URL}/adminPanel/getDoubts/${quizId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       setUsers(response.data.data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching doubts:", error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchUsers();
  // }, [quizId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // Ensure users is not null before accessing its properties
  const emptyRows =
    loading || !users ? 0 : Math.max(0, rowsPerPage - users.length);

  return (
    <Page title="Doubts">
      <Card>
        <Stack
          pr={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
        </Stack>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={users ? users.length : 0} // Ensure users is not null
              />
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <SyncLoader
                          color="#8080ff"
                          loading={loading}
                          size={20}
                        />
                        <Typography variant="body1">Loading...</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ) : !users || users.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography variant="body1">No doubts found.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  users?.map((row, index) => {
                    const { _id, que_title, doubt_desc, created_at } = row;
                    const isItemSelected = selected.indexOf(que_title) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell>
                          <Typography variant="subtitle2">
                            {index + 1}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" pl={2}>
                          <Typography variant="subtitle2" noWrap>
                            {que_title}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" pl={2}>
                          <Typography variant="subtitle2" noWrap>
                            {doubt_desc}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" pl={2}>
                          <Typography variant="subtitle2" noWrap>
                            {created_at}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          onClick={() => setSelectedId(_id)}
                        >
                          <UserMoreMenu
                            id={_id}
                            req="DeleteNotesDetails"
                            setLoading={setLoading}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users ? users.length : 0} // Ensure users is not null
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Page>
  );
}
