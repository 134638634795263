import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFileUrl } from '../../../utils/firebaseUtils';
// ----------------------------------------------------------------------

export default function AddLectureResources() {
  const navigate = useNavigate();
  const [fetchMessage, setFetchMessage] = useState()
  const [subject, setSubject] = useState()
  const [loading, setLoading] = useState(false)
  const [positive, setPositive] = useState(false);
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false)
  const [fileSelected, setFileSelected] = useState(false)
  const [msg, setMsg] = useState(false)
  const [languages, setLanguages] = useState([]);

  const [val, setVal] = useState(false)
  const { lectId, batchId } = useParams()
  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required('Title required'),
    // lastName: Yup.string().required('Last name required'),
    resource_type: Yup.string().required('Type is required'),
    // file_url: Yup.string().required('Url  is required'),
    status: Yup.string().required('Status Required'),
    language: Yup.string().required('Language is required'),
  });

  const defaultValues = {
    title: '',
    resource_type: '',
    // file_url: '',
    status: "true",
    language: '',
  };

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {

      const languageData = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      });

      if (languageData?.status !== 200)
        throw new Error("Failed to fetch languages");

      setLanguages(languageData?.data?.data);

    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);
  const [file, setFile] = useState()
  const [resource_url, setResourceUrl] = useState()
  const [loadingFile, setLoadingFile] = useState(false);
  //role
  const role = localStorage.getItem('role')

  const changeHandler = async (event) => {
    if (!type) {
      setLoadingFile(true);
      //  setFile(event.target?.files[0]) 
       setResourceUrl(await getFileUrl(event?.target?.files[0]));
       setLoadingFile(false);
      }
    else {
      setFile(event.target?.value);
      setResourceUrl(event.target?.value);
    }
    // setFile(event.target?.files[0])
    setFileSelected(true)

  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    
    
    // role === 'Admin' ? formData.append('is_Verified', true) : formData.append('is_Verified', false)
    
    
    
   
    
    const token = localStorage.getItem("token");
    const authToken = token;
    if(loadingFile){
      toast.warning("Wait for file to upload");
      return;
    }
    const payload = {
      ...data,
      resource_url,
      course_id: batchId,
      lecture_id: lectId,
    }
    console.log(payload)
    
    setLoading(true)
    const config = {
      headers: {
        // 'content-type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-lecture-resources`, payload, config).then((response) => {
        //console.log(response?.data);
        if(response.data.response_code===200){
        toast.success(response.data.message);
        setLoading(false)
        reset()
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        }
        else{
          setLoading(false);
          toast.error(response.data.message);
        }
    })
        .catch(e => {
            //console.log(e);
        })

  };
  const [users, usersSet] = useState([]);
  const [type, setType] = useState(false);
  const [typeName, setTypeName] = useState('');
  // useEffect(() => {
  //     const token = localStorage.getItem("token");
  //     async function fetchUsers() {
  //         const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Resources`, {
  //             method: 'GET',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 Authorization: `Bearer ${token}`
  //             },

  //         });
  //         const responseJson = await fullResponse.json();
  //         usersSet(responseJson?.data);
  //         const res = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-subjects`, {
  //             method: 'POST',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 Authorization: `Bearer ${token}`
  //             },

  //         });
  //         const resSubject = await res.json();
  //         console.log(resSubject.data)
  //         setSubject(resSubject.data);
  //         setLoading(false)
  //     }

  //     fetchUsers();
  // }, []);

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Resource</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />

            <RHFTextField name="resource_type" label="Resource type" select>
              {/* <MenuItem
                onClick={() => {
                  setType(true);
                  setTypeName("yt_videos");
                }}
                value="yt_videos"
                defaultValue="yt_videos"
              >
                Youtube Link
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  setType(true);
                  setTypeName("link");
                }}
                value="link"
              >
                Link
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  setType(false);
                  setTypeName("video");
                }}
                value="video"
              >
                Video
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  setType(false);
                  setTypeName("pdf");
                }}
                value="pdf"
              >
                PDF
              </MenuItem>
            </RHFTextField>

            <Stack direction="row" spacing={3}>
              <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
                <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  sx={{ width: "100%" }}
                  error={!fileSelected && val}
                  label="Resource"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  inputProps={{
                    accept: typeName == "pdf" ? "application/pdf" : "video/*",
                  }}
                  helperText={
                    type
                      ? typeName == "link"
                        ? "Links Only"
                        : "Youtube Videos link"
                      : typeName == "pdf"
                        ? "Pdf Files Only"
                        : "Video Files Only"
                  }
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPdf(true)}
                  type={pdf || focus ? (type ? "url" : "file") : "text"}
                />
                {!fileSelected && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "red",
                    }}
                  >
                    {msg}
                  </Typography>
                )}
              </Stack>
              <RHFTextField
                name="language"
                label="language"
                sx={{ width: "100%" }}
                select
              >
                {languages?.map((language, index) => (
                  <MenuItem key={index} value={language?.medium_code}>
                    {language?.medium_name}
                  </MenuItem>
                ))}
              </RHFTextField>
              <RHFTextField name="status" label="Status" select>
                <MenuItem value={"true"}>Publish</MenuItem>
                <MenuItem value={"false"}>Draft</MenuItem>
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
              onClick={() => {
                setMsg("Required");
                setVal(true);
              }}
            >
              Add
            </LoadingButton>
          </Stack>
          {/* <Typography variant='h4'>{fetchMessage}</Typography> */}
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
