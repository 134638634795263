import { Box, Grid, Typography } from '@mui/material'
import { Stack, width } from '@mui/system'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from 'react'

const BoxDesign = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}
const DefaultScreen = ({ use }) => {
    const role = localStorage.getItem('role')
    return (
        <>
            <Grid item xs={10} sm={9} md={role === 'Teacher' ? 10 : 9} sx={{
                position: 'relative', height: '72vh', display: use == 'MainPage' ? 'none' : 'block'
            }}>
                <Stack sx={{ justifyContent: 'space-around', height: '100%' }}>
                    <Box sx={{ flex: 1.5, ...BoxDesign }}>
                        <img src='https://cdn-icons-png.flaticon.com/512/2312/2312512.png' width={300} height={300} />

                    </Box>
                    <Box sx={{ flex: 1, flexDirection: 'column', margin: '20px 0', ...BoxDesign }}>
                        <Typography sx={{ color: '#4A4A4A', fontWeight: '600', textAlign: 'center', fontSize: '30px' }}>Invictaa Conversation</Typography>
                        <Typography sx={{ color: '#727070', textAlign: 'center', fontWeight: '400', fontSize: '18px', width: '70%' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Typography>
                    </Box>
                    <Box sx={{ flex: 1, ...BoxDesign }}>
                        <Typography sx={{ color: '#4A4A4A', textAlign: 'center', fontWeight: '400', fontSize: '26px', }}><LockOutlinedIcon mr={1} /> End -to-end encrypted</Typography>

                    </Box>
                </Stack>

            </Grid>
        </>
    )
}

export default DefaultScreen