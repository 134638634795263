import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { reset } from 'numeral';
// ----------------------------------------------------------------------

export default function AddResources() {
  const navigate = useNavigate();
  const [fetchMessage, setFetchMessage] = useState()
  const [subject, setSubject] = useState()
  const [loading, setLoading] = useState(true)
  const [positive, setPositive] = useState(false);
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false)
  const { lectId, batchId } = useParams()
  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required('Title required'),
    // lastName: Yup.string().required('Last name required'),
    resurce_type: Yup.string().required('Type is required'),
    // file_url: Yup.string().required('Url  is required'),
    is_active: Yup.boolean().required('Status Required'),
    language: Yup.string().required('Language is required'),
  });

  const defaultValues = {
    title: '',
    resurce_type: '',
    // file_url: '',
    is_active: '',
    language: '',
  };
  const [file, setFile] = useState()


  const changeHandler = async (event) => {
    if (!type) { setFile(event.target?.files[0]) }
    else {
      setFile(event.target?.value)
    }


  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true)
    const formData = new FormData();
    if (!type) { formData.append('file', file) }
    if (type) {
      formData.append('link', file)

    }
    formData.append('batch_id', batchId);
    formData.append('lecture_id', lectId);
    formData.append('title', data?.title);
    formData.append('resurce_type', data?.resurce_type);

    formData.append('language', data?.language);
    formData.append('is_active', data?.is_active);

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`,
      },
    };
    // axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/postLectureResource`, formData, config).then((response) => {
    //     //console.log(response?.data);

    //     setFetchMessage('Added the Resources Details')
    //     setLoading(false)
    //     setPositive(false)
    //     setPdf(false)
    //     reset()


    // })
    //     .catch(e => {
    //         //console.log(e);
    //     })

  };
  const [users, usersSet] = useState([]);
  const [type, setType] = useState(false);
  // useEffect(() => {
  //     const token = localStorage.getItem("token");
  //     async function fetchUsers() {
  //         const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Resources`, {
  //             method: 'GET',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 Authorization: `Bearer ${token}`
  //             },

  //         });
  //         const responseJson = await fullResponse.json();
  //         usersSet(responseJson?.data);
  //         const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`, {
  //             method: 'GET',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 Authorization: `Bearer ${token}`
  //             },

  //         });
  //         const resSubject = await res.json();
  //         setSubject(resSubject?.data);
  //         setLoading(false)
  //     }

  //     fetchUsers();
  // }, []);

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Resource</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />

            <RHFTextField name="resurce_type" label="Resource type" select>
              <MenuItem onClick={() => setType(true)} value="link">
                Link
              </MenuItem>
              <MenuItem onClick={() => setType(false)} value="file">
                File
              </MenuItem>
            </RHFTextField>

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="file"
                onChange={changeHandler}
                label="PDF"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                onClick={() => setPdf(true)}
                type={pdf || focus ? (type ? "url" : "file") : "text"}
              />
              <RHFTextField name="language" label="Language" select>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="hi">Hindi</MenuItem>
                <MenuItem value="enhi">Bilingual</MenuItem>
              </RHFTextField>
              <RHFTextField name="is_active" label="Status" select>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
            >
              Add
            </LoadingButton>
          </Stack>
          {/* <Typography variant='h4'>{fetchMessage}</Typography> */}
        </FormProvider>
        <Typography
          color={positive ? "green" : "red"}
          sx={{
            textAlign: "center",
            width: "70%",
            textTransform: "capitalize",
          }}
        >
          {fetchMessage}
        </Typography>
      </Stack>
    </>
  );
}
