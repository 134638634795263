import React from 'react';
import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";

// For Dialog Box
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { CSVLink } from 'react-csv';

const TABLE_HEAD = [
  { id: "", label: "Rank", alignRight: false },
  { id: "studentName", label: "Student Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "myScore", label: "Score", alignRight: false },
  { id: "totalMarks", label: "Total Marks", alignRight: false },
  { id: "accuracy", label: "Accuracy", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user?.studentName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

const LeaderBoard = () => {
  const { quizId, quizName } = useParams();
  // Code for handling Dialog box
  const [download, setDownload] = useState(true);
  const [errorOTP, setErrorOtp] = useState();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const sendOtp = () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      action: "Exporting All Users Data"
    };

    // axios
    //   .post(
    //     `${process.env.REACT_APP_LIVE_URL}/adminTeacher/send-otp`,
    //     data,
    //     config
    //   )
    //   .then((response) => {
    //   })
    //   .catch((e) => {
    //     //console.log(e);
    //   });

  }
  const verifyOtp = () => {
    //console.log(document.getElementById('name').value);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      otp: document.getElementById('name').value
    };

    // axios
    //   .post(
    //     `${process.env.REACT_APP_LIVE_URL}/adminTeacher/verify-otp`,
    //     data,
    //     config
    //   )
    //   .then((response) => {
    //     // //console.log(response);
    //     if (response?.data?.status == false) {
    //       setErrorOtp("Invalid Otp");
    //     }
    //     else {
    //       setDownload(false);
    //       setErrorOtp("Successfully Verified");
    //     }
    //   })
    //   .catch((e) => {
    //     //console.log(e);
    //   });
  }



  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();
  const [starting, setStarting] = useState(dayjs());
  const handleChange = (newValue) => {
    setStarting(newValue);
  };

  //Getting notes
  const [isActiveValue, setIsActiveValue] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/test-leaderboard/${quizId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      //console.log(responseJson?.data?.leaderBoard);
      usersSet(responseJson?.data?.leaderBoard);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  console.log(users);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n?.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event?.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );
  let helperArray = [];
  filteredUsers?.map((item) =>
    helperArray.push({
      ID: item?.studentId,
      StudentName: item?.studentName,
      Email: item?.email,
      MyScore: item?.MyScore,
      TotalMarks: item?.totalMarks,
      Accuracy: item?.accuracy
    })
  );

  const isUserNotFound = filteredUsers?.length === 0;
  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  const [index, setIndex] = useState(1);

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Leader Board">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                {quizName} Leader Board
              </Typography>

            </Stack>

            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected?.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                {/* <ExportCSV csvData={users} fileName="Notes Data" /> */}

                {/* <Button variant="outlined" onClick={() => { handleClickOpen(); sendOtp(); }}>
                  Export
                </Button> */}
                <Dialog open={open} onClose={handleClose}>
                  {download ? (
                    <>
                      <DialogTitle>OTP Varification</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          OTP is send successfully to your regisered e-mail.
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="OTP Number"
                          type="number"
                          fullWidth
                          variant="standard"
                        />
                        <DialogContentText sx={{ color: "red", fontSize: '12px', padding: "3px" }}>
                          {errorOTP}
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={verifyOtp}>Verify</Button>
                      </DialogActions>
                    </>
                  ) : (
                    <>
                      <DialogTitle>Download File</DialogTitle>
                      <DialogContent>
                        <CSVLink data={users} filename="leaderBoard.csv">Download</CSVLink>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => {
                          handleClose();
                          setDownload(true);
                        }}>Cancel</Button>

                      </DialogActions>
                    </>
                  )}
                </Dialog>

              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {users?.map((item, index) => {
                        // const {
                        //   studentName,
                        //   email,
                        //   myScore,
                        //   totalMarks,
                        //   accuracy
                        // } = item;


                        return (
                          <TableRow
                            hover

                            tabIndex={-1}
                            role="checkbox"

                          >
                            {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, title)} />
                                                    </TableCell> */}
                            <TableCell>
                              <Typography variant="subtitle2">
                                {index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" pl={2}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                {item?.studentName}
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" pl={2}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                {item?.email}
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" pl={2}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                {item?.myScore?.toFixed(2)}
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" pl={2}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                {item?.totalMarks}
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" pl={2}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                {item?.accuracy}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  )
}

export default LeaderBoard