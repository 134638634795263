import React, { useEffect, useState } from "react";
import {
  Card,
  Stack,
  Typography,
  TextField,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from "@mui/material";
import Scrollbar from "../../../../components/Scrollbar";
import UserListHead from "../UserListHead";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

export default function SubscriptionTableCard({ subscriptionInfo }) {
  const navigate = useNavigate();
  const [filteredSubscriptionInfo, setFilteredSubscriptionInfo] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    setFilteredSubscriptionInfo(subscriptionInfo);
  }, [subscriptionInfo]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredSubscriptionInfo?.length)
      : 0;

  const TABLE_HEAD = [
    { id: "", label: "S.No", alignRight: false },
    { id: "title", label: "Name", alignRight: false },

    { id: "course", label: "Course", alignRight: false },
    { id: "students", label: "No. Of Students", alignRight: false },
    { id: "created_at", label: "Date", alignRight: false },
    { id: "is_active", label: "Status", alignRight: false },
  ];

  const handleSearchInputChange = (event) => {
    const searchValue = event.target?.value;
    setSearchInput(searchValue);
    if (searchValue === "") {
      setFilteredSubscriptionInfo(subscriptionInfo);
    } else {
      const filteredData = subscriptionInfo.filter(
        (item) =>
          item?.title &&
          item?.title.toLowerCase().includes(searchValue.toLowerCase())
      );
      setPage(0);
      setFilteredSubscriptionInfo(filteredData);
    }
  };

  const handleRowClick = () => {
    toast.info("We are working on this feature. It will be available soon.");
  };


  return (
    <Card pr={3} pl={3} className="px-4">
      <Stack
        pr={3}
        direction="row-reverse"
        justifyContent="space-between"
        alignItems="center"
      >
        <TextField
          placeholder="Search Title"
          variant="outlined"
          margin="normal"
          value={searchInput}
          onChange={handleSearchInputChange}
          sx={{
            width: "300px",
            height: "15px",
            marginBottom: "70px",
            "& label": {
              color: "black",
            },
            "& fieldset": {
              borderColor: "black",
            },
          }}
        />
      </Stack>

      <Scrollbar>
        <TableContainer
          style={{ textWrap: "nowrap" }}
          className="text-nowrap"
          sx={{ minWidth: 800 }}
        >
          <Table>
            <UserListHead
              order="asc"
              orderBy="title"
              headLabel={TABLE_HEAD}
              rowCount={filteredSubscriptionInfo?.length}
              noWrap
            />

            <TableBody>
              {(filteredSubscriptionInfo || subscriptionInfo)
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const { title, created_at, students, is_active, course } =
                    row;

                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      style={{ cursor: "pointer" }}
                      // onClick={handleRowClick}
                      onClick={() =>
                        navigate(
                          `/dashboard/subscription/students/${
                            is_active ? "1" : "0"
                          }/${row?.id}`
                        )
                      }
                    >
                      <TableCell>
                        <Typography variant="" noWrap>
                          {index + 1 + rowsPerPage * page}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="" noWrap>
                          {title}
                        </Typography>
                      </TableCell>

                      <TableCell component="th" scope="row" pl={2}>
                        <Typography
                          variant=""
                          className="flex justify-center"
                          noWrap
                        >
                          {course}
                        </Typography>
                      </TableCell>

                      <TableCell component="th" scope="row" pl={2}>
                        <Typography
                          variant=""
                          className="flex justify-center"
                          noWrap
                        >
                          {students}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" pl={2}>
                        <Typography variant="" noWrap>
                          {created_at?.substring(0, 10)}
                        </Typography>
                      </TableCell>

                      <TableCell component="th" scope="row" pl={2}>
                        <Typography variant="" noWrap>
                          {is_active ? "Active" : "Inactive"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredSubscriptionInfo?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
    
  );
}
