import {
  Box,
  Card,
  CardMedia,
  Divider,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import TranslateIcon from "@mui/icons-material/Translate";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for react-toastify

const convertYouTubeURLToEmbedURL = (url) => {
  const urlPattern =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|live\/)?([a-zA-Z0-9_-]+)/;
  const match = url.match(urlPattern);
  if (match) {
    const videoID = match[1] || match[2];
    if (videoID) {
      const embedURL = `https://www.youtube.com/embed/${videoID}`;
      return embedURL;
    }
  }
  return null;
};

const BatchDetailsCard = ({ batchId, setBatchName }) => {
  const detailObject = JSON.parse(localStorage.getItem("detailObject"));
  const accessArr = detailObject?.access_level?.split(',');
  const role = localStorage.getItem('role');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState("false");
  const [anchorEl, setAnchorEl] = useState(null);
  const [sub, setSub] = useState(0);
  const [tea, setTea] = useState(0);
  const [demo, setDemo] = useState(0);
  const [batch, setBatch] = useState([]);
  const [link, setLink] = useState();

  const handleOpenActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleUpdateBatchStatus = async (batchId) => {
    handleCloseActions();
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-course-status/${batchId}`,
        { is_active: isActive === "true" ? "false" : "true" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setIsActive((prev) => (prev === "true" ? "false" : "true")); // Update the local state with the updated status
      toast.success("Batch status updated", { id: "unique-toast-id" }); // Use a unique ID to ensure it doesn't duplicate
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update batch status");
      console.error("Error updating batch status:", error);
    }
  };

  const iconStyle = {
    background: "#e6e6ff",
    padding: "5px",
    color: "black",
    borderRadius: "50%",
    cursor: "pointer",
  };

  const scrollSubject = {
    transition: "all 0.3s",
    transform: `translateX(${sub}px)`,
  };

  const scrollTeacher = {
    transition: "all 0.3s",
    transform: `translateX(${tea}px)`,
  };

  const scrollDemo = {
    transition: "all 0.3s",
    transform: `translateX(${demo}px)`,
  };

  const scrollRef = useRef(null);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const { scrollLeft, clientWidth } = scrollRef.current;
      const scrollTo =
        direction === "left"
          ? scrollLeft - clientWidth
          : scrollLeft + clientWidth;
      scrollRef.current.scrollTo({ left: scrollTo, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchBatchDetails() {
      try {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-course-by-id/${batchId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        console.log("Batch details response:", responseJson);
        setBatch(responseJson?.data);
        setBatchName(responseJson?.data?.course?.name);
        setIsActive(responseJson?.data?.course?.is_active);
        setLink(responseJson?.data?.course?.course_banner);
      } catch (error) {
        console.error("Error fetching batch details:", error);
      }
    }
    fetchBatchDetails();
  }, [batchId, setBatchName]);

  return (
    <Card sx={{ minHeight: "100%", margin: "0px 10px 100px 10px" }}>
      <Stack direction="column" sx={{ minHeight: "80vh" }}>
        <CardMedia
          component="img"
          height="140"
          width="87%"
          image={link}
          alt="Banner"
        />
        <Stack direction="row" justifyContent={"end"} spacing={7}>
          <Typography
            color="#8080ff"
            m={1}
            sx={{ fontWeight: "600", textAlign: "center" }}
          >
            {batch?.course?.name}{" "}
          </Typography>

          <Box>
            <MoreVertIcon
              sx={{
                color: "",
                background: "rgba(255, 252, 252, 0.72)",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={(event) => setAnchorEl(event.currentTarget)}
              aria-describedby={`popover-1`}
            />
            <Popover
              id={`popover-1`}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleCloseActions}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <>
                {
                  (role == 'Admin' || accessArr?.includes('write')) &&
                  <Typography
                    sx={{
                      padding: "8px 16px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#e6e6ff",
                      },
                    }}
                    onClick={() =>
                      navigate(`/dashboard/batches/updateBatchDetails/${batchId}`)
                    }
                  >
                    Edit
                  </Typography>
                }
                {/* 
                  <Typography
                    sx={{
                      padding: "8px 16px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#e6e6ff",
                      },
                    }}
                    onClick={() =>
                      navigate(`/dashboard/batches/addTeacherToBatch/${batchId}`)
                    }
                  >
                    Assign Teacher
                  </Typography> */}
                {/* {
                <Typography
                  sx={{
                    padding: "8px 16px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#e6e6ff",
                    },
                  }}
                  onClick={() => handleUpdateBatchStatus(batchId)}
                >
                  {isActive === "true" ? "Move to Draft" : "Publish"}
                </Typography>
                  } */}
                {
                  <Typography
                    sx={{
                      padding: "8px 16px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#e6e6ff",
                      },
                    }}
                    onClick={() => handleUpdateBatchStatus(batchId)}
                  >
                    {isActive === "true" ? (
                      accessArr?.includes('delete') || role === 'Admin' ? "Move to Draft" : null
                    ) : (
                      accessArr?.includes('write') || role === 'Admin' ? "Publish" : null
                    )}
                  </Typography>
                }


              </>
            </Popover>
            {/* <ToastContainer
              position="bottom-center"
              autoClose={1000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            /> */}
          </Box>
        </Stack>
        <Divider />
        <div
          className="batch_desc"
          dangerouslySetInnerHTML={{
            __html: batch?.course?.description,
          }}
        ></div>
        {/* <Typography color="#e6e6ff" m={1} ml={2} mr={2} sx={{ fontWeight: '400', fontSize: '15px' }}>{batch?.description}</Typography> */}
        {batch?.course?.remark != "" && (
          <Typography
            color="#727070"
            m={1}
            mt={0}
            ml={2}
            mr={2}
            sx={{ fontWeight: "400", fontSize: "15px" }}
          >
            <span style={{ color: "#4A4A4A", fontWeight: "600" }}>Remark-</span>
            {batch?.course?.remark}
          </Typography>
        )}
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          style={{ overflowX: "scroll" }}
          m={1}
          ml={2}
          mr={2}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {/* <Typography p={1} sx={{ borderRadius: '44px', background: '#FFC8C8', fontSize: '10px' }}>L</Typography> */}
            <TranslateIcon
              fontSize="medium"
              sx={{
                borderRadius: "50%",
                background: "#e6e6ff",
                color: "black",
                padding: "4px",
              }}
            />
            <Typography fontSize="small" fontWeight="700">
              {batch?.language == "en"
                ? "English"
                : batch?.language == "hi"
                  ? "हिंदी"
                  : "हिंदी/English"}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <div
              style={{
                borderRadius: "50%",
                background: "#e6e6ff",
                color: "black",
                fontSize: "10px",
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              B
            </div>
            <Typography fontSize="small" fontWeight="700">
              {batch?.course?.course}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <div
              style={{
                borderRadius: "50%",
                background: "#e6e6ff",
                color: "black",
                fontSize: "10px",
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              ET
            </div>
            <Typography fontSize="small" fontWeight="700">
              {batch?.exam_type}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <div
              style={{
                borderRadius: "50%",
                background: "#e6e6ff",
                color: "black",
                fontSize: "10px",
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              V
            </div>
            <Typography fontSize="small" fontWeight="700">
              {" "}
              {batch?.course?.validity} Validity
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Typography
          color="#8080ff"
          mt={1}
          sx={{ fontWeight: "500", textAlign: "center" }}
        >
          Teachers
        </Typography>
        <Stack direction="row" ml={2} mr={2} justifyContent="space-between">
          <ArrowBackIosOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyle }}
            onClick={() => setTea(tea + 50)}
          />
          <Stack
            direction="row"
            spacing={2}
            ml={1}
            mr={1}
            className="TeacherScroll"
            sx={{ flex: 3 }}
          >
            {batch?.teachers?.map((item, index) => (
              <Typography
                key={index}
                mb={1}
                sx={{ fontSize: "14px", ...scrollTeacher }}
              >
                {item?.name}
              </Typography>
            ))}
          </Stack>
          <ArrowForwardIosOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyle }}
            onClick={() => setTea(tea - 50)}
          />
        </Stack>
        <Divider />
        <Typography
          color="#8080ff"
          mt={1}
          sx={{ fontWeight: "500", textAlign: "center" }}
        >
          Subject
        </Typography>
        <Stack direction="row" ml={2} mr={2} justifyContent="space-between">
          <ArrowBackIosOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyle }}
            onClick={() => setSub(sub - 50)}
          />
          <Stack
            direction="row"
            spacing={2}
            ml={1}
            mr={1}
            style={{ alignItems: "center" }}
            className="SubjectScroll"
            sx={{ flex: 3 }}
          >
            {batch?.subjects?.map((item, index) => (
              <Typography
                key={index}
                mb={1}
                sx={{ fontSize: "14px", ...scrollSubject }}
              >
                {item?.name}
              </Typography>
            ))}
          </Stack>
          <ArrowForwardIosOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyle }}
            onClick={() => setSub(sub + 50)}
          />
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          m={1}
          ml={2}
          mr={2}
        >
          <Typography
            color="#4A4A4A"
            sx={{ fontWeight: "600", fontSize: "20px" }}
          >
            Amount
          </Typography>
          <Stack alignItems="flex-end">
            <Typography
              color="#8080ff"
              sx={{ fontWeight: "700", fontSize: "16px" }}
            >
              ₹{batch?.course?.charges}
            </Typography>
            <Typography
              color="#4A4A4A"
              sx={{
                fontWeight: "600",
                fontSize: "8px",
                margidemogonTop: "-5px",
              }}
            >
              GST+{batch?.course?.discount}% Discount
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Typography color="#8080ff" sx={{ textAlign: "center" }} mt={1}>
          Demo Videos
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems={"center"}
          m={1}
          spacing={1}
          className="TeacherScroll"
        >
          {batch?.course?.demoVideo?.length > 1 && (
            <ArrowBackIosOutlinedIcon
              fontSize="small"
              // sx={{ cursor: "pointer" }}
              sx={{ ...iconStyle }}
              onClick={() => scroll("left")}
            />
          )}
          <Stack
            direction="row"
            spacing={2}
            style={{ overflowX: "auto" }}
            ref={scrollRef}
          >
            {batch?.course?.demoVideo?.map((url, index) => (
              <div key={index} style={{ ...scrollDemo }}>
                <iframe
                  width="300"
                  height="250"
                  src={convertYouTubeURLToEmbedURL(url)}
                  title="YouTube video player"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            ))}
          </Stack>
          {batch?.course?.demoVideo?.length > 1 && (
            <ArrowForwardIosOutlinedIcon
              fontSize="small"
              // sx={{ cursor: "pointer" }}
              sx={{ ...iconStyle }}
              onClick={() => scroll("right")}
            />
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default BatchDetailsCard;
