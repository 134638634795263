import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import logoUpsc from '../data/Logo.png'


// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (

    // <div className='w-20 rounded-full overflow-hidden object-center flex'>
    //   <img src={logoUpsc} className='object-center'/>
    // </div>

    <Box
      sx={{
        width: "100%",
        height: 80,
        display: "flex",
        // bgcolor: "black",
        justifyContent: "center",
        borderRadius: "50%", 
        overflow: "hidden", 
        ...sx,
      }}
    >
      <img
        src={logoUpsc}
        alt="UPSC Hindi Logo"
        style={{marginBottom:"1rem", width: "100%", height: "auto" }}
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
