import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Menu, MenuItem, Button, IconButton } from '@mui/material';
import { useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import Iconify from '../../../components/Iconify';
import CategoryEditModal from './CategoryEditModal';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';



export default function CategoryTableNew({ optionsArray }) {


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElIndex, setAnchorElIndex] = React.useState(-1);
  const [openIndex, setOpenIndex] = useState(-1)
  const [modalOpen, setModalOpen] = useState(false);


  const open = Boolean(anchorEl);
  console.log(optionsArray);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setAnchorElIndex(index);
    setOpenIndex(index);
    console.log("event triggered")
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenIndex(-1);
  };

  const handleEdit = () => {
    setModalOpen(true);
  }

  const handleDelete = async (e, id, parent) => {
    console.log(id);
    //     try {
    //       const token = localStorage.getItem('token');
    //       const data = await axios.delete(
    //         `${process.env.REACT_APP_LIVE_URL}/adminPanel/DeleteCategorailDetails/${id}`,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //           data: {
    //             parent: parent,
    //           },
    //         }
    //       );
    // if(data.data.status){
    //   toast.success("Category Deleted Successfully!");
    //   setTimeout(() => {
    //     window.location.reload(); // Reload the page
    //   }, 2000); 
    // }
    // else{
    //   toast(data?.data?.msg)
    // }

    // console.log("this is data ", data.data);
    //     } catch (error) {
    //       toast.error("An Unexpected Error Occured!");
    //       handleClose();
    //       console.log("Error while deleting category", error)
    //     }
  }

  const handleModalClose = () => {
    setModalOpen(false);
    handleClose();
  }

  return (
    <TableContainer width={"60%"} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* <TableCell align='' width={"35%"}>S.no</TableCell> */}
            <TableCell align="" width={"35%"} style={{ paddingInline: "5rem" }}>Category</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align='right' style={{ paddingInline: "5rem" }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        {<TableBody>
          {optionsArray?.map((row, i) => (
            <TableRow
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {/* <TableCell component="th" scope="row">
                {i+1}
              </TableCell> */}
              <TableCell align="" style={{ paddingInline: "5rem" }}>{[...Array(row?.depth)]?.map(a => { return <>&ndash;&mdash;</> })}
                {row?.title}</TableCell>
              <TableCell align="center">{row?.is_active ? "Published" : "Drafted"}</TableCell>
              <TableCell align='right' style={{ paddingInline: "5rem" }}>
                <div>
                  <IconButton
                    id={`basic-button-${i}`} // Unique ID for each button
                    aria-controls={`basic-menu-${i}`} // Unique ID for each menu
                    aria-haspopup="true"
                    aria-expanded={openIndex === i ? 'true' : undefined}
                    onClick={(e) => handleClick(e, i)}
                  >

                    <Iconify icon="eva:more-vertical-fill" width={20} height={20} />

                  </IconButton>
                  <Menu
                    id={`basic-menu-${i}`} // Unique ID for each menu
                    anchorEl={anchorElIndex === i ? anchorEl : null} // Conditionally set anchorEl
                    open={openIndex === i} // Conditionally set open state
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': `basic-button-${i}`, // Unique ID for each button
                    }}
                  >

                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    <CategoryEditModal open={modalOpen} onClose={handleModalClose} categoryData={row} />
                    <MenuItem onClick={(e) => handleDelete(e, row?._id, row?.parentId)}>Delete</MenuItem>
                    <ToastContainer />
                  </Menu>
                </div>
              </TableCell>
              {/* <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
        }
      </Table>
    </TableContainer>
  );
}