import React, { useEffect, useState } from 'react';
import {
  Tabs, Tab, Box, Typography, TextField, Button, Modal, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
} from '@mui/material';
import axios from 'axios';
import { getFileUrl } from '../../../utils/firebaseUtils';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import RHFTextEditor from '../../../components/hook-form/RHFTextEditor';
import { FormProvider, useForm } from 'react-hook-form';
const token = localStorage.getItem("token");
const config = {
  headers: {
    // 'content-type': 'multipart/form-data',
    Authorization: `Bearer ${token}`,
  },
};
const Notification = () => {
  const access = JSON.parse(localStorage.getItem('detailObject')) || {};
  const role = localStorage.getItem('role') || '';
  const accessArr = access?.access_level?.split(',');
  const accessToContent = access?.accessToContent || [];

  const [tabValue, setTabValue] = useState(0); // Default to "All Notifications"
  const [notifications, setNotifications] = useState([]);
  const [file, setFile] = useState();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    image: '',
  });
  const [editData, setEditData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(0); // Default page number
  const [rowsPerPage, setRowsPerPage] = useState(5); // Default 5 rows per page

  // Dummy notifications


  // Fetch notifications from API
  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-notifications`, config); // Replace with your API URL
      setNotifications(response.data.data);
      toast.success(response.data.message)

    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {

    // Uncomment the line below to fetch notifications from your API
    fetchNotifications();
  }, []);

  // Handling tab change
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  // Handling input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handling page change for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handling rows per page change for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };
  // Add notification form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-notification`, { ...formData, message: formData.description, image_url: file }, config); // Replace with your API URL
      setNotifications([...notifications, response.data]);
      setFormData({
        title: '',
        description: '',
        image: '',
      });
      toast.success(response.data.message)
      setTabValue(0); // Switch to "All Notifications" after adding
      fetchNotifications();
    } catch (error) {
      toast.error(error.message)

      console.error('Error adding notification:', error);
    }
  };

  // Open modal to edit notification
  const handleEdit = (notification) => {
    setEditData(notification);
    setIsModalOpen(true);
  };

  // Handle modal close
  const handleModalClose = () => {
    setIsModalOpen(false);
    setEditData(null);
  };

  // Update notification
  const handleUpdate = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-notification/${editData.id}`, editData, config); // Replace with your API URL
    //  setNotifications(notifications.map((notif) => (notif.id === editData.id ? editData : notif)));
      handleModalClose();
      toast.success(response.data.message)
      fetchNotifications();
    } catch (error) {
      toast.error(error.message)

      console.error('Error updating notification:', error);
    }
  };

  // Delete notification
  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-notification/${deleteId}`, config); // Replace with your API URL
   //   setNotifications(notifications.filter((notif) => notif.id !== deleteId));
      setDeleteId(null);
      toast.success("Notification Deleted Success");
      fetchNotifications();
    } catch (error) {
      toast.error(error.message);

      console.error('Error deleting notification:', error);
    }
  };
  const changeHandler = async (event) => {

    setFile(await getFileUrl(event.target.files[0]));

  }

  const methods = useForm();

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: "space-between", px: 4, mt: 4 }}>
      <ToastContainer position="bottom-center" />
      <Tabs
        value={tabValue}
        onChange={handleChangeTab}

        sx={{
          width: '50%',
          '& .MuiTabs-flexContainer': {
            justifyContent: 'center',
          },
          '& .MuiTab-root': {
            width: '50%',
          },
        }}
      >
        {(role === 'Admin' || accessToContent?.includes('All Notifications')) && (
          <Tab label="All Notifications" />
        )}
        {(role === 'Admin' || accessToContent?.includes('All Notifications')) && (
            <Tab label="Add Notification" />
        )}


      </Tabs>

      <TabPanel value={tabValue} index={0}>
        {notifications?.length === 0 ? (
          <Typography sx={{ textAlign: 'center', mt: 3 }}>No notifications available.</Typography>
        ) : (
          <TableContainer component={Paper} sx={{ width: '100%', margin: '20px auto' }}>
            <Table aria-label="notification table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Created Date</TableCell>
                  {
                    (role=='Admin'||accessArr?.includes('write') ||accessArr?.includes('delete'))&&
                  <TableCell>Actions</TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((notification) => (
                  <TableRow key={notification.id}>
                    <TableCell>{notification?.id}</TableCell>
                    <TableCell>{notification?.title}</TableCell>
                    <TableCell>
                      <div dangerouslySetInnerHTML={{ __html: notification?.message?.slice(0, 50) + "..." }}></div>
                    </TableCell>

                    <TableCell>{notification?.created_at?.substring(0, 10) + " " + notification?.created_at?.substring(11, 19)}</TableCell>
                    <TableCell>
                      {
                        (role == 'Admin' || accessArr?.includes('write')) && <Button variant="outlined" onClick={() => handleEdit(notification)}>
                          Edit
                        </Button>
                      }
                      {
                        (role == 'Admin' || accessArr?.includes('delete')) && <Button variant="outlined" color="error" sx={{ ml: 1 }} onClick={() => setDeleteId(notification.id)}>
                          Delete
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* Pagination */}
            <TablePagination
              component="div"
              count={notifications?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </TableContainer>
        )}
      </TabPanel>

      {(role === 'Admin' || accessToContent?.includes('All Notifications')) && (
        <TabPanel value={tabValue} index={1}>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, width: '100%', margin: '0 auto' }}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <FormProvider {...methods}>
              <RHFTextEditor onChange={(value) => {
                setFormData({ ...formData, 'description': value })
              }} name="description" value={formData.description} label="Description" />
            </FormProvider>
            <TextField
              label="Image URL"
              name="image"
              type="file"

              onChange={changeHandler}
              fullWidth
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              Add Notification
            </Button>
          </Box>
        </TabPanel>
      )}


      {/* Edit Modal */}
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box sx={{ width: 400, padding: 4, margin: '10% auto', backgroundColor: 'white', borderRadius: 2 }}>
          <Typography variant="h6">Edit Notification</Typography>
          <TextField
            label="Title"
            name="title"
            value={editData?.title || ''}
            onChange={(e) => setEditData({ ...editData, title: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="message"
            value={editData?.message || ''}
            onChange={(e) => setEditData({ ...editData, message: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Image URL"
            disabled
            name="image"
            value={editData?.image_url || ''}
            // onChange={(e) => setEditData({ ...editData, image: e.target.value })}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleUpdate} sx={{ mt: 2 }}>
            Update
          </Button>
        </Box>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={Boolean(deleteId)}
        onClose={() => setDeleteId(null)}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this notification?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteId(null)}>Cancel</Button>
          <Button onClick={handleDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// TabPanel component to handle tab switching
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" style={{ width: "100%" }} hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default Notification;
