import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyDLADJsH20IVxOrribBi3j4VfNpqRe46Ck",
//   authDomain: "sonu-affbb.firebaseapp.com",
//   projectId: "sonu-affbb",
//   storageBucket: "sonu-affbb.appspot.com",
//   messagingSenderId: "559593314992",
//   appId: "1:559593314992:web:8c58628b5473dda8c9b975",
//   measurementId: "G-2X2T3RRTLJ",
// };
const firebaseConfig = {
  apiKey: "AIzaSyDS0Y5jo5M1PsRONEEw2TWTC6TCtgAG_bs",
  authDomain: "invictaa-f3ba8.firebaseapp.com",
  databaseURL:
    "https://invictaa-f3ba8-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "invictaa-f3ba8",
  storageBucket: "invictaa-f3ba8.appspot.com",
  messagingSenderId: "8923859368",
  appId: "1:8923859368:web:5f5a285e4bdee59df4b59d",
  measurementId: "G-ENDVHL7R97",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export default storage;
