import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
import { getFileUrl } from "../../../utils/firebaseUtils";

// ----------------------------------------------------------------------

export default function AddCenterLectureResources() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [fetchMessage, setFetchMessage] = useState();
  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(false);
  const [positive, setPositive] = useState(false);
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [msg, setMsg] = useState(false);
  const [val, setVal] = useState(false);
  const [languages, setLanguages] = useState([]);

  // const { lectId, batchId } = useParams()
  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    batch: Yup.string().required("batch required"),
    lecture: Yup.string().required("Lecture required"),
    resource_type: Yup.string().required("Type is required"),
    is_active: Yup.boolean().required("Status Required"),
    language: Yup.string().required("Language is required"),
  });

  const defaultValues = {
    title: "",
    resource_type: "",
    // file_url: '',
    is_active: "",
    language: "",
  };

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const languageData = await axios.post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (languageData?.status !== 200)
        throw new Error("Failed to fetch languages");

      setLanguages(languageData?.data?.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   async function fetchUsers() {
  //     const fullResponse = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Resources`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const responseJson = await fullResponse.json();
  //     usersSet(responseJson?.data);
  //     const res = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-subjects`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const resSubject = await res.json();
  //     console.log(resSubject?.data);
  //     setSubject(resSubject.data);

  //     const resBatch = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-courses`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const resBatchesData = await resBatch.json();
  //     // debugger;
  //     console.log(resBatchesData.data);
  //     setBatches(resBatchesData.data);
  //     setLoading(false);
  //   }

  //   fetchUsers();
  //   fetchLanguages();
  // }, []);

  // Fetch languages when the component mounts

  const [file, setFile] = useState();
  //role
  const role = localStorage.getItem("role");

  const changeHandler = async (event) => {
    if (!type) {
      setFile(event.target?.files[0]);
    } else {
      setFile(event.target?.value);
    }
    // setFile(event.target?.files[0])
    setFileSelected(true);
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    const formData = new FormData();

    if (!type) {
      formData.append("resource_type", typeName);
      //  formData.append("file", file);

      // Use getFileUrl to get the URL of the uploaded file
      const fileUrl = await getFileUrl(file); // Wait for the URL
      formData.append("resource_url", fileUrl); // Append the URL to formData
    }

    if (type) {
      formData.append("resource_type", typeName);
      formData.append("resource_url", file);
    }
    // formData.append('file', file);
    formData.append("course_id", data?.batch);
    formData.append("lecture_id", data?.lecture);
    formData.append("title", data?.title);

    role === "Admin"
      ? formData.append("is_Verified", true)
      : formData.append("is_Verified", false);

    formData.append("language", data?.language);
    formData.append("status", data?.is_active ? "true" : "false");

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    // for( const Pair of formData){
    //     console.log( Pair[0] + " ->" + Pair[1]);
    // }
    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-lecture-resources`,
        formData,
        config
      )
      .then((response) => {
        //console.log(response?.data);
        if (response.data.response_code === 200) {

          reset();

          setFile();
          setFileSelected(false);
          setType(false);
          setBatches([]);
          setLectures([]);
          setBatchId("");
          setTypeName("");

          setTimeout(() => {
            // navigate(`/dashboard/addCenterResource`);
            // window.location.reload();
          }, 1000);
          toast.success("Lecture Resource Added");
        } else toast.error(response?.data?.errors);
        setLoading(false);
      })
      .catch((e) => {
        //console.log(e);
        toast.error(e);
      });
  };
  const [users, usersSet] = useState([]);
  const [type, setType] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [batches, setBatches] = useState([]);
  const [batchId, setBatchId] = useState("");
  const [lectures, setLectures] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (batchId) {
      async function fetchLectures() {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-lecture-by-course-id/${batchId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setLectures(responseJson?.data);
      }
      fetchLectures();
    }
  }, [batchId]);

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Lecture Resource</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />
            <Stack direction="row" spacing={3}>
              <RHFTextField name="batch" label="Select Course" select>
                {batches?.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      onClick={() => setBatchId(item?.id)}
                    >
                      {item.name ?? ""}
                    </MenuItem>
                  );
                })}
              </RHFTextField>
              <RHFTextField name="lecture" label="Select Lecture" select>
                {lectures?.length > 0 ? (
                  lectures?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.id}>
                        {item.title ?? ""}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>{"Lectures not found"}</MenuItem>
                )}
              </RHFTextField>
            </Stack>

            <RHFTextField name="resource_type" label="Resource type" select>
              {/* <MenuItem
                  onClick={() => {
                    setType(true);
                    setTypeName("yt_videos");
                  }}
                  value="yt_videos"
                  defaultValue="yt_videos"
                >
                  Youtube Link
                </MenuItem> */}
              <MenuItem
                onClick={() => {
                  setType(true);
                  setTypeName("link");
                }}
                value="link"
              >
                Link
              </MenuItem>
              {/* <MenuItem
                  onClick={() => {
                    setType(false);
                    setTypeName("video");
                  }}
                  value="video" 
                >
                  Video
                </MenuItem> */}
              <MenuItem
                onClick={() => {
                  setType(false);
                  setTypeName("pdf");
                }}
                value="pdf"
              >
                PDF
              </MenuItem>
            </RHFTextField>

            <Stack direction="row" spacing={3}>
              <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
                <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  sx={{ width: "100%" }}
                  error={!fileSelected && val}
                  label="Resource"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  inputProps={{
                    accept: typeName == "pdf" ? "application/pdf" : "video/*",
                  }}
                  helperText={
                    type
                      ? typeName == "link"
                        ? "Links Only"
                        : "Youtube Videos link"
                      : typeName == "pdf"
                        ? "Pdf Files Only"
                        : typeName == "video"
                          ? "Video Files Only"
                          : ""
                  }
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPdf(true)}
                  type={pdf || focus ? (type ? "url" : "file") : "text"}
                />
                {!fileSelected && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "red",
                    }}
                  >
                    {msg}
                  </Typography>
                )}
              </Stack>
              <RHFTextField
                name="language"
                label="language"
                sx={{ width: "100%" }}
                select
              >
                {languages?.map((language, index) => (
                  <MenuItem key={index} value={language?.medium_code}>
                    {language?.medium_name}
                  </MenuItem>
                ))}
              </RHFTextField>
              <RHFTextField name="is_active" label="Status" select>
                <MenuItem value={true}>Publish</MenuItem>
                <MenuItem value={false}>Darft</MenuItem>
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
              onClick={() => {
                // setMsg("Required");
                setVal(true);
              }}
            >
              Add
            </LoadingButton>
          </Stack>
          {/* <Typography variant='h4'>{fetchMessage}</Typography> */}
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
