import * as Yup from "yup";
import React, { useState, useEffect } from "react";

// form
import { useForm, Controller } from "react-hook-form"; // Import Controller
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CategoryComponent from "../../../utils/CategoryComponent";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function AddCoupon({ setValue }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const [languages, setLanguages] = useState([]);

  //* CATEGORY IMPLEMENTATION useStates *
  const [selectedCategory, setSelectedCategory] = useState("");
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [isGlobal, setIsGlobal] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [coupon_type, setCoupon_type] = useState(false);
  const [categoryComponents, setCategoryComponents] = useState([
    {
      selectedCategory: null,
      classVal: null,
      centralExam: null,
      stateExam: null,
      selectedState: null,
      selectedBoard: null,
    },
  ]);
  const removeCategoryComponent = (index) => {
    setCategoryComponents(categoryComponents.filter((_, i) => i !== index));
  };

  // Function to handle updating the component state
  const updateComponent = (index, key, value) => {
    const updatedComponents = [...categoryComponents];
    updatedComponents[index][key] = value;
    setCategoryComponents(updatedComponents);
  };
  const addCategoryComponent = () => {
    setCategoryComponents([
      ...categoryComponents,
      {
        selectedCategory: null,
        classVal: null,
        centralExam: null,
        stateExam: null,
        selectedState: null,
        selectedBoard: null,
      },
    ]);
  };

  const RegisterSchema = Yup.object().shape({
    coupon_code: Yup.string().required("Coupon code is required"),
    coupon_percentage: Yup.number()
      .required("Coupon percentage is required")
      .min(0)
      .max(100),
    start_from: Yup.date().required("Start date is required"),
    end_to: Yup.date().required("End date is required"),
    is_active: Yup.string().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    coupon_code: "",
    coupon_percentage: "",
    start_from: "",
    end_to: "",
    language: "",
    notes_type: "",
    is_active: "true",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const formatDateForSubmission = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return date.toISOString().slice(0, 19).replace("T", " ");
  };
  console.log("Coupon Type ", isPrivate == 'true' ? 0 : 1)
  const onSubmit = async (data) => {
    setLoading(true);

    const formattedStartFrom = formatDateForSubmission(data.start_from);
    const formattedEndTo = formatDateForSubmission(data.end_to);

    const payload = {
      course: selectedCategory,
      board_id: selectedBoard,
      class_id: classVal,
      state_id: selectedState,
      exam_id: centralExam || stateExam,
      name: data.name,
      coupon_code: data.coupon_code,
      coupon_percentage: data.coupon_percentage,
      start_from: formattedStartFrom,
      end_to: formattedEndTo,
      status: data.is_active === "true" ? 1 : 0,
      access: data.access,
      global: isGlobal ? 1 : 0,
      goals: categoryComponents,
      // coupon_type:coupon_type,
      coupon_type: isPrivate ? 0 : 1,
    };
    console.log("Payload ", payload)
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-coupon`,
        payload,
        config
      );
      setLoading(false);

      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message);
        setLoading(false);
        reset();
        setValue("coupon");
        toast.success(response?.data?.message)
        window.location.reload()
      } 

    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-subjects`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resSubject = await res.json();
      // Removed setSubject as it's no longer needed
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch languages");
      }
      const data = await response.json();
      setLanguages(data.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Coupon</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="name" label="Name" />
              <RHFTextField name="access" label="Coupon Type" select>
                <MenuItem value={1}>Multiple time</MenuItem>
                <MenuItem value={2}>One time</MenuItem>
              </RHFTextField>
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField name="coupon_code" label="Coupon Code" />
              <RHFTextField
                name="coupon_percentage"
                label="Coupon Percentage"
                type="number"
              />
            </Stack>

            <Stack direction="row" spacing={3}></Stack>
            <Stack direction="row" spacing={36}>
              <Controller
                name="global"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={isGlobal}
                        onChange={(e) => setIsGlobal(e.target.checked)}
                      />
                    }
                    label="All Courses"
                  />
                )}
              />

              <Controller
                name="private"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={isPrivate}
                        onChange={(e) => setIsPrivate(e.target.checked)}
                      />
                    }
                    label="Private"
                  />
                )}
              />
            </Stack>

            {/* {!isGlobal && (
              <CategoryComponent
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                classVal={classVal}
                setClass={setClass}
                centralExam={centralExam}
                setCentralExam={setCentralExam}
                stateExam={stateExam}
                setStateExam={setStateExam}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                selectedBoard={selectedBoard}
                setSelectedBoard={setSelectedBoard}
              />
            )} */}
            <div>


              {categoryComponents.map((component, index) => (<>
                <div key={index} style={{ display: "flex", alignItems: "center" }}>
                  <CategoryComponent
                    width={"100%"}
                    selectedCategory={component.selectedCategory}
                    setSelectedCategory={(value) => updateComponent(index, 'selectedCategory', value)}
                    classVal={component.classVal}
                    setClass={(value) => updateComponent(index, 'classVal', value)}
                    centralExam={component.centralExam}
                    setCentralExam={(value) => updateComponent(index, 'centralExam', value)}
                    stateExam={component.stateExam}
                    setStateExam={(value) => updateComponent(index, 'stateExam', value)}
                    selectedState={component.selectedState}
                    setSelectedState={(value) => updateComponent(index, 'selectedState', value)}
                    selectedBoard={component.selectedBoard}
                    setSelectedBoard={(value) => updateComponent(index, 'selectedBoard', value)}
                  />

                  <Button sx={{ margin: 2 }} variant="contained"
                    color="secondary"
                    onClick={() => removeCategoryComponent(index)}>Remove</Button>
                </div>

              </>
              ))}
              <Button sx={{ margin: 2 }} variant="contained"
                color="primary"
                onClick={addCategoryComponent}>Add</Button>

            </div>

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="start_from"
                label="Start From"
                type="date"
              />
              <RHFTextField
                name="end_to"
                label="End To"
                type="date"
              />
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField name="is_active" label="Status" select>
                <MenuItem value={"true"}>Publish</MenuItem>
                <MenuItem value={"false"}>Draft</MenuItem>
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}
