import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { ToastContainer, toast } from 'react-toastify';
import CategoryComponent from '../../../utils/CategoryComponent';
import { getFileUrl } from "../../../utils/firebaseUtils";



// ----------------------------------------------------------------------

export default function AddAudio() {
    const navigate = useNavigate();
    const [fetchMessage, setFetchMessage] = useState()
  const [loading, setLoading] = useState(false);
    const [languages, setLanguages] = useState();

    //* CATEGORY IMPLEMENTATION useStates *
    const [selectedCategory, setSelectedCategory] = useState("");
    const [classVal, setClass] = useState(null);
    const [centralExam, setCentralExam] = useState(null);
    const [stateExam, setStateExam] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
   const [selectedBoard, setSelectedBoard] = useState(null);
   const [file, setFile] = useState(null);
   const [audioUrl, setAudioUrl] = useState("");
   const [restrict, setRestrict] = useState("");


    const RegisterSchema = Yup.object().shape({
      title: Yup.string().required("Title required"),
      // lastName: Yup.string().required('Last name required'),
      // category: Yup.string().required('Category is required'),
      //audio_url: Yup.string().required("Url No. is required"),
      // language: Yup.string().required('Language is required'),

      is_active: Yup.string().required("Status Required"),
    });

    const defaultValues = {
        title: '',
        // category: '',
        audio_url: '',
        language: '',
        is_active: "true",
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        setLoading(true)
        const payload = {
          ...data,
          course: selectedCategory,
          state_exam_id: selectedState,
          board_id: selectedBoard,
          exam_id: centralExam || stateExam,
          class_id: classVal,
          audio_url: audioUrl, 
        };
        let token = localStorage.getItem("token");
        
        try {

            const res = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-audio`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            })
          
          const result = await res.json()
          console.log(result)
            toast.success(result.message)
            setFetchMessage(result.message)
            if (result.response_code === 200) {
                setLoading(false)
              reset()
              setTimeout(() => {
               window.location.reload();
              }, 1500);

                // navigate('/dashboard', { replaced: true })
            }
        } catch (error) {
            //console.log(error);
        }
        // navigate('/dashboard', { replace: true });
    };

   const fetchLanguages = async () => {
     const token = localStorage.getItem("token");
     try {
       const response = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        //  `${process.env.REACT_APP_LIVE_URL}/adminPanel/get-subcategory/eSCuWT792yKq1ZBSP4RKS`,
         {
           method: "POST",
           headers: {
             "Content-Type": "application/json",
             Authorization: `Bearer ${token}`,
           },
         }
       );
       if (!response.ok) {
         throw new Error("Failed to fetch languages");
       }
       const data = await response.json();
       setLanguages(data.data);
       console.log(data)
     } catch (error) {
       console.error("Error fetching languages:", error);
     }
   };

   // Fetch languages when the component mounts
   useEffect(() => {
     fetchLanguages();
   }, []);
  
  
  
  const changeHandler = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    // Get the file URL
    const url = await getFileUrl(selectedFile);
    setAudioUrl(url);

    // Validate file type
    const fileName = selectedFile.name.toLowerCase();
    if (!fileName.endsWith(".pdf") && !fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("Only audio files are allowed");
    }
  };



    return (
      <>
        <Stack spacing={3} px={5}>
          <Typography variant="h3">Add Audio</Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "70%" }}>
              <RHFTextField name="title" label="Title" />
              <Stack direction="row" spacing={3}>
                {/*<RHFTextField name="audio_url" label="Audio URL" type="url" />*/}
                <RHFTextField
                  name="audio_file"
                 // label="Upload Audio File"
                  type="file"
                  inputProps={{ accept: "audio/*" }}
                  onChange={changeHandler}
                />

                {/*//! temperory
                <RHFTextField
                  name="language"
                  label="language"
                  sx={{ width: "100%" }}
                  select
                >
                  {languages?.map((language, index) => (
                    <MenuItem key={index} value={language.medium_code}>
                      {language.medium_name}
                    </MenuItem>
                  ))}
                </RHFTextField> */}
              </Stack>

              <CategoryComponent
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                classVal={classVal}
                setClass={setClass}
                centralExam={centralExam}
                setCentralExam={setCentralExam}
                stateExam={stateExam}
                setStateExam={setStateExam}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                selectedBoard={selectedBoard}
                setSelectedBoard={setSelectedBoard}
              />

              <Stack direction="row" spacing={3}>
                {/* <RHFTextField name="category" label="Category" select>
                            {users?.map((item) => { return <MenuItem value={item.title}>{item.title}</MenuItem> })}



                        </RHFTextField> */}
                <RHFTextField
                  name="is_active"
                  label="Status"
                  type="checkbox"
                  select
                >
                  <MenuItem value={"true"}>publish</MenuItem>
                  <MenuItem value={"false"}>Draft</MenuItem>
                </RHFTextField>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                sx={{
                  bgcolor: "#e6e6ff",
                  color: "black",
                  marginTop: "5%",
                  "&:hover": { background: " #8080ff" },
                }}
              >
                Add
              </LoadingButton>
            </Stack>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Stack>
      </>
    );
}
