import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
import { Link, useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import axios from "axios";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    id: "",
    label: "S.No",
    alignRight: false,
    editable: true,
  },
  { id: "title", label: "Title", alignRight: false, editable: true },
  { id: "batch", label: "Batch", alignRight: false, editable: true },
  { id: "lecture", label: "Lecture", alignRight: false },
  { id: "upload_file", label: "File ", alignRight: false },
  { id: "language", label: "Language", alignRight: false },
  // { id: 'is_Verfied', label: 'Verified', alignRight: false },
  { id: "is_active", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function TeacherGeneratedTable() {
  const [page, setPage] = useState(0);
  // const [users, usersSet] = React.useState([]);
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  // const [userss, userssSet] = useState()

  const [loading, setLoading] = useState(true);
  // const [batchDetails, setBatchDetails] = useState()

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   async function fetchUsers() {
  //     const fullResponse = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLectureResourceDetails`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const responseJson = await fullResponse.json();
  //     usersSet(responseJson.data);
  //     setLoading(false);
  //   }

  //   fetchUsers();
  // }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;
  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  // //console.log(updatedValues)

  let i = 0;

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Teacher Content">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              {/* <Typography variant="h3" gutterBottom>
                            All Pathyakram
                        </Typography> */}
              {/* <Button variant='contained' sx={{ width: '20%', alignSelf: 'center', background: '#EA506B', '&:hover': { background: 'red' } }} >Add Student</Button> */}
            </Stack>

            <Card>
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .filter((item) => !item.is_Verfied)
                        ?.map((row, index) => {
                          let {
                            id,
                            batch,
                            is_Verified,
                            is_active,
                            language,
                            lecture,
                            title,
                            upload_file,
                            _id,
                          } = row;
                          const isItemSelected = selected.indexOf(title) !== -1;

                          const onChange = (e, row, _id) => {
                            const { name, value } = e.target;

                            setUpdatedValues((prev) => {
                              return { ...prev, [name]: value };
                            });
                          };
                          const update = async () => {
                            const token = localStorage.getItem("token");
                            const authToken = token;
                            const config = {
                              headers: {
                                "content-type": "application/json",
                                Authorization: `Bearer ${authToken}`,
                              },
                            };
                            {/* const res = await axios.put(
                              `${process.env.REACT_APP_LIVE_URL}/adminPanel/verifyLectureresourceByAdmin/${_id}`,
                              {},
                              config
                            ); */}
                            setTimeout(() => {
                              window.location.reload();
                            }, 600);
                          };

                          return (
                            <>
                              {is_Verified ? null : (
                                <TableRow
                                  hover
                                  key={index}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, title)} />
                                                    </TableCell> */}
                                  <TableCell>
                                    <Typography variant="subtitle2">
                                      {is_Verified ? "" : ++i}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" pl={2}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      {/* <Avatar alt={name} src={avatarUrl} /> */}
                                      <Typography variant="subtitle2" noWrap>
                                        <span>{title}</span>
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell align="left">
                                    <span>{batch?.batch_name}</span>
                                  </TableCell>
                                  <TableCell align="left">
                                    <span>{lecture?.lecture_title}</span>
                                  </TableCell>
                                  <TableCell align="left">
                                    <a
                                      href={upload_file}
                                      style={{ color: "black" }}
                                    >
                                      <PictureAsPdfIcon
                                        sx={{
                                          cursor: "pointer",
                                          "&:hover": {
                                            color: "red",
                                          },
                                        }}
                                      />
                                    </a>
                                  </TableCell>
                                  <TableCell align="left">
                                    <span>
                                      {language === "hi" ? "Hindi" : "English"}
                                    </span>
                                  </TableCell>

                                  {/* <TableCell align="left">
                                                        <Typography sx={{ background: is_Verified ? 'green' : 'red', padding: '5px', borderRadius: '10px', color: 'white', fontWeight: '500', fontSize: '10px' }}> {is_Verified ? 'Verified' : 'Not Verified'}</Typography>

                                                    </TableCell> */}
                                  <TableCell align="left">
                                    {isEdit ? (
                                      <TextField
                                        onChange={(e) => onChange(e, row)}
                                        // value={value}
                                        label={
                                          is_active ? "Active" : "Inactive"
                                        }
                                        name="is_active"
                                        placeholder={
                                          is_active ? "Active" : "Inactive"
                                        }
                                        select
                                        sx={{ width: "100px" }}
                                      >
                                        <MenuItem value={true}>Active</MenuItem>
                                        <MenuItem value={false}>
                                          Inactive
                                        </MenuItem>
                                      </TextField>
                                    ) : (
                                      <span
                                        style={{
                                          background: is_active
                                            ? "green"
                                            : "red",
                                          padding: "5px",
                                          borderRadius: "10px",
                                          color: "white",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {is_active ? "Active" : "Inactive"}
                                      </span>
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    <Button
                                      variant="contained"
                                      onClick={update}
                                      sx={{ bgcolor: "#e6e6ff" }}
                                    >
                                      Verify
                                    </Button>
                                  </TableCell>
                                  {/* {isEdit ? (< TableCell align="left">
                                                        <Button variant='outlined' onClick={handleUpdate}>Update</Button>
                                                    </TableCell>) : null} */}

                                  {/* <TableCell align="right">
                                                        <UserMoreMenu id={_id} req='DeleteResourcesDetails' isEdit={isEdit} setIsEdit={setIsEdit} />
                                                    </TableCell> */}
                                </TableRow>
                              )}
                            </>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {filteredUsers.length == 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {(i == 0 || isUserNotFound) && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users.filter((item) => !item?.is_verfied).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
