import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  padding: "0.3rem !important",
  height:"2.5rem",
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({ numSelected, filterName, onFilterName, from }) {

  return (
    <RootStyle
      sx={{
        padding:"0"
        // ...(numSelected > 0 && {
        //   color: 'primary.main',
        //   bgcolor: 'white',
        // }),
      }}
    >
      {numSelected > 0 && (
        <Typography component="div" mr={2} variant="subtitle1" sx={{

          color: 'primary.main',
          bgcolor: 'white',
          height: '100%',
          padding: '5px',
          display: 'flex',
          alignItems: 'center'

        }} >
          {numSelected} selected
        </Typography>
      )
      }
      <SearchStyle
        value={filterName}
        size='sm'
        onChange={onFilterName}
        placeholder="Search ...."
        padding="0rem !important"
        style={{padding:"0px !important", margin:"0rem"}}
        sx={{
          border: from == 'AssignStudent' ? '1px solid  white !important' : "1px solid #96a2af !important",
          
        }}
        startAdornment={
          <InputAdornment position="start" style={{padding:"0rem !important", margin:"0rem !important"}}>
            <Iconify icon="eva:search-fill" sx={{ color: from == 'AssignStudent' ? 'white' : '#96a2af', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />



    </RootStyle >
  );
}
