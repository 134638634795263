import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
// ----------------------------------------------------------------------

export default function AddNewsClip() {
  const navigate = useNavigate();

  const [focus, setFocused] = useState(false)
  const [showAudio, setShowAudio] = useState(false)
  const [file, setFile] = useState()
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState('');
  const [val, setVal] = useState(false);
  const [languages, setLanguages] = useState([]);



  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required('Title required'),
    // lastName: Yup.string().required('Last name required'),
    // category: Yup.string().required('Category is required'),
    // file_url: Yup.string().required('Url  is required'),

    // category_id: Yup.string().required('category_id  is required'),
    language: Yup.string().required('Language  is required'),
    is_active: Yup.boolean().required('Status Required'),
  });

  const defaultValues = {
    title: '',
    is_active: '',
    // file_url: '',
    // category_id: '',
    language: ''
    // is_active: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;


  const changeHandler = async (event) => {
    setFile(event.target?.files[0])


  };
  const onSubmit = async (data) => {
    if (file?.size) {


      setLoading(true)
      const token = localStorage.getItem("token");
      const authToken = token;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('title', data?.title);
      formData.append('is_active', data?.is_active);
      formData.append('language', data?.language);
      formData.append('category_id', 'News Clip');

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
      };
      // axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addNewsClips`, formData, config).then((response) => {
      //   //console.log(response?.data);
      //   if (response?.data?.msg === 'added NewsClipData details successfully') {
      //     toast.success(response?.data?.msg)
      //     setFile(null)
      //     setVal(false)
      //     setMsg('')
      //     setShowAudio(false)
      //     setFocused(false)
      //     setLoading(false)
      //     reset()
      //   }
      // })
      //   .catch(e => {
      //     //console.log(e);
      //   })
    }

  };
  const [users, usersSet] = useState([]);
  // useEffect(() => {
  //     const token = localStorage.getItem("token");
  //     async function fetchUsers() {
  //         const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Resources`, {
  //             method: 'GET',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 Authorization: `Bearer ${token}`
  //             },

  //         });
  //         const responseJson = await fullResponse.json();
  //         usersSet(responseJson?.data);

  //         // setLoading(false)
  //     }

  //     fetchUsers();
  // }, []);


  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch languages");
      }
      const data = await response.json();
      setLanguages(data.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);


  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add News Clip</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />
            <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
              <RHFTextField
                name="file_url"
                label="NewsClip"
                onChange={changeHandler}
                error={!file && val}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                onClick={() => setShowAudio(true)}
                inputProps={{ accept: "application/pdf" }}
                helperText="Pdf Files Only"
                type={showAudio || focus ? "file" : "text"}
              />
              {!file && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    marginTop: "5px",
                    marginLeft: "10px",
                    color: "red",
                  }}
                >
                  {msg}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="language"
                label="language"
                sx={{ width: "100%" }}
                select
              >
                {languages?.map((language, index) => (
                  <MenuItem key={index} value={language.medium_code}>
                    {language.medium_name}
                  </MenuItem>
                ))}
              </RHFTextField>
              <RHFTextField name="is_active" label="Status" select>
                <MenuItem value={true}>Publish</MenuItem>
                <MenuItem value={false}>Draft</MenuItem>
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
