import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from 'react-hook-form';
import moment from "moment-timezone";
import {
  Card,
  Stack,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  Autocomplete
} from "@mui/material";
// import Loader from "react-loader-spinner";
import { DataGrid, GridToolbarExport, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DialogUserTable from "./DialogUserTable";
import CategoryComponent from "../../../../utils/CategoryComponent";

//* custom hook for debouncing
const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue.trim());
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};

export default function UserTableCard() {
  const methods = useForm();
  const detailObject = JSON.parse(localStorage.getItem("detailObject"));
  const accessArr = detailObject?.access_level?.split(',');
  console.log(accessArr, accessArr?.includes('write'));
  const role = localStorage.getItem('role');
  console.log(role)
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusUser, setStatusUser] = useState();
  const [statusStatus, setStatusStatus] = useState();
  const [verificationStatus, setVerificationStatus] = useState();
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [subCategories, setSubCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [boards, setBoards] = useState();
  const [classes, setClasses] = useState([])
  const [exams, setExams] = useState([])
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const streamCategory = [
    {
      id: "class",
      title: "School Exams"
    },
    {
      id: "central-exam",
      title: "Central Exams"
    },
    {
      id: "state-exam",
      title: "State Exams"
    },
  ]

  const handleCategoryChange = async (event) => {
    const selectedCategoryId = event.target.value;
    // console.log(selectedCategoryId);
    // console.log(event.target.value);
    setStates(null);
    setBoards(null);
    setSubCategories(null);


    setIsCategoryLoading(true);
    // setSelectedCategory(selectedCategoryId);

    const token = localStorage.getItem("token");

    // Fetch subcategories based on the selected category
    setIsCategoryLoading(false);

  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    // status: '',
    exam_id: "",
    class_id: "",
    board_id: "",
    course: "",
    state_exam_id: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [studentId, setStudentId] = useState();
  const token = localStorage.getItem("token");
  const handleSubmit = async (e) => {
    // console.log("hello");
    e.preventDefault();
    const token = localStorage.getItem("token");
    // Handle form submission here, e.g., update data
    console.log("Form submitted:", formData);

    const response = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-admin-user/${studentId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      }
    );
    if (response.ok) {
      sessionStorage.clear();
      console.log("response", response);
      console.log("formdata", formData);
      toast.success("Update Successfully");
      fetchData()
      setFormData({})
      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
    }

    if (!response?.ok) {
      throw new Error("Network response was not ok");
    }

    // useToast.success("updated");
    // Reset form data
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      // status: '',
      pin_code: "",
    });
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
    setLoading(true)
  };
  const handleClose1 = () => {
    setTimeout(() => {
      setOpen1(false);
    }, 500);
  };

  //! pagination model
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [totalUsers, setTotalUsers] = useState();
  const [dataLoading, setDataLoading] = useState();
  const debouncedSearchTerm = useDebouncedValue(searchText, 1000);

  const handlePaginationModelChange = (model) => {
    setPaginationModel(model);
  };
  function maskPhoneNumber(phoneNumber) {
    if (!phoneNumber) return "NA";

    const firstDigit = phoneNumber[0];
    const lastDigit = phoneNumber[phoneNumber.length - 1];
    const maskedDigits = phoneNumber.slice(1, -1).replace(/\d/g, 'x');

    return `<span class="math-inline">\{firstDigit\}</span>{maskedDigits}${lastDigit}`;
  }

  function maskEmail(email) {
    if (!email) return "NA";

    const [username, domain] = email.split('@');
    const maskedUsername = username.slice(0, 3) + 'xxx' + username.slice(-3);

    return `<span class="math-inline">\{maskedUsername\}@</span>{domain}`;
  }
  //* below useEFfect will run whenever the debounced term will get change (in current scenario its almost after 1000 sec)
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    setDataLoading(true);

    (async () => {
      try {
        if (debouncedSearchTerm === "") {
          fetchData();
          return;
        }

        const cachedData = JSON.parse(
          sessionStorage.getItem(
            `user-list?limit=${paginationModel.pageSize}&page=1&search=${debouncedSearchTerm}`
          )
        );
        if (cachedData) {
          setUsers(cachedData?.data);
          setTotalUsers(cachedData?.total);
          setDataLoading(false);
          return;
        }

        const token = localStorage.getItem("token");

        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-user-list?limit=${paginationModel.pageSize}&page=1&search=${debouncedSearchTerm}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }, // Link the signal
          { signal }
        );
        setDataLoading(false);

        if (response.data.response_code === 200) {
          // console.log(response?.data);
          setUsers(response?.data?.data?.data);
          setTotalUsers(response?.data?.data?.total || 0);
          sessionStorage.setItem(
            `user-list?limit=${paginationModel.pageSize}&page=1&search=${debouncedSearchTerm}`,
            JSON.stringify(response?.data?.data)
          );
          setDataLoading(false);
        } else {
          setDataLoading(false);
          console.error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        setDataLoading(false);
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    })();


    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, [debouncedSearchTerm]);

  console.log("Board ", boards);
  console.log("States ", states);
  console.log("Exam ", exams)
  //! below use Effect runs whenever the page change arrow is clicked
  useEffect(() => {
    fetchData();

  }, [paginationModel]);
  const onStateChange = async () => {
    const stateExamsList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-state-exams`, { state_exam_id: formData.state_exam_id })
    console.log(stateExamsList.data)
    setSubCategories(stateExamsList.data?.data);
  }
  const onBoardChange = async () => {
    const boardExamsList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-classes`, { board_id: formData.board_id })
    console.log(boardExamsList.data)
    setClasses(boardExamsList.data?.data);
  }
  const getCategory = async () => {
    const centralExams = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-central-exams`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    });
    setExams(centralExams?.data?.data);
    const stateList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-states`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    });
    setStateExam(stateList?.data?.data);
    setStates(stateList?.data?.data)
    onStateChange()
    const boardsList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-boards`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    });
    setBoards(boardsList?.data?.data)

    const classesList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-classes`, { board_id: formData.board_id }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    });
    setClasses(classesList?.data?.data)

  }
  useEffect(() => {


    getCategory()

  }, [formData]);
  console.log("Data of State ", stateExam)
  const fetchData = async () => {
    setDataLoading(true);
    const cachedData = JSON.parse(
      sessionStorage.getItem(
        `user-list?limit=${paginationModel.pageSize}&page=${paginationModel.page + 1
        }&search=${debouncedSearchTerm}`
      )
    );
    if (cachedData) {
      setUsers(cachedData?.data);
      setTotalUsers(cachedData?.total);
      setDataLoading(false);
      return;
    }
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-user-list?limit=${paginationModel.pageSize
        }&page=${paginationModel.page + 1}&search=${debouncedSearchTerm}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(response?.data?.data?.data);
      setTotalUsers(response?.data?.data?.total || 0); // Assuming the total number of users is returned by the API
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);


  }

  const handleStatusUpdate = (userId, status) => {
    setStatusUser(userId);
    setField("status");
    setStatusStatus(status);
    setOpen(true);
  };

  const handleVerificationUpdate = (userId, status) => {
    setStatusUser(userId);
    setField("verification");
    setVerificationStatus(status);
    setOpen(true);
  };
  const resetHandler = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/reset-count/${userId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message);

    }
    catch (error) {
      console.log("error", error)
      toast.info("Attempt Does Not Exists for This User");


    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target?.value);
  };

  const filteredUsers =
    users?.filter((user) =>
      Object.values(user).some((value) =>
        value?.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    ) || [];

  // const totalUsers = filteredUsers?.length;

  const startIndex = page * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedUsers = filteredUsers?.slice(startIndex, endIndex);
  const [field, setField] = useState("");

  const [studentData, setStudentData] = useState();
  const StudentData = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/view-user/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await fullResponse.json();
      // console.log(response);

      if (
        response?.response_code === "200" &&
        response?.data &&
        response?.data?.user
      ) {
        const userData = response.data.user;
        setStudentData(userData);
        setStudentId(userData.id);
        console.log(userData);

        formData.first_name = userData?.first_name;
        formData.last_name = userData?.last_name;
        formData.email = userData?.email;
        formData.mobile_no = userData?.mobile_no;
        formData.course = userData?.course;
        formData.class_id = userData?.class_id;
        formData.board_id = userData?.board_id;
        formData.exam_id = userData?.exam_id;
        formData.state_exam_id = userData?.state_exam_id;




        // formData.status = userData?.status;
        formData.pin_code = userData?.pin_code;


        setSelectedCategory(userData?.course);
        setSelectedState(userData?.state_exam_id);
        setSelectedBoard(userData?.board_id);
        setCentralExam(userData?.exam_id);
        setStateExam(userData?.exam_id);
        setClass(userData?.class_id);
        console.log("Data selectedCategory : ", selectedCategory)
        console.log("Data selectedState : ", selectedState)
        console.log("Data selectedBoard : ", selectedBoard)
        console.log("Data centralExam : ", centralExam)
        console.log("Data classVal : ", classVal)
        setLoading(false);
      } else {
        console.error("Error fetching user data:", response.message);
        setLoading(false);

      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);

    }
  };
  function maskPhoneNumber(phoneNumber) {
    if (!phoneNumber || phoneNumber.length !== 10) {
      return "Invalid phone number";
    }

    const firstTwoDigits = phoneNumber.slice(0, 2);
    const lastTwoDigits = phoneNumber.slice(-2);
    const maskedDigits = "xxxxx";

    return `${firstTwoDigits}${maskedDigits}${lastTwoDigits}`;
  }

  function maskEmail(email) {
    if (!email) {
      return "Invalid email";
    }

    const [username, domain] = email.split('@');
    const maskedUsername = username.slice(0, 3) + 'xxx' + username.slice(-3);

    return `${maskedUsername}@${domain}`;
  }
  const columns = [
    { field: "id", headerName: "Enrollment No.", width: 120 },
    {
      field: "first_name",
      headerName: "Name",
      width: 200,

      renderCell: (params) => (
        <Link
          style={{
            textDecoration: "none",
            cursor: "pointer",
            fontSize: "",
            color: "#000",
          }}
          to={`/dashboard/students/studentsProfile/${params.id}`}
        >
          {params?.row.first_name + " " + params?.row?.last_name || "NA"}
        </Link>
      ),
    },
    {
      field: "mobile_no", headerName: "Mobile No.", width: 150, valueGetter: ({ value, row }) => {
        return maskPhoneNumber(row?.mobile_no?.toString());
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      valueGetter: ({ value, row }) => {
        return maskEmail(row?.email || "NA");
      },
    },
    {
      field: "Exam Name",
      headerName: "Exam Name / Class",
      width: 250,
      valueGetter: ({ value, row }) => {
        return row?.exam_name || row?.class_name || "NA";
      },
    },
    {
      field: "created_at",
      headerName: "Registered On",
      width: 150,
      valueGetter: ({ value }) => {
        if (!value) return "NA";
        // Convert the UTC time to IST
        const istTime = moment.tz(value, "UTC").tz("Asia/Kolkata");
        // Format the IST time
        return istTime.format("YYYY-MM-DD HH:mm:ss");
        // return `${istTime.slice(0,10) } ${istTime.slice(11,19)}`;
      },
    },
    { field: "user_from", headerName: "Device", width: 70 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color={params.value ? "success" : "error"}
          onClick={() => {
            if (role == 'Admin' || accessArr?.includes('write')) {
              handleStatusUpdate(params.id, params.value)
            }
          }}
        >
          {params.value ? "Active" : "InActive"}
        </Button>
      ),
    },

    ...(role === 'Admin' || accessArr?.includes('read') ? [{
      field: "view",
      headerName: "View Student",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={() =>
            navigate(`/dashboard/students/studentsProfile/${params.id}`)
          }
        >
          view
        </Button>
      ),
    }] : []),

    ...(role === 'Admin' || accessArr?.includes('write') ? [{
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => {

            handleClickOpen1();
            setLoading(true)

            StudentData(params.id);
          }}
        >
          Edit
        </Button>
      ),
    }] : []),

    ...(role === 'Admin' || accessArr?.includes('write') ? [{
      field: "Reset",
      headerName: "Attempt Count",
      width: 120,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="info"
          onClick={() => resetHandler(params.id)}
        >
          Reset
        </Button>
      ),
    }] : []),
    ...(role === 'Admin' || accessArr?.includes('write') ? [{
      field: "is_otp_verified",
      headerName: "Mobile Verification",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color={params.value ? "success" : "error"}
          onClick={() => handleVerificationUpdate(params.id, params.value)}
        >
          {params.value ? "Verified" : "Not Verfied"}
        </Button>
      ),
    }] : []),

  ];

  console.log("Form Data States  ", stateExam)

  const CustomExportToolbar = () => (
    <div>
      <GridToolbarExport />
    </div>
  );

  return (
    <>
      {Array.isArray(users) ? (
        <Card>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            mb={3}
            px={5}
          >
            {/* <Typography
                variant="h3"
                gutterBottom
                sx={{ marginBottom: { xs: 2, sm: 0 } }}
              >
                All Users
              </Typography> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: { xs: 2, sm: 0 },
                marginLeft: "auto",
              }}
            >
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                value={searchText}
                onChange={handleSearchChange}
                sx={{
                  width: "250px",
                  height: "20px",
                  marginY: "1rem",
                  "& label": {
                    color: "gray",
                  },
                  "& fieldset": {
                    borderColor: "gray",
                  },
                }}
              />
            </div>
          </Stack>
          {/* <SearchNotFound */}

          <DataGrid
            rows={users}
            columns={columns}
            rowCount={totalUsers}
            pageSizeOptions={[5, 10, 20, 25, 50]}
            pagination
            paginationModel={paginationModel}
            loading={dataLoading}
            onPaginationModelChange={handlePaginationModelChange}
            paginationMode="server"
            style={{
              marginLeft: "30px",
              marginTop: "10px",
              marginBottom: "30px",
              border: "none",
            }}
            components={{
              Toolbar: GridToolbar,
              ExportToolbar: CustomExportToolbar,
            }}
          />

          {open1 && (
            <>
              <>
                <Dialog
                  open={true}
                  onClose={handleClose1}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Update User's Details"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please update the following information:
                    </DialogContentText>
                    {loading ? <CircularProgress
                      sx={{ marginY: "auto", display: "flex", marginX: "auto" }}
                    /> :
                      <form onSubmit={handleSubmit}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="first_name"
                          name="first_name"
                          label="First Name"
                          type="text"
                          fullWidth
                          value={formData.first_name}
                          onChange={handleChange}
                        />
                        <TextField
                          margin="dense"
                          id="last_name"
                          name="last_name"
                          label="Last Name"
                          type="text"
                          fullWidth
                          value={formData.last_name}
                          onChange={handleChange}
                        />
                        <TextField
                          margin="dense"
                          id="email"
                          name="email"
                          label="Email"
                          type="email"
                          fullWidth
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <TextField
                          margin="dense"
                          id="mobile_no"
                          name="mobile_no"
                          label="Mobile Number"
                          type="tel"
                          fullWidth
                          value={formData.mobile_no}
                          onChange={handleChange}
                        />
                        {/* <FormProvider {...methods}>

                          <CategoryComponent
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            classVal={classVal}
                            setClass={setClass}
                            centralExam={centralExam}
                            setCentralExam={setCentralExam}
                            stateExam={stateExam}
                            setStateExam={setStateExam}
                            selectedState={selectedState}
                            setSelectedState={setSelectedState}
                            selectedBoard={selectedBoard}
                            setSelectedBoard={setSelectedBoard}
                          />
                        </FormProvider> */}
                        <Select
                          value={formData.course}
                          sx={{ my: 1 }}
                          fullWidth
                          label="Course"
                          name="course"
                          onChange={(e) =>
                            setFormData({ ...formData, course: e.target.value })
                          }
                        >
                          {streamCategory?.map((item) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item?.title}
                            </MenuItem>
                          ))}
                        </Select>

                        {/* Central Exam Autocomplete */}
                        {formData.course == "central-exam" && (
                          <Select
                            sx={{ my: 1 }}
                            fullWidth
                            value={formData?.exam_id || ""}
                            onChange={(e) =>
                              setFormData({ ...formData, exam_id: e.target.value || null })
                            }
                            displayEmpty
                          >
                            <MenuItem value="" disabled>
                              Select Central Exam
                            </MenuItem>
                            {exams?.map((exam) => (
                              <MenuItem key={exam?.id} value={exam?.id}>
                                {exam?.name || "Unnamed Exam"}
                              </MenuItem>
                            ))}
                          </Select>
                        )}

                        {/* State Exam Autocomplete */}
                        {formData.course == "state-exam" && (
                          <>
                            <Select
                              sx={{ my: 1 }}
                              fullWidth
                              value={formData?.state_exam_id || ""}
                              onChange={(e) =>
                                setFormData({ ...formData, state_exam_id: e.target.value || null })
                              }
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                Select State Exam
                              </MenuItem>
                              {states?.map((exam) => (
                                <MenuItem key={exam?.id} value={exam?.id}>
                                  {exam?.name || "Unnamed Exam"}
                                </MenuItem>
                              ))}
                            </Select>
                            <Select
                              sx={{ my: 1 }}
                              fullWidth
                              value={formData?.exam_id || ""}
                              onChange={(e) =>
                                setFormData({ ...formData, exam_id: e.target.value || null })
                              }
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                Select Subcategory
                              </MenuItem>
                              {subCategories?.map((sub) => (
                                <MenuItem key={sub?.id} value={sub?.id}>
                                  {sub?.name || "Unnamed Subcategory"}
                                </MenuItem>
                              ))}
                            </Select>
                          </>
                        )}

                        {/* Class Selection */}
                        {formData.course === "class" && (
                          <>
                            <Select
                              sx={{ my: 1 }}
                              fullWidth
                              value={formData.board_id || ""}
                              onChange={(e) => {
                                setFormData({ ...formData, board_id: e.target.value });
                                onBoardChange();
                              }}
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                Select Board
                              </MenuItem>
                              {boards?.map((board) => (
                                <MenuItem key={board.id} value={board.id}>
                                  {board.name}
                                </MenuItem>
                              ))}
                            </Select>

                            <Select
                              sx={{ my: 1 }}
                              fullWidth
                              value={formData.class_id || ""}
                              onChange={(e) =>
                                setFormData({ ...formData, class_id: e.target.value })
                              }
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                Select Class
                              </MenuItem>
                              {classes?.map((cls) => (
                                <MenuItem key={cls.id} value={cls.id}>
                                  {cls.class_name}
                                </MenuItem>
                              ))}
                            </Select>

                          </>
                        )}


                        <TextField
                          margin="dense"
                          id="pin_code"
                          name="pin_code"
                          label="Pincode"
                          type="text"
                          fullWidth
                          value={formData.pin_code}
                          onChange={handleChange}
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="medium"
                          style={{
                            margin: "1rem",
                          }}
                          onClick={() => {
                            handleClose1();
                          }}
                        >
                          Update
                        </Button>
                      </form>}
                  </DialogContent>
                </Dialog>
              </>

            </>
          )}
        </Card>
      ) : (
        <div
          sx={{
            height: "full",
            width: "full",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress
            sx={{ marginY: "auto", display: "flex", marginX: "auto" }}
          />
        </div>
      )}

      {open && (
        <DialogUserTable
          open={open}
          handleClose={handleClose}
          field={field}
          userId={statusUser}
          statusStatus={statusStatus}
          verificationStatus={verificationStatus}
        />
      )}

      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
