import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components



import { useTheme } from "@mui/material/styles";



import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// ----------------------------------------------------------------------
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


export default function AddQuiz() {
  const [loading, setLoading] = useState(false);
  const [focusThree, setFocusedThree] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const [languages, setLanguages] = useState([]);

  const [subject, setSubject] = useState([]);
  const [subjectList, setsubjectList] = useState([]);


  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [states, setStates] = useState();
  const [boards, setBoards] = useState();
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null)
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);


  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);


  const streamCategory = [
    {
      _id: "class",
      title: "School Exams"
    },
    {
      _id: "central-exam",
      title: "Central Exams"
    },
    {
      _id: "state-exam",
      title: "State Exams"
    },
  ]

  const theme = useTheme();




  const navigate = useNavigate();

  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
  };

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    description: Yup.string().required("Description is required"),
    duration: Yup.string().required("Duration is required"),
    // NoOfQues: Yup.string().required('No. Of Questions is required'),
    isNegative: Yup.boolean().required("Negative Marking is required"),
    isActive: Yup.string().required("Status is required"),
    language: Yup.string().required("Language is required"),
    eachQueMarks: Yup.string().required("Language is required"),
    negativeMarks: Yup.number().when("isNegative", (isNegative, schema) => {
      return isNegative
        ? schema.test(
          "positive-and-greater-than-zero",
          "Negative Marks must be a positive number greater than zero",
          (value) => value > 0
        ).max(Yup.ref("eachQueMarks"), "Negative Marks must not exceed Each Question Marks")
        : schema;
    }),
    resultType: Yup.string().required("Result Type is required"),
  });

  const defaultValues = {
    title: "",
    description: "",
    duration: "",
    // NoOfQues: '',
    isNegative: "",
    isActive: "",
    language: "",
    eachQueMarks: "",
    negativeMarks: 0,
    resultType: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const [showNegative, setShowNegative] = useState(false);

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [file, setFile] = useState();

  const onSubmit = async (data) => {
    // console.log(data);
    setLoading(true);
    var formData = new FormData();
    // const obj = { ...data };
    // console.log(obj);

    const selectedCategoryTitle = streamCategory.find(
      (category) => category._id === selectedCategory
    )?.title;


    formData.append("category", selectedCategory);

    formData.append("subCategory", data.subCategory);

    subjectList?.map((item) => formData.append("subject", item));

    formData.append("file", file);
    formData.append("title", data?.title);
    formData.append("description", data?.description);
    formData.append("duration", data?.duration);
    // formData.append('no_ques    ', data?.NoOfQues);

    formData.append("course", selectedCategory);
    formData.append("state_exam_id", selectedState);
    formData.append("board_id", selectedBoard);
    formData.append("exam_id", centralExam || stateExam);
    formData.append("class_id", classVal);

    console.log(formData.get('course'), formData.get('board_id'), formData.get('state_exam_id'), formData.get('exam_id'), formData.get('class_id'));

    formData.append("isNegative", data?.isNegative);
    formData.append("language", data?.language);
    formData.append("isActive", data?.isActive);
    formData.append("eachQueMarks", data?.eachQueMarks);
    if (data?.isNegative) {
      formData.append("negativeMarks", data?.negativeMarks);
    }
    formData.append("resultType", data?.resultType);
    // alert(formData)


    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    // axios
    //   .post(
    //     `${process.env.REACT_APP_LIVE_URL}/adminPanel/addQuiz`,
    //     formData,
    //     config
    //   )
    //   .then((response) => {
    //     // axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addQuiz`, formData, config).then((response) => {
    //     // axios.post(`http:localhost:5000/api/v1/adminPanel/addQuiz`, formData, config).then((response) => {
    //     //console.log(response?.data);
    //     if (response?.data?.msg === "Quiz Details Added Successfully") {
    //       const quizName = encodeURIComponent(response.data.data.quiz_title); 
    //       const quizId = response.data.data._id; 
    //       toast.success(response?.data?.msg);
    //       setLoading(false);
    //       reset();
    //       setTimeout(() => {
    //         navigate(`/dashboard/quiz/${quizName}/${quizId}`); 
    //       }, 1000);
    //     }
    //   })
    //   .catch((e) => {
    //     //console.log(e);
    //   });
  };


  const handleCategoryChange = async (event) => {
    const selectedCategoryId = event.target.value;
    console.log(selectedCategoryId);
    console.log(event.target.value);
    setStates(null);
    setBoards(null);
    setSubCategories(null);
    setSelectedBoard(null);
    setSelectedState(null);

    setIsCategoryLoading(true);
    setSelectedCategory(selectedCategoryId);

    const token = localStorage.getItem("token");
    switch (selectedCategoryId) {
      case 'central-exam':
        const centralExams = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-central-exams`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        });
        setSubCategories(centralExams?.data?.data);
        break;

      case 'state-exam':
        const stateList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-states`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        });
        console.log(stateList?.data?.data)
        setStates(stateList?.data?.data);
        break;

      case 'class':
        const boardsList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-boards`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        });
        // console.log(boardsList?.data?.data)
        setBoards(boardsList?.data?.data);
        break;

      default:
        break;
    }
    // Fetch subcategories based on the selected category
    setIsCategoryLoading(false);

  };



  const handleStateChange = async (e) => {
    setIsCategoryLoading(true);
    setSelectedState(e.target.value);
    const currentState = e.target.value;
    setSubCategories(null);
    const stateExamsList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-state-exams`, { state_exam_id: currentState })
    console.log(stateExamsList.data)
    setSubCategories(stateExamsList.data.data);
    setIsCategoryLoading(false);
  }

  const handleBoardChange = async (e) => {
    setIsCategoryLoading(true);
    const currentBoard = e.target.value;
    setSelectedBoard(e.target.value);
    setSubCategories(null);
    const boardExamsList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-classes`, { board_id: currentBoard })
    console.log(boardExamsList.data)
    setSubCategories(boardExamsList.data.data);
    setIsCategoryLoading(false);
  }

  const handleSubCategoryChange = (e) => {
    setClass(null);
    setStateExam(null);
    setCentralExam(null);

    if (boards) setClass(e.target.value);
    else if (states) setStateExam(e.target.value);
    else setCentralExam(e.target.value);

    console.log("boards", classVal);
    console.log("states", stateExam);
    console.log("central", centralExam);

  }

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {

      const languageData = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      });

      if (languageData?.status !== 200)
        throw new Error("Failed to fetch languages");

      setLanguages(languageData?.data?.data);

    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);




  const handleChangeSubject = (event) => {
    const {
      target: { value },
    } = event;
    setsubjectList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };


  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Mock Test</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Mock Test Title" />
            <Stack direction={"row"} spacing={3}>
              <RHFTextField
                name="category"
                label="Category"
                sx={{ width: "50%" }}
                select
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                {streamCategory?.map((item, index) => (
                  <MenuItem key={index} value={item._id}>
                    {item.title}
                  </MenuItem>
                ))}
              </RHFTextField>

              {states && <RHFTextField
                name="states"
                label="State"
                sx={{ width: "50%" }}
                select
                value={selectedState}
                onChange={handleStateChange}
              >
                {states?.map((state, index) => (
                  <MenuItem key={index} value={state.id}>
                    {state.name}
                  </MenuItem>
                ))}
              </RHFTextField>}

              {boards && <RHFTextField
                name="boards"
                label="Boards"
                sx={{ width: "50%" }}
                select
                value={selectedBoard}
                onChange={handleBoardChange}
              >
                {boards?.map((board, index) => (
                  <MenuItem key={index} value={board.id}>
                    {board.name}
                  </MenuItem>
                ))}
              </RHFTextField>}

              <RHFTextField
                name="subCategory"
                label="Subcategory"
                sx={{ width: "50%" }}
                select
                onChange={handleSubCategoryChange}
              >
                {/* //!This subcategory is working for all categories simultaneously */}

                {subCategories?.map((subcategory, index) => (
                  <MenuItem key={index} value={subcategory.id}>
                    {subcategory?.name || subcategory?.class_name}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Stack>

            <Stack >
              <FormControl>
                <InputLabel>Subject</InputLabel>
                <Select
                  error={subjectList.length == 0 && val}
                  multiple
                  label="Subject"
                  name="subject"
                  value={subjectList}
                  onChange={handleChangeSubject}
                  sx={{ width: "100%", color: "black", zIndex: 9 }}
                  MenuProps={MenuProps}
                >
                  {subject?.map((name, index) => (
                    <MenuItem
                      key={index}
                      value={name.title}
                      style={getStyles(name, subject, theme)}
                    >
                      {name.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {subjectList.length == 0 && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    marginTop: "5px",
                    marginLeft: "10px",
                    color: "red",
                  }}
                >
                  {msg}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="duration"
                label="Duration (in Min)"
                type="number"
              />
              {/* <RHFTextField name="NoOfQues" label="No. Of Questions" /> */}
            </Stack>
            <RHFTextField name="description" label="Description" />
            <RHFTextField
              name="file"
              error={!file && val}
              onChange={changeHandler}
              onFocus={() => setFocusedThree(true)}
              onBlur={() => setFocusedThree(false)}
              // inputProps={{ accept: "application/pdf" }}
              // helperText="Pdf Files Only"
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
              }}
              label="Banner"
              onClick={() => setShowBanner(true)}
              type={showBanner || focusThree ? "file" : "text"}
            />
            <RHFTextField
              name="language"
              label="language"
              sx={{ width: "100%" }}
              select
            >
              {languages?.map((language, index) => (
                <MenuItem key={index} value={language?.medium_code}>
                  {language?.medium_name}
                </MenuItem>
              ))}
            </RHFTextField>
            <RHFTextField name="resultType" label="Result Type" select>
              <MenuItem value={true}>Manual Result</MenuItem>
              <MenuItem value={false}>Autogenerate Result</MenuItem>
            </RHFTextField>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="isNegative"
                label="Negative Marking"
                sx={{ width: "100%" }}
                select
              >
                <MenuItem value={true} onClick={() => setShowNegative(true)}>
                  Yes
                </MenuItem>
                <MenuItem value={false} onClick={() => setShowNegative(false)}>
                  No
                </MenuItem>
              </RHFTextField>
              <RHFTextField name="isActive" label="Status" select>
                <MenuItem value={true}>publish</MenuItem>
                <MenuItem value={false}>Draft</MenuItem>
              </RHFTextField>
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="eachQueMarks"
                label="Each Question Marks"
                sx={{ width: "100%" }}
                type="number"
              />

              {showNegative && (
                <RHFTextField
                  name="negativeMarks"
                  label="Negative Marking"
                  sx={{ width: "100%" }}
                  type="number"
                />
              )}
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}
