// import "./App.css";
import io from "socket.io-client";
import { useState } from "react";
import Chat from "./Chat";
import socket from "../batch/sockets";



function Messaging({ use }) {
    const [username, setUsername] = useState("");
    const [room, setRoom] = useState("");

    // let socket = io.connect("https://upsc.trando.in", { transports: ['websocket'] });

    let counter = 1;
    return (
        <div className="App" key={counter++}>
            <Chat key={counter++} socket={socket} username={username} room={room} use={use} />
        </div>
    );
}

export default Messaging;