import * as Yup from "yup";
import { useState, useEffect } from "react";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// @mui
import { Stack, Typography, MenuItem, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// ------------------------------------------------------------------
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function AddCoupon() {
  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(false);
  const [starting, setStarting] = useState(dayjs());
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("couponCode required"),
    description: Yup.string().required("couponCode required"),
    type: Yup.string().required("couponCode required"),

  });
  const defaultValues = {
    title: "",
    course: "",
    testseries: "",
    description: "",
    type: "",
    is_active: "true"
  };
  const handleChange = (newValue) => {
    setStarting(newValue);
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  // getting user data
  const [batches, setBatches] = useState([]);
  const [tests, setTests] = useState([]);
  const [studentsIds, setStudentsIds] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    /*async function fetchStudents() {
      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      const responseJson = await fullResponse.json();
      //console.log(responseJson.data);
      const extractids = responseJson.data?.map((item) => item.id);
      // const extractids = ['63d18abf0e7f5bc9451bff0c','63d4fbdb166ac3c884807b78'];
      setStudentsIds(extractids);
      //console.log(extractids);

    }*/

    async function fetchBatches() {
      const batchResponce = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-courses`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      //     //console.log(batchResponce);
      const batchesJson = await batchResponce.json();
      const batchesData = batchesJson.data;
      const filteredBatches = batchesData.filter((batch) => batch?.students?.length > 0);
      //     // console.log(filteredBatches);
      // console.log(batchesData);
      setBatches(filteredBatches);
    }

    async function fetchTests() {
      const testResponce = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-testseries`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      //     //console.log(testResponce);
      const testsJson = await testResponce.json();
      const testsData = testsJson.data;
      const filteredTests = testsData.filter((test) => test?.student?.length > 0);
      //     // console.log(filteredTests);
      //     // setTests(testsJson.data);
      setTests(filteredTests);

    }

    //fetchStudents();
    fetchBatches();
    fetchTests();
    setLoading(false);

  }, []);

  console.log("batches", batches);
  console.log("tests", tests);
  console.log("studentsIds", studentsIds);


  const [userDataa, setUserDataa] = useState([]);
  const onSubmit = async (data) => {
    const token = localStorage.getItem("token");
    // console.log(data);
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    async function fun1(data) {
      let updatedUserDataa = [];
      // debugger;
      if (data.user_type === "all") {
        //console.log("all");
        updatedUserDataa = studentsIds;
        //console.log('users data', updatedUserDataa);
      } else if (data.user_type === "test") {
        //console.log("batch");
        tests.forEach((test) => {
          console.log(test)
          if (test._id === data.user_type1) {
            //console.log('condition matches');
            //console.log(test.students);
            updatedUserDataa = test.student;
          }
        });
      } else {
        //console.log("test");
        batches.forEach((test) => {
          if (test._id === data.user_type1) {
            //console.log('condition matches');
            //console.log(test.student);
            updatedUserDataa = test.student;
          }
        });
      }
      return updatedUserDataa;
    }

    try {
      const updatedUserDataa = await fun1(data);
      // console.log(updatedUserDataa);
      const reqObject = {
        title: data.title,
        course: data.user_type == 'batch' ? selectedId : null,
        testseries: data.user_type == "test" ? selectedId : null,
        description: data.description,
        is_active: data.is_active,
        alert_type: data.type
      };
      //console.log(reqObject);

      setLoading(true);
      //console.log("Api call start");
      const response = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-alert`, reqObject, config);
      console.log(response);
      toast.success(response.data.message);
      setVal(false);
      setMsg("Alert Added");
      setLoading(false);
      reset();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      toast.error(error.message);
      console.log(error);
      setLoading(false);
    }
  };


  const [user1, setUser1] = useState('');
  //console.log(starting.$d);
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Alert </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="title" label="Title" />
              <RHFTextField name="type" label="Alert Type" select>
                <MenuItem value="warning">Warning</MenuItem>
                <MenuItem value="emergency">Emergency</MenuItem>
                <MenuItem value="information">Information</MenuItem>
              </RHFTextField>
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="description" label="Description" />
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="user_type" label="User Type" select>
                <MenuItem
                  value="all"
                  onClick={() => {
                    setUser1("all");
                  }}
                >
                  All
                </MenuItem>
                <MenuItem
                  value="batch"
                  onClick={() => {
                    setUser1("batch");
                  }}
                >
                  Courses
                </MenuItem>
                <MenuItem
                  value="test"
                  onClick={() => {
                    setUser1("test");
                  }}
                >
                  Test Series
                </MenuItem>
              </RHFTextField>
              <RHFTextField name="is_active" label="Status" select>
                <MenuItem value={"true"}>Publish</MenuItem>
                <MenuItem value={"false"}>Draft</MenuItem>
              </RHFTextField>
            </Stack>
            <Stack direction="row" spacing={3}>
              {user1 == "batch" ? (
                <RHFTextField name="course" label="Course" select onChange={(e) => setSelectedId(e.target.value)} value={selectedId}>
                  {batches?.map((batch) => {
                    return (
                      <MenuItem value={batch.id}>{batch.name}</MenuItem>
                    );
                  })}
                </RHFTextField>
              ) : user1 == "test" ? (
                <RHFTextField name="testseries" label="Test Series" select onChange={(e) => setSelectedId(e.target.value)} value={selectedId}>
                  {tests?.map((test) => {
                    return (
                      <MenuItem value={test.id} >
                        {test.name}
                      </MenuItem>
                    );
                  })}
                </RHFTextField>
              ) : (
                <></>
              )}
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add Announcements
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}