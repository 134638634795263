import { Box, Card, CardMedia, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
// import './batchDetailsCard.css'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import { useState } from 'react';
import { useEffect } from 'react';
const TestSeriesDetailsCard = ({ testSeriesId, setLoading }) => {
    // //console.log(testSeriesId)
    const iconStyle = {
      background: "#e6e6ff",
      padding: "5px",
      color: "black",
      borderRadius: "50%",
      cursor: "pointer",
    };
    const [sub, setSub] = useState(0)
    const [tea, setTea] = useState(0)
    const [demo, setDemo] = useState(0)
    const scrollSubject = {
        transition: 'all 0.3s'
        , translate: `${sub}px`

    }
    const scrollTeacher = {
        transition: 'all 0.3s'
        , translate: `${tea}px`

    }
    const scrollDemo = {
        transition: 'all 0.3s'
        , translate: `${demo}px`

    }


    //Getting Batch Details
    const [testSeries, settestSeries] = useState([])
    const [link, setLink] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchtestSeriesDetails() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-testseries/${testSeriesId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            const responseJson = await fullResponse.json();
            console.log(responseJson?.data)
            settestSeries(responseJson?.data);

            setLink(responseJson?.data?.banner_url)
        }
        fetchtestSeriesDetails()
    }, [])

    // //console.log(testSeries)

    return (
      <Card sx={{ minHeight: "40%" }}>
        <Stack direction="column" sx={{ minHeight: "40vh" }}>
          <CardMedia
            component="img"
            height="160"
            width="100%"
            image={link}
            alt="Banner"
          />
          {/* <Box sx={{ width: '100%', minHeight: '160px', background: '#FFC8C8' }}>
                    <img src={link} sx={{ objectFit: 'contain' }} />
                </Box> */}
          <Typography
            color=" #8080ff
"
            m={1}
            sx={{ fontWeight: "600", textAlign: "center" }}
          >
            {testSeries?.name}
          </Typography>
          <Divider />
          <Typography
            color="#727070"
            m={1}
            ml={2}
            mr={2}
            sx={{ fontWeight: "400", fontSize: "15px" }}
            dangerouslySetInnerHTML={{
              __html: testSeries?.description,
            }}
          />

          {testSeries?.remark != "" && (
            <Typography
              color="#727070"
              m={1}
              mt={0}
              ml={2}
              mr={2}
              sx={{ fontWeight: "400", fontSize: "15px" }}
            >
              <span style={{ color: "#4A4A4A", fontWeight: "600" }}>
                Remark-
              </span>
              {testSeries?.remark}
            </Typography>
          )}
          <Divider />
          <Stack
            direction="row"
            justifyContent="space-between"
            m={1}
            ml={2}
            mr={2}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* <Typography p={1} sx={{ borderRadius: '44px', background: '#FFC8C8', fontSize: '10px' }}>L</Typography> */}
              <TranslateIcon
                fontSize="medium"
                sx={{
                  borderRadius: "50%",
                  background: "#e6e6ff",
                  color: "black",
                  padding: "4px",
                }}
              />
              <Typography fontSize="small" fontWeight="700">
                {testSeries?.language == "en"
                  ? "English"
                  : testSeries?.language == "hi"
                  ? "हिंदी"
                  : "हिंदी/English"}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <div
                style={{
                  borderRadius: "50%",
                  background: "#e6e6ff",
                  color: "black",
                  fontSize: "10px",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                S
              </div>
              <Typography fontSize="small" fontWeight="700">
                {testSeries?.stream}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <div
                style={{
                  borderRadius: "50%",
                  background: "#e6e6ff",
                  color: "black",
                  fontSize: "10px",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                ET
              </div>
              <Typography fontSize="small" fontWeight="700">
                {testSeries?.exam_type}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <div
                style={{
                  borderRadius: "50%",
                  background: "#e6e6ff",
                  color: "black",
                  fontSize: "10px",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                V
              </div>
              <Typography fontSize="small" fontWeight="700">
                {" "}
                {testSeries?.Validity || "NA"} Day Validity
              </Typography>
            </Stack>
          </Stack>
          {/* <Divider /> */}
          {/* <Typography
            color=" #8080ff"
            mt={1}
            sx={{ fontWeight: "500", textAlign: "center" }}
          >
            Teachers
          </Typography>
          <Stack direction="row" ml={2} mr={2} justifyContent="space-between">
            <ArrowBackIosOutlinedIcon
              fontSize="small"
              sx={{ ...iconStyle }}
              onClick={() => setTea(tea + 50)}
            />
            <Stack
              direction="row"
              spacing={2}
              ml={1}
              mr={1}
              className="TeacherScroll"
              sx={{ flex: 3 }}
            >
              {Array.isArray(testSeries?.admin) && testSeries?.admin?.map((item, index) => (
                <Typography
                  key={index}
                  mb={1}
                  sx={{ fontSize: "14px", ...scrollTeacher }}
                >
                  {item?.FullName}
                </Typography>
              ))}
            </Stack>
            <ArrowForwardIosOutlinedIcon
              fontSize="small"
              sx={{ ...iconStyle }}
              onClick={() => setTea(tea - 50)}
            />
          </Stack>
          <Divider /> */}

          <Divider />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={1}
            ml={2}
            mr={2}
          >
            <Typography
              color="#4A4A4A"
              sx={{ fontWeight: "600", fontSize: "20px" }}
            >
              Test Series Amount
            </Typography>
            <Stack alignItems="flex-end">
              <Typography
                color=" #8080ff"
                sx={{ fontWeight: "700", fontSize: "16px" }}
              >
                ₹{testSeries?.charges}
              </Typography>
              <Typography
                color="#4A4A4A"
                sx={{ fontWeight: "600", fontSize: "8px", marginTop: "-5px" }}
              >
                GST+{testSeries?.discount}% Discount
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    );
}

export default TestSeriesDetailsCard