import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@emotion/react';
import { Divider } from '@mui/material';
import AddSubscription from './AddSubscriptions';
import SubscriptionTable from './SubscriptionTable';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const Subscriptions = () => {
    const access = JSON.parse(localStorage.getItem('detailObject')) || {};
    const role = localStorage.getItem('role') || '';
    const accessToContent = access?.accessToContent || [];
    const [value, setValue] = React.useState(() => {
        if (role == 'Admin') return 0;
        if (accessToContent?.includes("All Subscriptions")) return 0;
        if (accessToContent?.includes("Add Subscription")) return 1;
        return 0;
    });


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant='h3' mb={3} >Manage Subscriptions</Typography>
            <Divider />
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ width: '100%', }}>
                    {(role === 'Admin' || accessToContent.includes('All Subscriptions')) && (
                        <Tab value={0} label="All Subscriptions" sx={{ width: '100%' }} {...a11yProps(0)} />
                    )}
                    {(role === 'Admin' || accessToContent.includes('Add Subscription')) && (
                        <Tab value={1} label="Add Subscriptions" sx={{ width: '100%' }} {...a11yProps(1)} />
                    )}
                    {/* <Tab label="Item Three" sx={{ width: '100%' }} {...a11yProps(2)} /> */}
                </Tabs>
            </Box>
            {(role === 'Admin' || accessToContent.includes('All Subscriptions')) && (
                <TabPanel value={value} index={0}>
                    <SubscriptionTable />
                </TabPanel>
            )}
            {(role === 'Admin' || accessToContent.includes('Add Subscription')) && (
                <TabPanel value={value} index={1}>
                    <AddSubscription />
                </TabPanel>
            )}

        </Box >
    );
}

export default Subscriptions