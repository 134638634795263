import { createContext, useState } from "react";

export const User = createContext()
export const UserContext = ({ children }) => {
    const [role, setRole] = useState(false);
    const [username, setUsername] = useState('');
    const [form, setForm] = useState();
    const [percentage, setDownloadPercentage] = useState(0);
    const [showBackgroundPercentage, setShowBackgroundPercentage] = useState(false);
    //console.log('Context' + percentage)

    return (
        <User.Provider value={{ role, setRole, username, setUsername, form, setForm, percentage, setDownloadPercentage, showBackgroundPercentage, setShowBackgroundPercentage }}>
            {children}
        </User.Provider>
    )
}

