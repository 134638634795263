import React from 'react'
import * as Yup from 'yup';
import { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography, TextField, MenuItem, Select, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { User } from '../../../context/context'
import Page from '../../../components/Page';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,

        },
    },
};


function getStyles(name, teacher, theme) {
    return {
        fontWeight:
            teacher.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const UpdateQuizQuestions = () => {
    const navigate = useNavigate();
    // const { setRole } = useContext(User)
    const { quizId } = useParams()
    const [category, setCategory] = React.useState('');
    const [type, setType] = React.useState('');
    const [testSeriesDetails, setTestSeriesDetails] = React.useState([]);
    const [loading, setLoading] = useState();
    const [streamCategory, setStreamCategory] = useState([])
    const theme = useTheme();


    const LoginSchema = Yup.object().shape({


    });

    const defaultValues = {


    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });


    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;




    // React.useEffect(() => {
    //     const token = localStorage.getItem("token");
    //     async function fetchUsers() {
    //         const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getQuizDetails/${quizId}`, {
    //             // const fullResponse = await fetch(`http://localhost:5000/api/v1/adminPanel/getQuizDetails/${quizId}`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${token}`
    //             },

    //         });
    //         const responseJson = await fullResponse.json();
    //         setTestSeriesDetails(responseJson?.data);

    //         addDetails(responseJson?.data)
    //     }

    //     fetchUsers();
    // }, []);
    //console.log(testSeriesDetails)

    //Helper Function 

    const addDetails = (TestSeriesDetails) => {
        let arr2 = []
        TestSeriesDetails?.teacher?.map((item) => { arr2.push(item?._id) })
        // setteacher(arr2)
        setValue(prev => ({
            ...prev,
            quiz_title: TestSeriesDetails?.quiz_title,
            quiz_banner: TestSeriesDetails?.quiz_banner[0],
            isActive: TestSeriesDetails?.isActive,
            quiz_duration: TestSeriesDetails?.quiz_duration,
            quiz_desc: TestSeriesDetails?.quiz_desc,
            no_ques: TestSeriesDetails?.no_ques,
            isActive: TestSeriesDetails?.isActive,
            is_negative: TestSeriesDetails?.is_negative,

        }))
    }


    const [val, setVal] = useState(false)


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        // setteacher(
        //     // On autofill we get a stringified value.
        //     typeof value === 'string' ? value.split(',') : value,
        // );
    };







    //Banner
    const [file, setFile] = useState()
    const [msg, setMsg] = useState('')
    const [focusThree, setFocusedThree] = React.useState(false);
    const [showBanner, setShowBanner] = React.useState(false);
    const changeHandler = async (event) => {
        setFile(event.target?.files[0])
    };


    //Adding Details
    const onSubmit = async (data) => {


        setLoading(true)

        const formData = new FormData();



        if (file) {
            formData.append('file', file);
        } else {
            formData.append('file', value?.quiz_banner);

        }




        formData.append('title', value?.quiz_title);
        formData.append('description', value?.quiz_desc);
        formData.append('duration', value?.quiz_duration);
        formData.append('NoOfQues', '10');
        formData.append('isActive', true);
        formData.append('isNegative', value?.is_negative);







        let token = localStorage.getItem("token");

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        };
        // axios.put(`/adminPanel/editQuiz/${quizId}`, formData, config).then((response) => {
        axios.put(`http://localhost:5000/api/v1/adminPanel/editQuiz/${quizId}`, formData, config).then((response) => {

            toast.success("Test Series Details Added")
            setShowBanner(false)


            if (response?.data?.msg === 'Quiz Updated Successfully') {
                setLoading(false)
                setTimeout(() => {

                    navigate('/dashboard/quiz')

                }, 1000)
                reset()
            }
        })
            .catch(e => {
                //console.log(e);
            })


    }
    //console.log(testSeriesDetails?.testseries_name)
    const [value, setValue] = React.useState({
        quiz_title: '', quiz_duration: '', isActive: '', no_ques: '', is_negative: '', quiz_desc: ''
    })
    const [value2, setValue2] = React.useState('jhbj')
    const onChange2 = (e) => {
        const { name, value } = e.target;
        setValue(prev => ({ ...prev, [name]: value }))

    }
    // onChange2()
    //console.log(value)
    return (
        <>
            <Page quiz_title="Quiz Questions">
                <Stack spacing={3} ml={5}>
                    <Typography variant="h3">Update Quiz Questions</Typography>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={3} sx={{ width: "80%" }}>
                            <RHFTextField
                                name="question"
                                label="Quiz Question Title"
                                onChange={onChange2}
                            />
                            <Stack direction="row" spacing={3}>
                                <RHFTextField name="option1" label="Option 1" />
                                <RHFTextField name="option2" label="Option 2" />
                            </Stack>
                            <Stack direction="row" spacing={3}>
                                <RHFTextField name="option3" label="Option 3" />
                                <RHFTextField name="option4" label="Option 4" />
                            </Stack>

                            <RHFTextField name="correctAns" label="Correct Option" select>
                                <MenuItem value="a">A</MenuItem>
                                <MenuItem value="b">B</MenuItem>
                                <MenuItem value="c">C</MenuItem>
                                <MenuItem value="d">D</MenuItem>
                            </RHFTextField>
                            <RHFTextField name="answer" label="Answer" />
                        </Stack>

                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={loading}
                            sx={{
                                bgcolor: "#e6e6ff",
                                color: "black",
                                width: "80%",
                                marginTop: "5%",
                                "&:hover": { background: " #8080ff" },
                            }}
                        >
                            Update
                        </LoadingButton>
                    </FormProvider>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={1000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    {/* <Typography sx={{ color: 'red', textAlign: 'center' }}>{fetchMessage}</Typography> */}
                </Stack>
            </Page>
        </>
    );
}

export default UpdateQuizQuestions