import React, { useEffect, useState, useTransition } from "react";
import "katex/dist/katex.min.css";
// import { MathJax, MathJaxContext } from 'react-mathjax';
import { MathJax } from "better-react-mathjax";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { RotateLoader } from "react-spinners";
import { useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import CategoryComponent from "../../../utils/CategoryComponent";

export default function AddQuizQuestionsNew() {
  // const config = {
  //   tex: {
  //     inlineMath: [["$", "$"]],
  //   },
  // };
  // streamId, stateId, boardId, stateExamId, centralExamId, classId
  const { quizId } = useParams();
  const [searchparams, setSearchParams] = useSearchParams();
  const testseriesId = searchparams.get("testseriesId");
  const subjectName = searchparams.get("subName");
  const subjectId = searchparams.get("subId");
  const templateId = searchparams.get("template_id");

  const [questionsList, setQuiestionsList] = useState();
  const [selectedChapter, setSelectedChapter] = useState();
  const [chapterList, setChapterList] = useState();

  const [loading, setLoading] = useState();
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [staticData, setStaticData] = useState();
  const [addLoading, setAddLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
   const [paginationModel, setPaginationModel] = useState({
     page: 0,
     pageSize: 10,
   });
  
  const [exam_id, setExam_id] = useState("");
  const [course, setCourse] = useState("");





  // const [paginationModel, setPaginationModel] = useState({
  //   page: 0,
  //   pageSize: 10,
  // });
  // const handleRowSelectionChange = (newRowModel) => {
  //   const startRow = paginationModel.page * paginationModel.pageSize;
  //   const endRow = startRow + paginationModel.pageSize;

  //   const currentPageRows = rows.slice(startRow, endRow).map((row) => row.id);
  //   const newSelectedQuestions = newRowModel;

  //   const allSelectedOnCurrentPage = currentPageRows.every((rowId) =>
  //     newSelectedQuestions.includes(rowId)
  //   );

  //   if (allSelectedOnCurrentPage) {
  //     setSelectedQuestions((prevSelected) => {
  //       const filteredSelection = prevSelected.filter(
  //         (id) => !currentPageRows.includes(id)
  //       );
  //       return [...new Set([...filteredSelection, ...newSelectedQuestions])];
  //     });
  //   } else {
  //     setSelectedQuestions((prevSelected) => {
  //       const updatedSelection = prevSelected.filter(
  //         (id) => !currentPageRows.includes(id)
  //       );
  //       return [...updatedSelection, ...newSelectedQuestions];
  //     });
  //   }
  // };

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function f() {
      const res = await axios.get(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-testseries/${testseriesId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("testseries Data ", res);
      setStaticData(res?.data?.data);
      setExam_id(res?.data?.data.exam_id);
      setCourse(res?.data?.data.course);




    }
    f();

    return () => {};
  }, [testseriesId]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function f() {
      const res = await axios.get(
`${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-chapter-by-subject-id/${subjectId}?exam_id=${exam_id}&course=${course}`,


        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("tChapters Data ", res?.data?.data[0]?.chapter_id);
      setChapterList(res?.data?.data);
      setSelectedChapter(res?.data?.data[0]?.chapter_id);
      const res2 = await axios.post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-bulk-quiz-question`,

        {
          selected_subject_id: subjectId,
          chapter_id: res?.data?.data[0]?.chapter_id,
          test_id: quizId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuiestionsList(res2?.data?.data);
      setLoading(false);
    }
    f();

    return () => {};
  }, [subjectId,exam_id]);

  // console.log("selectMenus",selectMenus)

  const columns = [
    {
      field: "question_title",
      headerName: "Questions",
      renderCell: ({ row }) => {
        // console.log("this is my row", row.row);

        const { options } = row?.row;
        let decodedContent;
        try {
          decodedContent = decodeURIComponent(row?.question_title);
        } catch (error) {
          // Handle the decoding error here
          // You can set a default value or handle the error in another way if needed
          decodedContent = "Unable to decode content";
        }
        return (
          <Stack
            // margin={"1rem"}
            fontSize={"0.9rem"}
            spacing={3}
            style={{
              width: "65vw",
            }}
          >
            <Stack spacing={1}>
              <MathJax
                style={{
                  display: "flex",
                  padding: "10 0 10 0",
                  // alignContent: "center",
                  justifyItems: "center",
                }}
              >
                <p
                  className="text-lg"
                  style={{
                    display: "inline",
                    paddingRight: "1rem",
                  }}
                  dangerouslySetInnerHTML={{ __html: row?.question_title }}
                ></p>
              </MathJax>
            </Stack>

            {/*
              <MathJax>
                <Stack className="Options" direction={"column"} spacing={3}>
                  <Stack spacing={1}>
                    <strong>Options:</strong>
                    <Stack direction={"row"} spacing={5}>
                      <div>
                        a) {options[0]?.option || options[0]?.option_value}
                      </div>
                      <div>
                        b) {options[1]?.option || options[1]?.option_value}
                      </div>
                    </Stack>
                    <Stack direction={"row"} spacing={5}>
                      <div>
                        c) {options[2]?.option || options[2]?.option_value}
                      </div>
                      <div>
                        d) {options[3]?.option || options[3]?.option_value}
                      </div>
                    </Stack>
                  </Stack>

                  <Stack className="correct Option">
                    <Stack spacing={1}>
                      <strong>Correct Option:</strong>
                      {options?.map((opt, i) => {
                        if (opt.is_correct === 1)
                          return (
                            <div>
                              {String.fromCharCode(i + 97)}) {opt.option}
                            </div>
                          );
                      })}
                    </Stack>
                  </Stack>
                </Stack>
              </MathJax>
           */}
          </Stack>
        );
      },
      renderHeader: () => (
        <Stack
          direction={"row"}
          width={"70vw"}
          justifyItems={"center"}
          justifyContent={"center"}
        >
          <h1 style={{ fontSize: "18px" }}>Questions List</h1>
          <Button
            variant="contained"
            disabled={addLoading}
            size="small"
            style={{
              width: "10%",
              margin: "auto 0 auto auto",
              height: "fit-content",
            }}
            onClick={(e) => {
              if (addLoading) return; //agar questions add ho rahe h to disable krdo isko and loader laga do

              e.preventDefault();
              e.stopPropagation(); // this is done so that on clicking the add button the parent component which is header doesn't gets clicked other wise it will change the order of the questions, you may comment above 2 lines to check what will happen.
              addSelectedQuestions();
            }}
          >
            {addLoading ? <CircularProgress size={"2rem"} /> : "Add and Close"}
          </Button>

          <Button
            variant="contained"
            //color="secondary"
            disabled={addLoading}
            size="small"
            style={{
              width: "12%",
              margin: "auto 0 auto 1rem",
              height: "fit-content",
            }}
            onClick={(e) => {
              if (addLoading) return;

              e.preventDefault();
              e.stopPropagation();

              addSelectedQuestions(false); // Disable redirection in this case
            }}
          >
            {addLoading ? (
              <CircularProgress size={"2rem"} />
            ) : (
              "Add and Continue"
            )}
          </Button>
        </Stack>
      ),
      width: "auto",
    },
  ];

  const rows = questionsList?.map((row) => {
    // Create the row object with the desired properties
    const rowObj = {
      id: row?.question_id,
      question_title: row?.question_title,
      row,
    };
    return rowObj;
  });

  const handleSearch = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!subjectId || !selectedChapter) return;
    setLoading(true);

    const res = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-bulk-quiz-question`,
      {
        selected_subject_id: subjectId,
        chapter_id: selectedChapter,
        test_id: quizId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("bulk quiz questions", res);
    setQuiestionsList(res?.data?.data);
    setLoading(false);
  }, [selectedChapter, subjectId]);

  const addSelectedQuestions = async (shouldRedirect = true) => {
    if (!Array.isArray(selectedQuestions) || selectedQuestions.length === 0) {
      toast.warning("Please select questions to add!!");
      return;
    }
    setAddLoading(true);

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-test/${quizId}`,
        {
          question_id: selectedQuestions,
          testseries_id: testseriesId,
          subject_id: subjectId,
          subject_template_id: templateId,
        },
        config
      );

      setAddLoading(false);

      if (res?.data?.response_code === 200) {
        setSelectedRows([]); // Clear the selected rows
        setSelectedQuestions([]); // Clear the global selected questions state
        toast.success(res?.data?.message);

        if (shouldRedirect) {
          setTimeout(() => {
            window.history.back();
          }, 700);
        }
      } else {
        toast.error(res?.data?.data?.message);
      }
    } catch (error) {
      console.error("Error updating test:", error);
      toast.error("Failed to update test. Please try again.");
    }
  };







  const handleRowSelectionChange = (newSelection) => {
    const visibleRows = rows.slice(
      paginationModel.page * paginationModel.pageSize,
      (paginationModel.page + 1) * paginationModel.pageSize
    );
    const visibleRowIds = visibleRows.map((row) => row.id);

    // Filter selected rows only for the current page
    const newSelectedRows = newSelection.filter((id) =>
      visibleRowIds.includes(id)
    );

    // Update the state with selections from the current page only
    setSelectedRows(newSelectedRows);

    // Combine the selections from previous pages with the new selections for the current page
    const updatedSelectedQuestions = [
      ...selectedQuestions.filter((id) => !visibleRowIds.includes(id)), // Keep selections from other pages
      ...newSelectedRows, // Add selections from the current page
    ];

    setSelectedQuestions(updatedSelectedQuestions); // Update the global selected questions state
  };



  return (
    <div>
      <Stack>
        <h1>
          Select Questions to Add:{" "}
          <span style={{ fontSize: "1rem", color: "blue" }}>
            Selected Questions: {selectedQuestions.length}
          </span>
        </h1>

        <Stack className="search_component" marginY={"2rem"} direction={"row"}>
          <Stack direction={"row"} width={"80%"} spacing={3}>
            {/* <CategoryComponent 
                  selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}
                  classVal={classVal} setClass={setClass}
                  centralExam={centralExam} setCentralExam={setCentralExam}
                  stateExam={stateExam} setStateExam={setStateExam}
                  selectedState={selectedState} setSelectedState={setSelectedState}
                  selectedBoard={selectedBoard} setSelectedBoard={setSelectedBoard}
                  defaultDibled={true}
                   /> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {staticData?.course}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={examCategory}
                label={staticData?.course}
                disabled
              ></Select>
            </FormControl>
            {(staticData?.boards_name || staticData?.state_name) && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {staticData?.boards_name || staticData?.state_name}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={examCategory}
                  label={staticData?.boards_name || staticData?.state_name}
                  disabled
                ></Select>
              </FormControl>
            )}
            <FormControl fullWidth>
              <InputLabel id="exam_name">
                {staticData?.exam_name || staticData?.classes_name}
              </InputLabel>
              <Select
                labelId="exam_name"
                id="exam_name_select"
                // value={examName}
                disabled
                label={staticData?.exam_name || staticData?.classes_name}
              ></Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="subject">{subjectName}</InputLabel>
              <Select
                labelId="subject"
                id="subject_select"
                label={subjectName}
                disabled
              ></Select>
            </FormControl>
            <FormControl fullWidth>
              {chapterList && (
                <Autocomplete
                  // disablePortal
                  id="chapter-list"
                  options={chapterList}
                  defaultValue={chapterList[0] || null}
                  getOptionLabel={(option) =>
                    `${chapterList.indexOf(option) + 1}. ${option.name} - (${
                      option.count || 0
                    })`
                  }
                  onChange={(e, val) => setSelectedChapter(val?.chapter_id)}
                  // sx={{ width: 600 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Chapter" />
                  )}
                />
              )}
            </FormControl>
          </Stack>
          <Button
            onClick={handleSearch}
            disabled={loading}
            variant={"contained"}
            style={{ margin: "0 1rem 0 auto", width: "15%", fontSize: "1rem" }}
          >
            Search
          </Button>
        </Stack>

        {rows && columns && !loading ? (
          rows?.length > 0 ? (
            // <MathJaxContext config={config}>
            <DataGrid
              rows={rows}
              getRowHeight={() => "auto"}
              disableRowSelectionOnClick
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, 30, 50, 100]}
              checkboxSelection
              paginationModel={paginationModel}
              onPaginationModelChange={(newPaginationModel) =>
                setPaginationModel(newPaginationModel)
              }
              onRowSelectionModelChange={handleRowSelectionChange}
              rowSelectionModel={selectedRows}
            />
          ) : (
            <h2>No Questions Available</h2>
          )
        ) : (
          <>
            <div
              style={{
                display: "flex",
                marginTop: "6rem",
                justifyContent: "center",
                width: "auto",
              }}
            >
              <RotateLoader color="#2065D1" />
            </div>
          </>
        )}
      </Stack>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
