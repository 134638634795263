import React, { useEffect } from "react";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Autocomplete,
  Paper,
  Chip,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import DeleteIcon from "@mui/icons-material/Delete";


const UpdateTest = () => {
  const navigate = useNavigate();
  const [type, setType] = React.useState("");
  const [val, setVal] = useState(false);

  const [focus, setFocused] = React.useState(false);
  const [resources, setResources] = React.useState(false);
  const [users, usersSet] = React.useState([]);
  const { testId, testSeriesId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fetchMessage, setFetchMessage] = useState();
  const [starting, setStarting] = React.useState(dayjs("2022-01-01T21:11:54"));
  const [ending, setEnding] = React.useState(dayjs("2022-01-01T21:11:54"));
  const [showNegative, setShowNegative] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [nagetiveMarks, setNagetiveMarks] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selSubject, setSelSubject] = useState([]);



  // const handleChange = (newValue) => {
  //   setStarting(newValue);
  // };
  const handleChangeTwo = (newValue) => {
    setEnding(newValue);
  };




   const handleAdd = () => {
     setSubjects([
       ...subjects,
       {
         id: Date.now(),
         name: "",
         subject_id: [],
         chapter_id: [],
         chapterOptions: [],
       },
     ]);
   };

   const handleDelete = (id) => {
     setSubjects(subjects.filter((subject) => subject.id !== id));
   };

   const handleChange = (id, key, value) => {
     setSubjects(
       subjects.map((subject) =>
         subject.id === id ? { ...subject, [key]: value } : subject
       )
     );
     if (key === "subject_id") {
       setSelSubject(value);
     }
   };

  const LoginSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [file, setFile] = useState();
  //Date

  const [showStartingDate, setShowStartingDate] = React.useState(false);

  //Getting Existing details
  const [lectureDetails, setLectureDetails] = useState();
  const [value, setValue] = useState({})
    ;
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-test-by-id/${testId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      if (responseJson?.response_code !== 200) {
        toast.error(responseJson?.message + " Please return back");
        return;
      }
      console.log("test details ", responseJson);
      setLectureDetails(responseJson?.data);
     // setInstructions(responseJson?.data?.test?.instructions);
      setValue((prev) => ({
        ...prev,
        title: responseJson?.data?.test?.title,
        duration: responseJson?.data?.test?.duration,
        instructions: responseJson?.data?.test?.instructions,
        questions: responseJson?.data?.test?.questions,
        // No_of_question: responseJson?.data?.test?.No_of_questions,
        nagetiveMarking: responseJson?.data?.test?.nagetiveMarking,
        nagetiveMarks: responseJson?.data?.test?.nagetiveMarks,
        starting_date: responseJson?.data?.test?.starting_date,
        eachQuestionMarks: responseJson?.data?.test?.eachQuestionMarks,
      }));
      setNagetiveMarks(responseJson?.data?.test?.nagetiveMarks);
      setStarting(dayjs(responseJson?.data?.test?.starting_date));
      // setsubjectList(
      //   responseJson?.data?.test?.subject_id?.map((sub) => sub?.id)

         setShowNegative(responseJson?.data?.test?.nagetiveMarking=="1"||responseJson?.data?.test?.nagetiveMarking==true);
      // );
      console.log("Negative ",responseJson?.data?.test?.nagetiveMarking)
      setSubjectList(

        responseJson?.data?.test?.subject_id?.map((sub) => {
          return {
            subject_id: sub?.id,
            subject_name: sub?.name,
            subject_image: sub?.thumb_nail_image,
          };
        })
      );

      const formattedSubjects =responseJson?.data?.test?.subjects?.map((subject) => ({
        id: subject?.id,
        subject_name: subject?.subject_name,
        subject_id: subject?.subject_id.split(",").map(Number), 
        mock_test_id: subject?.mock_test_id || null,
        
        test_id: subject?.test_id,
      }));

      setSubjects(formattedSubjects); // Set subjects array

      // console.log("test details", value)
      // setEnding(dayjs(responseJson?.data?.ending_date))
    }
    fetchUsers();
  }, []);
 

  const [testseriesData, setTestSeriesData] = useState({});
  const [subject, setSubject] = useState();
  const [selectSubject, setSelectSubject] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchTestSeries = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-testseries/${testSeriesId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fullResponse = await response.data;
      setTestSeriesData(fullResponse.data);
      console.log("Test Series Data", fullResponse.data);
    };
    fetchTestSeries();
  }, [testSeriesId]);

 useEffect(() => {
   const token = localStorage.getItem("token");

   async function fetchUsers() {
     setLoading(true); // Set loading to true at the start

     try {
       const res = await fetch(
         `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-subject-by-goal`,
         {
           method: "POST",
           headers: {
             "Content-Type": "application/json",
             Authorization: `Bearer ${token}`,
           },
           body: JSON.stringify({
             course: testseriesData?.course,
             language: testseriesData?.language,
             class_exam_id: testseriesData?.exam_id || testseriesData?.class_id,
           }),
         }
       );

       const resSubject = await res.json();
       console.log("This is subject", resSubject);

       // Process the subject data
       const filteredSubjects = resSubject?.data?.map((subject) => ({
         id: subject.subject_id,
         name: subject.subject_name,
       }));

       setSubjectList(filteredSubjects); // Update the state with the processed data
     } catch (error) {
       console.error("Error fetching subjects:", error);
     } finally {
       setLoading(false); // Set loading to false at the end, regardless of success or failure
     }
   }

   fetchUsers();
 }, [testseriesData]);


  const onSubmit = async (data) => {
    // console.log(value, data)
    const payload = {
      ...value,
      questions: Array.isArray(value?.questions) ? value?.questions : [],
      starting_date: data?.starting_date,
      nagetiveMarks: nagetiveMarks,
      nagetiveMarking: data?.nagetiveMarking,
      subject_id: [],

      subjects: subjects,
      // subject: subjectList.map(sub => sub?.id),
      //subject_id: subjectList.map((sub) => sub?.subject_id),
    };
    console.log(payload);

    setLoading(true);
    const formData = new FormData();
    if (file) {
      formData.append("questionPaper", file);
    }
    if (answerTemplate) {
      formData.append("answerTemplate", answerTemplate);
    }
    // if (data?.nagetiveMarking) { formData.append(' nagetiveMarking', data?.nagetiveMarking); }
    // else {
    //     formData.append(' nagetiveMarking', data?.nagetiveMarking);

    // }
    formData.append("nagetiveMarking", data?.nagetiveMarking);
    // formData.append('totalmarks', data?.totalMarks);
    // if (showNegative) {
      formData.append("nagetiveMarks", nagetiveMarks);
    // }

    formData.append("title", value?.title);
    formData.append("instructions", value?.instructions);
     formData.append("eachQuestionMarks", value?.eachQuestionMarks);
    formData.append("No_of_question", value?.No_of_question);
    formData.append("duration", value?.duration);
    formData.append("starting_date", value?.starting_date);
    formData.append("testseries_id", testSeriesId);

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    //console.log(value?.starting_date);
    // //console.log(formData);
    axios
      .put(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-test-by-id/${testId}`,
        payload,
        config
      )
      .then((response) => {
        console.log(response);

        if (response?.data?.response_code === 200) {
          reset();
          setLoading(false);
          toast.success("Test Details Updated");

          setTimeout(() => {
            navigate(`/dashboard/test/testSeriesDetails/${testSeriesId}`);
             window.location.reload()
          }, 1500);
        } else {
          toast.error(response?.data?.errors);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
        toast.error(e);
        setLoading(false);
      });
  };

const onChange2 = (e) => {
    const { name, value } = e?.target;
    setValue((prev) => ({ ...prev, [name]: value }));
  };

  //Banner

  // const [msg, setMsg] = useState('')
  // const [focusThree, setFocusedThree] = React.useState(false);
  // const [showBanner, setShowBanner] = React.useState(false);
  // const changeHandler = async (event) => {
  //     setFile(event.target?.files[0])
  // };

  //Answer Template
  // const [focusTwo, setFocusedTwo] = React.useState(false);
  // const [showBannerTwo, setShowBannerTwo] = React.useState(false);
  const [answerTemplate, setAnswerTemplate] = useState();
  const changeHandlerTwo = async (event) => {
    setAnswerTemplate(event.target?.files[0]);
  };

  // temp
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    console.log(date);
    setSelectedDate(date);
    //console.log(selectedDate);
  };

  return (
    <>
      {/* <IconButton onClick={()=>window.history.back()} style={{marginBottom:"1rem"}}><ArrowBackIcon fontSize='large' /></IconButton> */}
      <Grid direction="row" pl={5}>
        <Typography variant="h2" ml={2} mb={2}>
          Update Test Details
        </Typography>
        <Typography variant="h6" ml={2} mb={4}>
          Enter the below required details to update a Test
        </Typography>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          {/* <Stack direction="row" spacing={5} > */}
          <Stack spacing={3} sx={{ width: "80%" }}>
            <Stack spacing={3} direction={"row"}>
              <Stack sx={{ width: "50%" }}>
                <h4> Test title</h4>
                <RHFTextField
                  name="title"
                  value={value?.title}
                  onChange={(e) => onChange2(e)}
                />
              </Stack>

              <Stack sx={{ width: "50%" }}>
                <h4>starting date</h4>
                <RHFTextField
                  // value={value?.starting_date}
                  name="starting_date"
                  type="datetime-local"
                  // label={lectureDetails?.starting_date}
                  // onChange={handleDateChange}
                ></RHFTextField>
              </Stack>
            </Stack>

       
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
              spacing={2}
            >
              {/* <Stack direction="row" sx={{ width: "100%" }}>
                <h4>Total Marks</h4>
                <RHFTextField
                  name="totalMarks"
                  value={value?.totalMarks}
                  onChange={onChange2}
                  type="number"
                />
              </Stack> */}

              <RHFTextField
                name="nagetiveMarking"
                label="Negative Marking"
                sx={{ width: "100%" }}
                select
                value={showNegative}
              >
                <MenuItem value={true} onClick={() => setShowNegative(true)}>
                  Yes
                </MenuItem>
                <MenuItem value={false} onClick={() => setShowNegative(false)}>
                  No
                </MenuItem>
              </RHFTextField>
              {showNegative && (
                <RHFTextField
                  name="nagetiveMarks"
                  label="Negative Marks"
                  sx={{ width: "100%" }}
                  type="text"
                  onChange={(e)=>setNagetiveMarks(e.target.value)}
                  value={nagetiveMarks}
                />
              )}
           
              <Stack sx={{ width: "100%" }}>
                {/* <h4>Duration</h4> */}
                <RHFTextField
                  name="duration"
                  label="Duration"
                  value={value?.duration}
                  onChange={onChange2}
                  type="number"
                />
              </Stack>

              <RHFTextField
                name="eachQuestionMarks"
                value={value?.eachQuestionMarks}
                label="Marks for each Question"
                type="number"
                onChange={onChange2}
              />
            </Stack>

            <Stack>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Add Subjects
              </Typography>
              {subjects?.map((subject, index) => (
                <Paper
                  key={subject.id}
                  elevation={3}
                  sx={{ p: 2, mb: 2, backgroundColor: "transparent" }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                      label="subject name"
                      variant="outlined"
                      value={subject.subject_name || " "}
                      onChange={(e) =>
                        handleChange(subject.id, "subject_name", e.target.value)
                      }
                      fullWidth
                    />
                    <FormControl fullWidth>
                      <InputLabel>Subjects</InputLabel>
                      <Select
                        multiple
                        value={subject.subject_id}
                        onChange={(e) =>
                          handleChange(subject.id, "subject_id", e.target.value)
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected?.map((value, selectedIndex) => {
                              const subjectName =
                                subjectList?.find((sub) => sub.id === value)
                                  ?.name || "Unknown"; // Safe access
                              return (
                                <Chip
                                  key={value}
                                  label={`${selectedIndex + 1}. ${subjectName}`}
                                />
                              );
                            })}
                          </Box>
                        )}
                      >
                        {subjectList?.map((subjectOption, optionIndex) => (
                          <MenuItem
                            key={subjectOption.id}
                            value={subjectOption.id}
                          >
                            {`${optionIndex + 1}. ${subjectOption.name}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <IconButton
                      onClick={() => handleDelete(subject.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </Paper>
              ))}
              <Button
                variant="contained"
                size="small"
                onClick={handleAdd}
                sx={{ mt: 2, py: 0.5, px: 2, width: "10%" }}
              >
                + Add
              </Button>
            </Stack>

            <Stack direction="row" sx={{ my: 2 }} spacing={2}>
              <Stack sx={{ width: "100%" }}>
                <h4>Instruction</h4>
                <RHFTextEditor
                  name="instructions"
                  value={value?.instructions}
                  // onChange={onChange2}
                  //value={instructions}
                  // label={"Instructions"}
                  onChange={(event) => {
                    setInstructions(event);
                    setValue((prev) => ({
                      ...prev,
                      instructions: instructions,
                    }));
                    // setValue((prev) => ({ ...prev, [name]: value }));
                  }}
                />
              </Stack>
            </Stack>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            sx={{
              bgcolor: "#e6e6ff",
              color: "black",
              width: "70%",
              marginTop: "5%",
              "&:hover": { background: "#8080ff" },
            }}
          >
            Update
          </LoadingButton>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Grid>
    </>
  );
};

export default UpdateTest;
