import React, { useState } from "react";
import {
  Box,
  Container,
  Divider,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../students.css";

const CustomTable = (props) => {
  const {
    sampleAssignment,
    sampleAssignmentHeader,
    dataArray,
    dataArrayForTest,
    use,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Determine the data to display based on pagination
  const paginatedDataArray = dataArray?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // //console.log(dataArrayForTest)
  return (
    <Container>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="custom table">
          <TableHead style={{ backgroundColor: "#e6e6ff" }}>
            <TableRow>
              {sampleAssignmentHeader?.map((item, i) => (
                <TableCell
                  key={i}
                  align="center"
                  sx={{
                    fontSize: "small",
                    fontWeight: "600",
                    minWidth: "100px",
                    maxWidth: "120px",
                  }}
                >
                  {item.NO}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {use !== "Timer" &&
              (paginatedDataArray?.length > 0 ? (
                paginatedDataArray?.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "700",
                        minWidth: "100px",
                        maxWidth: "120px",
                      }}
                    >
                      {item?.title}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        minWidth: "100px",
                        maxWidth: "100px",
                      }}
                    >
                      {item?.score}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        minWidth: "100px",
                        maxWidth: "100px",
                      }}
                    >
                      {item?.correctAnswer}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        minWidth: "100px",
                        maxWidth: "100px",
                      }}
                    >
                      {item?.wrongAnswer}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        minWidth: "100px",
                        maxWidth: "100px",
                      }}
                    >
                      {item?.attemptedQuestion}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        minWidth: "100px",
                        maxWidth: "100px",
                      }}
                    >
                      {item?.created_at?.slice(0, 10)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={sampleAssignmentHeader.length}
                    align="center"
                    sx={{ height: "60px" }}
                  >
                    No Data
                  </TableCell>
                </TableRow>
              ))}
            {use === "Timer" &&
              (paginatedDataArray?.length > 0 ? (
                paginatedDataArray?.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      {i + 1}
                    </TableCell>
                    <TableCell align="center">{item?.TimerTitle}</TableCell>
                    <TableCell align="center">{item?.timerDuration}</TableCell>
                    <TableCell align="center">
                      {item?.created_at.split(" ")[0]}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center" sx={{ height: "60px" }}>
                    No Data
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataArray?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Container>
  );
};

export default CustomTable;
