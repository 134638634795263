import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  InputLabel,
  Container,
  TableContainer,
  TablePagination,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import Scrollbar from "../../../../components/Scrollbar";
import UserListHead from "../UserListHead";
import { useNavigate } from "react-router-dom";

export default function CourseTableCard({ batchInfo }) {
  const navigate = useNavigate();
  const [filteredBatchInfo, setFilteredBatchInfo] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    setFilteredBatchInfo(batchInfo);
  }, [batchInfo]);

  function countUniqueElements(arr) {
    const filteredArray = arr.filter((item) => item !== "");
    const uniqueElements = new Set(filteredArray);
    return uniqueElements.size;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - batchInfo?.length) : 0;
  //**table head */

  const TABLE_HEAD = [
    { id: "", label: "S.No", alignRight: false },
    { id: "name", label: "Name", alignRight: false },
    { id: "studentRegistered", label: "No. of Students", alignRight: false },
    { id: "startDate", label: "Start Date", alignRight: false },
    { id: "endDate", label: "End Date", alignRight: false },
    // { id: "mode", label: "Mode", alignRight: false },
    { id: "amount", label: "Amount", alignRight: false },
    // { id: "language", label: "Language", alignRight: false },
    // { id: "Status", label: "Status", alignRight: false },
  ];

  //** table head ends  */

  // Handle search input changes
  const handleSearchInputChange = (event) => {
    const searchValue = event.target?.value;
    setSearchInput(searchValue);
    console.log("hi");
    // If the search input is empty, show all batch cards
    if (searchValue === "") {
      setFilteredBatchInfo(batchInfo);
    } else {
      // Filter the batch information based on the search input
      const filteredData = batchInfo.filter(
        (item) =>
          item?.name &&
          item?.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setPage(0);
      console.log(filteredData);
      setFilteredBatchInfo(filteredData);
    }
  };

  return (
    <Card pr={3} pl={3} className="px-4">
      <Stack
        pr={3}
        direction="row-reverse"
        justifyContent="space-between"
        alignItems="center"
      >
        <TextField
          // label="Search"
          placeholder="Search Name"
          variant="outlined"
          margin="normal"
          value={searchInput}
          onChange={(e) => handleSearchInputChange(e)}
          sx={{
            width: "300px",
            height: "15px",
            marginBottom: "70px",
            "& label": {
              color: "black",
            },
            "& fieldset": {
              borderColor: "black",
            },
          }}
        />
      </Stack>

      <Scrollbar>
        <TableContainer
          style={{ textWrap: "nowrap" }}
          className="text-nowrap"
          sx={{ minWidth: 800 }}
        >
          <Table>
            <UserListHead
              order={order}
              orderBy="batch_name"
              headLabel={TABLE_HEAD}
              rowCount={filteredBatchInfo?.length}
              noWrap
            />

            <TableBody>
              {(filteredBatchInfo || batchInfo)
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const {
                    batch_name,
                    charges,
                    discount,
                    starting_date,
                    ending_date,
                    exam_type,
                    is_active,
                    language,
                    mode,
                  } = row;

                  // console.log('row',row)
                  // const isItemSelected = selected.indexOf(name) !== -1;
                  // row.sno = index + 1 + rowsPerPage * page
                  // debugger;
                  // console.log( is_active + " "+ typeof(is_active))
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          `/dashboard/studentBatches/studentList/${
                            is_active ? "1" : "0"
                          }/${row?.id}`
                        )
                      }
                      // selected={isItemSelected}
                      // aria-checked={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                  <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, StudentName)} />
                </TableCell> */}
                      <TableCell>
                        <Typography variant="" noWrap>
                          {/* {index + 1} */}
                          {/* {row.sno} */}
                          {index + 1 + rowsPerPage * page}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="" noWrap>
                          {row.name}
                        </Typography>
                      </TableCell>

                      <TableCell component="th" scope="row" pl={2}>
                        <Typography
                          variant=""
                          className="flex justify-center"
                          noWrap
                        >
                          {/* {row?.students?.length} */}
                          {countUniqueElements(row?.students)}
                          {/* {row?.students} */}
                        </Typography>
                      </TableCell>

                      <TableCell component="th" scope="row" pl={2}>
                        <Typography variant="" noWrap>
                          {starting_date.substring(0, 10)}
                        </Typography>
                      </TableCell>

                      <TableCell component="th" scope="row" pl={2}>
                        <Typography variant="" noWrap>
                          {ending_date ? ending_date.substring(0, 10) : "N/A"}
                        </Typography>
                      </TableCell>

                      {/* <TableCell component="th" scope="row" pl={2}>
                        <Typography variant="" noWrap>
                          {mode}
                        </Typography>
                      </TableCell> */}

                      <TableCell component="th" scope="row" pl={2}>
                        <Typography variant="" noWrap>
                          {charges}
                        </Typography>
                      </TableCell>

                      {/* <TableCell component="th" scope="row" pl={2}>                              
                        <Typography variant="" noWrap>
                          {language}
                        </Typography>
                    </TableCell>

                    

                    <TableCell component="th" scope="row" pl={2}>                              
                        <Typography variant="" noWrap>
                          <div className={`px-2 py-1 border-[1px] text-center rounded-md ${is_active?"text-green-500  border-green-500":"text-red-500 border-red-500"}`}>
                          {is_active?"Active":"Inactive"}
                          </div>
                        </Typography>
                    </TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredBatchInfo?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
