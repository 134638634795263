import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
  Grid,
  TextField,
  IconButton,
  Paper,
  Chip,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@emotion/react";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { toast, ToastContainer } from "react-toastify";
import { set } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import { getFileUrl } from "../../../../utils/firebaseUtils";
import { Delete } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import RHFTextEditor from "../../../../components/hook-form/RHFTextEditor";


import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../../components/hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const UpdateManualTest = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const resultOption = [
    {
      id: "manual",
      title: "Manual",
    },
    {
      id: "automatic",
      title: "Automatic",
    },
  ];
  const streamCategory = [
    {
      id: "class",
      title: "School Exams",
    },
    {
      id: "central-exam",
      title: "Central Exams",
    },
    {
      id: "state-exam",
      title: "State Exams",
    },
  ];

  const [focus, setFocused] = React.useState(false);
  const [focusTwo, setFocusedTwo] = React.useState(false);
  const [focusThree, setFocusedThree] = React.useState(false);
  const [showStartingDate, setShowStartingDate] = React.useState(false);
  const [showEndingDate, setShowEndingDate] = React.useState(false);

  const [showBanner, setShowBanner] = React.useState(false);
  const [val, setVal] = useState(false);
  const [file, setFile] = useState();
  const [selectedResultType, setSelectedResultType] = useState("");
  const [negativeMark, setNegativeMark] = useState("true");
  const [description, setDescription] = useState("");

  const [category, setCategory] = useState("");
  const [exam, setExam] = useState("");
  const [selectedSubject, setSelectedSubject] = useState([]);

  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [states, setStates] = useState();
  const [boards, setBoards] = useState();
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);

  const [subjectList, setSubjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [languages, setLanguages] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedMedium, setSelectedMedium] = useState("");
  const [course, setCourseName] = useState("");
  const [selSubject, setSelSubject] = useState([]);
  const [goal, setGoal] = useState("");
  const [nagetive, setNagetive] = useState("");









  const token = localStorage.getItem("token");
  async function fetchSubjects() {
    setLoading(false);
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-subject-by-goal`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          course: selectedCategory,
          language: selectedLanguage,
          class_exam_id: goal,

        }),
      }
    );
    const resSubject = await res.json();
    console.log("This is subject", resSubject);
    const filteredSubjects = resSubject.data.map((subject) => ({
      id: subject.subject_id,
      name: subject.subject_name,
    }));
    setSubjectList(filteredSubjects);
    setLoading(false);
  }

  useEffect(() => {
    fetchSubjects();
  }, [goal, selectedLanguage]);

  // #region fetch Chapter acc to subject

  async function fetchChapters(subjectIds) {
    setLoading(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-multiple-chapter-by-subject-id`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            subject_id: subjectIds,
            course: selectedCategory,
            exam_id: goal,

          }),
        }
      );
      const resChapter = await res.json();
      const filteredChapters = resChapter.data.map((chapter) => ({
        id: chapter.chapter_id,
        name: chapter.name,
        questions: chapter.count,
      }));
      return filteredChapters;
    } catch (error) {
      console.error("Error fetching chapters:", error);
      return [];
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchChapters(selSubject).then((chapters) => {
      setSubjects((prevSubjects) =>
        prevSubjects.map((subject) =>
          subject.subject_id === selSubject
            ? { ...subject, chapterOptions: chapters }
            : subject
        )
      );
    });
    // fetchChapters(selSubject);
  }, [selSubject, goal, selectedLanguage]);



  const handleAdd = () => {
    setSubjects([
      ...subjects,
      {
        id: Date.now(),
        name: "",
        subject_id: [],
        chapter_id: [],
        chapterOptions: [],
      },
    ]);
  };

  const handleDelete = async (id) => {
    setSubjects(subjects.filter((subject) => subject.id !== id));
    console.log("Data ", id)
    const resp = await axios.delete(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/template-subjects/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Deleted ", resp.data.data);
  };

  const handleChange = (id, key, value) => {
    setSubjects(
      subjects.map((subject) =>
        subject.id === id ? { ...subject, [key]: value } : subject
      )
    );
    if (key === "subject_id") {
      setSelSubject(value);
    }
  };

 




  const handleAddSubjects = () => {
    setSubjects([
      ...subjects,
      {
        name: "",
        subject_id: [],
      },
    ]);
  };

  const handleSubjectChange = (index, name, value) => {
    const newSubjects = subjects.map((subject, i) =>
      i === index ? { ...subject, [name]: value } : subject
    );
    setSubjects(newSubjects);
  };

  const handleSelectChange = (index, value) => {
    const newSubjects = subjects.map((subject, i) =>
      i === index ? { ...subject, subject_id: value } : subject
    );
    setSubjects(newSubjects);
  };

  const handleSubjectDelete = (index) => {
    const newSubjects = subjects.filter((_, i) => i !== index);
    setSubjects(newSubjects);
  };


  async function fetchSubjects() {
    const token = localStorage.getItem("token");

    setLoading(false);
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-subject-by-goal`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          course: selectedCategory,
          language: selectedLanguage,
          class_exam_id: goal,

        }),
      }
    );
    const resSubject = await res.json();
    console.log("This is subject", resSubject);
    const filteredSubjects =
      resSubject?.data?.map((subject) => ({
        id: subject.subject_id,
        name: subject.subject_name,
      })) || [];

    setSubjectList(filteredSubjects);
    setLoading(false);
  }

  useEffect(() => {
    if (selectedCategory && (goal)) {
      fetchSubjects();
    }
  }, [goal, selectedLanguage]);



  async function fetchSelectedChapters(subjectIds, category) {
    setLoading(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-multiple-chapter-by-subject-id`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            subject_id: subjectIds,
            course: category,
            exam_id: goal,

          }),
        }
      );
      const resChapter = await res.json();
      const filteredChapters = resChapter.data.map((chapter) => ({
        id: chapter.chapter_id,
        name: chapter.name,
        questions: chapter.count,
      }));
      return filteredChapters;
    } catch (error) {
      console.error("Error fetching chapters:", error);
      return [];
    } finally {
      setLoading(false);
    }
  }

  const handleCategoryChange = async (event) => {
    const selectedCategoryId = event.target.value;
    console.log(selectedCategoryId);
    console.log(event.target.value);
    setStates(null);
    // setBoards(null);
    setSubCategories(null);
    setSelectedBoard(null);
    setSelectedState(null);

    setIsCategoryLoading(true);
    setSelectedCategory(selectedCategoryId);

    const token = localStorage.getItem("token");
    switch (selectedCategoryId) {
      case "central-exam":
        const centralExams = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-central-exams`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSubCategories(centralExams?.data?.data);
        break;

      case "state-exam":
        const stateList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-states`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(stateList?.data?.data);
        setStates(stateList?.data?.data);
        break;

      case "class":
        const boardsList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-boards`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(boardsList?.data?.data)
        // setBoards(boardsList?.data?.data);
        break;

      default:
        break;
    }
    // Fetch subcategories based on the selected category
    setIsCategoryLoading(false);
  };

  const handleStateChange = async (e) => {
    setIsCategoryLoading(true);
    setSelectedState(e.target.value);
    const currentState = e.target.value;
    setSubCategories(null);
    const stateExamsList = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-state-exams`,
      { state_exam_id: currentState }
    );
    console.log(stateExamsList.data);
    setSubCategories(stateExamsList.data?.data);
    setIsCategoryLoading(false);
  };

  const handleBoardChange = async (e) => {
    setIsCategoryLoading(true);
    const currentBoard = e.target.value;
    setSelectedBoard(e.target.value);
    setSubCategories(null);
    const boardExamsList = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-classes`,
      { board_id: currentBoard }
    );
    console.log(boardExamsList.data);
    setSubCategories(boardExamsList.data?.data);
    setIsCategoryLoading(false);
  };

  const handleSubCategoryChange = (e) => {
    setClass(null);
    setStateExam(null);
    setCentralExam(null);

    if (boards) setClass(e.target.value);
    else if (states) setStateExam(e.target.value);
    else setCentralExam(e.target.value);

    console.log("boards", classVal);
    console.log("states", stateExam);
    console.log("central", centralExam);
  };

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required("Title is required"),
    // starting_date: Yup.date().required("Starting date is required"),
    // ending_date: Yup.date().required("Ending date is required"),
    duration: Yup.number()
      .required("Duration is required")
      .positive("Duration must be a positive number"),
    mark_each_question: Yup.number()
      .required("Marks per question is required")
      .positive("Marks per question must be a positive number"),
    //  negative_mark: Yup.number().when("negative_marking", {
    //   is: "true",
    //   then: Yup.number()
    //     .required("Negative marks per question is required")
    //    .positive("Must be a positive number"),
    // }),
    // category: Yup.string().required("Exam category is required"),
    // exam: Yup.string().required("Exam is required"),
    // subjects: Yup.array().min(1, "Please select at least one subject"),
  });
  const defaultValues = {
    name: "",
    starting_date: "",
    ending_date: "",
    duration: "",
    mark_each_question: "",
    nagetiveMarks: "",
    negative_marking: "true",
    category: "",
    exam: "",
    subjects: [],
    description: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),

    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const changeHandler = async (event) => {
    const url = await getFileUrl(event.target.files[0])
    setFile(url);
  };

  const handleResultTypeChange = (event) => {
    setSelectedResultType(event.target.value);
  };
  const handleSubjects = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSubject(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  function formatDateToYYYYMMDD(date) {
    if (!date || !(date instanceof Date)) {
      console.error("Invalid date object:", date);
      return null; // Return null or handle as needed
    }

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const { manualId } = useParams();
  const [examName, setExamName] = useState("");
  const [boardName, setBoardName] = useState("");
  const [stateExamName, setStateExamName] = useState("");
  const [className, setClassName] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchManualTest() {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/mock-test/${manualId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await res.json();
      console.log(response);
      const data = response.data;
      console.log(data);
      reset({
        name: data?.title,
        starting_date: data?.starting_date?.slice(0, 10),
        ending_date: data?.end_date?.slice(0, 10),
        duration: data?.duration,
        mark_each_question: data?.eachQuestionMarks,
        nagetiveMarks: parseInt(data?.nagetiveMarking),
        negative_marking: data?.negative_marking,
        subCategory: data?.subCategory,
      });
      setSelectedResultType(data?.result_type);
      setSelectedCategory(data?.course);

      // setCentralExam(data?.exam_id);
      // setBoardName(data?.boards_name);
      // setStateExamName(data?.state_exam_name);
      // setClassName(data?.classes_name);

      setSelectedLanguage(data?.language);
      setSelectedMedium(data?.medium);

      setExam(data?.exam_name);
      setClass(data?.classes_name);
      setCourseName(data?.course);
      setBoards(data?.data?.boards_name);
      setStateExam(data?.state_exam_name);
      setGoal(data?.exam_id || data?.class_id);

      setNegativeMark(data?.nagetiveMarks);
      setNagetive(data?.nagetiveMarking);
      setClassName(data?.classes_name);





      // setSelectedSubject(data?.subject_id);
      setFile(data?.image);
      setDescription(data?.description);
      if (selectedCategory == "central-exam") {
        const token = localStorage.getItem("token");
        const centralExams = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-central-exams`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSubCategories(centralExams?.data?.data);
      } else if (selectedCategory == "state-exam") {
        const token = localStorage.getItem("token");
        const stateList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-states`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(stateList?.data?.data);
        setStates(stateList?.data?.data);
      } else if (selectedCategory == "class") {
        const token = localStorage.getItem("token");
        const boardsList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-boards`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(boardsList?.data?.data)
        setBoards(boardsList?.data?.data);
      }
      // if (boards || states || classVal) {
      setStateExam(data?.state_exam_name);
      setSelectedState(data?.state_exam_id);
      setSelectedBoard(data?.board_id);
      setExamName(data?.exam_name);
      setClass(data?.class_id);
      setSelectedSubject(
        // data?.subject_id.split(",").map((item) => parseInt(item))
        data?.subject_id?.map((item) => item?.id)
      );
      if (selectedCategory && (centralExam || stateExam || classVal)) {
        fetchSubjects();
      }


      if (selectedCategory && (centralExam || stateExam || classVal)) {
        fetchSubjects();
      }

      // if (selectedCategory) {
      const formattedSubjects = await Promise.all(
        data?.subjects?.map(async (subject) => {
          const chapters = await fetchSelectedChapters(
            subject?.subject_id.split(","),
            data?.course
          );
          return {
            id: subject?.id,
            name: subject?.subject_name,
            subject_id: subject?.subject_id.split(",").map(Number),
            chapter_id: subject?.chapters.map((chapter) => ({
              _id: chapter?.id,
              id: chapter?.chapter_id,
              question_count: chapter?.question_count,
            })),
            chapterOptions: chapters,
          };
        })
      );
      console.log(formattedSubjects);

      setSubjects(formattedSubjects);
      // }
    }
    fetchManualTest();
  }, []);

  const onSubmit = async (data) => {
    const formattedStartingDate = formatDateToYYYYMMDD(data.starting_date);
    const formattedEndingDate = formatDateToYYYYMMDD(data.ending_date);
    // console.log(data);
    // console.log("selectedResult", selectedResultType);
    // console.log("slectedSubject", selectedSubject);
    // console.log("Category", category);
    // console.log("exam", exam);

    setLoading(true);

    const payload = {
      title: data.name,
      image: file,
      starting_date: formattedStartingDate,
      ending_date: formattedEndingDate,
      duration: data.duration,
      eachQuestionMarks: data.mark_each_question,

      nagetiveMarks: negativeMark,
      nagetiveMarking: nagetive,
      subject_id: selectedSubject,
      result_type: selectedResultType,
      is_active: true,
      language: "English",
      language: selectedLanguage,
      medium: selectedMedium,
      instructions: "Please follow the instructions carefully.",
      attempted: 0,
      mock_test_type: 1,
      // subject_name: "Physics",
      subject_id: [],
      subjects: subjects,
      course: selectedCategory,
      state_exam_id: selectedState,
      board_id: selectedBoard,
      exam_id: centralExam || stateExam,
      class_id: classVal,
      subCategory: data?.subCategory,
      description: description,
    };
    console.log(payload);

    const token = localStorage.getItem("token");
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-mock-test/${manualId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    setLoading(false);
    const response = await res.json();
    if (response?.response_code === 200) {
      toast.success("Manual Test Updated Successfully");
      setTimeout(() => {
        // window.location.reload();
        navigate("/dashboard/mockTest");
      }, 1000);
    }
    console.log(response);
  };

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const languageData = await axios.post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (languageData?.status !== 200)
        throw new Error("Failed to fetch languages");

      setLanguages(languageData?.data?.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);

  return (
    <Page title="Mock Test">
      <Box sx={{ p: 3, maxWidth: 900, ml: 3 }}>
        <Typography variant="h2" sx={{ mb: 3 }}>
          Update Manual Test
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <Typography variant="h6" marginBottom={"0.5rem"}>
              Test Title
            </Typography>
            <RHFTextField name="name" label="Enter Test Title" />
          </Stack>
          <Stack
            direction={"row"}
            marginTop={"0.5rem"}
            sx={{ width: "100%" }}
            justifyContent={"space-between"}
          >
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Test Banner
              </Typography>

              <RHFTextField
                name="file"
                error={!file && val}
                onChange={changeHandler}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                helperText="Image with a size of 712x356 pixels, maintaining a 2:1 aspect ratio (jpeg,jpg,png,webp)"
                inputProps={{ accept: "image/*" }}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                label="Choose an Image"
                onClick={() => setShowBanner(true)}
                type={showBanner || focus ? "file" : "text"}
              />
              {!file && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    marginTop: "5px",
                    marginLeft: "10px",
                    color: "red",
                  }}
                ></Typography>
              )}
            </Stack>

            <Stack direction={"column"} sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Result Type
              </Typography>
              <RHFTextField
                name="result_type"
                label="Select Result Type"
                sx={{ width: "100%" }}
                select
                value={selectedResultType}
                onChange={handleResultTypeChange}
              >
                {resultOption?.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Stack>
          </Stack>

          <Stack direction={"row"} marginTop={"0.5rem"} sx={{ width: "100%" }}>
            {file}
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
            spacing={2}
          >
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Language
              </Typography>
              <RHFTextField
                name="language"
                select
                disabled
                SelectProps={{
                  native: true,
                }}
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
              >
                {languages.map((language) => (
                  <option key={language.id} value={language.medium_code}>
                    {language.medium_name}
                  </option>
                ))}
              </RHFTextField>
            </Stack>
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Medium
              </Typography>
              <RHFTextField
                name="medium"
                select
                SelectProps={{
                  native: true,
                }}
                value={selectedMedium}
                onChange={(e) => setSelectedMedium(e.target.value)}
              >
                {languages.map((language) => (
                  <option key={language.id} value={language.id}>
                    {language.medium_name}
                  </option>
                ))}
                <option value={3}>Both</option>
              </RHFTextField>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
            spacing={2}
          >
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Start Date
              </Typography>
              <RHFTextField
                name="starting_date"
                // onFocus={() => setFocusedTwo(true)}
                // onBlur={() => setFocusedTwo(false)}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                label="Starting Date"
                // onClick={() => setShowStartingDate(true)}
                type={showStartingDate || focusTwo ? "date" : "text"}
              />
            </Stack>
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                End Date
              </Typography>
              <RHFTextField
                name="ending_date"
                // onFocus={() => setFocusedThree(true)}
                // onBlur={() => setFocusedThree(false)}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                label="Ending Date"
                // onClick={() => setShowEndingDate(true)}
                type={showEndingDate || focusThree ? "date" : "text"}
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
            spacing={2}
          >
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Test Duration (in Minutes)
              </Typography>
              <RHFTextField name="duration" label="Enter Duration" />
            </Stack>
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Marks per Question
              </Typography>
              <RHFTextField name="mark_each_question" label="Enter Marks" />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
            spacing={2}
          >
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Negative Marking Available
              </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="negative_marking"
                onChange={(e) => setNagetive(e.target.value)}
                value={nagetive}
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </Stack>
            {nagetive === "1" && (
              <Stack direction="column" sx={{ width: "49%" }}>
                <Typography variant="h6" marginBottom={"0.5rem"}>
                  Negative Marks Per Question
                </Typography>
                <RHFTextField
                  value={negativeMark}
                  onChange={(e) => setNegativeMark(e?.target?.value)}
                  name="negative_mark"
                  label="Enter Marks"
                />
              </Stack>
            )}
          </Stack>
          <Stack direction={"row"} spacing={3}>
            <RHFTextField
              name="category"
              label="Category"
              sx={{ width: "50%" }}
              // select
              disabled
              value={course}
            // onChange={handleCategoryChange}
            />

            {stateExam && (
              <RHFTextField
                name="states"
                label="State"
                sx={{ width: "50%" }}
                disabled
                value={stateExam}
              // onChange={handleStateChange}
              />
            )}

            {boards && (
              <RHFTextField
                name="boards"
                label="Boards"
                sx={{ width: "50%" }}
                // select
                disabled
                value={boards}
              // onChange={handleBoardChange}
              />
            )}

            <RHFTextField
              disabled
              name="subCategory"
              // label="Subcategory"
              sx={{ width: "50%" }}
              value={exam || className}
            />
          </Stack>
          <Stack>
            {/*
            <Typography variant="h6" marginBottom={"0.5rem"}>
              Subjects
            </Typography>
            <Stack direction="column" sx={{ width: "100%" }}>
              <FormControl>
                <InputLabel>Subjects</InputLabel>
                <Select
                  label="Select Subjects"
                  multiple
                  name="subjects"
                  value={selectedSubject}
                  onChange={handleSubjects}
                  sx={{ width: "100%", color: "black", zIndex: 9 }}
                  MenuProps={MenuProps}
                >
                  {subjectList?.map((name, index) => (
                    <MenuItem
                      key={index}
                      value={name.id}
                      style={getStyles(name, selectedSubject, theme)}
                    >
                      {name?.name || "NA"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedSubject.length == 0 && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    marginTop: "5px",
                    marginLeft: "10px",
                    color: "red",
                  }}
                >
                  {"Please Select Subjects"}
                </Typography>
              )}
            </Stack>
            */}
            <Stack>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Add Subjects
              </Typography>
              {subjects?.map((subject, index) => (
                <Paper
                  key={subject.id}
                  elevation={3}
                  sx={{ p: 2, mb: 2, backgroundColor: "transparent" }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                      label={`Subject ${index + 1} Name`}
                      variant="outlined"
                      value={subject.name}
                      onChange={(e) =>
                      {  console.log("Id", subject.id);
                    handleChange(subject.id, "name", e.target.value);
                      }
                      }
                    fullWidth
                    key={index}
                    />
                    <FormControl fullWidth>
                      <InputLabel>Subjects</InputLabel>
                      <Select
                        multiple
                        value={subject.subject_id}
                        onChange={(e) =>
                          handleChange(subject.id, "subject_id", e.target.value)
                        }
                        renderValue={(selected) => (
                          <Box

                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected?.map((value, selectedIndex) => {
                              const subjectName = subjectList.find(
                                (sub) => sub.id === value
                              )?.name;
                              return (
                                <Chip
                                  key={value}
                                  label={`${selectedIndex + 1}. ${subjectName}`}
                                />
                              );
                            })}
                          </Box>
                        )}
                      >
                        {subjectList?.map((subjectOption, optionIndex) => (
                          <MenuItem
                            key={optionIndex}
                            value={subjectOption.id}
                          >
                            {`${optionIndex + 1}. ${subjectOption.name}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <IconButton
                      onClick={() => handleDelete(subject.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </Paper>
              ))}
              <Button
                variant="contained"
                size="small"
                onClick={handleAdd}
                sx={{ mt: 2, py: 0.5, px: 2, width: "10%" }}
              >
                + Add
              </Button>
            </Stack>
          </Stack>
          {/*
            <Stack>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Description
              </Typography>
              <TextareaAutosize
                placeholder="Write Description Here"
                minRows={8}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </Stack>
         */}
          <Stack>
            <RHFTextEditor
              value={description}
              onChange={(value) => setDescription(value)}
              name="description"
              label="Description"
            />
          </Stack>
          <Stack width={"100%"}>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                width: "100%",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
            >
              Update Manual Test
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </Page>
  );
};

export default UpdateManualTest;
