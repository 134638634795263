import { Box, Typography } from '@mui/material'
import React from 'react'
import Page from '../../../components/Page'
const BoxStyle = {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',

    height: '50vh'
}
const HelpAndSupport = () => {
    return (
        <Page title='Help And Support'>
            <Box>
                <Typography variant='h4' ml={5}>
                    Help And Support
                </Typography>
                <Box sx={{ ...BoxStyle }}>
                    <Typography variant='h4'>To Be Implemented</Typography>
                </Box>
            </Box>
        </Page>
    )
}

export default HelpAndSupport