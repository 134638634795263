import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddPathyakram from './AddPathyakram';
import PathyakramTable from './PathyakramTable';
import { Divider } from '@mui/material';
import Page from '../../../components/Page';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            aria-hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Pathyakram = () => {
    const access = JSON.parse(localStorage.getItem('detailObject')) || {};
    const role = localStorage.getItem('role') || '';
    const accessToContent = access?.accessToContent || [];
    const [value, setValue] = React.useState(()=>{
          if(role=='Admin')return 0;
          if(accessToContent?.includes("Syllabus"))return 0;
          if(accessToContent?.includes("Add Syllabus"))return 1;
          return 0;
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Page title='Syllabus'>
            <Box sx={{ width: '100%' }}>
                <Typography variant='h3' mb={3}>Manage Syllabus</Typography>
                <Divider />
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ width: '100%' }}>
                        {(role === 'Admin' || accessToContent.includes('Syllabus')) && (
                            <Tab value={0} label="All Syllabus" sx={{ width: '100%' }} {...a11yProps(0)} />
                        )}
                        {(role === 'Admin' || accessToContent.includes('Add Syllabus')) && (
                            <Tab value={1} label="Add Syllabus" sx={{ width: '100%' }} {...a11yProps(1)} />
                        )}
                    </Tabs>
                </Box>
                {(role === 'Admin' || accessToContent.includes('Syllabus')) && (
                    <TabPanel value={value} index={0}>
                        <PathyakramTable />
                    </TabPanel>
                )}
                {(role === 'Admin' || accessToContent.includes('Add Syllabus')) && (
                    <TabPanel value={value} index={1}>
                        <AddPathyakram />
                    </TabPanel>
                )}
            </Box>
        </Page>
    );
};

export default Pathyakram;
