import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  TextField,
  MenuItem,
  Input,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';

import SyncLoader from "react-spinners/SyncLoader";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "@mui/material/Select";
import { getFileUrl } from "../../../utils/firebaseUtils";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "S.No", alignRight: false },
  { id: "name", label: "Title", alignRight: false },
  { id: "image_url", label: "Subject Image", alignRight: false },
  // { id: 'created_at', label: 'Date', alignRight: false },
  { id: "is_active", label: "Status", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function SubjectTable() {
  const detailObject = JSON.parse(localStorage.getItem("detailObject"));
  const accessArr = detailObject?.access_level?.split(',');
  const role = localStorage.getItem('role');
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState();
  // const[selectedStatus , setSelectedStatus] = useState();
  const token = localStorage.getItem("token");
  async function fetchUsers() {
    const fullResponse = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/classroom-subjects`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseJson = await fullResponse.json();
    console.log(responseJson?.data);
    usersSet(responseJson.data);
    toast.success("Subjects Fetched Successfully")
    setLoading(false);
  }
  useEffect(() => {
   

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  //console.log(updatedValues)
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page name="Subject">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                {/*<ExportCSV csvData={filteredUsers} fileName="Subject Data" /> */}
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers.length > 0 &&
                        filteredUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((row, index) => {
                            const {
                              id,
                              name,
                              image_url,
                              category,
                              language,
                              created_at,
                              is_active,
                            } = row;
                            const isItemSelected =
                              selected.indexOf(name) !== -1;
                            {
                              /* //console.log('activ statues' + is_active) */
                            }
                            const onChange = (e, row, id) => {
                              const { name, value } = e.target;

                              setUpdatedValues((prev) => {
                                return { ...prev, [name]: value };
                              });
                            };

                            const changeHandler = async (event) => {
                              const url = await getFileUrl(
                                event.target?.files[0]
                              );
                              console.log(url);
                              // setFile(event.);
                              setImageUrl(url);
                            };

                            const handleUpdate = async () => {
                              // setLoading(true);
                              const token = localStorage.getItem("token");
                              const authToken = token;
                              const config = {
                                headers: {
                                  "content-type": "application/json",
                                  Authorization: `Bearer ${authToken}`,
                                },
                              };
                              // const data = {
                              //     name : updatedValues.name,
                              //     is_active : selectedStatus,
                              // }
                              const res = await axios.put(
                                `${process.env.REACT_APP_LIVE_URL_NEW}/admin/classroom-subjects/${id}`,
                                {
                                  ...updatedValues,
                                  image_url: imageUrl || image_url,
                                },
                                config
                              );
                              if (res.data.response_code === 200) {
                                // setLoading(false);
                                toast.success(res.data.message);
                               fetchUsers()
                               setIsEdit(false)
                              } else {
                                toast.error(res.data.message);
                                setLoading(false);
                              }
                            };
                            return (
                              <TableRow
                                hover
                                key={index}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                {/* <TableCell padding="checkbox">
                                                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
                                                        </TableCell> */}
                                <TableCell>
                                  <Typography variant="subtitle2">
                                    {index + 1 + rowsPerPage * page}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {/* <Avatar alt={name} src={avatarUrl} /> */}
                                    <Typography variant="subtitle2" noWrap>
                                      {isEdit && selectedId === id ? (
                                        <Input
                                          onChange={(e) => onChange(e, row, id)}
                                          // value={value}
                                          name="name"
                                          placeholder={name}
                                        />
                                      ) : (
                                        <span>{name}</span>
                                      )}
                                    </Typography>
                                  </Stack>
                                </TableCell>

                                <TableCell align="left">
                                  {" "}
                                  {isEdit && selectedId === id ? (
                                    <>
                                      <input
                                        style={{
                                          display: "none",
                                        }}
                                        id="choose-file"
                                        type="file"
                                        name="file"
                                        onChange={changeHandler}
                                        // onChange={handleChange}
                                      />
                                      <label htmlFor="choose-file">
                                        <FileUploadIcon
                                          sx={{
                                            cursor: "pointer",
                                            "&:hover": {
                                              color: "red",
                                            },
                                          }}
                                        />
                                      </label>
                                    </>
                                  ) : (
                                     <>{
                                      image_url ?
                                     
                                      <img
                                      src={image_url}
                                      alt="NA"
                                      style={{ width: "5rem", height:"3rem", objectFit:"cover" }}
                                    ></img>
                                    :
                                    <Typography variant="subtitle2">
                                    Image not Available
                                  </Typography>
                                     }
                                    </>
                                    
                                    
                                  )}
                                </TableCell>
                                {/* <TableCell align="left">{created_at}</TableCell> */}
                                <TableCell align="left">
                                  {isEdit && selectedId === id ? (
                                    <TextField
                                      onChange={(e) => onChange(e, row)}
                                      // onChange={(e) => setSelectedStatus(e.target.value)}
                                      label={
                                        is_active == "true"
                                          ? "Publish"
                                          : "Draft"
                                      }
                                      name="is_active"
                                      // value={selectedStatus}
                                      placeholder={
                                        is_active == "true"
                                          ? "Publish"
                                          : "Draft"
                                      }
                                      select
                                      sx={{ width: "100px" }}
                                    >
                                      <MenuItem value={"true"}>
                                        Publish
                                      </MenuItem>
                                      <MenuItem value={"false"}>Draft</MenuItem>
                                    </TextField>
                                  ) : (
                                    <span
                                      style={{
                                        padding: "5px",
                                        borderRadius: "10px",
                                        color:
                                          is_active == "true" ? "green" : "red",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {is_active == "true"
                                        ? "published"
                                        : "Draft"}
                                    </span>
                                  )}
                                </TableCell>
                                {isEdit && selectedId === id ? (
                                  <TableCell align="left">
                                    <Button
                                      variant="outlined"
                                      onClick={handleUpdate}
                                    >
                                      Update
                                    </Button>
                                  </TableCell>
                                ) : null}

                                <TableCell onClick={() => setSelectedId(id)}>
                                  <UserMoreMenu
                                    id={id}
                                    req="classroom-subjects"
                                    isEdit={isEdit}
                                    setIsEdit={setIsEdit}
                                    setLoading={setLoading}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
