export const INITIAL_STATE = {
    zoom: false,
    video: false,
    mute: false,
    showControls: false,

};

export const reducer = (state, action) => {
    switch (action.type) {
        case "ZOOMIN":
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case "ZOOMOUT":
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case "VIDEOON":
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case "VIDEOOFF":
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case "MUTE":
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case "UNMUTE":
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case "SHOWCONTROLS":
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case "NOSHOWCONTROLS":
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };


        default:
            return state;
    }
};